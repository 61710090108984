import
{
    AMANLEEK_LEAD_INSERT_LOADING,
    AMANLEEK_LEAD_INSERT_SUCCESS,
    AMANLEEK_LEAD_INSERT_FAILURE
}
from "redux/types";

const initialState = {
    leadInsertLoading: false,
    leadInsertSuccess: null,
    errors: null
}

export const amanleekLeadInsertReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AMANLEEK_LEAD_INSERT_LOADING :
            return {...state, leadInsertLoading: action.payload};
        case AMANLEEK_LEAD_INSERT_SUCCESS :
            return {...state, leadInsertSuccess: action.payload.message};
        case AMANLEEK_LEAD_INSERT_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}