import
{
    UPDATE_POST_LOADING,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAILURE,
    SET_UPDATE_POST_DATA
}
from "redux/types";

import { Dispatch } from "redux";

import { updatePostService } from "services/updatePostService";

import { listSinglelPostService } from "services/listSinglePostService";

export const updatePostAction = (postId: any, data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_POST_LOADING, payload: true});
        const response = await updatePostService(postId, data);
        dispatch({type: UPDATE_POST_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: UPDATE_POST_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_POST_LOADING, payload: false});
    }
}

export const setUpdatePostData = (postId: any) => async (dispatch: Dispatch) => {
    const post = await listSinglelPostService(postId);
    dispatch({type: SET_UPDATE_POST_DATA, payload: post?.data?.data});
}