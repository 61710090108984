import * as React from "react";
import type { SVGProps } from "react";
const SvgBuySellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 41 40"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M20.4 0c-3.9 0-7.8 1.2-11 3.4C6.1 5.5 3.5 8.6 2 12.3.5 15.9.1 19.9.9 23.8c.8 3.9 2.7 7.4 5.4 10.2 2.8 2.8 6.3 4.7 10.2 5.4 3.9.8 7.8.4 11.5-1.1 3.6-1.5 6.7-4.1 8.9-7.3 2.2-3.3 3.3-7.1 3.3-11.1 0-5.3-2.1-10.3-5.8-14.1-3.7-3.7-8.8-5.8-14-5.8zm0 38.5c-3.7 0-7.3-1.1-10.3-3.1-3.1-2-5.4-4.9-6.8-8.3-1.4-3.4-1.8-7.1-1.1-10.7.7-3.6 2.5-6.9 5.1-9.5s5.9-4.4 9.5-5.1c3.6-.7 7.3-.3 10.7 1.1 3.4 1.4 6.3 3.8 8.3 6.8 2 3.1 3.1 6.6 3.1 10.3 0 4.9-2 9.6-5.4 13.1-3.5 3.4-8.2 5.4-13.1 5.4z" />
    <path d="M23.6 19.5c-.7-.3-1.5-.6-2.3-.7h-.4c-.6-.1-1.2-.3-1.9-.5-.4-.1-.8-.4-1.1-.7-.3-.4-.5-.9-.4-1.4 0-.4 0-.7.2-1.1.1-.3.3-.6.6-.9.7-.5 1.5-.7 2.3-.6.6 0 1.1.1 1.6.3.6.2 1.2.5 1.7.9l1.5-2c-.5-.3-1-.6-1.6-.9-.5-.2-1-.4-1.6-.5-.3-.1-.5-.1-.8-.1v-2H19v2c-.5.1-1.1.2-1.6.5-.8.3-1.5.9-1.9 1.7-.4.9-.7 1.9-.6 2.9-.1 1 .2 1.9.7 2.7.4.6 1 1.1 1.7 1.3.8.3 1.7.5 2.6.7h.4c.6.1 1.1.2 1.6.4.4.2.8.4 1 .7.3.4.4.9.4 1.3s0 .7-.2 1.1c-.1.3-.4.7-.6.9-.7.5-1.6.7-2.5.7-.8 0-1.5-.2-2.2-.5-.7-.4-1.4-.9-1.9-1.5l-1.6 1.9c.5.5 1.1 1 1.7 1.4.6.4 1.2.6 1.9.8.4.1.8.2 1.2.2v2h2.4v-2c.6-.1 1.2-.2 1.8-.5.8-.3 1.5-.9 1.9-1.7.5-.8.7-1.8.6-2.8 0-.9-.2-1.9-.6-2.7-.4-.5-.9-1-1.6-1.3" />
  </svg>
);
export default SvgBuySellIcon;
