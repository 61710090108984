import {
  SEND_MESSAGE_LOADING,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
} from "redux/types";

import { sendAMessageService } from "services/sendAMessageService";

import { Dispatch } from "redux";

export const sendMessageAction =
  (post_title: string, email: string, message: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: SEND_MESSAGE_LOADING, payload: true });
      const response = await sendAMessageService(post_title, email, message);

      dispatch({ type: SEND_MESSAGE_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: SEND_MESSAGE_FAILURE, payload: err });
    } finally {
      dispatch({ type: SEND_MESSAGE_LOADING, payload: false });
    }
  };
