import
{
    TERMS_OF_USE_LOADING,
    TERMS_OF_USE_SUCCESS,
    TERMS_OF_USE_FAILURE
}
from "redux/types";

const initialState = {
    termsOfUseLoading: false,
    termsOfUse: null,
    errors: null
}

export const termsOfUseReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TERMS_OF_USE_LOADING :
            return {...state, termsOfUseLoading: action.payload};
        case TERMS_OF_USE_SUCCESS :
            return {...state, termsOfUse: action.payload.data};
        case TERMS_OF_USE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}