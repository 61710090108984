import styles from "components/V3/FeaturesCard/FeaturesCard.module.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface FeaturesCardProps {
  featuresData: any;
  isMobile: boolean;
}

const FeaturesCard: FC<FeaturesCardProps> = ({
  featuresData,
  isMobile = false,
}) => {
  const { t } = useTranslation();

  return (
    <div
      dir="rtl"
      className={`${styles.shadow} w-full flex flex-col items-start justify-start gap-y-3 rounded-[0.625rem] px-3 py-3`}
    >
      <p className={`text-[1.125rem]`}>{t("homepage_features")}</p>
      <div className="w-full h-full flex flex-row items-center justify-start flex-wrap gap-x-2 gap-y-2">
        {featuresData?.map((item: any) => {
          return (
            <div
              className={`${styles.blueBackground} ${
                isMobile ? "h-[1.75rem]" : "h-[2.4375rem]"
              } px-4 rounded-[0.3125rem] flex items-center justify-center`}
            >
              <p className={isMobile ? "text-[0.75rem]" : ""}>{item}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturesCard;
