import
{
    BRANCH_TYPES_LOADING,
    BRANCH_TYPES_SUCCESS,
    BRANCH_TYPES_FAILURE
}
from "redux/types";

const initialState = {
    branchTypesLoading: false,
    branchTypes: null,
    errors: null
};

export const listBranchTypesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case BRANCH_TYPES_LOADING :
            return {...state, branchTypesLoading: action.payload};
        case BRANCH_TYPES_SUCCESS :
            return {...state, branchTypes: action.payload.data};
        case BRANCH_TYPES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}