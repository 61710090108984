import * as React from "react";
import type { SVGProps } from "react";
const SvgWorldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm6.667 8a6.625 6.625 0 0 1-1.108 3.672l-.795-.795a.333.333 0 0 1-.097-.236V10a2 2 0 0 0-2-2h-2A.667.667 0 0 1 8 7.333V7a.333.333 0 0 1 .333-.333A1.667 1.667 0 0 0 10 5v-.667A.333.333 0 0 1 10.333 4h.92a1.677 1.677 0 0 0 1.178-.488l.251-.251A6.647 6.647 0 0 1 14.667 8Zm-13.301.642 2.105 2.105a1.985 1.985 0 0 0 1.415.586h1.78a.667.667 0 0 1 .667.667v2.633a6.677 6.677 0 0 1-5.967-5.991Zm7.3 5.99V12a2 2 0 0 0-2-2h-1.78a.666.666 0 0 1-.472-.195L1.442 6.833A6.652 6.652 0 0 1 11.637 2.42l-.149.15a.338.338 0 0 1-.235.097h-.92a1.667 1.667 0 0 0-1.666 1.666V5a.333.333 0 0 1-.334.333A1.667 1.667 0 0 0 6.667 7v.333a2 2 0 0 0 2 2h2a.667.667 0 0 1 .666.667v.641c.002.442.177.865.488 1.178l.892.892a6.648 6.648 0 0 1-4.046 1.922Z"
    />
  </svg>
);
export default SvgWorldIcon;
