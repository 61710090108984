import api from "api";
import { listProfilePostsEndPoint } from "api/apiUrls";
import store from "redux/store";

export const listProfilePostsService = async (postStatus: string, postCatgeoryId: any) => {
    const response = await api.get(
        listProfilePostsEndPoint(postStatus, postCatgeoryId),
        {
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' :  `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );
    return response;
}