import styles from "screens/V3/StoreScreen/DesktopStoreScreen/DesktopStoreScreen.module.css";
import { Spin, Tooltip } from "antd";
import { useLanguage } from "hooks/useLanguage";
import StoreCard from "components/V3/StoreCard/StoreCard";
import CartItem from "components/V3/CartItem/CartItem";
import { useUtilities } from "hooks/useUtilities";
import { AnimatePresence, motion } from "framer-motion";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FC } from "react";

interface StoreScreenProps {
  useStore: any;
}

const StoreScreen: FC<StoreScreenProps> = ({ useStore }) => {
  const { t } = useUtilities();
  const { textDirectionStyle } = useLanguage();
  const {
    // CONSTANTS
    cartContainerRef,
    allProducts,
    infoText,
    // STATES
    cartTotal,
    storeCart,

    currentProductID, // when selecting a product (باقة) to show its details
    setCurrentProductID,

    details, // the details of the selected product (باقة)
    setDetails,
    // FUNCTIONS
    deselectProduct,
    updateInCart,
    resetCart,
    getActiveState,
    handleCheckout,
    getPriceAfterDiscount,
    getFormattedDiscount,
  } = useStore;

  return (
    <div
      className="w-full flex justify-center"
      style={{
        minHeight: "calc(100vh - var(--nav-bar-height))",
        // paddingInline: "20rem",
        paddingBlock: "1.25rem",
      }}
      onClick={(e: any) => {
        if (
          e.target.getAttribute("data-store-selected-product") !==
          "dontDeselectProduct"
        ) {
          deselectProduct();
        }
      }}
    >
      <div className="contentWidth flex justify-center gap-3">
        <div className="flex-grow h-full">
          <p
            className={`${styles.infoHeading} flex items-center gap-4 shadow-focus`}
          >
            {/* INFO HEADING */}
            {infoText}
          </p>
          {/* LEFT CONTAINER */}
          <div className="border-round-s min-h-[49.85rem] p-[0.9rem] pb-12 shadow-focus">
            {allProducts.map((section: any) => (
              <div className="w-full">
                {/* HEADING */}
                <p
                  className={`${styles.containerHeading} flex items-center gap-4`}
                >
                  {section?.info && (
                    <Tooltip
                      className="cursor-pointer"
                      title={<p dir={textDirectionStyle}>{section?.info}</p>}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  )}
                  <span>{section?.productType}</span>
                </p>

                {/* PRODUCT CARDS */}
                <div
                  dir={textDirectionStyle}
                  className="w-full flex flex-wrap gap-4 mb-3"
                >
                  {section.products.map((product: any, idx: any) => (
                    <StoreCard
                      key={idx}
                      product={product}
                      priceAfterDiscount={getPriceAfterDiscount(product)}
                      cartItem={
                        storeCart.find(
                          (cartItem: any) =>
                            cartItem.product.data_id === product.data_id
                        ) || null
                      }
                      updateInCart={(quantity: number) => {
                        updateInCart(product, quantity);
                      }}
                      activeState={getActiveState(product)}
                      detailsState={[currentProductID, setDetails]}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* RIGHT CONTAINER */}
        <div
          className="w-1/3 flex flex-col gap-3"
          style={{ minWidth: "33.333333333%" }}
        >
          <div
            dir={textDirectionStyle}
            className="h-[13.0625rem] pt-4 shadow-focus border-round-s bg-white"
            style={{
              paddingInline: "1.25rem",
              paddingBottom: "0.5rem",
              border: "1px solid var(--new-blue)",
            }}
          >
            {/* TOP - DETAILS SECTION */}
            <div className="storeDontDeselect w-full h-full flex flex-col overflow-auto">
              <p className="text-[var(--new-blue)] mb-1">تفاصيل الباقة :</p>
              <AnimatePresence>
                {details?.length > 0 ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, curve: "easeInOut" }}
                  >
                    <ul>
                      {details.map((item: any, index: any) => (
                        <div className="flex gap-1 mb-1">
                          <span
                            className="text-[1.5rem]"
                            style={{ lineHeight: "1.25rem" }}
                          >
                            •
                          </span>
                          <li key={index}>{item}</li>
                        </div>
                      ))}
                    </ul>
                  </motion.div>
                ) : (
                  <motion.p
                    className="w-full souq-large opacity-20 flex-grow flex items-center justify-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.2 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, curve: "easeInOut" }}
                  >
                    {t("choose_product")}
                  </motion.p>
                )}
              </AnimatePresence>
            </div>
          </div>

          {/* BOTTOM - CART ITEMS */}
          <div className="max-h-[41.9375rem] p-[0.9rem] shadow-focus border-round-s flex flex-col justify-between">
            <div className="w-full min-h-[27.5rem]">
              <p className={`${styles.containerHeading}`}>ملخص الطلب</p>
              <div
                dir={textDirectionStyle}
                ref={cartContainerRef}
                className="flex flex-col gap-[0.62rem] max-h-[20rem] pb-3 overflow-auto"
              >
                {storeCart?.map((item: any, idx: any) => (
                  // <AnimatePresence>
                  <motion.div
                    className="storeDontDeselect cursor-pointer"
                    key={item.product.data_id} // Assuming 'item' has a unique property 'id'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, delay: idx * 0.1 }}
                    onClick={() => {
                      if (currentProductID !== item.product.data_id) {
                        setCurrentProductID(item.product.data_id);
                        setDetails(item.product.details);
                      } else {
                        deselectProduct();
                      }
                    }}
                    onMouseOver={() => {
                      if (!currentProductID) {
                        setDetails(item.product.details);
                      }
                    }}
                    onMouseLeave={() => {
                      if (!currentProductID) {
                        setDetails([]);
                      }
                    }}
                  >
                    <CartItem item={item} />
                  </motion.div>
                  // </AnimatePresence>
                ))}
              </div>
            </div>
            <a onClick={resetCart}>{t("reset_cart")}</a>
            <div className={`divider`} />
            <div className="w-full flex item-center justify-between">
              <p dir="rtl" className="flex justify-end">
                {cartTotal}ج
              </p>
              <p>الاجمالي</p>
            </div>
            <div className={`divider`} />
            <a
              onClick={() => {
                handleCheckout(storeCart);
              }}
              className={`${styles.checkoutContainer} shadow-focus`}
            >
              أتمام الشراء
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreScreen;
