import api from "api";
import { whatsappLoginEndPoint } from "api/apiUrls";

export const whatsappOtpService = async (data: any, captcha: string) => {
    const response = await api.post(
        whatsappLoginEndPoint(),
        {
            mobile: data,
            captcha: captcha
        },
        {
            headers: {
                "Content-Type" : "application/json"
            }
        }
    );

    return response;
}