import styles from "components/Footer/Footer.module.css";
import CustomButton from "components/CustomButton/CustomButton";
import {
  UpOutlined,
  FacebookFilled,
  WhatsAppOutlined,
  YoutubeFilled,
  TwitterOutlined,
  LinkedinFilled,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useTranslation } from "react-i18next";
import {
  FooterInstagram,
  FooterTwitter,
  FooterFacebook,
  FooterYoutube,
} from "assets/IconComponents";
import { listCarMakerAction } from "redux/actions/CarMakerActions/listCarMakerAction";
import { Spin } from "antd";
import { useLanguage } from "hooks/useLanguage";
import { useResponsive } from "hooks/useResponsive";

interface FooterProps {
  style?: any;
}

const Footer: FC<FooterProps> = ({ style }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const { isArabic, currentLanguage, textDirectionStyleFlipped } =
    useLanguage();
  const { isMobile } = useResponsive();
  const [isColumn, setIsColumn] = useState(false);
  const [textAlignment, setTextAlignment] = useState<any>();

  const { carMakers } = useSelector(
    (state: RootState) => state.listCarMakerReducer
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth > 1023 ? setIsColumn(false) : setIsColumn(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1023) {
      setIsColumn(true);
    }

    if (currentLanguage === "ar") setTextAlignment("text-right");
    else setTextAlignment("text-left");
  }, []);

  useEffect(() => {
    dispatch(listCarMakerAction());
  }, []);

  const quickLinks = [
    {
      title: t("navbar_home_text"),
      link: `/${currentLanguage}`,
    },
    // Cars for sale
    {
      title: t("cars_for_sale"),
      link: `/${currentLanguage}/ads/view?category_id=1`,
    },
    // Cars for rent
    {
      title: t("cars_for_rent"),
      link: `/${currentLanguage}/ads/view?category_id=2`,
    },
    // Exhibitions
    {
      title: t("car_exhibitions"),
      link: `/${currentLanguage}/exhibitions`,
    },
    // Insurance
    {
      title: t("car_shop"),
      link: `https://souq.car`,
    },
    // News
    {
      title: t("navbar_news_text"),
      link: `/${currentLanguage}/News/view`,
    },
    {
      title: t("used_cars_for_sale"),
      link: `https://souq.car`,
    },
  ];

  const helpLinks = [
    // FAQs, Terms of use, Privacy policy, Contact us, About us
    {
      title: t("faqs"),
      link: `/${currentLanguage}/faq`,
    },
    {
      title: t("terms_of_use"),
      link: `/${currentLanguage}/terms-of-use`,
    },
    {
      title: t("privacy_policy"),
      link: `/${currentLanguage}/privacy-policy`,
    },
    {
      title: t("contact_us"),
      link: `/${currentLanguage}/contact-us`,
    },
    {
      title: t("about_us"),
      link: `/${currentLanguage}/about-us`,
    },
  ];

  const renderPopularCars = (startIndex: any, includeMargin?: boolean) => {
    const currColumn = carMakers.filter(
      (_: any, idx: any) =>
        idx === startIndex || idx === startIndex + 3 || idx === startIndex + 6
    );
    return currColumn.map((item: any) => {
      return (
        <a
          className={`${textAlignment} ${styles.footerContent} ${
            includeMargin && "mb-3"
          }}`}
          href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${item?.id}`}
        >
          {item?.name}
        </a>
      );
    });
  };

  return !isColumn ? (
    <footer
      dir={isArabic ? "rtl" : "ltr"}
      className={`w-full ${styles.footerContainer} flex items-center justify-center`}
      style={{ ...style }}
    >
      <div className={`contentWidth flex flex-row justify-between items-start`}>
        <div
          className={`flex flex-col ${styles.aboutSection} items-start justify-between gap-y-6`}
        >
          <div className={`w-full flex flex-col justify-between items-start`}>
            <p className={`${textAlignment} ${styles.footerHeading}`}>
              {t("about_Souq_Car")}
            </p>
            <div
              className={`${styles.souqDescription} ${styles.footerContent}`}
            >
              <p className={`${textAlignment}`}>{t("about_souqCarDesc")}</p>
            </div>
          </div>

          <div
            className={`${styles.newSocialIcons} flex items-end justify-end gap-x-3`}
          >
            <a target="_blank" href="https://twitter.com/home">
              <FooterTwitter color="white" />
            </a>
            <a target="_blank" href="https://www.instagram.com/">
              <FooterInstagram color="white" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC2uTHE7G5UbbYvhtn5PJkWA"
            >
              <FooterYoutube color="white" />
            </a>
            <a target="_blank" href="https://www.facebook.com/souq.car.egy">
              <FooterFacebook />
            </a>
          </div>

          <p className={`${textAlignment}`}>{t("copyRights")}</p>
        </div>

        <div className={`flex flex-col`}>
          <p className={`${textAlignment} ${styles.footerHeading}`}>
            {t("Quick_links")}
          </p>

          {
            <div className={`flex flex-col gap-y-4`}>
              {quickLinks.map((link) => (
                <a
                  href={link.link}
                  className={`${textAlignment} ${styles.footerContent} `}
                >
                  {link.title}
                </a>
              ))}
            </div>
          }
        </div>

        <div className={`flex flex-col`}>
          <p className={`${textAlignment} ${styles.footerHeading}`}>
            {t("help")}
          </p>
          {
            <div className={`flex flex-col gap-y-4`}>
              {helpLinks.map((link) => (
                <a
                  href={link.link}
                  className={`${textAlignment} ${styles.footerContent} `}
                >
                  {link.title}
                </a>
              ))}
            </div>
          }
        </div>

        {carMakers && (
          <div className={`flex flex-col`}>
            <p className={`${textAlignment} ${styles.footerHeading}`}>
              {t("The_most_famous_cars")}
            </p>
            <div
              dir={textDirectionStyleFlipped}
              className={`flex justify-between`}
            >
              <div className={`flex flex-col ${styles.famousCars}`}>
                {renderPopularCars(2)}
              </div>

              <div className={`flex flex-col ${styles.famousCars}`}>
                {renderPopularCars(1)}
              </div>

              <div className={`flex flex-col ${styles.famousCars}`}>
                {renderPopularCars(0)}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <div className={``}></div> */}
    </footer>
  ) : (
    <footer
      dir={isArabic ? "rtl" : "ltr"}
      className={`w-full ${styles.footerContainerMobile} flex flex-col justify-between items-start`}
    >
      <div className={`w-full flex flex-col mt-6`}>
        <p className={`${textAlignment} ${styles.footerHeading}`}>
          {t("Quick_links")}
        </p>

        {
          <div className={`flex flex-wrap gap-y-4 gap-x-6`}>
            {quickLinks.map((link) => (
              <a
                href={link.link}
                className={`${textAlignment} ${styles.footerContent}  whitespace-nowrap`}
              >
                {link.title}
              </a>
            ))}
          </div>
        }
      </div>
      <div className={`w-full flex flex-col mt-12`}>
        <p className={`${textAlignment} ${styles.footerHeading}`}>
          {t("help")}
        </p>
        {
          <div className={`flex flex-wrap gap-y-4 gap-x-6`}>
            {helpLinks.map((link) => (
              <a
                href={link.link}
                className={`${textAlignment} ${styles.footerContent}  whitespace-nowrap`}
              >
                {link.title}
              </a>
            ))}
          </div>
        }
      </div>
      {carMakers && (
        <div className={`w-full flex flex-col mt-12`}>
          <p className={`${textAlignment} ${styles.footerHeading}`}>
            {t("The_most_famous_cars")}
          </p>
          <div
            dir={textDirectionStyleFlipped}
            className={`w-full flex justify-between`}
          >
            <div
              className={`w-full flex flex-col gap-3 ${styles.famousCarsMobile}`}
            >
              {renderPopularCars(2, true)}
            </div>

            <div
              className={`w-full flex flex-col gap-3 ${styles.famousCarsMobile}`}
            >
              {renderPopularCars(1, true)}
            </div>

            <div
              className={`w-full flex flex-col gap-3 ${styles.famousCarsMobile}`}
            >
              {renderPopularCars(0, true)}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col items-center justify-center w-full">
        <div
          className={`flex justify-center items-center gap-3 w-[60%] pb-4 pt-12 text-[var(--new-blue)]`}
        >
          <a target="_blank" href="https://www.facebook.com/souq.car.egy">
            <FooterFacebook height={"1.75rem"} width={"auto"} />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UC2uTHE7G5UbbYvhtn5PJkWA"
          >
            <FooterYoutube height={"1.75rem"} width={"auto"} color="white" />
          </a>

          <a target="_blank" href="https://twitter.com/home">
            <FooterTwitter height={"1.75rem"} width={"auto"} color="white" />
          </a>

          <a target="_blank" href="https://www.instagram.com/">
            <FooterInstagram height={"1.75rem"} width={"auto"} color="white" />
          </a>
        </div>

        <p className={`${textAlignment}`}>{t("copyRights")}</p>
      </div>
    </footer>
  );
};

export default Footer;
