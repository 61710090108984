import api from "api";
import { listMessagesEndPoint } from "api/apiUrls";
import store from "redux/store";

export const listMessagesService = async (userId: string) => {
    const response = await api.get(
        listMessagesEndPoint(userId),
        {
            headers: {
                'Authorization': `Bearer ${store.getState().verifyOtpReducer?.userData?.token}`
            }
        }
    );
    return response;
}