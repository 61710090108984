import styles from "screens/V3/Faqs/Faqs.module.css";
import { useTranslation } from "react-i18next";
import FaqCard from "components/FaqCard/FaqCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import CustomButton from "components/CustomButton/CustomButton";
import { useNavigate } from "react-router";
import Seo from "components/Seo/Seo";
import Footer from "components/Footer/Footer";
import { useEffect } from "react";
import { listFaqsAction } from "redux/actions/FAQsAction/listFaqsAction";

const Faqs = () => {
  const { t } = useTranslation();

  const dispatch: any = useDispatch();

  const { faqs } = useSelector((state: RootState) => state.listFaqsReducer);

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  useEffect(() => {
    dispatch(listFaqsAction());
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Seo
        title="سوق كار - الأسئلة الشائعة"
        description="الأسئلة الشائعة"
        keywords="سوق السيارات,سيارات,شراء عربيات,مدينة نصر، مصر,هيونداي,بيع سيارات مستعملة,عربيات للبيع,شراء سيارات,سيارات مستعملة للبيع,souq.car,souq car,سوق دوت كار,سوق كار"
      />
      <div className="w-full flex flex-col items-center gap-y-10 mt-[2.94rem]">
        <h1 className={`text-[var(--new-blue)] text-[1.25rem] font-[700]`}>
          {t("faqs")}
        </h1>
        <div className="w-full flex flex-col gap-y-4 items-center">
          {faqs?.reverse().map((item: any, idx: number) => {
            return (
              <FaqCard
                key={idx}
                question={item?.question}
                answer={item?.answer}
              />
            );
          })}
        </div>
        <div
          dir="rtl"
          className={`mt-[2.87rem] mb-10 w-[20.75rem] h-[3.875rem] p-[0.6rem] rounded-[0.625rem] flex flex-row items-center justify-between gap-x-3 ${styles.shadow}`}
        >
          <div className="w-1/2 h-full flex items-center justify-center">
            <p>{t("didnt_find_your_question")}</p>
          </div>
          <CustomButton
            className={`w-1/2 h-full rounded-[0.3125rem] flex items-center justify-center`}
            onClick={() => {
              navigate(`/${currentLanguage}/contact-us`);
            }}
            color={"var(--new-blue)"}
          >
            <p className="text-white">{t("contact_us")}</p>
          </CustomButton>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Faqs;
