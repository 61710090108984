import styles from "components/UpdatePostForms/SparePartsUpdateForm/SparePartsUpdateForm.module.css";
import priceStatusData from "MockupData/priceStatusData";
import * as Yup from "yup";
import CreatePostModel from "models/CreatePostModel";
import CustomMap from "components/CustomMap/Map/Map";
import { listCarBodiesAction } from "redux/actions/CarBodiesActions/listCarBodiesAction";
import { listEngineCapacityAction } from "redux/actions/EngineCapacityActions/listEngineCapacityAction";
import { listArticlesAction } from "redux/actions/Articles/ListArticlesActions";
import { listCarConditionAction } from "redux/actions/CarConditionActions/listCarConditionAction";
import { listCarMakerAction } from "redux/actions/CarMakerActions/listCarMakerAction";
import { listCitiesAction } from "redux/actions/CitiesActions/listCitiesAction";
import { listColorsAction } from "redux/actions/ColorsAction/listColorsAction";
import { listFeaturesAction } from "redux/actions/FeaturesActions/listFeaturesAction";
import { listTransmissionTypesAction } from "redux/actions/TransmissionTypesActions/listTransmissionTypesAction";
import { listYearsAction } from "redux/actions/YearsActions/listYearsAction";
import { listCarModelsAction } from "redux/actions/CarModelsActions/listCarModelsAction";
import { listPaymentTypesAction } from "redux/actions/PaymentTypesActions/listPaymentTypesAction";
import { mobileLoginAction } from "redux/actions/LoginActions/mobileLoginAction";
import { RESET_CREATE_POST, RESET_OTP } from "redux/types";
import { verifyOtpAction } from "redux/actions/LoginActions/verifyOtpAction";
import { listFuelTypesAction } from "redux/actions/FuelTypesActions/listFuelTypesAction";
import scrollToTop from "utils/functions/scrollToTop";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Checkbox, Input, Select, Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { setProgressStateAction } from "redux/actions/SetProgressStateAction/setProgressStateAction";
import CustomButton from "components/CustomButton/CustomButton";
import { plusCircleIcon } from "assets/icons";
import CustomImageContainer from "components/CustomImageContainer/CustomImageContainer";
import { postPaymentTypeAction } from "redux/actions/PostPaymentTypeAction/postPaymentTypeAction";
import { updatePostAction } from "redux/actions/UpdatePostAction/updatePostAction";
import { listRentalTypesAction } from "redux/actions/RentalTypesAction/listRentalTypesAction";
import { listRentalDriverAction } from "redux/actions/CreatePostActions/listRentalDriverAction";
import { setMapCoordinatesPostAction } from "redux/actions/SetMapCoordinatesPostAction/setMapCoordinatesPostAction";
import { listSparePartTypeAction } from "redux/actions/CreatePostActions/listSparePartTypeAction";
import { listSparePartConditionAction } from "redux/actions/CreatePostActions/listSparePartConditionAction";
import { useDropdowns } from "hooks/useDropdowns";

interface SparePartsUpdateFormProps {
  image?: any;
}

const SparePartsUpdateForm: FC<SparePartsUpdateFormProps> = ({ image }) => {
  const navigate = useNavigate();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const isArabic = currentLanguage === "ar";
  const dir = isArabic ? "rtl" : "ltr";
  const dirFlip = !isArabic ? "rtl" : "ltr";

  // const [progress, setProgress] = useState(0);

  const { categoryState } = useSelector(
    (state: RootState) => state.postCategoryStateReducer
  );

  const { mapState } = useSelector(
    (state: RootState) => state.setMapCoordinatesPostReducer
  );

  const { postPaymentType } = useSelector(
    (state: RootState) => state.postPaymentTypeReducer
  );

  const { rentalTypesLoading, rentalTypes } = useSelector(
    (state: RootState) => state.listRentalTypesReducer
  );

  const { rentalDriverLoading, rentalDriver } = useSelector(
    (state: RootState) => state.listRentalDriverReducer
  );

  const { carBodiesLoading, carBodies } = useSelector(
    (state: RootState) => state.listCarBodiesReducer
  );
  const { engineCapactiyLoading, engineCapacity } = useSelector(
    (state: RootState) => state.listEngineCapacityReducer
  );
  const { carConditionLoading, carConditions } = useSelector(
    (state: RootState) => state.listCarConditionReducer
  );
  const { yearsLoading, years } = useSelector(
    (state: RootState) => state.listYearsReducer
  );
  const { carMakersLoading, carMakers } = useSelector(
    (state: RootState) => state.listCarMakerReducer
  );
  const { carModelsLoading, carModels } = useSelector(
    (state: RootState) => state.listCarModelsReducer
  );
  const { colorsLoading, colors } = useSelector(
    (state: RootState) => state.listColorsReducer
  );
  const { featuresLoading, features } = useSelector(
    (state: RootState) => state.listFeaturesReducer
  );
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );
  const { fuelTypesLoading, fuelTypes } = useSelector(
    (state: RootState) => state.listFuelTypesReducer
  );
  const { transmissionTypesLoading, transmissionTypes } = useSelector(
    (state: RootState) => state.listTransmissionTypesReducer
  );
  const { paymentTypesLoading, paymentTypes } = useSelector(
    (state: RootState) => state.listPaymentTypesReducer
  );

  const { loginLoading, customer, otp } = useSelector(
    (state: RootState) => state.mobileLoginReducer
  );
  const { verifyOtpLoading, userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { progressState } = useSelector(
    (state: RootState) => state.setProgressStateReducer
  );

  const { post, updatePostLoading, updatedPost } = useSelector(
    (state: RootState) => state.updatePostReducer
  );

  const { sparePartConditionLoading, sparePartCondition } = useSelector(
    (state: RootState) => state.listSparePartConditionReducer
  );

  const { sparePartTypeLoading, sparePartType } = useSelector(
    (state: RootState) => state.listSparePartTypeReducer
  );

  const formik = useFormik({
    initialValues: {
      name: customer ? customer?.name : "",
      service_id: post?.service_id,
      title: post?.title,
      description: post?.description,
      classification: "Popular",
      address: post?.address,
      lat: post?.lat,
      long: post?.long,
      engine_capacity: post?.engine_capacity,
      car_rental_type: rentalTypes?.find(
        (item: any) => item.name === post?.car_rental_type
      )?.key,
      driver_status: rentalDriver?.find(
        (item: any) => item.name === post?.driver_status
      )?.key,
      car_type: carBodies?.find((item: any) => item.name === post?.car_type)
        ?.key,
      car_condition: sparePartCondition?.find(
        (item: any) => item.name === post?.car_condition
      )?.key,
      transmission_type: transmissionTypes?.find(
        (item: any) => item.name === post?.transmission_type
      )?.key,
      fuel_type: fuelTypes?.find((item: any) => item.name === post?.fuel_type)
        ?.key,
      payment_type: paymentTypes?.find(
        (item: any) => item.name === post?.payment_type
      )?.key,
      color_id: post?.color,
      brand_id: post?.brand,
      car_model_id: post?.car_model,
      down_payment: post?.down_payment,
      // branch_id: "1",
      customer_id: post?.customer_id,
      city_id: post?.city,
      package_id: "1",
      category_id: categoryState,
      price: post?.price,
      kilo_meterage: post?.kilo_meterage,
      production_date: post?.production_date,
      main_image: post?.main_image,
      features: post?.features,
      postMedias: post?.post_medias,
      spare_part_id: post?.spare_part,
      mobile: "",
      otp: "",
    },
    validationSchema: Yup.object({
      // title: Yup.string().required(),
      // description: Yup.string().required(),
      // engine_capacity: Yup.string().required(),
      // car_type: Yup.string().required(),
      // car_condition: Yup.string().required(),
      // transmission_type: Yup.string().required(),
      // fuel_type: Yup.string().required(),
      // payment_type: Yup.string().required(),
      // color_id: Yup.string().required(),
      // brand_id: Yup.string().required(),
      // car_model_id: Yup.string().required(),
      // city_id: Yup.string().required(),
      price: Yup.number().required(),
      // name: Yup.string().required(),
      // kilo_meterage: Yup.number().required(),
      // production_date: Yup.string().required(),
      // main_image: Yup.string().required(),
      // features: Yup.string().required()
    }),
    onSubmit: async (values: CreatePostModel) => {
      if (!customer && formik.values.name.toString().trim() === "") {
        formik.setFieldError("name", "Please Provide this field");
        return;
      }
      if (values.main_image === undefined || values.main_image === "") {
        setMainCoverMessage(true);
      } else {
        setMainCoverMessage(false);
        const postMediasUploadCopy = [...fileList];
        const postMediasFinal = postMediasUploadCopy.filter(
          (file: any) => file.media_url !== values.main_image.media_url
        );

        const mainImageValue = values.main_image.file
          ? values.main_image.file
          : values.main_image.media_url;

        const formData: any = {
          title: values.title,
          description: values.description,
          classification: "Popular",
          address: values.address,
          lat: mapState.lat,
          long: mapState.lng,
          engine_capacity: values.engine_capacity,
          car_type: values.car_type,
          car_condition: values?.car_condition,
          transmission_type: values?.transmission_type,
          fuel_type: values?.fuel_type,
          payment_type: values.payment_type,
          color_id: values.color_id,
          car_model_id:
            typeof values.car_model_id == "number"
              ? values.car_model_id + "$%$"
              : values.car_model_id,
          // branch_id: values.branch_id,
          down_payment: values.down_payment,
          customer_id: 2,
          city_id: values.city_id,
          package_id: 1,
          category_id: values.category_id,
          price: values.price,
          kilo_meterage: values.kilo_meterage,
          driver_status: values.driver_status,
          car_rental_type: values.car_rental_type,
          spare_part_id: values.spare_part_id,
          main_image: mainImageValue,
          production_date: values.production_date,
          postMedias: postMediasFinal.map((item: any) => {
            const mediaFile = item.file ? item.file : item.media_url;
            return {
              media_url: mediaFile,
            };
          }),
          features: values.features,
          brand_id: values.brand_id,
        };

        await dispatch(updatePostAction(post?.id, formData));
        navigate(`/${currentLanguage}/profile/view`);
      }
    },
  });

  const checkErrors = () => {
    // formik.setFieldValue("category_id", categoryState);
    var errorExists: any[] = [false];

    if (formik.values.category_id?.toString()?.trim() === "") {
      formik.setFieldError("category_id", "Please Provide this field");
      errorExists[0] = true;
      errorExists.push("category_id");
    }
    if (formik.values.title?.trim() === "") {
      formik.setFieldError("title", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("title");
    }
    if (formik.values.description?.trim() === "") {
      formik.setFieldError("description", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("description");
    }
    if (formik.values.engine_capacity?.trim() === "") {
      formik.setFieldError("engine_capacity", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("engine_capacity");
    }
    if (formik.values.car_type?.toString()?.trim() === "") {
      formik.setFieldError("car_type", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("car_type");
    }
    if (formik.values.car_condition?.toString()?.trim() === "") {
      formik.setFieldError("car_condition", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("car_condition");
    }
    if (formik.values.transmission_type?.toString()?.trim() === "") {
      formik.setFieldError("transmission_type", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("transmission_type");
    }
    if (formik.values.fuel_type?.toString()?.trim() === "") {
      formik.setFieldError("fuel_type", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("fuel_type");
    }
    if (formik.values.payment_type?.trim() === "") {
      formik.setFieldError("payment_type", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("payment_type");
    } else {
      if (
        formik.values.payment_type === "Installment" &&
        formik.values.down_payment?.trim() === ""
      ) {
        formik.setFieldError("down_payment", "Please provide this field");
        errorExists[0] = true;
        errorExists.push("down_payment");
      }
    }
    if (formik.values.color_id?.toString()?.trim() === "") {
      formik.setFieldError("color_id", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("color_id");
    }
    if (formik.values.brand_id?.toString()?.trim() === "") {
      formik.setFieldError("brand_id", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("brand_id");
    }
    if (formik.values.car_model_id?.toString()?.trim() === "") {
      formik.setFieldError("car_model_id", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("car_model_id");
    }
    if (formik.values.city_id?.toString()?.trim() === "") {
      formik.setFieldError("city_id", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("city_id");
    }
    if (formik.values.price?.toString()?.trim() === "") {
      formik.setFieldError("price", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("price");
    }
    if (formik.values.kilo_meterage?.toString()?.trim() === "") {
      formik.setFieldError("kilo_meterage", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("kilo_meterage");
    }
    if (formik.values.production_date?.toString()?.trim() === "") {
      formik.setFieldError("production_date", "Please provide this field");
      errorExists[0] = true;
      errorExists.push("production_date");
    }
    if (errorExists[0]) {
      setMainError(true);
    }
    if (!errorExists[0]) {
      dispatch(setProgressStateAction(progressState + 1));
    }
  };

  const { TextArea } = Input;

  const dispatch: any = useDispatch();

  const updateNextProgressState = () => {
    dispatch(setProgressStateAction(progressState + 1));
  };

  const engineCapacityDropdown = engineCapacity?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const carConditionsDropdown = carConditions?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const driverDropdown = rentalDriver?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const rentalTypesDropdown = rentalTypes?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const yearsDropdown = years?.map((item: any) => {
    return {
      value: item,
      label: item,
    };
  });

  const { carMakerDropdown, carModelsDropdown } = useDropdowns();

  const colorsDropdown = colors?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const featuresDropdown = features?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const citiesDropdown = cities?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const carBodiesDropdown = carBodies?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const fuelTypesDropdown = fuelTypes?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const paymentTypesDropdown = paymentTypes?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const sparePartTypeDropdown = sparePartType?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const [mainError, setMainError] = useState(false);

  const [postMediasUpload, setPostMediasUpload] = useState<any>([]);

  const [mainCoverMessage, setMainCoverMessage] = useState(false);

  const [selected, setSelected] = useState({ lat: 30.11525, lng: 31.52652 });

  const [fileList, setFileList] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isMobile, setIsMobile] = useState(false);

  /*
        transmissionType:
        - -1 Unset
        - 0 أتوماتيك
        - 1 مانيوال
        - 2 مزدوج
    */

  const [transmissionType, setTransmissionType] = useState(
    transmissionTypes?.find(
      (item: any) => item.name === post?.transmission_type
    )?.key
  );

  /*
        carCondition:
        - -1 Unset
        - 0 مستعمل
        - 1 جديد
        - 2 كسر زيرو
    */
  const [carCondition, setCarCondition] = useState(
    carConditions?.find((item: any) => item.name === post?.car_condition)?.key
  );

  /*
        carFuelType:
        - -1 Unset
        - 0 بنزين
        - 1 ديزل
        - 2 غاز طبيعي
        - 3 بنزين و غاز
        - 4 كهرباء
        - 5 هجين 
    */
  const [carFuelType, setCarFuelType] = useState(
    fuelTypes?.find((item: any) => item.name === post?.fuel_type)?.key
  );

  /*
        carBody:
        - -1 Unset
        - 0 سيدان
        - 1 هاتشباك
        - 2 suv
        - 3 بيك أب (نقل) 
        - 4 كوبية
        - 5 ميني فان
        - 6 باص / أتوبيس
        - 7 ستيشن واجن
        - 8 مكشوف
    */
  const [carBody, setCarBody] = useState(
    carBodies?.find((item: any) => item.name === post?.car_type)?.key
  );

  /*
        carBody:
        - -1 Unset
        - 0 فبريكا بالكامل
        - 1 بعض الاجزاء فبريكا
        - 2 مش فبريكا
    */
  const [fabrika, setFabrika] = useState(-1);

  const [inGuarantee, setInGuarantee] = useState(true);

  /*
        contactType:
        - -1 Unset
        - 0 الجميع 
        - 1 موبايل فقط
        - 2 واتساب فقط 
    */
  const [contactType, setContactType] = useState(-1);

  const [featuresList, setFeaturesList] = useState<any>([]);

  const [spareCondition, setSpareCondition] = useState(
    sparePartCondition?.find((item: any) => item.name === post?.condition)?.key
  );

  const [coverPhotoIdx, setCoverPhotoIdx] = useState<any>();

  const handleMobileLoginClick = async () => {
    var errorExists = false;
    if (formik.values.mobile?.trim() === "") {
      formik.setFieldError("mobile", "Please provide this field");
      errorExists = true;
    }

    if (!errorExists) {
      await dispatch(mobileLoginAction(formik.values.mobile, false, ""));
    }
  };

  const handleOtpVerifyClick = async () => {
    var errorExists = false;
    if (formik.values.otp?.trim() === "") {
      formik.setFieldError("otp", "Please provide this field");
      errorExists = true;
    }

    if (!errorExists) {
      await dispatch(
        verifyOtpAction(
          formik.values.mobile,
          formik.values.otp,
          formik.values.name
        )
      );
    }
  };

  const handleDeleteImage = (idx: any) => {
    setFileList((oldFiles: any) =>
      oldFiles.filter((item: any, id: any) => id !== idx)
    );
  };

  const makeCoverPhoto = (inputFile: any) => {
    setCoverPhotoIdx(inputFile);
    const postMediasCopy = [...fileList];
    const coverPhotoFile = postMediasCopy.filter(
      (file: any, id: any) => file.media_url === inputFile.media_url
    );

    formik.setFieldValue("main_image", coverPhotoFile[0]);
  };

  function selectFiles() {
    fileInputRef.current?.click();
  }

  const handleFileListUpdate = (event: any) => {
    const files = event.target.files;

    if (files.length == 0) return;

    for (let i = 0; i < files.length; i++) {
      if (!fileList.some((e: any) => e.name == files[i].name)) {
        setFileList((prevFileList: any[]) => [
          ...prevFileList,
          {
            media_url: URL.createObjectURL(files[i]),
            file: files[i],
          },
        ]);
      }
    }

    formik.setFieldValue("postMedias", fileList);
  };

  // const handleFileUpload = (event: any) => {
  //   const files = event.target.files;

  //
  //   if (files.length == 0) return;

  //   for (let i = 0; i < files.length; i++) {
  //     if (!fileList.some((e: any) => e.name == files[i].name)) {
  //
  //       setPostMediasUpload((prevMedias: any[]) => [...prevMedias, files[i]]);
  //     }
  //   }

  //
  //

  //   formik.setFieldValue("postMedias", postMediasUpload);
  // };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    //
  }, [fileList]);

  useEffect(() => {
    dispatch(setMapCoordinatesPostAction({ lat: post?.lat, lng: post?.long }));
    dispatch(listCarBodiesAction());
    dispatch(listEngineCapacityAction());
    dispatch(listCarConditionAction());
    dispatch(listYearsAction());
    dispatch(listCarMakerAction());
    dispatch(listArticlesAction());
    dispatch(listColorsAction());
    dispatch(listFeaturesAction());
    dispatch(listRentalTypesAction());
    dispatch(listRentalDriverAction());
    dispatch(listCitiesAction());
    dispatch(listTransmissionTypesAction());
    dispatch(listPaymentTypesAction());
    dispatch(listFuelTypesAction());
    dispatch(postPaymentTypeAction([]));
    dispatch(listSparePartTypeAction());
    dispatch(listSparePartConditionAction());
    dispatch(setProgressStateAction(0));

    setFileList(formik.values.postMedias);
    formik.setFieldValue("main_image", { media_url: formik.values.main_image });

    dispatch({ type: RESET_OTP });
    dispatch({ type: RESET_CREATE_POST });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
  }, []);

  const { t } = useTranslation();

  return (
    <form
      className="w-full flex flex-col items-center"
      onSubmit={formik.handleSubmit}
    >
      {progressState === 0 ? (
        <div className="w-[100%]">
          <>
            <p
              className="text-lg text-center mb-3"
              style={{ fontFamily: "rbFontBold" }}
            >
              {t("car_info")} SPARE PARTS UPDATE
            </p>
            <p className="text-xs md:text-sm text-center">
              {t("car_info_msg")}{" "}
            </p>
          </>
          <div
            dir={dirFlip}
            className="w-full mt-6 p-3 flex flex-col gap-y-8 items-end"
          >
            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right">
                : {t("homepage_car_maker")}
              </p>
              {carMakersLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_car_maker")}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={carMakerDropdown}
                    status={formik.errors.brand_id ? "error" : ""}
                    value={formik.values.brand_id}
                    onSelect={(value: any) => {
                      formik.setFieldValue("brand_id", value);
                      dispatch(listCarModelsAction(value));
                    }}
                    size="large"
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.brand_id && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right">: {t("type_of_product")}</p>
              {carMakersLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("type_of_product")}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={sparePartTypeDropdown}
                    status={formik.errors.brand_id ? "error" : ""}
                    onSelect={(value: any) => {
                      formik.setFieldValue("spare_part_id", value);
                    }}
                    size="large"
                    value={formik.values.spare_part_id}
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.brand_id && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>

            {/* SPARE PART CONDITION */}
            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right">: {t("state_of_product")}</p>
              <div className="w-full flex flex-col items-end gap-y-2">
                <div className="w-full flex flex-row items-center gap-x-4">
                  {sparePartConditionLoading ? (
                    <Spin />
                  ) : (
                    sparePartCondition?.map((item: any) => {
                      return (
                        <div
                          className={`${
                            spareCondition === item.key
                              ? styles.transmissionTypeSelected
                              : styles.transmissionType
                          } p-2 ${
                            styles.selectButton
                          } w-full flex  justify-center`}
                          onClick={() => {
                            setSpareCondition(item.key);
                            formik.setFieldValue("car_condition", item.key);
                          }}
                        >
                          <p>{item.name}</p>
                        </div>
                      );
                    })
                  )}
                </div>
                {formik.errors.car_condition && (
                  <p className="text-[red]">{t("please_provide_this_field")}</p>
                )}
              </div>
            </div>

            <hr className={`${styles.formDivider}`} />

            <p
              className="w-full text-lg text-center mb-3"
              style={{ fontFamily: "rbFontBold" }}
            >
              {t("post_details")}
            </p>

            <div className={`${styles.containerRow}`}>
              <p className="text-right font-bold">: {t("post_title")}</p>
              <>
                <Input
                  placeholder={t("title_hint")}
                  size="large"
                  className="w-full"
                  name="title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  status={formik.errors.title ? "error" : ""}
                  dir={dir}
                />
                {formik.errors.title && (
                  <p className="text-[red]">{t("please_provide_this_field")}</p>
                )}
              </>
            </div>
            <div className={`${styles.containerRow}`}>
              <p className="text-right font-bold">: {t("post_description")}</p>
              <>
                <TextArea
                  rows={6}
                  placeholder={t("post_description_hint")}
                  // className="text-right"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  status={formik.errors.description ? "error" : ""}
                  dir={dir}
                />
                {formik.errors.description && (
                  <p className="text-[red]">{t("please_provide_this_field")}</p>
                )}
              </>
            </div>
            <div className={`${styles.containerRow}`}>
              <p className="text-right font-bold">: {t("price")}</p>
              <>
                <Input
                  placeholder={t("price_hint")}
                  className="w-full text-right"
                  size="large"
                  prefix={
                    <p className={`text-blue-600 text-left`}>{t("egp")}</p>
                  }
                  name="price"
                  onChange={formik.handleChange}
                  value={formik.values.price}
                  status={formik.errors.price ? "error" : ""}
                  dir={dir}
                />
                {formik.errors.price && (
                  <p className="text-[red]">{t("please_provide_this_field")}</p>
                )}
              </>
            </div>
            <div className={`${styles.containerRow}`}>
              <p className="text-right font-bold">: {t("payment_options")}</p>
              {paymentTypesLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    // mode="multiple"
                    // allowClear
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("payment_options_hint")}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={paymentTypesDropdown}
                    onSelect={(value: any) => {
                      formik.setFieldValue("payment_type", value);
                    }}
                    value={formik.values.payment_type}
                    status={formik.errors.payment_type ? "error" : ""}
                    size="large"
                    className={`w-full`}
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.payment_type && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>

            {/* <div className={`${styles.containerRow}`}>
              <p className="text-right font-bold">: {t("price_condition")}</p>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={t("price_condition_hint")}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={priceStatusData}

                size="large"
                className="w-full"
                style={{ direction: dir }}
                dropdownStyle={{ direction: dir }}
              />
            </div> */}

            <hr className={`${styles.formDivider}`} />

            <div className={`${styles.containerRow}`}>
              <p className="text-right font-bold">
                : {t("homepage_governate")}
              </p>
              {citiesLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_governate")}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={citiesDropdown}
                    value={formik.values.city_id}
                    onSelect={(value: any) => {
                      formik.setFieldValue("city_id", value);
                    }}
                    status={formik.errors.city_id ? "error" : ""}
                    size="large"
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.city_id && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>
            <div className={`${styles.containerRow}`}>
              <p className="text-right font-bold">
                : {t("inspection_location")}
              </p>
              <div className={`${styles.containerRow}`}>
                <>
                  <Input
                    placeholder={t("address_hint")}
                    size="large"
                    className="w-full"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    status={formik.errors.address ? "error" : ""}
                    dir={dir}
                  />
                  {formik.errors.address && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              </div>
              <div className={`w-full flex items-center justify-center `}>
                <CustomMap
                  width={"100%"}
                  height={"400px"}
                  selectWidth={isMobile ? "150px" : "300px"}
                  top={isMobile ? "60px" : "10px"}
                  left={isMobile ? "28%" : "62%"}
                  selected={mapState}
                  setSelected={setSelected}
                />
              </div>
            </div>
            <div className={`${styles.containerRow}`}>
              <div className="w-full mt-10 flex flex-row items-center justify-center gap-x-4">
                <div
                  className={`${styles.transmissionTypeSelected} p-2 ${styles.selectButton} w-1/4 flex  justify-center`}
                  onClick={() => checkErrors()}
                >
                  <p>{t("next")}</p>
                </div>
                <div
                  className={`${styles.transmissionType} p-2 ${styles.selectButton} w-1/6 flex  justify-center`}
                  onClick={() => navigate(-1)}
                >
                  <p>{t("cancel")}</p>
                </div>
              </div>
              {mainError && (
                <p className="w-full text-center text-[red]">
                  {t("please_check_all_fields_provided")}
                </p>
              )}
              {/* <p className="w-full mt-6 text-center font-bold">
                {t("click_next")}
              </p> */}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {progressState === 1 ? (
        <div dir={dirFlip} className="w-full flex flex-col items-center">
          <p className="font-bold text-xl">{t("car_photos")}</p>
          <p className="font-bold">{t("upload_10")}</p>
          <div
            dir={"ltr"}
            className={`mt-8 mb-8 ${
              styles.imageDisclaimerContainer
            } rounded-md p-4 flex flex-col items-center justify-between md:flex-row${
              !isArabic ? "-reverse mx-[-10rem]" : ""
            }`}
          >
            <div>
              <p className="text-xs md:text-md">{t("obscured")}</p>
              <p className="text-xs md:text-md">{t("front_photo")}</p>
            </div>
            <div>
              <p className="text-xs md:text-md">{t("clear_photos")}</p>
              <p className="text-xs md:text-md">{t("no_competing")}</p>
            </div>
          </div>
          <input
            id="file"
            type="file"
            onChange={(event: any) => {
              handleFileListUpdate(event);
              // handleFileUpload(event);
            }}
            ref={fileInputRef}
            accept="image/*"
            multiple
          />

          {/* ALL FILES */}
          <div
            dir={dir}
            className={`mt-6 w-full flex flex-wrap gap-x-6 gap-y-3
            ${fileList.length === 0 ? "justify-center" : ""}`}
          >
            {fileList?.map((file: any, idx: any) => {
              if (file) {
                const isCover =
                  file.media_url === formik.values.main_image.media_url;
                return (
                  <CustomImageContainer
                    deleteButtonOnClick={() => handleDeleteImage(idx)}
                    makeCoverPhotoOnClick={() => makeCoverPhoto(file)}
                    opacity={idx === coverPhotoIdx ? 0.95 : 0.95}
                    isCover={isCover}
                    key={idx}
                    thumbUrl={file.media_url}
                  />
                );
              } else {
                //
                return <></>;
              }
            })}
            <label
              // htmlFor="file"
              onClick={selectFiles}
              className={`${styles.uploadImageContainer}`}
            >
              <div className={`${styles.uploadButton}`}>
                <div className={`${styles.uploadTextContainer}`}>
                  {t("addImage")}
                </div>
                <img src={plusCircleIcon} alt="" />
              </div>
            </label>
          </div>
          {mainCoverMessage ? (
            <p style={{ color: "red" }}>{t("main_cover_message")}</p>
          ) : (
            <></>
          )}
          <div className="w-full mt-10 flex flex-row items-center justify-center gap-x-4">
            {userData?.token ? (
              updatePostLoading ? (
                <Spin />
              ) : (
                <CustomButton
                  color={"blue"}
                  className={`${styles.transmissionTypeSelected} p-2 ${styles.selectButton} h-16 w-1/4 flex justify-center items-center md:h-10`}
                  type={"submit"}
                  disabled={userData ? false : true}
                >
                  <p className="text-center text-white">{t("publish_post")}</p>
                </CustomButton>
              )
            ) : (
              <div
                className={`${styles.transmissionTypeSelected} p-2 ${styles.selectButton} w-1/4 flex  justify-center`}
                onClick={() =>
                  dispatch(setProgressStateAction(progressState + 1))
                }
              >
                <p>{t("next")}</p>
              </div>
            )}
            <div
              className={`${styles.transmissionType} p-2 ${styles.selectButton} w-1/6 flex  justify-center`}
              onClick={() =>
                dispatch(setProgressStateAction(progressState - 1))
              }
            >
              <p>{t("back")}</p>
            </div>
          </div>
          {userData?.token ? (
            <></>
          ) : (
            <p className="w-full mt-6 text-center font-bold">
              {t("last_step_msg1")}
            </p>
          )}
        </div>
      ) : (
        <></>
      )}

      {progressState === 2 && (
        <div dir={dirFlip} className="w-full flex flex-col items-center px-3">
          <p className="font-bold text-xl">{t("contact_info")}</p>
          <p className="font-bold">{t("last_step_msg2")}</p>
          <div className="mt-6 w-full flex flex-row items-center gap-x-4">
            {loginLoading ? (
              <Spin />
            ) : (
              <div
                className={`${styles.transmissionTypeSelected} p-2 ${styles.selectButton} w-1/4 flex  justify-center`}
                onClick={() => {
                  handleMobileLoginClick();
                }}
              >
                <p>{t("confirm")}</p>
              </div>
            )}
            {otp && <CheckOutlined className="text-xl text-[green]" />}
            <Input
              placeholder={t("mobile_hint")}
              className="w-full"
              size="large"
              name="mobile"
              onChange={formik.handleChange}
              status={formik.errors.mobile ? "error" : ""}
              style={{ direction: dir }}
            />
          </div>
          <div className="mt-6 w-full flex flex-row items-center gap-x-4">
            <div
              className={`${styles.transmissionType} p-2 ${styles.selectButton} w-1/4 flex justify-center`}
              onClick={() => {
                handleMobileLoginClick();
              }}
            >
              <p>{t("resend")}</p>
            </div>
            {verifyOtpLoading ? (
              <Spin />
            ) : (
              <div
                className={`${styles.transmissionTypeSelected} p-2 ${styles.selectButton} w-1/4 flex justify-center items-center`}
                onClick={() => {
                  handleOtpVerifyClick();
                }}
              >
                <p>{t("confirm")}</p>
              </div>
            )}
            {userData && <CheckOutlined className="text-xl text-[green]" />}
            <Input
              placeholder={t("otp_hint")}
              className="w-full"
              size="large"
              name="otp"
              onChange={formik.handleChange}
              status={formik.errors.otp ? "error" : ""}
              style={{ direction: dir }}
            />
          </div>
          <div className="mt-16 w-full flex flex-row justify-center gap-x-4">
            {!isMobile ? (
              <div
                className={`border-black border-2 p-2 ${styles.selectButton} flex justify-center items-center`}
                onClick={() => {}}
              >
                <p className="text-center">{t("save_draft")}</p>
              </div>
            ) : (
              <></>
            )}
            {updatePostLoading ? (
              <Spin />
            ) : (
              <CustomButton
                color={"blue"}
                className={`${styles.transmissionTypeSelected} p-2 ${styles.selectButton} w-52 flex ${styles.doubleW} justify-center items-center`}
                type={"submit"}
                disabled={userData ? false : true}
              >
                <p className="text-center text-white">{t("publish_post")}</p>
              </CustomButton>
            )}
            <div
              className={`${styles.transmissionType} p-2 ${styles.selectButton} flex ${styles.singleW} justify-center items-center`}
              onClick={() => {
                dispatch(setProgressStateAction(progressState - 1));
              }}
            >
              <p>{t("back")}</p>
            </div>
          </div>
          <p className="text-center my-4">{t("finished")}</p>
          {isMobile ? (
            <div
              className={`border-black border-2 p-2 ${styles.selectButton} flex justify-center items-center`}
              onClick={() => {}}
            >
              <p className="text-center">{t("save_draft")}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </form>
  );
};

export default SparePartsUpdateForm;
