import
{
    LIST_CATEGORIES_LOADING,
    LIST_CATEGORIES_SUCCESS,
    LIST_CATEGORIES_FAILURE
}
from "redux/types";

const initalState = {
    categoriesLoading: false,
    categories: null,
    errors: null
};

export const listCategoriesReducer = (state = initalState, action: any) => {
    switch (action.type) {
        case LIST_CATEGORIES_LOADING :
            return {...state, categoriesLoading: action.payload};
        case LIST_CATEGORIES_SUCCESS :
            return {...state, categories: action.payload.data};
        case LIST_CATEGORIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}