import {
  VERIFY_OTP_LOADING,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  RESET_USER_DATA,
  UPDATE_CUSTOMER_DATA,
} from "redux/types";

const initialState = {
  verifyOtpLoading: false,
  userData: null,
  isExhibitor: false,
  errors: null,
};

export const verifyOtpReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case VERIFY_OTP_LOADING:
      return { ...state, verifyOtpLoading: action.payload };
    case VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        userData: action.payload,
        isExhibitor: action.payload?.is_exhibitor,
      };
    }
    case VERIFY_OTP_FAILURE:
      return { ...state, errors: action.payload };
    case UPDATE_CUSTOMER_DATA:
      return {
        ...state,
        userData: action.payload,
        isExhibitor: action.payload?.is_exhibitor,
      };
    case RESET_USER_DATA:
      return { ...state, userData: null };
    default:
      return state;
  }
};
