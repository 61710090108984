import { FC } from "react";
import styles from "components/CustomCard/CustomCard.module.css";

interface CardProps {
  name: string;
  image: any;
  cardStyle?: string;
  textStyle?: string;
  onClick?: any;
  hover?: boolean;
  style?: any;
  makeBlue?: boolean;
}

const CustomCard: FC<CardProps> = ({
  name,
  image,
  cardStyle,
  textStyle,
  style,
  onClick,
  makeBlue = false,
  hover = false,
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-between rounded-xl truncate ${
        styles.cardContainer
      } ${cardStyle} ${hover ? styles.hover : ""}`}
      style={style}
      onClick={onClick}
    >
      <div className={`${makeBlue ? styles.blueIcon : ''}`}>
        <img className={styles.image} src={image} alt={name} />
      </div>
      <p className={`${styles.textStyle}`}>{name}</p>
    </div>
  );
};

export default CustomCard;
