import api from "api";
import { suspendPostEndPoint } from "api/apiUrls";
import store from "redux/store";

export const suspendPostService = async (postId: any) => {
    const response = await api.post(
      suspendPostEndPoint(postId),
        {
          
        },
        {
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${store.getState().verifyOtpReducer?.userData?.token}`
            }
        }
    )
    return response;
}