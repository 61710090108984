import { SET_MAP_POST_STATE } from "redux/types";

const initialState = {
  mapState: 0,
};

export const setMapCoordinatesPostReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MAP_POST_STATE:
      return { ...state, mapState: action.payload };
    default:
      return state;
  }
};
