import { ArrowLeftIcon } from "assets/icons/V3";
import styles from "components/V3/NotificationsCard/NotificationsCard.module.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useResponsive } from "hooks/useResponsive";

interface NotificationsCardProps {
  id: any;
  postID: any;
  postTitle: any;
  notificationTitle: string;
  description: string;
}

const NotificationsCard: FC<NotificationsCardProps> = ({
  id,
  postID,
  postTitle,
  notificationTitle,
  description,
}) => {
  const { t } = useTranslation();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const navigate = useNavigate();

  const { isMobile } = useResponsive();

  var newDescription = null;
  var rejectReason = null;

  if (notificationTitle === "Your Post Has Been Rejected") {
    notificationTitle = t("rejected_title");
    rejectReason = description.split(": ")[1];
    newDescription =
      description.split("has been rejected")[0] +
      " " +
      t("rejected_desc") +
      " : " +
      rejectReason;
  } else if (notificationTitle === "Your Post Has Been Accepted") {
    notificationTitle = t("accepted_title");
    newDescription =
      description.split("has been accepted")[0] + " " + t("accepted_desc");
  }

  return (
    <div
      dir="rtl"
      className={`w-full ${
        isMobile ? "h-[4.8125rem]" : "h-[6.05319rem]"
      } flex flex-row justify-between items-center px-6 bg-white ${
        styles.shadow
      } rounded-[0.9375rem] hover:cursor-pointer`}
      onClick={() => {
        navigate(`/${currentLanguage}/ads/show/${postID}/${postTitle}}`);
      }}
    >
      <div className={`w-full flex flex-col`}>
        <p className="text-[0.9375rem]">{notificationTitle}</p>
        <p className="text-[0.8125rem] opacity-[0.5]">
          {newDescription ?? description}
        </p>
      </div>
      <img src={ArrowLeftIcon} className="w-[1rem] h-[1rem]" />
    </div>
  );
};

export default NotificationsCard;
