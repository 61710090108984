import * as React from "react";
import type { SVGProps } from "react";
const SvgFooterYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <circle cx={14.228} cy={13.697} r={13.697} fill="#0050FF" />
    <path
      fill="currentColor"
      d="M19.326 12.95a1 1 0 0 1 0 1.731l-7.027 4.057a1 1 0 0 1-1.5-.866V9.758a1 1 0 0 1 1.5-.866l7.027 4.057Z"
    />
  </svg>
);
export default SvgFooterYoutube;
