import { FC } from "react";
import { UserOutlined } from "@ant-design/icons";
import styles from "components/ChatContactCard/ChatContactCard.module.css";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface ChatContactCardProps {
  contactName: string;
  onClick: any;
  isSelected: boolean;
  personalImage: any;
  id: any;
}

const ChatContactCard: FC<ChatContactCardProps> = ({
  contactName,
  onClick,
  isSelected,
  personalImage,
  id,
}) => {
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  return (
    <div
      onClick={onClick}
      className={`w-full ${styles.contactCard} ${
        isSelected ? styles.selectedContact : styles.normalContact
      } h-[70px] flex flex-row items-center justify-start p-2 gap-x-4 hover:cursor-pointer`}
    >
      <a
        target="_blank"
        href={`/${currentLanguage}/vendor/${id}`}
        style={{
          backgroundImage: `url('${encodeURI(personalImage)}')`,
          backgroundSize: "cover",
        }}
        className={`${styles.contactImage} rounded-full h-10 w-10 flex items-center justify-center`}
      >
        {/* <UserOutlined className="text-white text-lg" /> */}
      </a>

      <div className="h-full flex flex-col items-start justify-center">
        <p className={`${styles.contactName} font-bold`}>{contactName}</p>
        <p className={`${styles.contactDesc}`}>Click to chat</p>
      </div>
    </div>
  );
};

export default ChatContactCard;
