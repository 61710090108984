import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  suspendPostAction,
  profilePostStatusAction,
  reactivatePostAction,
  setMapCoordinatesPostAction,
  setUpdatePostData,
} from "redux/actions";
import { useLanguage } from "./useLanguage";
import { useNavigate } from "react-router";
import { makePostSpecialAction } from "redux/actions/MakePostSpecialAction/makePostSpecialAction";

type Breakpoint = {
  value: number;
  type: "MAX" | "MIN";
  setState: React.Dispatch<React.SetStateAction<boolean>>;
};

const mobileWidth = 480;

export const usePostActions = () => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();
  const { currentLanguage } = useLanguage();

  const handleSuspendPostClick = async (postId: any) => {
    await dispatch(suspendPostAction(postId));
    window.location.reload();
    dispatch(profilePostStatusAction("Inactive"));
  };

  const handleReactivatePostClick = async (postId: any) => {
    await dispatch(reactivatePostAction(postId));
    window.location.reload();
    dispatch(profilePostStatusAction("Pending"));
  };

  const handlePostEditClick = async (id: any, lat?: any, lng?: any) => {
    dispatch(setMapCoordinatesPostAction({ lat: lat, lng: lng }));
    await dispatch(setUpdatePostData(id));
    navigate(`/${currentLanguage}/post/update`);
  };

  const handleMakeSpecialClick = async (postUUId: any) => {
    await dispatch(makePostSpecialAction(postUUId));
    window.location.reload();
  };

  return {
    handleSuspendPostClick,
    handleReactivatePostClick,
    handlePostEditClick,
    handleMakeSpecialClick,
  };
};
