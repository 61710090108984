import
{
    AMANLEEK_BRANDS_LOADING,
    AMANLEEK_BRANDS_SUCCESS,
    AMANLEEK_BRANDS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { amanleekListBrandsService } from "ThirdPartyServices/Amanleek/amanleekListBrandsService";

export const amanleekListBrandsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: AMANLEEK_BRANDS_LOADING, payload: true});
        const response = await amanleekListBrandsService();
        dispatch({type: AMANLEEK_BRANDS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: AMANLEEK_BRANDS_FAILURE, payload: err});
    } finally {
        dispatch({type: AMANLEEK_BRANDS_LOADING, payload: false});
    }
}