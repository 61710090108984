import styles from "components/V3/ExhibitorComponents/ExhibitorCard/ExhibitorCard.module.css";
import {
  PhoneFilled,
  EyeFilled,
  HeartFilled,
  HeartOutlined,
  ShareAltOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { FC, useEffect } from "react";
import { CustomIcon } from "components/CustomIcon/CustomIcon";
import { websiteIcon, whatsAppIcon } from "assets/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Modal, Tooltip, notification } from "antd";
import { WhatsAppIcon as WhatsappSVG } from "assets/icons/svg-components";

import ShareButton from "components/V3/ShareButton/ShareButton";
import { favouritePostAction } from "redux/actions/FavouritePostActions/favouritePostAction";
import { useResponsive } from "hooks/useResponsive";
import {
  facebookIcon,
  linkedinIcon,
  instagramIcon,
  xTwitter,
  youtubeIcon,
} from "assets/icons";
import {
  FacebookIcon,
  XTwitter,
  InstagramIcon,
  LinkedinIcon,
  YoutubeIcon,
  TiktokIcon,
} from "assets/IconComponents";
import { PhoneIcon, WhatsAppIcon } from "assets/icons/index-svg";
import { ChatIcon, DashedCircle } from "assets/IconComponents";
import { WebsiteIcon } from "assets/icons/svg-components/index";
import { useIsDefined } from "hooks/useIsDefined";
import { useTimeFormat } from "hooks/useTimeFormat";
import {
  BlackUserIcon,
  ShareButtonIcon,
  StarIcon,
  WhiteLogo,
} from "assets/icons/V3";
import api from "api";
import { useNavigate, useParams } from "react-router";
import { getVendorDataAction } from "redux/actions/VendorDataActions/vendorDataActions";
import { sendChatMessageAction } from "redux/actions/ChatsActions/sendChatMessageAction";

interface ExhibitorCardProps {
  // id: any;
  profileImage: any;
  coverImage: any;
  name: string;
  description?: string;
  email: string;
  callsCount: number;
  viewsCount: number;
  totalPosts: number;
  phoneNumber: string;
  whatsappNumber: string;
  hotline?: string;
  isFavorited: boolean;
  mainService: string;
  whatsappCount: string;
  socialLinks?: any;
  style?: any;
}

const ExhibitorCard: FC<ExhibitorCardProps> = ({
  callsCount,
  // id,
  email,
  viewsCount,
  whatsappCount,
  totalPosts,
  name,
  description = null,
  mainService,
  phoneNumber,
  isFavorited,
  whatsappNumber,
  hotline,
  profileImage,
  coverImage,
  socialLinks,
  style,
}) => {
  // EXAMPLE LINKS
  // {
  //   website_link: 'https://www.example.com',
  //   facebook_link: 'https://www.facebook.com/example',
  //   youtube_link: 'https://www.youtube.com/user/example',
  //   instagram_link: 'https://www.instagram.com/example',
  //   twitter_link: 'https://twitter.com/example',
  //   linkedin_link: 'https://www.linkedin.com/in/example',
  // };
  const { t } = useTranslation();

  const { isMobile } = useResponsive();
  const { isDefined } = useIsDefined();
  const { formatDate } = useTimeFormat();

  const centreStyle = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  // const exampleCover =
  //   "https://images.unsplash.com/photo-1643235988160-9aabe50b74ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3431&q=80";

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { verifyOtpLoading, userData, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { vendorData, vendorDataLoading } = useSelector(
    (state: RootState) => state.getVendorDataReducer
  );

  const isArabic = currentLanguage === "ar";
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showHotlineModal, setShowHotlineModal] = useState(false);
  const [favoriteState, setFavoriteState] = useState(isFavorited);
  const { id } = useParams();
  // {`'${}'`} customers
  const coverPhotoExists = encodeURI(coverImage).split("customers/")[1] !== "";

  const profilePhotoExists =
    encodeURI(profileImage).split("customers/")[1] !== "";

  const imageEmpty = (imageUrl: any) => {
    if (imageUrl.split("customers/")[1] === "") {
      return true;
    } else {
      return false;
    }
  };

  // check if whatsapp number starts with +20. if not add it
  if (whatsappNumber) {
    if (whatsappNumber.length === 10) {
      whatsappNumber = "+20" + whatsappNumber;
    } else if (whatsappNumber.length === 11 && whatsappNumber.startsWith("0")) {
      whatsappNumber = "+2" + whatsappNumber;
    }
  }

  useEffect(() => {
    if (vendorData) {
      setFavoriteState(vendorData?.favourited);
    }
  }, [vendorData]);

  const handleFavoriteClick = async () => {
    setFavoriteState((prev) => !prev);

    const res = await api.post(
      `portal/favouriteExhibitor/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      }
    );
    await dispatch(getVendorDataAction(id));
  };

  const handleSendMessage = async () => {
    await dispatch(sendChatMessageAction(id, "*$$*"));
    navigate(`/${currentLanguage}/messages?vendorId=${id}`);
  };

  const emptySocialLinks: boolean =
    !isDefined(socialLinks) ||
    (!isDefined(socialLinks?.facebook_link) &&
      !isDefined(socialLinks?.twitter_link) &&
      !isDefined(socialLinks?.instagram_link) &&
      !isDefined(socialLinks?.youtube_link) &&
      !isDefined(socialLinks?.linkedin_link));

  const renderStats = () => {
    return (
      <div
        className={`w-full flex ${
          isMobile && "flex-col gap-2"
        } items-center justify-between mb-[0.625rem]`}
      >
        <div
          dir={"ltr"}
          className={`${styles.dateContainer}  souq-xs flex justify-end ${
            isMobile && "w-full items-center justify-center"
          } gap-3 border-round-xs ${styles.grayBackground}`}
          style={{
            paddingInline: isMobile ? "0.5rem" : "1rem",
            paddingBlock: isMobile ? "0.5rem" : "0.3rem",
          }}
        >
          <p>{formatDate(vendorData?.created_at, "en", "d - M - yyyy")}</p>
          <p>:عضو منذ</p>
        </div>
        <div
          className={`souq-xs ${
            isMobile && "w-full"
          } h-full flex flex-row gap-x-[0.5rem]`}
        >
          <div
            className={`${
              styles.grayBackground
            } border-round-xs h-full flex flex-row items-center ${
              isMobile && "justify-center flex-grow"
            } gap-x-2 rounded-full`}
            style={{
              paddingInline: isMobile ? "0.5rem" : "1rem",
              paddingBlock: isMobile ? "0.5rem" : "0.3rem",
            }}
          >
            <EyeFilled />
            <p>{viewsCount}</p>
          </div>
          <div
            className={`${
              styles.grayBackground
            } border-round-xs h-full flex flex-row items-center ${
              isMobile && "justify-center flex-grow"
            } gap-x-2 rounded-full`}
            style={{
              paddingInline: isMobile ? "0.5rem" : "1rem",
              paddingBlock: isMobile ? "0.5rem" : "0.3rem",
            }}
          >
            <PhoneFilled />
            <p>{callsCount}</p>
            <div className="w-[0.2rem] h-[0.2rem] rounded-[20rem] bg-black"></div>
            <WhatsappSVG />
            <p>{whatsappCount}</p>
          </div>
        </div>
      </div>
    );
  };

  return !isMobile ? (
    <div
      className={`w-full border-round-s ${styles.contactCard} bg-white ${styles.shadow} flex flex-col`}
      style={{ ...style }}
    >
      {renderStats()}

      {/* COVER IMAGE + SOCIAL ICONS*/}
      <div
        className="w-full h-[13.125rem]"
        style={{
          backgroundImage: !imageEmpty(coverImage)
            ? `url('${encodeURI(coverImage)}')`
            : ``,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "var(--new-blue)",
          borderRadius: "0.5rem",
          position: "relative",
        }}
      >
        {!coverPhotoExists && (
          <img
            className="absolute"
            src={WhiteLogo}
            style={{
              width: "15rem",
              opacity: 0.2,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}

        {/* SOCIAL ICONS */}
        {!emptySocialLinks && (
          <div
            className={`flex flex-wrap items-center ${styles.socialContainer}`}
            style={isArabic ? { right: "0.6rem" } : { left: "0.6rem" }}
          >
            {socialLinks?.website_link && (
              <a href={socialLinks?.website_link} target="_blank">
                <WebsiteIcon style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={websiteIcon}
                  alt=""
                /> */}
              </a>
            )}
            {socialLinks?.tiktok_link && (
              <a href={socialLinks?.tiktok_link} target="_blank">
                <TiktokIcon style={{ fontSize: "1.2rem" }} />
              </a>
            )}
            {socialLinks?.facebook_link && (
              <a href={socialLinks?.facebook_link} target="_blank">
                <FacebookIcon style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={facebookIcon}
                  alt=""
                /> */}
              </a>
            )}
            {socialLinks?.youtube_link && (
              <a href={socialLinks?.youtube_link} target="_blank">
                <YoutubeIcon style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={websiteIcon}
                  alt=""
                /> */}
              </a>
            )}
            {socialLinks?.instagram_link && (
              <a href={socialLinks?.instagram_link} target="_blank">
                {/* <InstagramIcon style={{ fontSize: "1.2rem" }} /> */}
                <img
                  className={`${styles.socialIcons}`}
                  src={instagramIcon}
                  alt=""
                />
              </a>
            )}
            {socialLinks?.twitter_link && (
              <a href={socialLinks?.twitter_link} target="_blank">
                <XTwitter style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={websiteIcon}
                  alt=""
                /> */}
              </a>
            )}
            {socialLinks?.linkedin_link && (
              <a href={socialLinks?.linkedin_link} target="_blank">
                <LinkedinIcon style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={websiteIcon}
                  alt=""
                /> */}
              </a>
            )}
          </div>
        )}
        <div
          className="absolute flex flex-row items-start justify-center gap-x-2"
          style={{
            top: "0.6rem",
            ...(isArabic ? { left: "0.6rem" } : { right: "0.6rem" }),
          }}
        >
          {userData?.token && (
            <div
              id="favoriteClick"
              className={`bg-white pt-1 cursor-pointer rounded-full flex items-center justify-center ${
                isMobile
                  ? "w-[1.625rem] h-[1.625rem]"
                  : "w-[2.1875rem] h-[2.1875rem]"
              }`}
              onClick={handleFavoriteClick}
            >
              {!favoriteState ? (
                <HeartOutlined
                  style={{
                    fontSize: isMobile ? "1rem" : "1.25rem",
                  }}
                />
              ) : (
                <HeartFilled
                  style={{
                    fontSize: isMobile ? "1rem" : "1.25rem",
                    color: "red",
                  }}
                />
              )}
            </div>
          )}
          <ShareButton url={window.location.href}>
            <img
              alt="share"
              src={ShareButtonIcon}
              className={`cursor-pointer ${
                isMobile
                  ? "w-[1.625rem] h-[1.625rem]"
                  : "w-[2.1875rem] h-[2.1875rem]"
              }`}
            />
          </ShareButton>
        </div>
        {/* <ShareButton
          className={`absolute top-[0.6rem] `}
          style={{
            ...(isArabic ? { left: "0.6rem" } : { right: "0.6rem" }),
          }}
          // style={isArabic ? { left: "0.6rem" } : { right: "0.6rem" }}
          width={2}
          showText={false}
          url={window.location.href}
        /> */}
      </div>

      <div
        className="w-full flex pr-5"
        // style={{ display: "grid", position: "relative", alignSelf: "center" }}
      >
        {/* PROFILE IMAGE */}
        <div
          className={`relative mt-3 ml-6`}
          style={{ width: "6.8rem", height: "6.8rem" }}
          // style={{ width: "fit-content" }}
        >
          {vendorData?.hasStory && (
            <div
              className={`absolute`}
              style={{
                width: "6.8rem",
                height: "6.8rem",
                ...centreStyle,
              }}
            >
              <DashedCircle className={`w-full h-full ${styles.rotate}`} />
            </div>
          )}
          <img
            className="absolute top-2 right-1 h-full"
            style={{
              backgroundColor: "var(--souq-orange)",
              borderRadius: "100rem",
              width: "1.86194rem",
              height: "1.375rem",
              zIndex: 2,
            }}
            src={StarIcon}
          />
          <div
            className={`absolute ${styles.profileImageContainer} ${
              vendorData?.hasStory ? "cursor-pointer" : ""
            }`}
            onClick={() => {
              if (vendorData?.hasStory) {
                navigate(`/${currentLanguage}/stories/${vendorData?.id}`);
              }
            }}
            style={{
              width: "6rem",
              height: "6rem",
              ...centreStyle,
            }}
          >
            <div
              className={`${styles.profileImage} bg-[var(--new-blue)]`}
              style={{
                backgroundImage:
                  profileImage?.split("customers/")[1] === ""
                    ? `url('${BlackUserIcon}')`
                    : `url('${encodeURI(profileImage)}')`,
                backgroundSize:
                  profileImage?.split("customers/")[1] === ""
                    ? "auto 50%"
                    : "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
        </div>

        {/* CONTACT BUTTONS {PHONE + WHATSAPP} */}
        <div className="flex flex-grow items-center justify-between">
          <div className="flex justify-end">
            {/* <div style={{ width: "8rem", marginLeft: "1rem" }}></div> */}
            <div className="flex flex-col gap-y-2">
              <div className="flex items-center justify-start">
                <h1 className={`${styles.exhibitorName}`}>{name}</h1>
                {mainService !== "" && (
                  <p className={`${styles.exhibitorType}`}>{mainService}</p>
                )}
              </div>
              {description && (
                <p
                  className={`${styles.exhibitorDesc}`}
                  style={{
                    visibility: `${description ? "visible" : "hidden"}`,
                    fontSize: ".75rem",
                  }}
                >
                  {description ?? t("description")}
                </p>
              )}
            </div>
          </div>

          <div className={`flex flex-wrap items-center justify-center gap-2`}>
            {hotline && (
              <a
                target="_blank"
                className={`${styles.contactButton}`}
                style={{
                  backgroundColor: "var(--sky-blue-background)",
                  border: "1px solid red",
                }}
                onClick={() => {
                  setShowHotlineModal(true);
                }}
              >
                <PhoneIcon style={{ color: "red" }} fontSize={".7rem"} />
                {t("hotline")}
              </a>
            )}
            <a
              target="_blank"
              className={`${styles.contactButton}`}
              style={{
                backgroundColor: "var(--sky-blue-background)",
                border: "1px solid var(--new-blue)",
              }}
              onClick={() => {
                setShowPhoneModal(true);
              }}
            >
              <PhoneIcon
                style={{ color: "var(--new-blue)" }}
                fontSize={".7rem"}
              />
              {t("call_us")}
            </a>
            <a
              // href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              className={`${styles.contactButton}`}
              style={{
                backgroundColor: "var(--sky-blue-background)",
                border: "1px solid var(--new-blue)",
              }}
              onClick={handleSendMessage}
            >
              <ChatIcon
                style={{ color: "var(--new-blue)" }}
                fontSize={"1rem"}
              />
              {t("send_message")}
            </a>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              className={`${styles.contactButton}`}
              style={{
                backgroundColor: "rgba(1, 219, 49, 0.03)",
                border: "1px solid #01DB31",
              }}
            >
              <WhatsAppIcon style={{ color: "#01DB31" }} fontSize={"1rem"} />
              {t("whatsapp")}
            </a>
          </div>
        </div>
      </div>
      <Modal
        open={showPhoneModal}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        onCancel={() => {
          setShowPhoneModal(false);
        }}
      >
        <div className="w-full flex flex-col items-center gap-y-6">
          <p className="mt-[1.8rem] text-[var(--new-blue)] text-[1.25rem] font-[700]">
            {t("with_phone_number")}
          </p>
          <div
            className={`${styles.blueOpacityHalf} w-full h-[3.4375rem] flex items-center justify-center rounded-[0.625rem]`}
          >
            <p>{phoneNumber}</p>
          </div>
          <p>{t("bedan_word")}</p>
        </div>
      </Modal>

      <Modal
        open={showHotlineModal}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        onCancel={() => {
          setShowHotlineModal(false);
        }}
      >
        <div className="w-full flex flex-col items-center gap-y-6">
          <p className="mt-[1.8rem] text-[var(--new-blue)] text-[1.25rem] font-[700]">
            {t("hotline")}
          </p>
          <div
            className={`${styles.blueOpacityHalf} w-full h-[3.4375rem] flex items-center justify-center rounded-[0.625rem]`}
          >
            <p>{hotline}</p>
          </div>
          <p>{t("bedan_word")}</p>
        </div>
      </Modal>
    </div>
  ) : (
    ////////////
    // MOBILE //
    ////////////
    <div
      className={`w-full rounded-xl ${styles.contactCardMobile} flex flex-col`}
      style={{ ...style }}
    >
      {/* TOP ICONS [STATS + SHARE BUTTON] */}
      {/* <div className={`w-full flex flex-row justify-between items-center mb-2`}> */}
      {/* LEFT SECTION */}
      {/* <ShareButton url={window.location.href} /> */}

      {renderStats()}

      {/* COVER IMAGE + SOCIAL ICONS*/}
      <div
        className="w-full h-[12rem] mb-3"
        style={{
          backgroundImage: !imageEmpty(coverImage)
            ? `url('${encodeURI(coverImage)}')`
            : ``,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "var(--new-blue)",
          borderRadius: "0.5rem",
          position: "relative",
        }}
      >
        {!coverPhotoExists && (
          <img
            className="absolute"
            src={WhiteLogo}
            style={{
              width: "13rem",
              opacity: 0.2,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}

        {/* SOCIAL ICONS */}
        {!emptySocialLinks && (
          <div
            className={`flex flex-wrap items-center ${styles.socialContainer}`}
            style={isArabic ? { right: "0.6rem" } : { left: "0.6rem" }}
          >
            {socialLinks?.website_link && (
              <a href={socialLinks?.website_link} target="_blank">
                <WebsiteIcon style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={websiteIcon}
                  alt=""
                /> */}
              </a>
            )}
            {socialLinks?.tiktok_link && (
              <a href={socialLinks?.tiktok_link} target="_blank">
                <TiktokIcon style={{ fontSize: "1.2rem" }} />
              </a>
            )}
            {socialLinks?.facebook_link && (
              <a href={socialLinks?.facebook_link} target="_blank">
                <FacebookIcon style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={facebookIcon}
                  alt=""
                /> */}
              </a>
            )}
            {socialLinks?.youtube_link && (
              <a href={socialLinks?.youtube_link} target="_blank">
                <YoutubeIcon style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={websiteIcon}
                  alt=""
                /> */}
              </a>
            )}
            {socialLinks?.instagram_link && (
              <a href={socialLinks?.instagram_link} target="_blank">
                {/* <InstagramIcon style={{ fontSize: "1.2rem" }} /> */}
                <img
                  className={`${styles.socialIcons}`}
                  src={instagramIcon}
                  alt=""
                />
              </a>
            )}
            {socialLinks?.twitter_link && (
              <a href={socialLinks?.twitter_link} target="_blank">
                <XTwitter style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={websiteIcon}
                  alt=""
                /> */}
              </a>
            )}
            {socialLinks?.linkedin_link && (
              <a href={socialLinks?.linkedin_link} target="_blank">
                <LinkedinIcon style={{ fontSize: "1.2rem" }} />
                {/* <img
                  className={`${styles.socialIcons}`}
                  src={websiteIcon}
                  alt=""
                /> */}
              </a>
            )}
          </div>
        )}
      </div>

      <div
        className="w-full"
        style={{ display: "grid", position: "relative", alignSelf: "center" }}
      >
        {/* PROFILE IMAGE */}
        <div
          className={`${styles.profileImageContainer}`}
          style={{
            position: "absolute",
            alignSelf: "center",
            justifySelf: "center",
            top: "-3.25rem",
            backgroundColor: "white",
            outline: "0.3rem solid var(--new-souq-background)",
            width: "7rem",
            height: "7rem",
          }}
        >
          <div
            className={`${styles.profileImage}`}
            style={{
              backgroundImage: `url('${encodeURI(profileImage)}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>
      {/* INFO AND CONTACT */}
      <div className="flex flex-col-reverse items-center justify-between">
        {/* CONTACT BUTTONS {PHONE + WHATSAPP} */}
        {hotline && (
          <a
            href={`tel:${vendorData?.hotline}`}
            target="_blank"
            className={`${styles.contactButton}`}
            style={{
              backgroundColor: "var(--sky-blue-background)",
              border: "1px solid red",
            }}
          >
            <PhoneIcon style={{ color: "red" }} fontSize={".7rem"} />
            {t("hotline")}
          </a>
        )}
        {/* EXHIBITOR NAME + DESCRIPTION */}
        <div className="flex justify-end" style={{ marginTop: "4rem" }}>
          <div className="flex flex-col items-center">
            <h1 className={`${styles.exhibitorName} text-center`}>{name}</h1>
            <p
              className={`${styles.exhibitorType}`}
              style={{ width: "fit-content" }}
            >
              {mainService}
            </p>
            {description && (
              <p
                className={`${styles.exhibitorDesc}`}
                style={{
                  visibility: `${description ? "visible" : "hidden"}`,
                  fontSize: ".75rem",
                  textAlign: "center",
                }}
              >
                {description ?? t("description")}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitorCard;
