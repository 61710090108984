import api from "api/amanleekApi";
import { amanleekBrandsEndPoint } from "api/apiUrls";
import store from "redux/store";

export const amanleekListBrandsService = async () => {
    const response = await api.get(
        amanleekBrandsEndPoint(),
        {
            headers : {
                "Authorization" : `Bearer ${store.getState()?.amanleekAuthenticationReducer?.amanleekToken}`
            }
        }
    );
    
    return response;
}