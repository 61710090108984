import ProtectedRoutes from "Routes/ProtectedRoutes";
import {
  HOME_PATH,
  LOGIN_PATH,
  CREATE_POST_PATH,
  UPDATE_POST_PATH,
  VIEW_POST_PATH,
  VIEW_PROFILE_PATH,
  ERROR_PATH,
  BASE_PATH,
  FAQS_PATH,
  CONTACT_US_PATH,
  VIEW_POSTS_PATH,
  ABOUT_US_PATH,
  PRIVACY_POLICY,
  VIEW_CAR_NEWS,
  VIEW_NEWS_ARTICLE,
  TERMS_OF_USE,
  VENDOR_PATH,
  CHAT_SCREEN_PATH,
  SPECIAL_ADS_PATH,
  FAVORITE_POSTS_PATHS,
  INSURANCE_SCREEN_PATH,
  NOTIFICATIONS_PATHS,
  EXHIBITIONS_PATH,
  STORIES_PATH,
  CREATE_STORY_PATH,
  SINGLE_STORY_PATH,
  STORE_PATH,
} from "Routes/paths";
import HomeScreen from "screens/V3/HomeScreen/HomeScreen";
import LoginScreen from "screens/V3/LoginScreen/LoginScreen";
import CreatePostScreen from "screens/V3/CreatePostScreen/CreatePostScreen";
import ViewPostScreen from "screens/V3/ViewPostScreen/ViewPostScreen";
import ViewProfileScreen from "screens/ViewProfileScreen/ViewProfileScreen";
import ErrorScreen from "screens/ErrorScreen/ErrorScreen";
import { Navigate } from "react-router";
import getPreferredLanguage from "lang/languageUtils";
import Faqs from "screens/V3/Faqs/Faqs";
import ContactUs from "screens/V3/ContactUs/ContactUs";
import ViewPostsScreen from "screens/V3/ViewPostsScreen/ViewPostsScreen";
import AboutUs from "screens/V3/AboutUs/AboutUs";
import PrivacyPolicy from "screens/V3/PrivacyPolicy/PrivacyPolicy";
import Article_page from "screens/V3/SingleArticleScreen/SingleArticleScreen";
import AllArticlesPage from "screens/V3/ArticlesScreen/ArticlesScreen";
import TermsOfUse from "screens/V3/TermsOfUse/TermsOfUse";
import VendorScreen from "screens/VendorScreen/VendorScreen";
import ChatScreen from "screens/ChatScreen/ChatScreen";
import UpdatePostScreen from "screens/V3/UpdatePostScreen/UpdatePostScreen";
import SpecialAds from "screens/V3/SpecialAds/SpecialAds";
import FavoritePostsScreen from "screens/V3/FavouritePostsScreen/FavouritePostsScreen";
import InsuranceScreen from "screens/InsuranceScreen/InsuranceScreen";
import NotificationScreen from "screens/V3/NotificationsScreen/NotificationsScreen";
import ExhibitionsScreen from "screens/V3/ExhibitionsScreen/ExhibitionsScreen";
import SingleStoryScreen from "screens/V3/SingleStoryScreen/SingleStoryScreen";
import CreateStoryScreen from "screens/V3/CreateStoryScreen/CreateStoryScreen";
import StoriesScreen from "screens/V3/StoriesScreen/StoriesScreen";
import StoreScreen from "screens/V3/StoreScreen/StoreScreen";

export const routes = [
  // {
  //   path: ERROR_ROUTE_PATH,
  //   element: <ResponseModal error message="Page not found" errorBoundary />,
  // },
  {
    path: BASE_PATH,
    element: <Navigate to={`${getPreferredLanguage()}`} />,
  },
  {
    path: HOME_PATH,
    element: <HomeScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: LOGIN_PATH,
    element: <LoginScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_POST_PATH,
    element: <CreatePostScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_POST_PATH,
    element: <UpdatePostScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: VIEW_POST_PATH,
    element: <ViewPostScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: VIEW_PROFILE_PATH,
    element: <ViewProfileScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: ERROR_PATH,
    element: <ErrorScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: FAQS_PATH,
    element: <Faqs />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CONTACT_US_PATH,
    element: <ContactUs />,
    parent: <ProtectedRoutes />,
  },
  {
    path: VIEW_POSTS_PATH,
    element: <ViewPostsScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: ABOUT_US_PATH,
    element: <AboutUs />,
    parent: <ProtectedRoutes />,
  },
  {
    path: PRIVACY_POLICY,
    element: <PrivacyPolicy />,
    parent: <ProtectedRoutes />,
  },
  {
    path: VIEW_CAR_NEWS,
    element: <AllArticlesPage />,
    parent: <ProtectedRoutes />,
  },
  {
    path: VIEW_NEWS_ARTICLE,
    element: <Article_page />,
    parent: <ProtectedRoutes />,
  },
  {
    path: TERMS_OF_USE,
    element: <TermsOfUse />,
    parent: <ProtectedRoutes />,
  },
  {
    path: VENDOR_PATH,
    element: <VendorScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CHAT_SCREEN_PATH,
    element: <ChatScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: SPECIAL_ADS_PATH,
    element: <SpecialAds />,
    parent: <ProtectedRoutes />,
  },
  {
    path: FAVORITE_POSTS_PATHS,
    element: <FavoritePostsScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: INSURANCE_SCREEN_PATH,
    element: <InsuranceScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: NOTIFICATIONS_PATHS,
    element: <NotificationScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: EXHIBITIONS_PATH,
    element: <ExhibitionsScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: SINGLE_STORY_PATH,
    element: <SingleStoryScreen />,
  },
  {
    path: CREATE_STORY_PATH,
    element: <CreateStoryScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: STORIES_PATH,
    element: <StoriesScreen />,
    parent: <ProtectedRoutes />,
  },
  {
    path: STORE_PATH,
    element: <StoreScreen />,
    parent: <ProtectedRoutes />,
  },
];
