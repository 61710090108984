import { CopyOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Tooltip, notification } from "antd";
import styles from "components/ShareButton/ShareButton.module.css";
import { t } from "i18next";
import { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface ShareButtonProps {
  url: string;
  copyURL?: string;
  fontSize?: any;
  px?: any;
  py?: any;
  showText?: boolean;
}

const ShareButton: FC<ShareButtonProps> = ({
  url,
  copyURL,
  fontSize = "",
  px = "",
  py = "",
  showText = true,
}) => {
  const currentLanguage = useSelector(
    (state: any) => state.changeLanguageReducer.currentLanguage
  );
  const dirFlip = currentLanguage === "en" ? "rtl" : "ltr";
  const [copySuccess, setCopySuccess] = useState("");
  const [copyText, setCopyText] = useState("");
  const textAreaRef = useRef(null);

  return (
    <Tooltip
      color="white"
      style={{ padding: 10 }}
      title={
        <div className="w-full flex flex-col items-center gap-y-2">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <FacebookShareButton url={url}>
              <FacebookIcon size={30} />
            </FacebookShareButton>
            <p className="text-black">Facebook</p>
          </div>
          <div className="w-full flex flex-row gap-x-2 items-center">
            <WhatsappShareButton url={url}>
              <WhatsappIcon size={30} />
            </WhatsappShareButton>
            <p className="text-black">Whatsapp</p>
          </div>
          <div className="w-full flex flex-row gap-x-2 items-center">
            <CopyOutlined className="text-black" />
            <CopyToClipboard text={copyURL ?? url}>
              <button style={{ color: "black", fontFamily: "rbFont" }}>
                Copy To Clipboard
              </button>
            </CopyToClipboard>
          </div>
        </div>
      }
    >
      <div
        dir={dirFlip}
        className={`${styles.shareButton} flex flex-row items-center gap-x-1 rounded-full hover:cursor-pointer`}
        style={{
          paddingInline: px,
          paddingBlock: py,
          aspectRatio: !showText ? "1/1" : undefined,
        }}
      >
        {showText && (
          <p style={{ fontSize: fontSize }} className="text-sm">
            {t("viewpost_share")}
          </p>
        )}
        <ShareAltOutlined className="text-sm" style={{ fontSize: fontSize }} />
      </div>
    </Tooltip>
  );
};

export default ShareButton;
