import api from "api";
import { updateExhibitorSocialLinksEndPoint } from "api/apiUrls";
import store from "redux/store";

export const updateSocialLinksService = async (data: any) => {
    const response = await api.post(updateExhibitorSocialLinksEndPoint(), 
        data,
        {
            headers: {
                'Authorization': `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );

    return response;
}