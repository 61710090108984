import 
{
    MY_STORIES_LOADING,
    MY_STORIES_SUCCESS,
    MY_STORIES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listMyStoriesService } from "services/listMyStoriesService";

export const listMyStoriesAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: MY_STORIES_LOADING, payload: true});
        const response = await listMyStoriesService();
        dispatch({type: MY_STORIES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: MY_STORIES_FAILURE, payload: err});
    } finally {
        dispatch({type: MY_STORIES_LOADING, payload: false});
    }
}