import
{
    RENTAL_TYPES_LOADING,
    RENTAL_TYPES_SUCCESS,
    RENTAL_TYPES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listRentalTypesService } from "services/listRentalTypesService";

export const listRentalTypesAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: RENTAL_TYPES_LOADING, payload: true});
        const response = await listRentalTypesService();
        dispatch({type: RENTAL_TYPES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: RENTAL_TYPES_FAILURE, payload: err});
    } finally {
        dispatch({type: RENTAL_TYPES_LOADING, payload: false});
    }
}