import { ArrowLeftIcon } from "assets/icons/V3";
import styles from "components/V3/ExhibitorList/ExhibitorList.module.css";
import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useResponsive } from "hooks/useResponsive";
import { Empty, Spin } from "antd";
import CircularExhibitorCard from "../CircularExhibitorCard/CircularExhibitorCard";
import { useLanguage } from "hooks/useLanguage";
import ExhibitorCarousel from "components/Carousel/ExhibitorCarousel/ExhibitorCarousel";

interface ExhibitorListProps {
  whiteVariant?: boolean;
  slidesToShow?: number;
  smallVariant?: boolean;
}

const ExhibitorList: FC<ExhibitorListProps> = ({
  whiteVariant = false,
  slidesToShow,
  smallVariant = false,
}) => {
  const { t } = useTranslation();

  const { currentLanguage, textDirectionStyle } = useLanguage();
  const { isMobile } = useResponsive();

  const navigate = useNavigate();
  const { homeExhibitorsLoading, homeExhibitors } = useSelector(
    (state: RootState) => state.listHomeScreenExhibitorsReducer
  );

  return !isMobile ? (
    <div
      dir={textDirectionStyle}
      className={`py-4 w-full gap-5 rounded-[1.25rem] flex flex-col justify-between ${
        homeExhibitors?.length <= 0 || homeExhibitors === null
          ? "items-center"
          : "items-start"
      } ${whiteVariant && `px-4 bg-white ${styles.shadow}`}`}
    >
      <div
        dir={textDirectionStyle}
        className={`w-full flex flex-row items-center justify-between`}
      >
        <p className={`text-[1.25rem]`}>{t("homepage_special_dealers")}</p>
        <a
          href={`/${currentLanguage}/exhibitions`}
          className="hover:cursor-pointer"
          onClick={() => {
            // navigate(`/${currentLanguage}/exhibitions`);
          }}
        >
          {t("more")}
        </a>
      </div>
      {homeExhibitors?.length <= 0 || homeExhibitors === null ? (
        <Empty description={"لا يوجد معارض"} />
      ) : (
        <ExhibitorCarousel data={homeExhibitors} slidesToShow={slidesToShow} />
      )}
    </div>
  ) : (
    <div
      dir={textDirectionStyle}
      className={`w-full flex flex-col items-between gap-y-4 ${
        whiteVariant ? `bg-white px-3 py-8 ${styles.shadow}` : "mb-2"
      }`}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <p className="text-[0.8125rem]">{t("featured_exhibitions")}</p>
        <a
          href={`/${currentLanguage}/exhibitions`}
          className="text-[0.8125rem]"
          // onClick={() => navigate(`/${currentLanguage}/exhibitions`)}
        >
          {t("more_exhibitions")}
        </a>
      </div>
      {homeExhibitors?.length <= 0 || homeExhibitors === null ? (
        <Empty description={"لا يوجد معارض"} />
      ) : (
        <ExhibitorCarousel data={homeExhibitors} smallVariant />
      )}
    </div>
  );
};

export default ExhibitorList;
