import
{
    LIST_NOTIFICATIONS_LOADING,
    LIST_NOTIFICATIONS_SUCCESS,
    LIST_NOTIFICATIONS_FAILURE
}   
from "redux/types";

const initialState = {
    notificationsLoading: false,
    notifications: null,
    currentPage: null,
    lastPage: null,
    errors: null
};

export const listNotificationsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_NOTIFICATIONS_LOADING :
            return {...state, notificationsLoading: action.payload};
        case LIST_NOTIFICATIONS_SUCCESS :
            return {...state, notifications: action.payload.data.data, currentPage: action.payload.data.current_page, lastPage: action.payload.data.last_page};
        case LIST_NOTIFICATIONS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}