import { Modal } from "antd";
import styles from "components/V3/StoreCard/StoreCard.module.css";
import { FC, useEffect, useState } from "react";
import Stories from "react-insta-stories";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { ChatIcon } from "assets/IconComponents";
import { useResponsive } from "hooks/useResponsive";

interface StoreCardProps {
  product: any;
  priceAfterDiscount: any;
  cartItem: any;
  updateInCart: any;
  activeState: [isActive: boolean, setActiveProduct: any];
  detailsState: [currentProductID: any, setDetails: any];
}

const StoreCard: FC<StoreCardProps> = ({
  product,
  priceAfterDiscount,
  cartItem,
  updateInCart,
  activeState,
  detailsState,
}) => {
  const { isMobile } = useResponsive();
  const [isActive, setActiveProduct] = activeState;
  const [currentProductID, setDetails] = detailsState;
  const cardWidth = `calc(100% / 4 - 1rem + 0.25rem)`;
  const discountExists = product.discount > 0;
  const isInCart = cartItem ? true : false;
  const iconWidth = "75%";

  // This function is to prevent a click event from deselecting the current selected product
  const addDatasetAttributeToElements = (input: {
    parentClass: any;
    datasetName: any;
    datasetValue: any;
  }) => {
    // Select all elements within the specified parent container
    const elements = document.querySelectorAll(
      `.${input.parentClass}, .${input.parentClass} *`
    );

    // Add the specified dataset attribute to each element if it doesn't already exist
    elements.forEach((element) => {
      const attributeName = `data-${input.datasetName}`;

      // Check if the dataset attribute already exists
      if (!element.hasAttribute(attributeName)) {
        element.setAttribute(attributeName, input.datasetValue);
      }
    });
  };

  useEffect(() => {
    addDatasetAttributeToElements({
      parentClass: "storeCard",
      datasetName: "store-selected-product",
      datasetValue: "dontDeselectProduct",
    });
  }, [isInCart]); // Empty dependency array ensures that this effect runs once after the initial render

  const formatPrice = (price: number) => {
    let formattedPrice;

    if (Number.isInteger(price)) {
      formattedPrice = price.toFixed(0);
    } else {
      formattedPrice = price.toFixed(2);
      if (formattedPrice.endsWith(".00")) {
        formattedPrice = price.toFixed(0);
      }
    }

    return formattedPrice;
  };

  const isNumber = (value: any) => {
    return typeof value === "number";
  };

  const getProductStatus = (price: any) => {
    // round to 3 decimal places
    const roundedPrice = Math.round(price * 1000) / 1000;
    if (roundedPrice === 1.234) {
      return "contact";
    } else if (roundedPrice === 5.678) {
      return "soon";
    } else {
      return price;
    }
  };

  return !isMobile ? (
    <div
      className={`${styles.storeCard} storeCard flex flex-col cursor-pointer justify-between shadow-focus border-round-large p-[0.45rem]`}
      onClick={(e: any) => {
        const isCartAction = e.target.dataset.name === "cartAction";
        setActiveProduct(isCartAction);
        if (isCartAction) {
          return;
        }
      }}
      onMouseOver={() => {
        if (!currentProductID) {
          setDetails(product.details);
        }
      }}
      onMouseLeave={() => {
        if (!currentProductID) {
          setDetails([]);
        }
      }}
      style={{
        border: "1.3px solid",
        ...(isActive
          ? { borderColor: "var(--new-blue)" }
          : { borderColor: "transparent" }),
        width: cardWidth,
        aspectRatio: "1/1",
        ...(getProductStatus(product.price) === "soon" && {
          filter: "grayscale(100%)",
        }),
        ...(getProductStatus(product.price) === "contact" && {
          paddingBottom: "1rem",
        }),
      }}
    >
      <div className="w-full flex items-center justify-between">
        <p
          style={{
            ...(discountExists && { backgroundColor: "var(--new-orange)" }),
            color: "white",
            fontSize: "0.7rem",
            paddingInline: "0.5rem",
            paddingBlock: "0.1rem",
            borderRadius: "100rem",
          }}
        >
          {discountExists
            ? `خصم ${Math.round(product.discount)}%`
            : "لا يوجد خصم"}
        </p>
        {discountExists && (
          <p
            style={{
              fontSize: "0.75rem",
              opacity: "0.4",
              textDecoration: "line-through",
            }}
          >
            {product?.price}ج
          </p>
        )}
      </div>

      {/* ICON */}
      <div
        className="w-full h-[50%] flex items-center justify-center"
        style={{
          ...(getProductStatus(product?.price) === "soon" && {
            opacity: "50%",
          }),
        }}
      >
        <img src={`${product.icon}`} alt={product.id} />
        {/* <ChatIcon width={iconWidth} height={iconWidth} /> */}
      </div>

      {/* NAME, PRICE AND CART ACTIONS */}
      <div
        className={`w-full flex flex-col ${
          !isNumber(getProductStatus(product.price)) && "gap-1"
        }`}
      >
        {/* NAME */}
        <p
          style={{
            fontFamily: "rbFontBold",
            ...(getProductStatus(product?.price) === "soon" && {
              opacity: "50%",
            }),
          }}
        >
          {product.name}
        </p>

        {/* PRICE AND CART ACTIONS */}
        <div className={`w-full flex items-center justify-between`}>
          {/* PRICE */}
          {getProductStatus(product.price) === "contact" ? (
            <a
              // href="https://m.me/souq.car.egy"
              href="https://wa.me/201022229990"
              target="_blank"
              className="text-[var(--new-blue)]"
            >
              تواصل معنا
            </a>
          ) : getProductStatus(product.price) === "soon" ? (
            <p
              className="souq-s"
              style={{
                ...(getProductStatus(product?.price) === "soon" && {
                  opacity: "50%",
                }),
              }}
            >
              قربياً
            </p>
          ) : (
            <p className="souq-s">{priceAfterDiscount}ج</p>
          )}

          {/* CART ACTIONS */}
          {isNumber(getProductStatus(product.price)) && (
            <div className="flex items-center gap-x-1">
              {/* MINUS BUTTON */}
              {isInCart && (
                <div
                  className={`h-[1.5rem] w-[1.5rem] text-[2rem] rounded-full flex items-center justify-center`}
                  data-name="cartAction"
                  style={{
                    userSelect: "none",
                    border: `1px solid ${
                      isActive ? "var(--new-blue)" : "rgba(0, 0, 0, 0.2)"
                    }`,
                    transition: "all var(--smooth-animation-fast)",
                  }}
                  onClick={() => {
                    updateInCart(cartItem.quantity - 1);
                  }}
                >
                  <div
                    className={`w-[0.6rem] h-[0.1rem]`}
                    data-name="cartAction"
                    style={{
                      backgroundColor: isActive
                        ? "var(--new-blue)"
                        : "rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </div>
              )}

              {/* ADD BUTTON */}
              <div
                className={`${
                  isActive ? styles.addButtonActive : styles.addButton
                } h-[2rem] w-[2rem] text-[2rem] rounded-full flex items-center justify-center`}
                style={{
                  color: "white",
                  userSelect: "none",
                }}
                onClick={() => {
                  updateInCart(cartItem ? cartItem.quantity + 1 : 1);
                }}
              >
                <p
                  className="relative h-full w-full flex items-center justify-center"
                  style={{ marginBottom: "0.15rem" }}
                  data-name="cartAction"
                >
                  {cartItem && (
                    <p
                      className="absolute"
                      style={{
                        color: isActive ? "black" : "rgb(0, 0, 0, 0.2)",
                        fontSize: "0.65rem",
                        top: "0",
                        transform: "translateY(-100%)",
                      }}
                    >
                      x{cartItem.quantity}
                    </p>
                  )}
                  +
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    ////////////
    // MOBILE //
    ////////////
    <div
      className={`${styles.storeCard} storeCard flex cursor-pointer items-center justify-between shadow-focus border-round-large p-[0.45rem]`}
      onClick={(e: any) => {
        const isCartAction = e.target.dataset.name === "cartAction";

        setActiveProduct(isCartAction);
        if (isCartAction) {
          return;
        }
      }}
      onMouseOver={() => {
        if (!currentProductID) {
          setDetails(product.details);
        }
      }}
      onMouseLeave={() => {
        if (!currentProductID) {
          setDetails([]);
        }
      }}
      style={{
        border: "1.3px solid",
        ...(isActive
          ? { borderColor: "var(--new-blue)" }
          : { borderColor: "transparent" }),
        ...(getProductStatus(product.price) === "soon" && {
          filter: "grayscale(100%)",
        }),
      }}
    >
      {/* ICON */}
      <div
        className="w-[35%] h-[80%] flex items-center justify-center"
        style={{
          ...(getProductStatus(product?.price) === "soon" && {
            opacity: "50%",
          }),
        }}
      >
        <img src={`${product.icon}`} alt="" />
        {/* <ChatIcon width={iconWidth} height={iconWidth} /> */}
      </div>

      {/* VERTICAL SEPERATOR */}
      <div
        className="h-[80%] w-[1px] me-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      />

      {/* NAME, PRICE AND CART ACTIONS */}
      <div
        className={`w-full h-[85%] flex flex-col justify-center ${
          !isNumber(getProductStatus(product.price)) && "gap-3"
        }`}
        style={{
          alignSelf: "end",
        }}
      >
        {/* NAME */}
        <p
          // className="flex flex-col justify-end w-full"
          style={{
            fontFamily: "rbFontBold",
            fontSize: "0.9375rem",
            // flexGrow: 1,
            ...(getProductStatus(product?.price) === "soon" && {
              opacity: "50%",
            }),
          }}
        >
          {product.name}
        </p>

        {/* PRICE AND CART ACTIONS */}
        <div
          className={`${styles.infoRow} w-full flex items-center justify-between gap-2`}
        >
          {/* PRICE */}
          {isNumber(getProductStatus(product.price)) ? (
            <p className="souq-s">{priceAfterDiscount}ج</p>
          ) : getProductStatus(product.price) === "contact" ? (
            <a
              // href="https://m.me/souq.car.egy"
              href="https://wa.me/201022229990"
              className="text-[var(--new-blue)]"
            >
              تواصل معنا
            </a>
          ) : (
            <p
              className="souq-s"
              style={{
                ...(getProductStatus(product?.price) === "soon" && {
                  opacity: "50%",
                }),
              }}
            >
              قربياً
            </p>
          )}

          {discountExists && (
            <p
              style={{
                fontSize: "0.75rem",
                opacity: "0.4",
                textDecoration: "line-through",
              }}
            >
              {product?.price}ج
            </p>
          )}

          {/* DISCOUNT */}
          {discountExists && (
            <div className="w-full flex items-center justify-between">
              <p
                style={{
                  ...(discountExists && {
                    backgroundColor: "var(--new-orange)",
                  }),
                  color: "white",
                  fontSize: "0.8rem",
                  paddingInline: "0.5rem",
                  paddingBlock: "0.1rem",
                  borderRadius: "100rem",
                }}
              >
                {discountExists
                  ? `خصم ${Math.round(product.discount)}%`
                  : "لا يوجد خصم"}
              </p>
            </div>
          )}

          {/* 200x5 */}
          {/* <p
            className="text-[0.625rem] text-center"
            style={{ color: "rgba(0, 0, 0, 1)", lineHeight: "1rem" }}
          >
            200x5
          </p> */}

          {/* CART ACTIONS */}
          {isNumber(getProductStatus(product.price)) && (
            <div className="flex items-center justify-end gap-x-1">
              {/* MINUS BUTTON */}
              {isInCart && (
                <div
                  className={`h-[1.5rem] w-[1.5rem] text-[2rem] rounded-full flex items-center justify-center`}
                  data-name="cartAction"
                  style={{
                    userSelect: "none",
                    border: `1px solid ${
                      isActive ? "var(--new-blue)" : "rgba(0, 0, 0, 0.2)"
                    }`,
                    transition: "all var(--smooth-animation-fast)",
                  }}
                  onClick={() => {
                    updateInCart(cartItem.quantity - 1);
                  }}
                >
                  <div
                    className={`w-[0.6rem] h-[0.1rem]`}
                    data-name="cartAction"
                    style={{
                      backgroundColor: isActive
                        ? "var(--new-blue)"
                        : "rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </div>
              )}

              {/* ADD BUTTON */}
              <div
                className={`${
                  isActive ? styles.addButtonActive : styles.addButton
                } h-[2rem] w-[2rem] text-[2rem] rounded-full flex items-center justify-center`}
                style={{
                  color: "white",
                  userSelect: "none",
                }}
                onClick={() => {
                  updateInCart(cartItem ? cartItem.quantity + 1 : 1);
                }}
              >
                <p
                  className="relative h-full w-full flex items-center justify-center"
                  style={{ marginBottom: "0.15rem" }}
                  data-name="cartAction"
                >
                  {cartItem && (
                    <p
                      className="absolute"
                      style={{
                        color: isActive ? "black" : "rgb(0, 0, 0, 0.2)",
                        fontSize: "0.65rem",
                        top: "0",
                        transform: "translateY(-100%)",
                      }}
                    >
                      x{cartItem.quantity}
                    </p>
                  )}
                  +
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreCard;
