import * as React from "react";
import type { SVGProps } from "react";
const SvgFooterInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 29 28"
    {...props}
  >
    <circle cx={14.463} cy={13.697} r={13.697} fill="#0050FF" />
    <path
      fill="currentColor"
      d="M21.57 11.323c-.034-.758-.156-1.279-.332-1.73a3.479 3.479 0 0 0-.825-1.265 3.51 3.51 0 0 0-1.262-.822c-.454-.175-.972-.298-1.73-.331-.763-.036-1.005-.045-2.941-.045-1.937 0-2.179.009-2.94.042-.757.034-1.278.156-1.73.332a3.48 3.48 0 0 0-1.264.824 3.511 3.511 0 0 0-.822 1.262c-.176.454-.298.973-.332 1.73-.036.764-.044 1.006-.044 2.942 0 1.936.008 2.179.041 2.94.034.757.156 1.278.332 1.73.181.479.462.908.825 1.264.356.362.788.644 1.262.822.454.175.972.298 1.73.331.76.034 1.003.042 2.939.042 1.936 0 2.178-.008 2.939-.042.758-.033 1.279-.155 1.73-.331a3.648 3.648 0 0 0 2.087-2.087c.175-.454.298-.972.331-1.73.033-.76.042-1.003.042-2.939 0-1.936-.003-2.178-.037-2.939Zm-1.285 5.823c-.03.696-.147 1.072-.245 1.323a2.365 2.365 0 0 1-1.354 1.354c-.25.097-.63.214-1.323.245-.752.033-.978.042-2.88.042-1.904 0-2.132-.009-2.881-.042-.697-.03-1.073-.148-1.324-.245a2.193 2.193 0 0 1-.819-.532 2.218 2.218 0 0 1-.532-.82c-.097-.25-.214-.629-.245-1.323-.033-.752-.042-.977-.042-2.88s.009-2.131.042-2.88c.03-.697.148-1.073.245-1.324.114-.31.296-.59.535-.82.231-.236.51-.417.82-.531.25-.098.629-.215 1.322-.245.753-.034.978-.042 2.881-.042 1.906 0 2.131.008 2.88.042.697.03 1.073.147 1.324.245.31.114.59.295.819.532.237.231.418.51.532.819.098.25.215.63.245 1.323.034.752.042.978.042 2.88 0 1.904-.008 2.126-.042 2.879Z"
    />
    <path
      fill="currentColor"
      d="M14.48 10.599a3.664 3.664 0 1 0 .002 7.328 3.664 3.664 0 0 0-.002-7.328Zm0 6.04a2.377 2.377 0 1 1 0-4.754 2.377 2.377 0 0 1 0 4.754ZM19.143 10.454a.855.855 0 1 1-1.71 0 .855.855 0 0 1 1.71 0Z"
    />
  </svg>
);
export default SvgFooterInstagram;
