import styles from "components/CreatePostForms/RentCarForm/RentCarForm.module.css";
import priceStatusData from "MockupData/priceStatusData";
import * as Yup from "yup";
import CreatePostModel from "models/CreatePostModel";
import CustomMap from "components/CustomMap/Map/Map";
import { listCarBodiesAction } from "redux/actions/CarBodiesActions/listCarBodiesAction";
import { listEngineCapacityAction } from "redux/actions/EngineCapacityActions/listEngineCapacityAction";
import { listArticlesAction } from "redux/actions/Articles/ListArticlesActions";
import { listCarConditionAction } from "redux/actions/CarConditionActions/listCarConditionAction";
import { listCarMakerAction } from "redux/actions/CarMakerActions/listCarMakerAction";
import { listCategoriesAction } from "redux/actions/CategoriesActions/listCategoriesAction";
import { listCitiesAction } from "redux/actions/CitiesActions/listCitiesAction";
import { listColorsAction } from "redux/actions/ColorsAction/listColorsAction";
import { listFeaturesAction } from "redux/actions/FeaturesActions/listFeaturesAction";
import { listTransmissionTypesAction } from "redux/actions/TransmissionTypesActions/listTransmissionTypesAction";
import { listYearsAction } from "redux/actions/YearsActions/listYearsAction";
import { listCarModelsAction } from "redux/actions/CarModelsActions/listCarModelsAction";
import { listPaymentTypesAction } from "redux/actions/PaymentTypesActions/listPaymentTypesAction";
import installementData from "MockupData/installementData";
import { mobileLoginAction } from "redux/actions/LoginActions/mobileLoginAction";
import { RESET_CREATE_POST, RESET_OTP } from "redux/types";
import { verifyOtpAction } from "redux/actions/LoginActions/verifyOtpAction";
import { createPostAction } from "redux/actions/CreatePostActions/createPostAction";
import { listFuelTypesAction } from "redux/actions/FuelTypesActions/listFuelTypesAction";
import scrollToTop from "utils/functions/scrollToTop";
import { keyBy, set } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Checkbox, Input, Select, Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { setProgressStateAction } from "redux/actions/SetProgressStateAction/setProgressStateAction";
import CustomButton from "components/CustomButton/CustomButton";
import { plusCircleIcon } from "assets/icons";
import CustomImageContainer from "components/CustomImageContainer/CustomImageContainer";
import { listRentalTypesAction } from "redux/actions/RentalTypesAction/listRentalTypesAction";
import { listRentalDriverAction } from "redux/actions/CreatePostActions/listRentalDriverAction";
import {
  profilePostStatusAction,
  profilePostCategoryAction,
} from "redux/actions";
import { getUserDataAction } from "redux/actions/GetUserDataAction/getUserDataAction";
import FormButtons from "../CommonFormSections/components/FormButtons";
import { AnimatePresence } from "framer-motion";
import PostDetails from "../CommonFormSections/PostDetails/PostDetails";
import PhotoSection from "../CommonFormSections/PhotoSection/PhotoSection";
import { useUtilities } from "hooks/useUtilities";
import { useResponsive } from "hooks/useResponsive";
import { useForm } from "hooks/useForm";
import RenderCards from "../CommonFormSections/components/RenderCards/RenderCards";
import { motion } from "framer-motion";
import { useLanguage } from "hooks/useLanguage";
import { useDropdowns } from "hooks/useDropdowns";

interface RentCarFormProps {
  image?: any;
}

const RentCarForm: FC<RentCarFormProps> = ({ image }) => {
  const { navigate, t } = useUtilities();
  const { isMobile } = useResponsive();
  const { progressState, updateProgressState } = useForm();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const { textDirectionStyleFlipped, textDirectionStyle } = useLanguage();
  const isArabic = currentLanguage === "ar";
  const dir = isArabic ? "rtl" : "ltr";
  const dirFlip = !isArabic ? "rtl" : "ltr";

  const { rentalTypesLoading, rentalTypes } = useSelector(
    (state: RootState) => state.listRentalTypesReducer
  );

  const { rentalDriverLoading, rentalDriver } = useSelector(
    (state: RootState) => state.listRentalDriverReducer
  );

  const { categoryState } = useSelector(
    (state: RootState) => state.postCategoryStateReducer
  );

  const { mapState } = useSelector(
    (state: RootState) => state.setMapCoordinatesPostReducer
  );

  const { loginLoading, customer, otp } = useSelector(
    (state: RootState) => state.mobileLoginReducer
  );

  const formik = useFormik({
    initialValues: {
      name: customer ? customer?.name : undefined,
      service_id: undefined,
      title: undefined,
      description: undefined,
      classification: "Popular",
      address: undefined,
      lat: mapState,
      long: mapState,
      down_payment: undefined,
      engine_capacity: undefined,
      car_type: undefined,
      car_condition: undefined,
      transmission_type: undefined,
      fuel_type: undefined,
      payment_type: undefined,
      color_id: undefined,
      brand_id: undefined,
      car_model_id: undefined,
      // branch_id: "1",
      car_rental_type: undefined,
      driver_status: undefined,
      customer_id: "2",
      city_id: undefined,
      package_id: "1",
      category_id: categoryState,
      price: undefined,
      kilo_meterage: undefined,
      production_date: undefined,
      main_image: undefined,
      features: undefined,
      postMedias: undefined,
      // condition: undefined,
      spare_part_id: undefined,
      mobile: undefined,
      otp: undefined,
      phone: undefined,
    },
    validationSchema: Yup.object({
      price: Yup.number().required(),
      kilo_meterage: Yup.number().required(),
    }),
    onSubmit: async (values: CreatePostModel) => {
      if (!customer && formik.values?.name?.toString().trim() === "") {
        formik.setFieldError("name", "Please Provide this field");
        return;
      }
      if (values.main_image === undefined || values.main_image === "") {
        setMainCoverMessage(true);
      } else {
        setMainCoverMessage(false);
        const postMediasUploadCopy = [...values.postMedias];
        const postMediasFinal = postMediasUploadCopy.filter(
          (file: any) => file !== values.main_image
        );
        await dispatch(
          createPostAction({
            title: values.title,
            description: values.description,
            classification: "Popular",
            address: values.address,
            lat: mapState.lat,
            long: mapState.lng,
            engine_capacity: values.engine_capacity,
            car_type: values.car_type,
            car_condition: values.car_condition,
            transmission_type: values.transmission_type,
            fuel_type: values.fuel_type,
            payment_type: values.payment_type,
            color_id: values.color_id,
            car_model_id: values.car_model_id,
            // branch_id: values.branch_id,
            car_rental_type: values.car_rental_type,
            driver_status: values.driver_status,
            customer_id: 2,
            city_id: values.city_id,
            package_id: 1,
            category_id: values.category_id,
            price: values.price,
            kilo_meterage: values.kilo_meterage,
            production_date: values.production_date,
            main_image: values.main_image,
            postMedias: postMediasFinal.map((item: any) => {
              return {
                media_url: item,
              };
            }),
            features: values.features,
            brand_id: values.brand_id,
            ...(values.phone && { phone: values.phone }),
          })
        );

        dispatch(getUserDataAction());
        dispatch(profilePostStatusAction("Pending"));
        dispatch(profilePostCategoryAction(2));
        navigate(`/${currentLanguage}/profile/view`);
      }
    },
  });

  const { TextArea } = Input;

  const dispatch: any = useDispatch();

  const { carBodiesLoading, carBodies } = useSelector(
    (state: RootState) => state.listCarBodiesReducer
  );
  const { engineCapactiyLoading, engineCapacity } = useSelector(
    (state: RootState) => state.listEngineCapacityReducer
  );
  const { carConditionLoading, carConditions } = useSelector(
    (state: RootState) => state.listCarConditionReducer
  );
  const { yearsLoading, years } = useSelector(
    (state: RootState) => state.listYearsReducer
  );
  const { carMakersLoading, carMakers } = useSelector(
    (state: RootState) => state.listCarMakerReducer
  );
  const { carModelsLoading, carModels } = useSelector(
    (state: RootState) => state.listCarModelsReducer
  );
  const { colorsLoading, colors } = useSelector(
    (state: RootState) => state.listColorsReducer
  );
  const { featuresLoading, features } = useSelector(
    (state: RootState) => state.listFeaturesReducer
  );
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );
  const { fuelTypesLoading, fuelTypes } = useSelector(
    (state: RootState) => state.listFuelTypesReducer
  );
  const { transmissionTypesLoading, transmissionTypes } = useSelector(
    (state: RootState) => state.listTransmissionTypesReducer
  );
  const { paymentTypesLoading, paymentTypes } = useSelector(
    (state: RootState) => state.listPaymentTypesReducer
  );

  const engineCapacityDropdown = engineCapacity?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const carConditionsDropdown = carConditions?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const yearsDropdown = years?.map((item: any) => {
    return {
      value: item,
      label: item,
    };
  });

  const { carMakerDropdown, carModelsDropdown } = useDropdowns();

  const driverDropdown = rentalDriver?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const rentalTypesDropdown = rentalTypes?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const colorsDropdown = colors?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const featuresDropdown = features?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const citiesDropdown = cities?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const carBodiesDropdown = carBodies?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const fuelTypesDropdown = fuelTypes?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const paymentTypesDropdown = paymentTypes?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const [mainError, setMainError] = useState(false);
  const [mainCoverMessage, setMainCoverMessage] = useState(false);
  const [selected, setSelected] = useState({ lat: 30.11525, lng: 31.52652 });
  const [transmissionType, setTransmissionType] = useState(-1);
  const [carCondition, setCarCondition] = useState(-1);
  const [carFuelType, setCarFuelType] = useState(-1);
  const [carBody, setCarBody] = useState(-1);
  const [paymentType, setPaymentType] = useState(-1);

  useEffect(() => {
    scrollToTop();
    updateProgressState(0);
  }, []);

  useEffect(() => {
    dispatch(listCarBodiesAction());
    dispatch(listEngineCapacityAction());
    dispatch(listCarConditionAction());
    dispatch(listYearsAction());
    dispatch(listCarMakerAction());
    dispatch(listArticlesAction());
    dispatch(listColorsAction());
    dispatch(listFeaturesAction());
    dispatch(listCitiesAction());
    dispatch(listRentalTypesAction());
    dispatch(listTransmissionTypesAction());
    dispatch(listPaymentTypesAction());
    dispatch(listFuelTypesAction());
    dispatch(listRentalDriverAction());

    dispatch({ type: RESET_OTP });
    dispatch({ type: RESET_CREATE_POST });
  }, []);

  const renderPaymentSection = () => {
    return (
      <>
        <div className={`${styles.containerRow}`}>
          <p className="font-bold text-right"> {t("payment_options")}</p>
          {
            <RenderCards
              formik={formik}
              items={paymentTypes}
              name="payment_type"
              state={paymentType}
              setState={setPaymentType}
              gap={isMobile ? "0.6rem" : "1.19rem"}
            />
          }
          {formik.errors.payment_type && (
            <p className="text-[red]">{t("please_provide_this_field")}</p>
          )}
        </div>

        <div className={`${styles.containerRow}`}>
          <p className="text-right font-bold"> {t("price")}</p>
          <>
            <Input
              placeholder={t("price_hint")}
              className="w-full text-right"
              size="large"
              name="price"
              defaultValue={formik.values.price}
              value={formik.values.price}
              onChange={(e) => {
                const inputValue = e.target.value;

                // Remove non-numeric characters and update the input value
                e.target.value = inputValue.replace(/[^0-9.]/g, "");

                // Update the formik values if it's a valid number
                formik.handleChange(e);
              }}
              status={formik.errors.price ? "error" : ""}
              dir={dir}
            />

            {formik.errors.price && (
              <p className="text-[red]">{t("please_provide_this_field")}</p>
            )}
          </>
        </div>

        {formik.values.payment_type === "Installment" && (
          <div className={`${styles.containerRow}`}>
            <p className="text-right font-bold"> {t("upfront")}</p>
            <>
              <Input
                placeholder={t("price_hint")}
                className="w-full text-right"
                size="large"
                defaultValue={formik.values.down_payment}
                value={formik.values.down_payment}
                // prefix={
                //   <p className={`text-blue-600 text-left`}>{t("egp")}</p>
                // }
                name="down_payment"
                onChange={formik.handleChange}
                status={formik.errors.down_payment ? "error" : ""}
                dir={dir}
              />

              {formik.errors.down_payment && (
                <p className="text-[red]">{t("please_provide_this_field")}</p>
              )}
            </>
          </div>
        )}

        <div className={`${styles.containerRow}`}>
          <p className="text-right font-bold"> {t("price_condition")}</p>
          <Select
            showSearch
            optionFilterProp="children"
            placeholder={t("price_condition_hint")}
            // defaultValue={formik.values.price}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={priceStatusData}
            size="large"
            className="w-full"
            style={{ direction: dir }}
            dropdownStyle={{ direction: dir }}
          />
        </div>
      </>
    );
  };

  return (
    <form
      className="w-full flex flex-col items-center"
      onSubmit={formik.handleSubmit}
    >
      <AnimatePresence>
        {progressState === 2 && <PhotoSection formik={formik} />}
      </AnimatePresence>

      <AnimatePresence>
        {progressState === 1 && <PostDetails formik={formik} />}
      </AnimatePresence>

      <AnimatePresence>
        {progressState === 0 && (
          <motion.div
            dir={dirFlip}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="w-full flex flex-col gap-y-8 items-end"
          >
            <div className={`${styles.containerRow}`}>
              {
                <RenderCards
                  formik={formik}
                  items={carConditions}
                  name="car_condition"
                  state={carCondition}
                  setState={setCarCondition}
                  gap={isMobile ? "0.6rem" : undefined}
                />
              }
              {formik.errors.car_condition && (
                <p className="text-[red]">{t("please_provide_this_field")}</p>
              )}
            </div>
            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("homepage_car_maker")}</p>
              {carMakersLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_car_maker")}
                    defaultValue={formik.values.brand_id}
                    value={formik.values.brand_id}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carMakerDropdown}
                    status={formik.errors.brand_id ? "error" : ""}
                    onSelect={(value: any) => {
                      formik.setFieldValue("brand_id", value);
                      dispatch(listCarModelsAction(value));
                    }}
                    size="large"
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.brand_id && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>
            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("homepage_car_model")}</p>
              {carModelsLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_car_model")}
                    defaultValue={formik.values.car_model_id}
                    value={formik.values.car_model_id}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carModelsDropdown}
                    onSelect={(value: any) => {
                      formik.setFieldValue("car_model_id", value);
                    }}
                    size="large"
                    status={formik.errors.car_model_id ? "error" : ""}
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.car_model_id && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("homepage_year")}</p>
              {yearsLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_year")}
                    defaultValue={formik.values.production_date}
                    value={formik.values.production_date}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={yearsDropdown}
                    onSelect={(value: any) => {
                      formik.setFieldValue("production_date", value);
                    }}
                    status={formik.errors.production_date ? "error" : ""}
                    size="large"
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.production_date && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>

            <div
              className={`${styles.containerRow}`}
              // dir={textDirectionStyleFlipped}
            >
              <p className="font-bold text-right"> {t("transmission")}</p>
              {
                <RenderCards
                  formik={formik}
                  items={transmissionTypes}
                  name="transmission_type"
                  state={transmissionType}
                  setState={setTransmissionType}
                  gap={isMobile ? "0.6rem" : "1.19rem"}
                />
              }
              {formik.errors.transmission_type && (
                <p className="text-[red]">{t("please_provide_this_field")}</p>
              )}
            </div>

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("form_rental_types")}</p>
              {rentalTypesLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("form_rental_types")}
                    defaultValue={formik.values.car_rental_type}
                    value={formik.values.car_rental_type}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={rentalTypesDropdown}
                    onSelect={(value: any) => {
                      formik.setFieldValue("car_rental_type", value);
                    }}
                    status={formik.errors.production_date ? "error" : ""}
                    size="large"
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.production_date && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("form_rental_driver")}</p>
              {rentalDriverLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("form_rental_driver")}
                    defaultValue={formik.values.driver_status}
                    value={formik.values.driver_status}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={driverDropdown}
                    onSelect={(value: any) => {
                      formik.setFieldValue("driver_status", value);
                    }}
                    status={formik.errors.production_date ? "error" : ""}
                    size="large"
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.production_date && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>

            {/* <hr className={`${styles.formDivider}`} /> */}

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("homepage_distance")}</p>
              <div className="w-full flex flex-col items-end gap-y-2">
                <div className="w-full flex flex-row gap-x-4 items-center justify-end">
                  <Input
                    placeholder={t("distance_covered_hint")}
                    className="text-right w-full"
                    size="large"
                    name="kilo_meterage"
                    // suffix={<p className={`text-blue-600`}>{t("homepage_km")}</p>}
                    status={formik.errors.kilo_meterage ? "error" : ""}
                    value={formik.values.kilo_meterage}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Remove non-numeric characters and update the input value
                      e.target.value = inputValue.replace(/[^0-9.]/g, "");
                      // Update the formik values if it's a valid number
                      formik.handleChange(e);
                    }}
                    style={{ direction: dir }}
                  />
                </div>
                {formik.errors.kilo_meterage && (
                  <p className="text-[red]">{t("please_provide_this_field")}</p>
                )}
              </div>
            </div>

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("homepage_fuel_type")}</p>
              {
                <RenderCards
                  formik={formik}
                  items={fuelTypes}
                  name="fuel_type"
                  state={carFuelType}
                  setState={setCarFuelType}
                  numberPerRow={isMobile ? 3 : undefined}
                  gap={isMobile ? "0.6rem" : "1.19rem"}
                />
              }
              {formik.errors.fuel_type && (
                <p className="text-[red]">{t("please_provide_this_field")}</p>
              )}
            </div>

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("homepage_carbody")}</p>
              {
                <RenderCards
                  formik={formik}
                  items={carBodies}
                  name="car_type"
                  state={carBody}
                  setState={setCarBody}
                  numberPerRow={3}
                  gap={isMobile ? "0.6rem" : "1.19rem"}
                />
              }
              {formik.errors.car_type && (
                <p className="text-[red]">{t("please_provide_this_field")}</p>
              )}
            </div>

            {/* <hr className={`${styles.formDivider}`} /> */}

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("engine_capacity")}</p>
              <div className="w-full flex flex-col items-end gap-y-2">
                <div className="w-full flex flex-row gap-x-4 items-center justify-end">
                  {engineCapactiyLoading ? (
                    <Spin />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("homepage_cc")}
                      defaultValue={formik.values.engine_capacity}
                      value={formik.values.engine_capacity}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={engineCapacityDropdown}
                      onSelect={(value: any) => {
                        formik.setFieldValue("engine_capacity", value);
                      }}
                      status={formik.errors.engine_capacity ? "error" : ""}
                      size="large"
                      className="w-full"
                      style={{ direction: dir }}
                      dropdownStyle={{ direction: dir }}
                    />
                  )}
                </div>
                {formik.errors.engine_capacity && (
                  <p className="text-[red]">{t("please_provide_this_field")}</p>
                )}
              </div>
            </div>

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("homepage_color")}</p>
              {colorsLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_color")}
                    defaultValue={formik.values.color_id}
                    value={formik.values.color_id}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={colorsDropdown}
                    onSelect={(value: any) => {
                      formik.setFieldValue("color_id", value);
                    }}
                    status={formik.errors.color_id ? "error" : ""}
                    size="large"
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.color_id && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>

            <div className={`${styles.containerRow}`}>
              <p className="text-right font-bold"> {t("homepage_features")}</p>
              {featuresLoading ? (
                <Spin />
              ) : (
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder={t("homepage_features")}
                  defaultValue={formik.values.features}
                  value={formik.values.features}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={featuresDropdown}
                  onChange={(values: any) => {
                    formik.setFieldValue("features", values);
                  }}
                  // status={formik.errors.features ? "error" : ""}
                  size="large"
                  className="w-full"
                  style={{ direction: dir }}
                  dropdownStyle={{ direction: dir }}
                />
              )}
            </div>

            <hr className={`${styles.formDivider}`} />

            {renderPaymentSection()}
            <FormButtons
              formik={formik}
              includedFields={[
                "car_condition",
                "brand_id",
                "car_model_id",
                "production_date",
                "transmission_type",
                "car_rental_type",
                "driver_status",
                "kilo_meterage",
                "fuel_type",
                "car_type",
                "engine_capacity",
                "color_id",
                "features",
                "payment_type",
                "price",
              ]}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  );
};

export default RentCarForm;
