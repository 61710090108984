import api from "api";
import { listSinglePostEndPoint } from "api/apiUrls";
import store from "redux/store";

export const listSinglelPostService = async (postId: string) => {
    const response = await api.get(
        listSinglePostEndPoint(postId),
        {
            headers: {
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${store.getState().verifyOtpReducer?.userData?.token}`
            }
        }
    );
    return response;
}