import 
{
    CUSTOMER_STORIES_LOADING,
    CUSTOMER_STORIES_SUCCESS,
    CUSTOMER_STORIES_FAILURE
}
from "redux/types";

const initialState = {
    customerStoriesLoading: false,
    customerStories: null,
    errors: null
}

export const listCustomerStoriesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CUSTOMER_STORIES_LOADING :
            return {...state, customerStoriesLoading: action.payload};
        case CUSTOMER_STORIES_SUCCESS :
            return {...state, customerStories: action.payload.data};
        case CUSTOMER_STORIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}