import
{
    RENTAL_DRIVER_LOADING,
    RENTAL_DRIVER_SUCCESS,
    RENTAL_DRIVER_FAILURE
}
from "redux/types";

const initialState = {
    rentalDriverLoading: false,
    rentalDriver: null,
    errors: null
}

export const listRentalDriverReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case RENTAL_DRIVER_LOADING :
            return {...state, rentalDriverLoading: action.payload}
        case RENTAL_DRIVER_SUCCESS :
            return {...state, rentalDriver: action.payload.driver}
        case RENTAL_DRIVER_FAILURE :
            return {...state, errors: action.payload}
        default:
            return state;
    }
}

