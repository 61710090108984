import styles from "components/CreatePostForms/SparePartsForm/SparePartsForm.module.css";
import priceStatusData from "MockupData/priceStatusData";
import * as Yup from "yup";
import CreatePostModel from "models/CreatePostModel";
import CustomMap from "components/CustomMap/Map/Map";
import { listCarBodiesAction } from "redux/actions/CarBodiesActions/listCarBodiesAction";
import { listEngineCapacityAction } from "redux/actions/EngineCapacityActions/listEngineCapacityAction";
import { listArticlesAction } from "redux/actions/Articles/ListArticlesActions";
import { listCarConditionAction } from "redux/actions/CarConditionActions/listCarConditionAction";
import { listCarMakerAction } from "redux/actions/CarMakerActions/listCarMakerAction";
import { listCategoriesAction } from "redux/actions/CategoriesActions/listCategoriesAction";
import { listCitiesAction } from "redux/actions/CitiesActions/listCitiesAction";
import { listColorsAction } from "redux/actions/ColorsAction/listColorsAction";
import { listFeaturesAction } from "redux/actions/FeaturesActions/listFeaturesAction";
import { listTransmissionTypesAction } from "redux/actions/TransmissionTypesActions/listTransmissionTypesAction";
import { listYearsAction } from "redux/actions/YearsActions/listYearsAction";
import { listCarModelsAction } from "redux/actions/CarModelsActions/listCarModelsAction";
import { listPaymentTypesAction } from "redux/actions/PaymentTypesActions/listPaymentTypesAction";
import installementData from "MockupData/installementData";
import { mobileLoginAction } from "redux/actions/LoginActions/mobileLoginAction";
import { RESET_CREATE_POST, RESET_OTP } from "redux/types";
import { verifyOtpAction } from "redux/actions/LoginActions/verifyOtpAction";
import { createPostAction } from "redux/actions/CreatePostActions/createPostAction";
import { listFuelTypesAction } from "redux/actions/FuelTypesActions/listFuelTypesAction";
import scrollToTop from "utils/functions/scrollToTop";
import { keyBy, set } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Checkbox, Input, Select, Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { setProgressStateAction } from "redux/actions/SetProgressStateAction/setProgressStateAction";
import CustomButton from "components/CustomButton/CustomButton";
import { plusCircleIcon } from "assets/icons";
import CustomImageContainer from "components/CustomImageContainer/CustomImageContainer";
import { listSparePartTypeAction } from "redux/actions/CreatePostActions/listSparePartTypeAction";
import { listSparePartConditionAction } from "redux/actions/CreatePostActions/listSparePartConditionAction";
import {
  profilePostCategoryAction,
  profilePostStatusAction,
} from "redux/actions";
import { getUserDataAction } from "redux/actions/GetUserDataAction/getUserDataAction";
import { useResponsive } from "hooks/useResponsive";
import { useUtilities } from "hooks/useUtilities";
import { useForm } from "hooks/useForm";
import { AnimatePresence, motion } from "framer-motion";
import PhotoSection from "../CommonFormSections/PhotoSection/PhotoSection";
import PostDetails from "../CommonFormSections/PostDetails/PostDetails";
import FormButtons from "../CommonFormSections/components/FormButtons";
import RenderCards from "../CommonFormSections/components/RenderCards/RenderCards";
import { updatePostAction } from "redux/actions/UpdatePostAction/updatePostAction";
import { useDropdowns } from "hooks/useDropdowns";

interface SparePartsFormProps {
  image?: any;
}

const SparePartsForm: FC<SparePartsFormProps> = ({ image }) => {
  const { navigate, dispatch, t } = useUtilities();
  const { progressState, updateProgressState } = useForm();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const isArabic = currentLanguage === "ar";
  const dir = isArabic ? "rtl" : "ltr";
  const dirFlip = !isArabic ? "rtl" : "ltr";

  const { sparePartConditionLoading, sparePartCondition } = useSelector(
    (state: RootState) => state.listSparePartConditionReducer
  );

  const { sparePartTypeLoading, sparePartType } = useSelector(
    (state: RootState) => state.listSparePartTypeReducer
  );

  const { mapState } = useSelector(
    (state: RootState) => state.setMapCoordinatesPostReducer
  );

  const { categoryState } = useSelector(
    (state: RootState) => state.postCategoryStateReducer
  );

  const sparePartTypeDropdown = sparePartType?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const { loginLoading, customer, otp } = useSelector(
    (state: RootState) => state.mobileLoginReducer
  );

  const { post, updatePostLoading, updatedPost } = useSelector(
    (state: RootState) => state.updatePostReducer
  );

  const { fuelTypesLoading, fuelTypes } = useSelector(
    (state: RootState) => state.listFuelTypesReducer
  );
  const { transmissionTypesLoading, transmissionTypes } = useSelector(
    (state: RootState) => state.listTransmissionTypesReducer
  );
  const { carConditionLoading, carConditions } = useSelector(
    (state: RootState) => state.listCarConditionReducer
  );
  const { carBodiesLoading, carBodies } = useSelector(
    (state: RootState) => state.listCarBodiesReducer
  );

  const { rentalTypesLoading, rentalTypes } = useSelector(
    (state: RootState) => state.listRentalTypesReducer
  );

  const { rentalDriverLoading, rentalDriver } = useSelector(
    (state: RootState) => state.listRentalDriverReducer
  );

  const { paymentTypesLoading, paymentTypes } = useSelector(
    (state: RootState) => state.listPaymentTypesReducer
  );

  const [spareCondition, setSpareCondition] = useState(
    sparePartCondition?.find((item: any) => item.name === post?.car_condition)
      ?.key
  );

  useEffect(() => {}, [spareCondition]);

  const formik = useFormik({
    initialValues: {
      name: customer ? customer?.name : "",
      service_id: post?.service_id,
      title: post?.title,
      description: post?.description,
      classification: "Popular",
      address: post?.address,
      lat: post?.lat,
      long: post?.long,
      engine_capacity: post?.engine_capacity,
      car_rental_type: rentalTypes?.find(
        (item: any) => item.name === post?.car_rental_type
      )?.key,
      driver_status: rentalDriver?.find(
        (item: any) => item.name === post?.driver_status
      )?.key,
      car_type: carBodies?.find((item: any) => item.name === post?.car_type)
        ?.key,
      car_condition: sparePartCondition?.find(
        (item: any) => item.name === post?.car_condition
      )?.key,
      transmission_type: transmissionTypes?.find(
        (item: any) => item.name === post?.transmission_type
      )?.key,
      fuel_type: fuelTypes?.find((item: any) => item.name === post?.fuel_type)
        ?.key,
      payment_type: paymentTypes?.find(
        (item: any) => item.name === post?.payment_type
      )?.key,
      color_id: post?.color,
      brand_id: post?.brand,
      car_model_id: post?.car_model,
      down_payment: post?.down_payment,
      // branch_id: "1",
      customer_id: post?.customer_id,
      city_id: post?.city,
      package_id: "1",
      category_id: categoryState,
      price: post?.price,
      kilo_meterage: post?.kilo_meterage,
      production_date: post?.production_date,
      main_image: post?.main_image,
      features: post?.features,
      postMedias: post?.post_medias,
      spare_part_id: post?.spare_part,
      mobile: "",
      otp: "",
    },
    validationSchema: Yup.object({
      // price: Yup.number().required(),
    }),
    onSubmit: async (values: CreatePostModel) => {
      if (!customer && formik.values.name.toString().trim() === "") {
        formik.setFieldError("name", "Please Provide this field");
        return;
      }
      if (values.main_image === undefined || values.main_image === "") {
        setMainCoverMessage(true);
      } else {
        setMainCoverMessage(false);
        const postMediasUploadCopy = [...values.postMedias];
        const postMediasFinal = postMediasUploadCopy.filter(
          (file: any) => file.media_url !== values.main_image.media_url
        );

        const mainImageValue = values.main_image.file
          ? values.main_image.file
          : values.main_image.media_url;

        const formData: any = {
          title: values.title,
          description: values.description,
          classification: "Popular",
          address: values.address,
          lat: mapState.lat,
          long: mapState.lng,
          engine_capacity: values.engine_capacity,
          car_type: values.car_type,
          car_condition: values?.car_condition,
          transmission_type: values?.transmission_type,
          fuel_type: values?.fuel_type,
          payment_type: values.payment_type,
          color_id: values.color_id,
          car_model_id:
            typeof values.car_model_id == "number"
              ? values.car_model_id + "$%$"
              : values.car_model_id,
          // branch_id: values.branch_id,
          down_payment: values.down_payment,
          customer_id: 2,
          city_id: values.city_id,
          package_id: 1,
          category_id: values.category_id,
          price: values.price,
          kilo_meterage: values.kilo_meterage,
          driver_status: values.driver_status,
          car_rental_type: values.car_rental_type,
          spare_part_id: values.spare_part_id,
          main_image: mainImageValue,
          production_date: values.production_date,
          postMedias: postMediasFinal.map((item: any) => {
            const mediaFile = item.file ? item.file : item.media_url;
            return {
              media_url: mediaFile,
            };
          }),
          features: values.features,
          brand_id: values.brand_id,
        };

        await dispatch(updatePostAction(post?.id, formData));
        navigate(`/${currentLanguage}/profile/view`);
      }
    },
  });

  const { carMakersLoading, carMakers } = useSelector(
    (state: RootState) => state.listCarMakerReducer
  );

  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const { carMakerDropdown, carModelsDropdown } = useDropdowns();

  const citiesDropdown = cities?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const paymentTypesDropdown = paymentTypes?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const [mainError, setMainError] = useState(false);

  const [postMediasUpload, setPostMediasUpload] = useState<any>([]);

  const [mainCoverMessage, setMainCoverMessage] = useState(false);

  const [selected, setSelected] = useState({ lat: 30.11525, lng: 31.52652 });

  const { isMobile } = useResponsive();

  useEffect(() => {
    scrollToTop();
    updateProgressState(0);
  }, []);

  useEffect(() => {
    dispatch(listCarMakerAction());
    dispatch(listSparePartTypeAction());
    dispatch(listSparePartConditionAction());

    dispatch({ type: RESET_OTP });
    dispatch({ type: RESET_CREATE_POST });
  }, []);

  return (
    <form
      className="w-full flex flex-col items-center"
      onSubmit={formik.handleSubmit}
    >
      <AnimatePresence>
        {progressState === 2 && <PhotoSection formik={formik} />}
      </AnimatePresence>

      <AnimatePresence>
        {progressState === 1 && <PostDetails formik={formik} />}
      </AnimatePresence>

      <AnimatePresence>
        {progressState === 0 && (
          <motion.div
            dir={dirFlip}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="w-full flex flex-col gap-y-8 items-end"
          >
            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("homepage_car_maker")}</p>
              {carMakersLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_car_maker")}
                    defaultValue={formik.values.brand_id}
                    value={formik.values.brand_id}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carMakerDropdown}
                    status={formik.errors.brand_id ? "error" : ""}
                    onSelect={(value: any) => {
                      formik.setFieldValue("brand_id", value);
                      dispatch(listCarModelsAction(value));
                    }}
                    size="large"
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.brand_id && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("type_of_product")}</p>
              {carMakersLoading ? (
                <Spin />
              ) : (
                <>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("type_of_product")}
                    defaultValue={formik.values.spare_part_id}
                    value={formik.values.spare_part_id}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={sparePartTypeDropdown}
                    status={formik.errors.brand_id ? "error" : ""}
                    onSelect={(value: any) => {
                      formik.setFieldValue("spare_part_id", value);
                    }}
                    size="large"
                    className="w-full"
                    style={{ direction: dir }}
                    dropdownStyle={{ direction: dir }}
                  />
                  {formik.errors.brand_id && (
                    <p className="text-[red]">
                      {t("please_provide_this_field")}
                    </p>
                  )}
                </>
              )}
            </div>
            {/* SPARE PART CONDITION */}

            <div className={`${styles.containerRow}`}>
              <p className="font-bold text-right"> {t("state_of_product")}</p>
              {
                <RenderCards
                  formik={formik}
                  items={sparePartCondition}
                  name="car_condition"
                  state={spareCondition}
                  setState={setSpareCondition}
                  gap={"0.5rem"}
                />
              }
              {formik.errors.car_condition && (
                <p className="text-[red]">{t("please_provide_this_field")}</p>
              )}
            </div>
            <FormButtons
              formik={formik}
              includedFields={["brand_id", "spare_part_id", "car_condition"]}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  );
};

export default SparePartsForm;
