import
{
    LIST_SINGLE_POST_LOADING,
    LIST_SINGLE_POST_SUCCESS,
    LIST_SINGLE_POST_FAILURE
}
from "redux/types";

const initialState = {
    loading: false,
    post: null,
    similar_posts: null,
    errors: null
};

export const listSinglePostReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_SINGLE_POST_LOADING :
            return {...state, loading: action.payload};
        case LIST_SINGLE_POST_SUCCESS :
            return {...state, post: action.payload.data, similar_posts: action.payload.similar_posts};
        case LIST_SINGLE_POST_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}