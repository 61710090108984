import
{
    UPDATE_CUSTOMER_DATA_LOADING,
    UPDATE_CUSTOMER_DATA_SUCCESS,
    UPDATE_CUSTOMER_DATA_FAILURE
}
from "redux/types";

const initalState = {
    updateCustomerDataLoading: false,
    updatedCustomer: null,
    errors: null
}

export const updateCustomerDataReducer =  (state = initalState, action: any) => {
    switch (action.type) {
        case UPDATE_CUSTOMER_DATA_LOADING :
            return {...state, updateCustomerDataLoading: action.payload};
        case UPDATE_CUSTOMER_DATA_SUCCESS :
            return {...state, updatedCustomer : action.payload.customer};
        case UPDATE_CUSTOMER_DATA_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}