import
{
    RENTAL_TYPES_LOADING,
    RENTAL_TYPES_SUCCESS,
    RENTAL_TYPES_FAILURE
}
from "redux/types";

const initialState = {
    rentalTypesLoading: false,
    rentalTypes: null,
    errors: null
}

export const listRentalTypesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case RENTAL_TYPES_LOADING :
            return {...state, rentalTypesLoading: action.payload}
        case RENTAL_TYPES_SUCCESS :
            return {...state, rentalTypes: action.payload.records}
        case RENTAL_TYPES_FAILURE :
            return {...state, errors: action.payload}
        default:
            return state;
    }
}