import styles from "screens/V3/ArticlesScreen/ArticlesScreen.module.css";
import { useTranslation } from "react-i18next";
import WideArticleCard from "components/V3/WideArticleCard/WideArticleCard";
import { Footer } from "components";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { changeCurrentPageAction, listArticlesAction } from "redux/actions";
import { Spin } from "antd";
import CustomArticleCard from "components/V3/CustomArticleCard/CustomArticleCard";
import { useResponsive } from "hooks/useResponsive";

const ArticlesScreen = () => {
  const dispatch: any = useDispatch();

  const { t } = useTranslation();

  const { isMobile } = useResponsive();

  const { articlesLoading, articles } = useSelector(
    (state: RootState) => state.listarticlesReducer
  );

  useEffect(() => {
    dispatch(listArticlesAction());
    dispatch(changeCurrentPageAction(0));

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="mt-[2.94rem] w-full flex flex-col items-center gap-y-[2.12rem]">
      <h1 className={`text-[var(--new-blue)] font-[700] text-[1.25rem]`}>
        {t("news")}
      </h1>
      {!isMobile && (
        <Spin spinning={articlesLoading} className="w-full">
          <WideArticleCard
            id={articles[0]?.id}
            image={articles[0]?.image}
            title={articles[0]?.title}
            description={articles[0]?.description}
            date={articles[0]?.created_at?.split("T")[0]}
          />
        </Spin>
      )}
      <div className="w-full flex flex-col items-center">
        {!isMobile && (
          <p className={`text-[var(--new-blue)] text-[1.125rem] mt-3 mb-5`}>
            {t("homepage_all_articles")}
          </p>
        )}
        <div
          dir="rtl"
          className={`articles-spin-container ${
            isMobile ? "w-full px-3" : "w-[70%]"
          } flex flex-row flex-wrap gap-x-2 gap-y-6 items-center ${
            isMobile ? "justify-center" : "justify-start"
          }`}
        >
          {isMobile ? (
            <Spin spinning={articlesLoading}>
              {articles?.map((item: any) => {
                return (
                  <CustomArticleCard
                    isMobile={isMobile}
                    id={item?.id}
                    image={item?.image}
                    createdAt={item?.created_at}
                    title={item?.title}
                    description={item?.description}
                    isHomePageCard={false}
                  />
                );
              })}
            </Spin>
          ) : articlesLoading ? (
            <Spin />
          ) : (
            articles?.map((item: any) => {
              return (
                <CustomArticleCard
                  isMobile={isMobile}
                  id={item?.id}
                  image={item?.image}
                  createdAt={item?.created_at}
                  title={item?.title}
                  description={item?.description}
                  isHomePageCard={false}
                />
              );
            })
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ArticlesScreen;
