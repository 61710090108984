import 
{
    LIST_CAR_MAKER_LOADING,
    LIST_CAR_MAKER_SUCCESS,
    LIST_CAR_MAKER_FAILURE
}
from "redux/types";

const initialState = {
    carMakersLoading: false,
    carMakers: null,
    errors: null
};

export const listCarMakerReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_CAR_MAKER_LOADING :
            return {...state, carMakersLoading: action.payload};
        case LIST_CAR_MAKER_SUCCESS :
            return {...state, carMakers: action.payload.data};
        case LIST_CAR_MAKER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}