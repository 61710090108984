import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from "@reach/combobox";
import "@reach/combobox/styles.css";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { setMapCoordinatesPostAction } from "redux/actions/SetMapCoordinatesPostAction/setMapCoordinatesPostAction";
import { RootState } from "redux/rootReducer";

interface PlacesAutoCompleteProps {
  // setSelected: (data: any) => void;
  initialValue?: string;
  setMarkerPosition?: any;
}

const PlacesAutoComplete: FC<PlacesAutoCompleteProps> = ({ initialValue, setMarkerPosition }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const dispatch: any = useDispatch();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setMarkerPosition({ lat, lng });
    dispatch(setMapCoordinatesPostAction({ lat, lng }));
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        style={{
          width: "100%",
          padding: "0.5rem"
        }}
        placeholder="Search an address"
      />
      <ComboboxPopover style={{ zIndex: '9999999999' }}>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}

export default PlacesAutoComplete;