import * as React from "react";
import type { SVGProps } from "react";
const SvgChatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <g fill="#0050FF">
      <path d="M4.949 6.281h4.302a.538.538 0 0 1 0 1.076H4.95a.538.538 0 0 1 0-1.076ZM4.949 8.322h4.302a.538.538 0 0 1 0 1.076H4.95a.538.538 0 0 1 0-1.076Z" />
      <path d="M.637 6.519a6.46 6.46 0 1 1 6.445 6.858H3.326a2.692 2.692 0 0 1-2.689-2.689V6.52Zm1.076 4.17a1.613 1.613 0 0 0 1.613 1.613h3.756a5.399 5.399 0 0 0 5.14-3.755c.23-.725.302-1.49.212-2.245A5.401 5.401 0 0 0 7.08 1.546a5.335 5.335 0 0 0-3.43 1.237A5.4 5.4 0 0 0 1.712 6.57v4.118Z" />
      <path d="M7.054 5.31H4.903a.538.538 0 1 1 0-1.075h2.151a.538.538 0 1 1 0 1.075Z" />
    </g>
  </svg>
);
export default SvgChatIcon;
