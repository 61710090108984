import 
{
    UPDATE_EXHIBITOR_LOADING,
    UPDATE_EXHIBITOR_SUCCESS,
    UPDATE_EXHIBITOR_FAILURE
}
from "redux/types";

const initialState = {
    updateExhibitorLoading: false,
    updateExhibitorSuccess: null,
    errors: null
};

export const updateExhibitorDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_EXHIBITOR_LOADING :
            return {...state, updateExhibitorLoading: action.payload};
        case UPDATE_EXHIBITOR_SUCCESS :
            return {...state, updateExhibitorSuccess: action.payload.success};
        case UPDATE_EXHIBITOR_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}