import { DRAWER_STATUS_TYPE } from "redux/types";

const initialState = {
  drawerOpen: false,
};

export const drawerStatusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DRAWER_STATUS_TYPE:
      return { ...state, drawerOpen : action.payload };
    default:
      return state;
  }
};
