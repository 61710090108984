import api from "api";
import { loginWithMobileEndPoint } from "api/apiUrls";

export const loginWithMobileService = async (mobile: string, captcha: string) => {
    const response = await api.post(
        loginWithMobileEndPoint(),
        {
            mobile: mobile,
            captcha: captcha
        },
        {
            headers: {'Content-Type' : 'application/json'}
        }
    );
    return response;
}