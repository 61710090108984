import
{
    VIEW_PROFILE_POSTS_LOADING,
    VIEW_PROFILE_POSTS_SUCCESS,
    VIEW_PORFILE_POSTS_FAILURE
}
from "redux/types";

import { listProfilePostsService } from "services/listProfilePostsService";

import { Dispatch } from "redux";

export const listProfilePostsAction = (postStatus: string, postCategoryId: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: VIEW_PROFILE_POSTS_LOADING, payload: true});
        const response = await listProfilePostsService(postStatus, postCategoryId);
        dispatch({type: VIEW_PROFILE_POSTS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: VIEW_PORFILE_POSTS_FAILURE, payload: err});
    } finally {
        dispatch({type: VIEW_PROFILE_POSTS_LOADING, payload: false});
    }
}