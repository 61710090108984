import styles from "components/V3/CustomCarDetailCard/CustomCarDetailCard.module.css";
import { useLanguage } from "hooks/useLanguage";
import { FC } from "react";
import { number } from "yup";

interface CarDetailCardProps {
  data: any;
  isMobile: any;
}

const CustomCarDetailCard: FC<CarDetailCardProps> = ({ data, isMobile }) => {
  const gap = 0.5;

  const { textDirectionStyle } = useLanguage();

  return (
    <div
      dir={textDirectionStyle}
      className={`w-full py-3 ${styles.shadow} rounded-[0.625rem] flex flex-row items-center flex-wrap px-3`}
    >
      <div
        className={`w-full flex items-center flex-wrap`}
        style={{
          gap: `${gap}rem`,
        }}
      >
        {data?.map((item: any, index: any) => {
          const numberPerRow = 5;
          const maxWidth = `calc(100% / ${numberPerRow} - ${gap}rem + ${
            gap / numberPerRow
          }rem)`;
          //
          return (
            <div
              className={`${
                isMobile ? "h-[2.375rem]" : "h-[3.49056rem]"
              } rounded-[0.3125rem] flex items-center justify-center ${
                styles.blueBackground
              }`}
              style={{ width: "100%", maxWidth: maxWidth }}
            >
              <p className={`${isMobile ? "text-[0.75rem]" : ""} text-center`}>
                {item.text}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomCarDetailCard;
