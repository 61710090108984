import styles from "components/PostGrid/PostGrid.module.css";
import { useTranslation } from "react-i18next";
import { RootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { gridView, columnView } from "assets/icons";
import { CustomIcon } from "components/CustomIcon/CustomIcon";
import { Empty, Spin } from "antd";
import { useResponsive } from "hooks/useResponsive";
import NewWidePostCard from "components/V3/NewCustomPostCard/NewCustomPostCard";
import NewPostCard from "components/V3/NewCustomPostCard/NewWidePostCard/NewWidePostCard";
import WidePostCard from "components/V3/CustomPostCard/WidePostCard/WidePostCard";
import PostCard from "components/V3/CustomPostCard/PostCard/PostCard";
// import PostCard from "components/V3/NewCustomPostCard/NewCustomPostCard";
import Pagination from "components/Pagination/Pagination";
import { useIsDefined } from "hooks/useIsDefined";
import NewPaginator from "../NewPaginator/NewPaginator";
import { useSearch } from "hooks/useSearch";

interface PostGridProps {
  postsLoading: boolean;
  posts: any;
  forcedViewType?: "grid" | "list";
  width?: any;
  title?: string;
  pagination?: any;
  style?: any;
}
const PostGrid: FC<PostGridProps> = ({
  postsLoading,
  posts,
  forcedViewType = null,
  width = "72%",
  title = null,
  pagination = null,
  style,
}) => {
  const [viewType, setViewType] = useState(forcedViewType ?? "grid");
  const { t } = useTranslation();
  const { getSearchParam } = useSearch();
  const { isMobile, isMax1280 } = useResponsive();
  const { id } = useParams();
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const isArabic = currentLanguage === "ar";
  const dir = isArabic ? "rtl" : "ltr";

  const { isDefined } = useIsDefined();

  return (
    <div
      className={`${styles.postsContainer} flex flex-col`}
      style={{ width: `${isMobile ? "100%" : width}`, ...style }}
    >
      <div
        className={`flex flex-col ${isArabic ? "items-end" : ""} justify-end`}
      >
        {(title || !forcedViewType) && !isMax1280 && (
          <div
            dir={dir}
            className={`${
              title && !forcedViewType ? "w-full" : ""
            } flex items-center justify-between ${styles.headingContainer}`}
          >
            {title && (
              <p className="text-xl" style={{ fontFamily: "rbFontBold" }}>
                {title}
              </p>
            )}
            {/* VIEW TYPE BUTTONS */}
            {!forcedViewType && !isMax1280 && (
              <div className="flex gap-x-2">
                {/* LIST */}
                <div
                  className={`p-1 bg-[#EFEFFF] flex items-center justify-center ${styles.hover}`}
                  style={{
                    borderRadius: ".3rem",
                    fontSize: "10rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setViewType("list");
                  }}
                >
                  <CustomIcon className="pl-2 pt-2" href={columnView} />
                </div>
                {/* GRID */}
                <div
                  className={`p-1 bg-[#EFEFFF] flex items-center justify-center ${styles.hover}`}
                  style={{
                    borderRadius: ".3rem",
                    fontSize: "10rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setViewType("grid");
                  }}
                >
                  <CustomIcon className="pl-2 pt-2" href={gridView} />
                </div>
              </div>
            )}
          </div>
        )}

        {/* POST AND WIDE POST CARDS */}
        {isMobile ? (
          <div
            className={`w-full px-3 ${isArabic ? styles.ar : ""} ${
              styles.grid
            }`}
            style={{ gap: "0.62rem" }}
          >
            {postsLoading ? (
              <Spin />
            ) : posts?.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ transform: "scale(1.5)" }}
                description={t("no_posts")}
              />
            ) : (
              posts?.map((item: any, idx: number) => {
                return (
                  <NewWidePostCard
                    key={idx}
                    isMobile={isMobile}
                    id={item.uuid}
                    isSpecialAd={true}
                    showActions={false}
                    width={"w-full"}
                    category={item.category}
                    customerName={item.customer}
                    brandLogo={item.brand_logo}
                    isFavorited={item.favourited}
                    // className={`${styles.widePostCard}`}
                    callsCount={item.contact_count}
                    viewsCount={item.views_count}
                    uploadDate={item.created_at?.split("T")[0]}
                    postTitle={item.title}
                    city={item.city}
                    price={
                      isDefined(item.down_payment)
                        ? item.down_payment
                        : item.price
                    }
                    carCondition={item.car_condition}
                    productionDate={item.production_date}
                    postDescription={item.description}
                    kilometrage={item.kilo_meterage}
                    carMaker={item.brand}
                    image={item.main_image}
                    paymentOption={item.payment_type}
                    created_at={item.created_at}
                    address={item.address}
                  />
                );
              })
            )}
          </div>
        ) : isMax1280 ? (
          // BETWEEN MOBILE AND DESKTOP
          // ${styles.grid}
          <div
            className={`w-full ${
              isArabic ? styles.ar : ""
            } flex flex-wrap gap-5`}
          >
            {postsLoading ? (
              <Spin />
            ) : posts?.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ transform: "scale(1.5)" }}
                description={t("no_posts")}
              />
            ) : (
              posts?.map((item: any, idx: number) => {
                return (
                  <NewWidePostCard
                    key={idx}
                    isMobile={isMobile}
                    id={item.uuid}
                    isSpecialAd={true}
                    showActions={false}
                    width={"w-full"}
                    category={item.category}
                    customerName={item.customer}
                    brandLogo={item.brand_logo}
                    isFavorited={item.favourited}
                    // className={`${styles.widePostCard}`}
                    callsCount={item.contact_count}
                    viewsCount={item.views_count}
                    uploadDate={item.created_at?.split("T")[0]}
                    postTitle={item.title}
                    city={item.city}
                    price={
                      isDefined(item.down_payment)
                        ? item.down_payment
                        : item.price
                    }
                    carCondition={item.car_condition}
                    productionDate={item.production_date}
                    postDescription={item.description}
                    kilometrage={item.kilo_meterage}
                    carMaker={item.brand}
                    image={item.main_image}
                    paymentOption={item.payment_type}
                    created_at={item.created_at}
                    address={item.address}
                  />
                  // <PostCard
                  //   key={idx}
                  //   isSpecialAd={false}
                  //   isFavorited={item.favourited}
                  //   width={"w-full"}
                  //   callsCount={item.contact_count}
                  //   viewsCount={item.views_count}
                  //   uploadDate={item.created_at?.split("T")[0]}
                  //   category={item.category}
                  //   postTitle={item.title}
                  //   city={item.address}
                  //   price={
                  //     isDefined(item?.down_payment)
                  //       ? item?.down_payment
                  //       : item?.price
                  //   }
                  //   carCondition={item.car_condition}
                  //   productionDate={item.production_date}
                  //   kilometrage={item.kilo_meterage}
                  //   id={item.uuid}
                  //   postType={item.post_type}
                  //   customerName={"بوديا"}
                  //   paymentOption={item.payment_type}
                  //   isMobile={false}
                  //   postCardStyle={{
                  //     // justifyContent: "center",
                  //     itemAlign: "center",
                  //   }}
                  //   image={item.main_image}
                  // />
                );
              })
            )}
          </div>
        ) : // DESKTOP
        viewType === "list" ? (
          // LIST VIEW
          <div
            className={`${styles.postCards} flex flex-col items-end justify-start gap-y-6 w-full`}
          >
            {/* map vendor posts here */}
            {postsLoading ? (
              <Spin />
            ) : posts?.length === 0 ? (
              <div className="w-full flex flex-col items-center mt-10">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ transform: "scale(1.5)" }}
                  description={t("no_posts")}
                />
              </div>
            ) : (
              Array.isArray(posts) &&
              posts?.map((item: any, idx: any) => {
                return (
                  <WidePostCard
                    key={idx}
                    id={item?.uuid}
                    isSpecialAd={false}
                    isFavorited={item?.favourited}
                    showActions={false}
                    width={"w-full"}
                    brandLogo={item.brand_logo}
                    className={`${styles.widePostCard}`}
                    callsCount={item?.contact_count}
                    customerName={item?.customer}
                    paymentOption={item?.payment_type}
                    category={item?.category_id}
                    viewsCount={item?.views_count}
                    uploadDate={item?.created_at.split("T")[0]}
                    postTitle={item?.title}
                    city={item?.address}
                    price={
                      isDefined(item?.down_payment)
                        ? item?.down_payment
                        : item?.price
                    }
                    carCondition={item?.car_condition}
                    productionDate={item?.production_date}
                    kilometrage={item?.kilo_meterage}
                    carMaker={item.brand}
                    image={item?.main_image}
                  />
                );
              })
            )}
          </div>
        ) : // GRID VIEW
        postsLoading ? (
          <Spin />
        ) : posts?.length === 0 ? (
          <div className="w-full flex flex-col items-center mt-10">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ transform: "scale(1.5)" }}
              description={t("no_posts")}
            />
          </div>
        ) : (
          <div
            className={`w-full ${isArabic ? styles.ar : styles.en} ${
              styles.grid
            }`}
          >
            {posts?.map((item: any, idx: number) => {
              return (
                <NewPostCard
                  key={idx}
                  isMobile={isMobile}
                  id={item.uuid}
                  isSpecialAd={true}
                  showActions={false}
                  width={"w-full"}
                  postCardStyle={{
                    width: "100%",
                  }}
                  category={item.category}
                  customerName={item.customer}
                  brandLogo={item.brand_logo}
                  isFavorited={item.favourited}
                  // className={`${styles.widePostCard}`}
                  callsCount={item.contact_count}
                  viewsCount={item.views_count}
                  uploadDate={item.created_at?.split("T")[0]}
                  postTitle={item.title}
                  city={item.city}
                  price={
                    isDefined(item.down_payment)
                      ? item.down_payment
                      : item.price
                  }
                  carCondition={item.car_condition}
                  productionDate={item.production_date}
                  postDescription={item.description}
                  kilometrage={item.kilo_meterage}
                  carMaker={item.brand}
                  image={item.main_image}
                  paymentOption={item.payment_type}
                  created_at={item.created_at}
                  address={item.address}
                />
                // <PostCard
                //   isSpecialAd={false}
                //   isFavorited={item.favourited}
                //   width={""}
                //   maxWidth={"auto"}
                //   callsCount={item.contact_count}
                //   viewsCount={item.views_count}
                //   uploadDate={item.created_at}
                //   postTitle={item.title}
                //   city={item.address}
                //   price={
                //     isDefined(item?.down_payment)
                //       ? item?.down_payment
                //       : item?.price
                //   }
                //   carCondition={item.car_condition}
                //   productionDate={item.production_date}
                //   kilometrage={item.kilo_meterage}
                //   id={item.uuid}
                //   postType={item.category}
                //   customerName={item.customer}
                //   paymentOption={item.payment_type}
                //   isMobile={false}
                //   style={styles.gridItem}
                //   image={item.main_image}
                //   category={item.category}
                // />
              );
            })}
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {pagination && (
        // <Pagination
        //   current_page={pagination.currentPage}
        //   last_page={pagination.lastPage}
        //   path={pagination.path}
        //   loading={postsLoading}
        //   style={{ marginTop: "2rem" }}
        // />
        <NewPaginator
          className="mt-16 mb-16"
          currentPage={
            isNaN(parseInt(getSearchParam("page")))
              ? 1
              : parseInt(getSearchParam("page"))
          }
          lastPage={pagination.lastPage}
          url={`/${currentLanguage}/vendor/${id}`}
        />
      )}
    </div>
  );
};

export default PostGrid;
