import
{
    EXHIBITIONS_LOADING,
    EXHIBITIONS_SUCCESS,
    EXHIBITIONS_FAILURE
}
from "redux/types";

const initialState = {
    loading: false,
    exhibitions: null,
    currentPage: null,
    lastPage: null,
    errors: null
}

export const listExhibitionsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case EXHIBITIONS_LOADING :
            return {...state, loading: action.payload};
        case EXHIBITIONS_SUCCESS :
            return {...state, exhibitions: action.payload.data.data, currentPage: action.payload.data.current_page, lastPage: action.payload.data.last_page};
        case EXHIBITIONS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}