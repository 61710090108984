import api from "api";
import { deleteStoryEndPoint } from "api/apiUrls";
import store from "redux/store";

export const deleteStoryService = async (storyId: any) => {
    const response = await api.post(
        deleteStoryEndPoint(storyId),
        {},
        {
            headers: {
                'Authorization' : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );

    return response;
}