import * as React from "react";
import type { SVGProps } from "react";
const SvgTiktokIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      d="M24 4H6a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-1.311 9.474a4.27 4.27 0 0 1-3.967-1.911v6.577a4.861 4.861 0 1 1-4.861-4.862c.102 0 .201.009.3.015v2.396c-.1-.012-.197-.03-.3-.03a2.481 2.481 0 0 0 0 4.962c1.371 0 2.581-1.08 2.581-2.45l.024-11.17h2.289a4.268 4.268 0 0 0 3.934 3.811v2.662z"
    />
  </svg>
);
export default SvgTiktokIcon;
