import
{
    REACTIVATE_POST_LOADING,
    REACTIVATE_POST_SUCCESS,
    REACTIVATE_POST_FAILURE
}
from "redux/types";

import { reactivatePostService } from "services/reactivatePostService";

import { Dispatch } from "redux";

export const reactivatePostAction = (postId: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: REACTIVATE_POST_LOADING, payload: true});
        const response = await reactivatePostService(postId);
        dispatch({type: REACTIVATE_POST_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: REACTIVATE_POST_FAILURE, payload: err});
    } finally {
        dispatch({type: REACTIVATE_POST_LOADING, payload: false});
    }
}