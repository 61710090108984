import
{
    MESSAGES_LOADING,
    MESSAGES_SUCCESS,
    MESSAGES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listMessagesService } from "services/listMessagesService";

export const listMessagesAction = (userId: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: MESSAGES_LOADING, payload: true});
        const response = await listMessagesService(userId);
        dispatch({type: MESSAGES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: MESSAGES_FAILURE, payload: err});
    } finally {
        dispatch({type: MESSAGES_LOADING, payload: false});
    }
}