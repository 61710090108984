import styles from "screens/V3/ExhibitorDashboardScreen/ExhibitorDashboardScreen.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listExhibitorDataAction } from "redux/actions/ExhibitorDataActions/listExhibitorDataAction";
import { CustomButton, Footer } from "components";
import {
  ArrowLeftIcon,
  BlackUserIcon,
  CircularSettingsIcon,
  NoImageIcon,
  ShareButtonIcon,
  Star,
  UserIcon,
} from "assets/icons/V3";
import {
  FacebookFilled,
  TwitterCircleFilled,
  GlobalOutlined,
  InstagramFilled,
  YoutubeFilled,
  DeleteOutlined,
  SyncOutlined,
  EyeOutlined,
  PhoneFilled,
  WhatsAppOutlined,
  ClockCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { SettingsIcon } from "assets/IconComponents";
import ShareButton from "components/V3/ShareButton/ShareButton";
import { useTranslation } from "react-i18next";
import { getExhibitorStatisticsAction } from "redux/actions/ExhibitorsActions/getExhibitorStatisticsAction";
import { Empty, Input, Modal, Select, Spin, notification } from "antd";
import { useNavigate } from "react-router";
import {
  listCategoriesAction,
  listCitiesAction,
  listPostStatusAction,
  listProfilePostsAction,
} from "redux/actions";
import NewWidePostCard from "components/V3/NewCustomPostCard/NewWidePostCard/NewWidePostCard";
import { useResponsive } from "hooks/useResponsive";
import { useIsDefined } from "hooks/useIsDefined";
import { listExhibitorServicesAction } from "redux/actions/ExhibitorServicesAction/listExhibitorServicesAction";
import { useFormik } from "formik";
import SocialLinksModel from "models/SocialLinksModel";
import { updateSocialLinksAction } from "redux/actions/ExhibitorsActions/updateSocialLinksAction";
import GeneralExhibitorDataModel from "models/GeneralExhibitorDataModel";
import { updateGeneralDataAction } from "redux/actions/ExhibitorsActions/updateGeneralDataAction";
import { listMyStoriesAction } from "redux/actions/StoriesActions/ListMyStoriesAction/listMyStoriesAction";
import ArchiveStoryCard from "components/V3/ArchiveStoryCard/ArchiveStoryCard";
import DashboardBranchCard from "components/V3/DashboardBranchCard/DashboardBranchCard";
import BranchModel from "models/BranchModel";
import { listBranchTypesAction } from "redux/actions/BranchTypesAction/listBranchTypesAction";
import { useDropdowns } from "hooks/useDropdowns";
import { listWeekDaysAction } from "redux/actions/WeekDaysAction/listWeekDaysAction";
import { createBranchAction } from "redux/actions/ExhibitorsActions/createBranchAction";
import { updateBranchAction } from "redux/actions/ExhibitorsActions/updateBranchAction";
import { deleteCoverPhotoService } from "services/ExhibitorServices/deleteCoverPhotoService";
import { updateCoverPhotoService } from "services/ExhibitorServices/updateCoverPhotoService";
import { useLanguage } from "hooks/useLanguage";
import NewCustomPostCardWithEdit from "components/V3/NewCustomPostCard/NewWidePostCardWithEdit";
import { usePostActions } from "hooks/usePostActions";
import { updateMainPhotoService } from "services/ExhibitorServices/updateMainPhotoService";
import { deleteMainPhotoService } from "services/ExhibitorServices/deleteMainPhotoService";

const ExhibitorDashboardScreen = () => {
  const { TextArea } = Input;

  const dispatch: any = useDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    handleSuspendPostClick,
    handleReactivatePostClick,
    handlePostEditClick,
  } = usePostActions();

  const { userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { generateTimesDropdown } = useDropdowns();

  const { currentLanguage, textDirectionStyle } = useLanguage();

  const { exhibitorDataLoading, exhibitorData, branchesData } = useSelector(
    (state: RootState) => state.listExhibitorDataReducer
  );

  const { statisticsLoading, statistics } = useSelector(
    (state: RootState) => state.getExhibitorStatisticsReducer
  );

  const { postStatusLoading, postStatus } = useSelector(
    (state: RootState) => state.listPostStatusReducer
  );

  const { categoriesLoading, categories } = useSelector(
    (state: RootState) => state.listCategoriesReducer
  );

  const { profilePostsLoading, profilePosts } = useSelector(
    (state: RootState) => state.listProfilePostsReducer
  );

  const { exhibitorServicesLoading, exhibitorServices } = useSelector(
    (state: RootState) => state.listExhibitorServicesReducer
  );

  const { myStoriesLoading, myStories } = useSelector(
    (state: RootState) => state.listMyStoriesReducer
  );

  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const { branchTypesLoading, branchTypes } = useSelector(
    (state: RootState) => state.listBranchTypesReducer
  );

  const { weekDaysLoading, weekDays } = useSelector(
    (state: RootState) => state.listWeekDaysReducer
  );

  const { updateSocialLinksLoading, updateSocialLinksSuccess } = useSelector(
    (state: RootState) => state.updateSocialLinksReducer
  );
  const socialLinksFormik = useFormik({
    initialValues: {
      facebookLink: exhibitorData?.facebook_link ?? "",
      instagramLink: exhibitorData?.instagram_link ?? "",
      twitterLink: exhibitorData?.twitter_link ?? "",
      websiteLink: exhibitorData?.website_link ?? "",
      youtubeLink: exhibitorData?.youtube_link ?? "",
    },
    onSubmit: async (values: SocialLinksModel) => {
      await dispatch(
        updateSocialLinksAction({
          facebookLink: values.facebookLink,
          instagramLink: values.instagramLink,
          twitterLink: values.twitterLink,
          websiteLink: values.websiteLink,
          youtubeLink: values.youtubeLink,
        })
      );
      notification.success({
        message: t("success_notification"),
      });
      window.location.reload();
    },
  });

  const { updateGeneralDataLoading, updateGeneralDataSuccess } = useSelector(
    (state: RootState) => state.updateGeneralDataReducer
  );
  const generalDataFormik = useFormik({
    initialValues: {
      main_service: exhibitorData?.main_service?.value ?? "",
      whatsapp: exhibitorData?.customer?.whatsapp ?? "",
      description: exhibitorData?.description ?? "",
    },
    onSubmit: async (values: GeneralExhibitorDataModel) => {
      await dispatch(
        updateGeneralDataAction({
          whatsapp: values.whatsapp,
          main_service: values.main_service,
          description: values.description,
        })
      );
      notification.success({
        message: t("success_notification"),
      });
      window.location.reload();
    },
  });

  const { createBranchLoading, newBranch } = useSelector(
    (state: RootState) => state.createBranchReducer
  );
  const createBranchFormik = useFormik({
    initialValues: {
      city_id: "",
      address: "",
      branch_type: "",
      work_from: "",
      work_to: "",
      day_off: "",
      phone_one: "",
      phone_two: "",
    },
    onSubmit: async (values: BranchModel) => {
      var errorExists = false;
      if (values.city_id === "") {
        createBranchFormik.setFieldError("city_id", "err");
        errorExists = true;
      }
      if (values.address === "") {
        createBranchFormik.setFieldError("address", "err");
        errorExists = true;
      }
      if (values.branch_type === "") {
        createBranchFormik.setFieldError("branch_type", "err");
        errorExists = true;
      }
      if (values.work_from === "") {
        createBranchFormik.setFieldError("work_from", "err");
        errorExists = true;
      }
      if (values.work_to === "") {
        createBranchFormik.setFieldError("work_to", "err");
        errorExists = true;
      }
      if (values.day_off === "") {
        createBranchFormik.setFieldError("day_off", "err");
        errorExists = true;
      }
      if (values.phone_one === "") {
        createBranchFormik.setFieldError("phone_one", "err");
        errorExists = true;
      }
      if (errorExists) {
        return;
      }
      await dispatch(
        createBranchAction({
          city_id: values.city_id,
          address: values.address,
          branch_type: values.branch_type,
          phone_one: values.phone_one,
          phone_two: values.phone_two,
          work_from: values.work_from,
          work_to: values.work_to,
          day_off: values.day_off,
        })
      );
      notification.success({
        message: t("success_notification"),
      });
      window.location.reload();
    },
  });

  const [updatingBranchId, setUpdatingBranchId] = useState("");
  const [updatingCityId, setUpdatingCityId] = useState("");
  const [updatingBranchType, setUpdatingBranchType] = useState("");
  const [updatingDayOff, setUpdatingDayOff] = useState("");
  const [updatingWorkFrom, setUpdatingWorkFrom] = useState("");
  const [updatingWorkTo, setUpdatingWorkTo] = useState("");
  const { updateBranchLoading, updatedBranch } = useSelector(
    (state: RootState) => state.updateBranchReducer
  );
  const updateBranchFormik = useFormik({
    initialValues: {
      city_id: "",
      address: "",
      branch_type: "",
      work_from: "",
      work_to: "",
      day_off: "",
      phone_one: "",
      phone_two: "",
    },
    onSubmit: async (values: BranchModel) => {
      var errorExists = false;
      if (values.city_id === "") {
        updateBranchFormik.setFieldError("city_id", "err");
        errorExists = true;
      }
      if (values.address === "") {
        updateBranchFormik.setFieldError("address", "err");
        errorExists = true;
      }
      if (values.branch_type === "") {
        updateBranchFormik.setFieldError("branch_type", "err");
        errorExists = true;
      }
      if (values.work_from === "") {
        updateBranchFormik.setFieldError("work_from", "err");
        errorExists = true;
      }
      if (values.work_to === "") {
        updateBranchFormik.setFieldError("work_to", "err");
        errorExists = true;
      }
      if (values.day_off === "") {
        updateBranchFormik.setFieldError("day_off", "err");
        errorExists = true;
      }
      if (values.phone_one === "") {
        updateBranchFormik.setFieldError("phone_one", "err");
        errorExists = true;
      }
      if (errorExists) {
        return;
      }

      const updatingData: any = {
        address: values.address,
        phone_one: values.phone_one,
        phone_two: values.phone_two,
      };

      if (updatingCityId !== "") {
        updatingData.city_id = updatingCityId;
      }
      if (updatingBranchType !== "") {
        updatingData.branch_type = updatingBranchType;
      }
      if (updatingDayOff !== "") {
        updatingData.day_off = updatingDayOff;
      }
      if (updatingWorkFrom !== "") {
        updatingData.work_from = updatingWorkFrom;
      }
      if (updatingWorkTo !== "") {
        updatingData.work_to = updatingWorkTo;
      }

      await dispatch(updateBranchAction(updatingBranchId, updatingData));
      notification.success({
        message: t("success_notification"),
      });
      window.location.reload();
    },
  });

  const { isMobile, addBreakpoint } = useResponsive();
  const [isMax1440, setIsMax1440] = useState(false);
  addBreakpoint({ value: 1440, type: "MAX", setState: setIsMax1440 });
  const { isDefined } = useIsDefined();

  const [selectedPostStatus, setSelectedPostStatus] = useState("Pending");
  const [selectedPostCategory, setSelectedPostCategory] = useState(1);

  useEffect(() => {}, [selectedPostStatus]);

  const [moreStatsModalOpen, setMoreStatsModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const coverPhotoInputRef = useRef<HTMLInputElement | null>(null);
  const [coverPhoto, setCoverPhoto] = useState<any>();
  const handleCoverPhotoUpload = async (event: any) => {
    setCoverPhoto(event.target.files[0]);
    await updateCoverPhotoService({
      image: event.target.files[0],
    });
    notification.success({
      message: t("success_notification"),
    });
  };
  const handleDeleteCoverPhoto = async () => {
    await deleteCoverPhotoService();
    notification.success({
      message: t("success_notification"),
    });
    setCoverPhoto(null);
  };

  const mainPhotoInputRef = useRef<HTMLInputElement | null>(null);
  const [mainPhoto, setMainPhoto] = useState<any>();
  const handleMainPhotoUpload = async (event: any) => {
    setMainPhoto(event.target.files[0]);
    await updateMainPhotoService({
      main_image: event.target.files[0],
    });
    notification.success({
      message: t("success_notification"),
    });
  };
  const handleDeleteMainPhoto = async () => {
    await deleteMainPhotoService();
    notification.success({
      message: t("success_notification"),
    });
    setMainPhoto(null);
  };

  /*
    0: General Settings
    1: Branches
    2: Social Media Links
    3: Create Branch
    4: Edit Branch
  */

  const [selectedSettingsSection, setSelectedSettingsSection] = useState(0);

  /* Settings Input Field States */
  const [whatsappNum, setWhatsappNum] = useState("");
  const [mainService, setMainService] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  /* End Settings Input Field States */

  const checkSocialLinksEmpty = () => {
    var isEmpty = true;
    if (
      exhibitorData?.facebook_link !== "" &&
      exhibitorData?.facebook_link !== null
    ) {
      isEmpty = false;
    }
    if (
      exhibitorData?.twitter_link !== "" &&
      exhibitorData?.twitter_link !== null
    ) {
      isEmpty = false;
    }
    if (
      exhibitorData?.website_link !== "" &&
      exhibitorData?.website_link !== null
    ) {
      isEmpty = false;
    }
    if (
      exhibitorData?.instagram_link !== "" &&
      exhibitorData?.instagram_link !== null
    ) {
      isEmpty = false;
    }
    if (
      exhibitorData?.youtube_link !== "" &&
      exhibitorData?.youtube_link !== null
    ) {
      isEmpty = false;
    }
    return isEmpty;
  };

  const exhibitorServicesDropdown = exhibitorServices?.map((item: any) => {
    return {
      label: item.name,
      value: item.key,
    };
  });

  const citiesDropdown = cities?.map((item: any) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const branchTypesDropdown = branchTypes?.map((item: any) => {
    return {
      label: item.name,
      value: item.key,
    };
  });

  const weekDaysDropdown = weekDays?.map((item: any) => {
    return {
      label: item.name,
      value: item.key,
    };
  });

  useEffect(() => {
    dispatch(listExhibitorDataAction());
    dispatch(getExhibitorStatisticsAction());
    dispatch(listPostStatusAction());
    dispatch(listCategoriesAction());
    dispatch(listExhibitorServicesAction());
    dispatch(listCitiesAction());
    dispatch(listBranchTypesAction());
    dispatch(listWeekDaysAction());

    setCoverPhoto(exhibitorData?.cover_photo);
    setMainPhoto(exhibitorData?.main_image);
  }, []);

  useEffect(() => {
    dispatch(listProfilePostsAction(selectedPostStatus, selectedPostCategory));
  }, [selectedPostCategory, selectedPostStatus]);

  const infoCardStyle = {
    width: `calc(100% / 2 - 0.5rem + ${0.5 / 2}rem)`,
  };
  return (
    <>
      <div
        dir={textDirectionStyle}
        className="mt-[1rem] w-full flex flex-col items-center gap-y-3"
        style={{ paddingInline: isMobile ? "0.5rem" : "15%" }}
      >
        {/* Top Section */}
        <div
          className={`w-full ${styles.shadow} rounded-[0.9375rem] py-2 px-2`}
        >
          {/* COVER */}
          <div
            style={
              coverPhoto !== null &&
              coverPhoto !== "https://souqcarlive.s3.amazonaws.com/customers/"
                ? {
                    backgroundImage:
                      coverPhoto instanceof File
                        ? `url('${encodeURI(URL.createObjectURL(coverPhoto))}')`
                        : `url('${coverPhoto}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }
                : {
                    backgroundImage: `url('${NoImageIcon}')`,
                    backgroundSize: "40% 40%",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }
            }
            className={`w-full h-[13.125rem] rounded-[0.625rem] bg-[var(--new-blue)] flex flex-col items-between justify-between px-3 py-3`}
          >
            <div className="w-full flex flex-row items-between justify-between">
              {!checkSocialLinksEmpty() && (
                <div className="bg-white rounded-[0.9375rem] h-[1.75rem] px-4 flex flex-row gap-x-3 items-center justify-center">
                  {exhibitorData?.facebook_link !== "" &&
                    exhibitorData?.facebook_link !== null && (
                      <a
                        href={exhibitorData?.facebook_link}
                        className="flex items-center justify-center"
                      >
                        <FacebookFilled className="text-[var(--new-blue)]" />
                      </a>
                    )}
                  {exhibitorData?.twitter_link !== "" &&
                    exhibitorData?.twitter_link !== null && (
                      <a
                        href={exhibitorData?.twitter_link}
                        className="flex items-center justify-center"
                      >
                        <TwitterCircleFilled className="text-[var(--new-blue)]" />
                      </a>
                    )}
                  {exhibitorData?.website_link !== "" &&
                    exhibitorData?.website_link !== null && (
                      <a
                        href={exhibitorData?.website_link}
                        className="flex items-center justify-center"
                      >
                        <GlobalOutlined className="text-[var(--new-blue)]" />
                      </a>
                    )}
                  {exhibitorData?.instagram_link !== "" &&
                    exhibitorData?.instagram_link !== null && (
                      <a
                        href={exhibitorData?.instagram_link}
                        className="flex items-center justify-center"
                      >
                        <InstagramFilled className="text-[var(--new-blue)]" />
                      </a>
                    )}
                  {exhibitorData?.youtube_link !== "" &&
                    exhibitorData?.youtube_link !== null && (
                      <a
                        href={exhibitorData?.youtube_link}
                        className="flex items-center justify-center"
                      >
                        <YoutubeFilled className="text-[var(--new-blue)]" />
                      </a>
                    )}
                </div>
              )}
              <div className="flex h-[1.8rem] flex-row items-start gap-x-2">
                {coverPhoto !== null &&
                coverPhoto !==
                  "https://souqcarlive.s3.amazonaws.com/customers/" ? (
                  <div
                    onClick={handleDeleteCoverPhoto}
                    className="w-[2rem] h-[2rem] bg-white rounded-full flex items-center justify-center hover:cursor-pointer"
                  >
                    <DeleteOutlined className="text-[red]" />
                  </div>
                ) : (
                  <>
                    <input
                      id="file"
                      type="file"
                      onChange={(event: any) => {
                        handleCoverPhotoUpload(event);
                      }}
                      ref={coverPhotoInputRef}
                      accept="image/*"
                    />
                    <label
                      // htmlFor="file"
                      onClick={() => {
                        coverPhotoInputRef.current?.click();
                      }}
                      className={`flex items-center justify-center cursor-pointer w-[2rem] h-[2rem] bg-white rounded-full ${styles.shadow}`}
                    >
                      <div className="flex flex-col items-center justify-center gap-y-1">
                        <div
                          className={`${styles.plusCircle} flex items-center justify-center`}
                        >
                          <PlusOutlined />
                        </div>
                      </div>
                    </label>
                  </>
                )}
                <div className="w-[2rem] h-[2rem] bg-white rounded-full flex items-center justify-center hover:cursor-pointer">
                  <SyncOutlined className="" />
                </div>
              </div>
            </div>
            <div className="h-[1.8rem] flex flex-row items-center justify-between">
              <img
                alt="settings"
                src={CircularSettingsIcon}
                className="w-[3rem] h-[3rem] hover:cursor-pointer"
                onClick={() => {
                  // Show settings modal
                  setSettingsModalOpen(true);
                }}
              />
              <div className="flex flex-row items-center gap-x-3 mb-2">
                <ShareButton url={window.location.href}>
                  <img
                    alt="share"
                    src={ShareButtonIcon}
                    className="w-[2rem] h-[2rem]"
                  />
                </ShareButton>
                {!isMobile && (
                  <CustomButton
                    className={`flex flex-row items-center justify-center gap-x-3 bg-white h-[2.1875rem] w-[9.625rem] rounded-[0.625rem]`}
                    onClick={() => {
                      navigate(
                        `/${currentLanguage}/vendor/${exhibitorData?.customer?.id}`
                      );
                    }}
                  >
                    <EyeOutlined className="text-[var(--new-blue)]" />
                    <p>{t("see_profile_as_visitor")}</p>
                  </CustomButton>
                )}
              </div>
            </div>
          </div>

          {/* PROFILE PIC AND TITLE */}
          {!isMobile ? (
            // DESKTOP PROFILE PIC
            <div className="pt-[0.8rem] pb-[0.6rem] ps-3 w-full flex flex-row items-center gap-x-3">
              <div
                style={{
                  backgroundImage:
                    mainPhoto instanceof File
                      ? `url('${encodeURI(URL.createObjectURL(mainPhoto))}')`
                      : mainPhoto?.split("customers/")[1] === "" ||
                        mainPhoto === null
                      ? `url('${BlackUserIcon}')`
                      : `url('${mainPhoto}')`,
                  backgroundSize:
                    (!(mainPhoto instanceof File) &&
                      mainPhoto?.split("customers/")[1] === "") ||
                    mainPhoto === null
                      ? "auto 50%"
                      : "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                className="relative w-[5rem] h-[5rem] rounded-full bg-[var(--new-blue)]"
              >
                <div
                  className={`absolute bottom-0 left-0 w-[1.5rem] h-[1.5rem] bg-white rounded-full flex items-center justify-center hover:cursor-pointer ${styles.shadow}`}
                  onClick={handleDeleteMainPhoto}
                >
                  <DeleteOutlined className="text-[red]" />
                </div>
                <>
                  <input
                    id="file"
                    type="file"
                    onChange={(event: any) => {
                      handleMainPhotoUpload(event);
                    }}
                    ref={mainPhotoInputRef}
                    accept="image/*"
                  />
                  <label
                    // htmlFor="file"
                    onClick={() => {
                      mainPhotoInputRef.current?.click();
                    }}
                    // className={`flex items-center justify-center cursor-pointer w-[2rem] h-[2rem] bg-white rounded-full ${styles.shadow}`}
                  >
                    <div
                      className={`absolute bottom-0 right-0 w-[1.5rem] h-[1.5rem] bg-white rounded-full flex items-center justify-center hover:cursor-pointer ${styles.shadow}`}
                    >
                      <SyncOutlined className="" />
                    </div>
                  </label>
                </>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row items-center gap-x-2">
                  <p className={`text-[1.25rem] font-[700]`}>
                    {exhibitorData?.customer?.name}
                  </p>
                  {exhibitorData?.main_service?.label &&
                    exhibitorData?.main_service?.label != "" && (
                      <div
                        className={`px-2 h-[1.5rem] rounded-[1.125rem] border-[0.5px] border-[var(--new-blue)] flex items-center justify-center`}
                      >
                        <p className={`text-[0.75rem]`}>
                          {exhibitorData?.main_service?.label}
                        </p>
                      </div>
                    )}
                </div>
                <p className={`text-[0.8125rem]`}>
                  {exhibitorData?.description}
                </p>
              </div>
            </div>
          ) : (
            // MOBILE PROFILE PIC
            <div className="pt-[0.8rem] pb-[0.6rem] ps-3 w-full flex flex-col items-center gap-y-2">
              {/* PIC */}
              <div
                style={{
                  outline: "0.3rem solid var(--new-souq-background)",
                  width: "7rem",
                  height: "7rem",
                  marginTop: "-3.6rem",
                  backgroundImage:
                    mainPhoto instanceof File
                      ? `url('${encodeURI(URL.createObjectURL(mainPhoto))}')`
                      : `url('${mainPhoto}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                className="relative w-[5rem] h-[5rem] rounded-full"
              >
                <div
                  className={`absolute bottom-0 left-0 w-[1.5rem] h-[1.5rem] bg-white rounded-full flex items-center justify-center hover:cursor-pointer ${styles.shadow}`}
                  onClick={handleDeleteMainPhoto}
                >
                  <DeleteOutlined className="text-[red]" />
                </div>
                <>
                  <input
                    id="file"
                    type="file"
                    onChange={(event: any) => {
                      handleMainPhotoUpload(event);
                    }}
                    ref={mainPhotoInputRef}
                    accept="image/*"
                  />
                  <label
                    // htmlFor="file"
                    onClick={() => {
                      mainPhotoInputRef.current?.click();
                    }}
                    // className={`flex items-center justify-center cursor-pointer w-[2rem] h-[2rem] bg-white rounded-full ${styles.shadow}`}
                  >
                    <div
                      className={`absolute bottom-0 right-0 w-[1.5rem] h-[1.5rem] bg-white rounded-full flex items-center justify-center hover:cursor-pointer ${styles.shadow}`}
                    >
                      <SyncOutlined className="" />
                    </div>
                  </label>
                </>
              </div>
              {/* TITLE, DESC, BADGE */}
              <p className={`text-[1.25rem] font-[700]`}>
                {exhibitorData?.customer?.name}
              </p>
              <p className={`text-[0.8125rem]`}>{exhibitorData?.description}</p>
              {exhibitorData?.main_service?.label &&
                exhibitorData?.main_service?.label != "" && (
                  <div
                    className={`px-2 h-[1.5rem] rounded-[1.125rem] border-[0.5px] border-[var(--new-blue)] flex items-center justify-center`}
                  >
                    <p className={`text-[0.75rem]`}>
                      {exhibitorData?.main_service?.label}
                    </p>
                  </div>
                )}
            </div>
          )}
        </div>
        {/* End Top Section */}

        {/* Stats Section */}
        <div className="w-full flex flex-wrap items-center justify-between gap-2">
          {/* Profile Add Ons Section */}
          <div
            className={`w-[${
              isMax1440 ? "100%" : "48%"
            }] flex flex-wrap justify-between gap-2`}
          >
            {/* Stars */}
            <div
              className={`w-[17.9375rem]test h-[5.3125rem] bg-white ${styles.shadow} flex flex-row items-center gap-x-3 px-3 rounded-[0.9375rem]`}
              style={infoCardStyle}
            >
              <div
                className={`relative ${styles.blueOpacity} w-[4rem] h-[4rem] rounded-full flex items-center justify-center`}
              >
                <p className="text-[1.25rem]">
                  {userData?.user?.available_stars}
                </p>
                <img
                  alt="star"
                  src={Star}
                  className="absolute right-0 bottom-0 w-[1.4rem] h-[1.4rem]"
                />
              </div>
              <div className="flex flex-col gap-y-3">
                <p>{t("num_of_stars")}</p>
                <p
                  className="text-[var(--new-blue)] hover:cursor-pointer"
                  onClick={() => {
                    navigate(`/${currentLanguage}/store`);
                  }}
                >
                  {t("more_stars")}
                </p>
              </div>
            </div>
            {/* End Stars */}

            {/* Ads */}
            <div
              className={`w-[17.9375rem]test h-[5.3125rem] bg-white ${styles.shadow} flex flex-row items-center gap-x-3 px-3 rounded-[0.9375rem]`}
              style={infoCardStyle}
            >
              <div
                className={`relative ${styles.blueOpacity} w-[4rem] h-[4rem] rounded-full flex items-center justify-center`}
              >
                <p className="text-[1.25rem]">
                  {userData?.user?.available_posts}
                </p>
              </div>
              <div className="flex flex-col gap-y-3">
                <p>{t("num_ads")}</p>
                <p
                  className="text-[var(--new-blue)] hover:cursor-pointer"
                  onClick={() => {
                    // navigate to store
                    navigate(`/${currentLanguage}/store`);
                  }}
                >
                  {t("more_ads")}
                </p>
              </div>
            </div>
            {/* <div className="flex flex-row items-center gap-x-2">
            </div> */}
            {/* End Ads */}

            {/* Stories */}
            <div
              className={`w-[17.9375rem]test h-[5.3125rem] bg-white ${styles.shadow} flex flex-row items-center gap-x-3 px-3 rounded-[0.9375rem]`}
              style={infoCardStyle}
            >
              <div
                className={`relative ${styles.blueOpacity} w-[4rem] h-[4rem] rounded-full flex items-center justify-center`}
              >
                <p className="text-[1.25rem]">
                  {userData?.user?.available_stories}
                </p>
              </div>
              <div className="flex flex-col gap-y-3">
                <p>{t("available_stories")}</p>
                <p
                  className="text-[var(--new-blue)] hover:cursor-pointer"
                  onClick={() => {
                    // navigate to store
                    navigate(`/${currentLanguage}/store`);
                  }}
                >
                  {t("more_stories")}
                </p>
              </div>
            </div>
            {/*End Stories */}

            {/* Notifications */}
            <div
              className={`w-[17.9375rem]test h-[5.3125rem] bg-white ${styles.shadow} flex flex-row items-center gap-x-3 px-3 rounded-[0.9375rem]`}
              style={infoCardStyle}
            >
              <div
                className={`relative ${styles.blueOpacity} w-[4rem] h-[4rem] rounded-full flex items-center justify-center`}
              >
                <p className="text-[1.25rem]">0</p>
              </div>
              <div className="flex flex-col gap-y-3">
                <p>{t("notifications_available")}</p>
                <p
                  className="text-[var(--new-blue)] hover:cursor-pointer"
                  onClick={() => {
                    // navigate to store
                    navigate(`/${currentLanguage}/store`);
                  }}
                >
                  {t("more_notifications")}
                </p>
              </div>
            </div>
            {/* End Notifications */}
          </div>
          {/* End Profile Add Ons Section */}

          {isMobile && (
            <CustomButton
              className={`${styles.shadow} flex flex-row items-center justify-center gap-x-3 bg-white w-full rounded-[0.625rem] px-4 py-3`}
              onClick={() => {
                navigate(
                  `/${currentLanguage}/vendor/${exhibitorData?.customer?.id}`
                );
              }}
            >
              <EyeOutlined className="text-[var(--new-blue)]" />
              <p>{t("see_profile_as_visitor")}</p>
            </CustomButton>
          )}

          {/* Profile Stats */}
          <div
            className={`h-[11.25rem] bg-white rounded-[0.9375rem] py-3 px-3 flex flex-grow flex-col ${styles.shadow}`}
          >
            <div className="w-full flex flex-row items-center justify-between">
              <p>{t("exhibition_stats")}</p>
              <p
                className="text-[var(--new-blue)] hover:cursor-pointer"
                onClick={() => {
                  // open more stats modal
                  setMoreStatsModalOpen(true);
                }}
              >
                {t("more")}
              </p>
            </div>
            <div className="dashboard-stats mt-2 w-full flex flex-col items-center justify-between">
              <Spin
                className="w-full flex flex-col"
                style={{ width: "100%" }}
                spinning={statisticsLoading}
              >
                {/* Top Row */}
                <div className="w-full flex flex-row items-center justify-between gap-x-2">
                  <div
                    className={`${styles.blueOpacity} h-[2.75rem] rounded-[0.625rem] flex flex-row items-center justify-center gap-x-3`}
                    style={{ width: `calc(100% / 3 - 0.5rem + ${0.5 / 3}rem)` }}
                  >
                    <EyeOutlined className="text-black" />
                    <p className="text-[var(--new-blue)]">
                      {statistics?.total_views}
                    </p>
                  </div>
                  <div
                    className={`${styles.blueOpacity} h-[2.75rem] rounded-[0.625rem] flex flex-row items-center justify-center gap-x-3`}
                    style={{ width: `calc(100% / 3 - 0.5rem + ${0.5 / 3}rem)` }}
                  >
                    <PhoneFilled className="text-black rotate-[100deg]" />
                    <p className="text-[var(--new-blue)]">
                      {statistics?.total_calls}
                    </p>
                  </div>
                  <div
                    className={`${styles.blueOpacity} h-[2.75rem] rounded-[0.625rem] flex flex-row items-center justify-center gap-x-3`}
                    style={{ width: `calc(100% / 3 - 0.5rem + ${0.5 / 3}rem)` }}
                  >
                    <WhatsAppOutlined className="text-black" />
                    <p className="text-[var(--new-blue)]">
                      {statistics?.total_whatsapp_calls}
                    </p>
                  </div>
                </div>
                {/* End Top Row */}

                {/* Bottom Row */}
                <div className="mt-6 w-full flex flex-row items-center justify-between gap-x-2">
                  <div
                    className={`${styles.blueOpacity} h-[3.25rem] rounded-[0.625rem] flex flex-row items-center justify-center gap-x-3`}
                    style={{ width: `calc(100% / 2 - 0.5rem + ${0.5 / 2}rem)` }}
                  >
                    <p
                      style={{
                        maxWidth: "60%",
                      }}
                    >
                      {t("total_posted_ads")}
                    </p>
                    <div className={`w-[0.5px] bg-black h-[1rem]`}></div>
                    <p className="text-[var(--new-blue)]">
                      {statistics?.total_posts_count}
                    </p>
                  </div>
                  <div
                    className={`${styles.blueOpacity} h-[3.25rem] rounded-[0.625rem] flex flex-row items-center justify-center gap-x-3`}
                    style={{ width: `calc(100% / 2 - 0.5rem + ${0.5 / 2}rem)` }}
                  >
                    <p>{t("total_posted_stories")}</p>
                    <div className={`w-[0.5px] bg-black h-[1rem]`}></div>
                    <p className="text-[var(--new-blue)]">
                      {statistics?.total_story_count}
                    </p>
                  </div>
                </div>
                {/* End Bottom Row */}
              </Spin>
            </div>
          </div>
          {/* End Profile Stats */}
        </div>
        {/* End Stats Section */}

        {/* Post Status List Section */}
        <div
          className={`post-status-spin-container w-full h-[3.25rem] flex flex-row items-center bg-white ${styles.shadow} rounded-[0.625rem]`}
        >
          <Spin className="w-full" spinning={postStatusLoading}>
            <div className="w-full flex flex-row items-center">
              {postStatus?.map((item: any) => {
                return (
                  <div
                    className={`h-[3.25rem] rounded-[0.625rem] w-[20%] ${
                      selectedPostStatus === item.key
                        ? `${styles.blueOpacityHalf}`
                        : ""
                    } flex items-center justify-center hover:cursor-pointer`}
                    onClick={() => {
                      setSelectedPostStatus(item.key);
                    }}
                  >
                    <p
                      className="text-center"
                      style={{ fontSize: isMobile ? "0.68rem" : undefined }}
                    >
                      {item.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </Spin>
        </div>
        {/* End Post Status List Section */}

        {/* Post Category List Section */}
        <div
          className={`post-status-spin-container w-full h-[2.4375rem] flex flex-row items-center bg-white ${styles.shadow} rounded-[1.25rem]`}
        >
          <Spin className="w-full" spinning={categoriesLoading}>
            <div className="w-full flex flex-row items-center">
              {categories
                ?.filter((item: any) => item.id < 5)
                ?.map((item: any) => {
                  return (
                    <div
                      className={`h-[2.4375rem] rounded-[1.25rem] w-[20%] ${
                        selectedPostCategory === item.id
                          ? `${styles.blueOpacityHalf}`
                          : ""
                      } flex items-center justify-center hover:cursor-pointer`}
                      onClick={() => {
                        setSelectedPostCategory(item.id);
                      }}
                    >
                      <p
                        className="text-center"
                        style={{ fontSize: isMobile ? "0.68rem" : undefined }}
                      >
                        {item.name}
                      </p>
                    </div>
                  );
                })}
              <div
                className={`h-[2.4375rem] rounded-[1.25rem] w-[20%] ${
                  selectedPostCategory === -1 ? `${styles.blueOpacityHalf}` : ""
                } flex items-center justify-center hover:cursor-pointer`}
                onClick={() => {
                  setSelectedPostCategory(-1);
                  dispatch(listMyStoriesAction());
                }}
              >
                <p
                  className="text-center"
                  style={{ fontSize: isMobile ? "0.68rem" : undefined }}
                >
                  {t("story")}
                </p>
              </div>
            </div>
          </Spin>
        </div>
        {/* End Post Category List Section */}

        {/* Posts */}
        <div className="profile-posts-list-spin-container w-full flex mb-3">
          <Spin
            spinning={profilePostsLoading || myStoriesLoading}
            className="w-full"
          >
            <div
              className={`w-full flex flex-row items-center justify-start flex-wrap gap-x-2 gap-y-2`}
            >
              {selectedPostCategory === -1 && myStories?.length === 0 ? (
                <div className="w-full flex items-center justify-center">
                  <Empty description={t("no_posts")} />
                </div>
              ) : (
                selectedPostCategory === -1 &&
                myStories?.map((item: any, idx: any) => {
                  return (
                    <ArchiveStoryCard
                      id={item.id}
                      key={idx}
                      description={item.description}
                      createdAt={item.created_at?.split("T")[0]}
                      calls={item.calls ?? 4}
                      views={item.views ?? 4}
                      favourites={item.favourites ?? 4}
                      shares={item.shares ?? 4}
                      width={"w-[49%]"}
                    />
                  );
                })
              )}
              {profilePosts?.length === 0 && selectedPostCategory !== -1 ? (
                <div className="w-full flex items-center justify-center">
                  <Empty description={t("no_posts")} />
                </div>
              ) : (
                selectedPostCategory !== -1 &&
                profilePosts?.map((item: any, idx: any) => {
                  return !isMobile ? (
                    <NewWidePostCard
                      key={idx}
                      isMobile={isMobile}
                      id={item.uuid}
                      isSpecialAd={item.isSpecial || false}
                      showActions={true}
                      isDeleted={selectedPostStatus === "Inactive"}
                      width={"w-[24.2675rem]"}
                      imageWidth={"w-[23rem]"}
                      category={item.category}
                      customerName={item.customer}
                      brandLogo={item.brand_logo}
                      isFavorited={item.favourited}
                      // className={`${styles.widePostCard}`}
                      callsCount={item.contact_count}
                      viewsCount={item.views_count}
                      uploadDate={item.created_at?.split("T")[0]}
                      postTitle={item.title}
                      city={item.city}
                      price={
                        isDefined(item.down_payment)
                          ? item.down_payment
                          : item.price
                      }
                      carCondition={item.car_condition}
                      productionDate={item.production_date}
                      postDescription={item.description}
                      kilometrage={item.kilo_meterage}
                      carMaker={item.brand}
                      image={item.main_image}
                      paymentOption={item.payment_type}
                      created_at={item.created_at.split("T")[0]}
                      address={item.address}
                      lat={item.lat}
                      lng={item.lng}
                    />
                  ) : (
                    <div className="w-full mb-1">
                      <NewCustomPostCardWithEdit
                        idx={idx}
                        item={item}
                        handleSuspendPostClick={() =>
                          handleSuspendPostClick(item?.id)
                        }
                        handleReactivatePostClick={() =>
                          handleReactivatePostClick(item?.id)
                        }
                        handlePostEditClick={() =>
                          handlePostEditClick(item.uuid, item.lat, item.long)
                        }
                        isDeleted={selectedPostStatus === "Inactive"}
                        showActions={selectedPostStatus !== "Rejected"}
                        showPayButton={
                          item.status === "Outstanding" ||
                          item.status === "منتظر الدفع"
                        }
                      />
                    </div>
                  );
                })
              )}
            </div>
          </Spin>
        </div>
        {/* End Posts */}

        {/* Modals Section */}

        {/* More Stats Modal */}
        <Modal
          open={moreStatsModalOpen}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={() => setMoreStatsModalOpen(false)}
          style={{ direction: textDirectionStyle }}
        >
          <div className="w-full flex flex-col items-center gap-y-4">
            <p>{t("exhibition_statistics")}</p>
            <div
              className={`w-full h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
            >
              <EyeOutlined className="text-black font-bold text-[1.2rem]" />
              <p>{t("views")}</p>
              <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
              <p className="text-[var(--new-blue)]">
                {statistics?.total_views}
              </p>
            </div>
            <div
              className={`w-full h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
            >
              <PhoneFilled className="text-black font-bold text-[1.2rem] rotate-[100deg]" />
              <p>{t("calls")}</p>
              <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
              <p className="text-[var(--new-blue)]">
                {statistics?.total_calls}
              </p>
            </div>
            <div
              className={`w-full h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
            >
              <WhatsAppOutlined className="text-black font-bold text-[1.2rem]" />
              <p>{t("whatsapp")}</p>
              <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
              <p className="text-[var(--new-blue)]">
                {statistics?.total_whatsapp_calls}
              </p>
            </div>
            <div
              className={`w-full h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
            >
              <ClockCircleFilled className="text-black font-bold text-[1.2rem]" />
              <p>{t("user_member_since")}</p>
              <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
              <p className="text-[var(--new-blue)]">
                {statistics?.created_at?.split("T")[0]}
              </p>
            </div>

            <div className="w-full h-[0.5px] bg-black"></div>

            <div
              className={`w-full h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
            >
              <p>{t("total_posted_ads")}</p>
              <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
              <p className="text-[var(--new-blue)]">
                {statistics?.total_posts_count}
              </p>
            </div>

            <div className="w-full flex flex-row items-center justify-center gap-x-2">
              <div
                className={`w-[33%] h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
              >
                <p>{t("used")}</p>
                <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
                <p className="text-[var(--new-blue)]">
                  {statistics?.total_used_cars}
                </p>
              </div>
              <div
                className={`w-[33%] h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
              >
                <p>{t("almost_new")}</p>
                <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
                <p className="text-[var(--new-blue)]">
                  {statistics?.total_almost_new_cars}
                </p>
              </div>
              <div
                className={`w-[33%] h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
              >
                <p>{t("new")}</p>
                <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
                <p className="text-[var(--new-blue)]">
                  {statistics?.total_new_cars}
                </p>
              </div>
            </div>

            <div
              className={`w-full h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
            >
              <p>{t("total_posted_stories")}</p>
              <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
              <p className="text-[var(--new-blue)]">5</p>
            </div>
            <div
              className={`w-full h-[2.91131rem] ${styles.blueOpacity} rounded-[0.3125rem] flex flex-row items-center justify-start gap-x-3 px-3`}
            >
              <p>{t("total_notifications")}</p>
              <div className="w-[0.5px] h-[1.5rem] bg-black"></div>
              <p className="text-[var(--new-blue)]">0</p>
            </div>
          </div>
        </Modal>
        {/* End More Stats Modal */}

        {/* Settings Modal */}
        <Modal
          open={settingsModalOpen}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={() => {
            setSelectedSettingsSection(0);
            setSettingsModalOpen(false);
          }}
          wrapClassName="exhibitor-modal-wrapper"
          style={{
            direction: textDirectionStyle,
            width: "40rem",
          }}
          bodyStyle={{
            maxHeight: "44rem",
            overflowY: "auto",
            paddingInline: isMobile ? "0" : "1.2rem",
            paddingBlock: isMobile ? "0" : "1.2rem",
          }}
          centered
        >
          <div className="w-full flex flex-col items-center gap-y-3">
            {(selectedSettingsSection === 0 ||
              selectedSettingsSection === 1) && (
              <p className="text-[1.25rem] text-[var(--new-blue)] font-[700]">
                {t("drawer_settings")}
              </p>
            )}
            {selectedSettingsSection === 2 && (
              <p className="text-[1.25rem] text-[var(--new-blue)] font-[700]">
                {t("social_media_links")}
              </p>
            )}
            {(selectedSettingsSection === 0 ||
              selectedSettingsSection === 1) && (
              <div
                className={`w-full h-[3.625rem] rounded-[0.9375rem] flex flex-row items-center justify-between ${styles.shadow}`}
              >
                <div
                  className={`w-[50%] h-[3.625rem] rounded-[0.9375rem] flex items-center justify-center ${
                    selectedSettingsSection === 0 ? styles.blueOpacityHalf : ""
                  } hover:cursor-pointer`}
                  onClick={() => {
                    setSelectedSettingsSection(0);
                  }}
                >
                  <p>{t("general_settings")}</p>
                </div>
                <div
                  className={`w-[50%] h-[3.625rem] rounded-[0.9375rem] flex items-center justify-center ${
                    selectedSettingsSection === 1 ? styles.blueOpacityHalf : ""
                  } hover:cursor-pointer`}
                  onClick={() => {
                    setSelectedSettingsSection(1);
                  }}
                >
                  <p className="whitespace-nowrap">{t("the_branches")}</p>
                </div>
              </div>
            )}
            {selectedSettingsSection === 0 && (
              <form
                onSubmit={generalDataFormik.handleSubmit}
                className="w-full flex flex-col items-start gap-y-2"
              >
                <p>{t("exhibitor_name")}*</p>
                <Input
                  className="w-full h-[3.88775rem]"
                  value={exhibitorData?.customer?.name}
                  contentEditable={false}
                />
                <p>{t("whatsapp")}*</p>
                <Input
                  className="w-full h-[3.88775rem]"
                  name="whatsapp"
                  value={generalDataFormik.values.whatsapp}
                  onChange={generalDataFormik.handleChange}
                  placeholder={t("whatsapp")}
                />
                <p>{t("badge")}*</p>
                <div className="w-full flex flex-row items-center gap-x-2">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("badge")}
                    value={generalDataFormik.values.main_service}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={exhibitorServicesDropdown}
                    onSelect={(value: any) => {
                      generalDataFormik.setFieldValue("main_service", value);
                    }}
                    size="large"
                    className="w-full"
                    style={{ direction: "rtl" }}
                    dropdownStyle={{ direction: "rtl" }}
                  />
                  <CustomButton
                    className={`h-[3.1rem] w-[30%] rounded-[0.625rem] px-2 flex items-center justify-center`}
                    color={"rgba(0, 80, 255, 0.5)"}
                    onClick={() => {
                      setSelectedSettingsSection(2);
                    }}
                  >
                    <p className={`text-[${isMobile ? "0.6rem" : "0.75rem"}]`}>
                      {t("social_media_links")}
                    </p>
                  </CustomButton>
                </div>
                <p>{t("description")}*</p>
                <TextArea
                  placeholder={t("description")}
                  rows={6}
                  name="description"
                  style={{ resize: "none" }}
                  value={generalDataFormik.values.description}
                  onChange={generalDataFormik.handleChange}
                />
                <div className="mt-2 full-width-spin w-full flex items-center justify-center">
                  <Spin
                    spinning={updateGeneralDataLoading}
                    className="flex items-center justify-center"
                  >
                    <CustomButton
                      className={`w-[60%] h-[3.75rem] rounded-[0.9375rem]`}
                      color={"var(--new-blue)"}
                      type={"submit"}
                    >
                      <p className="text-white">{t("confirm")}</p>
                    </CustomButton>
                  </Spin>
                </div>
              </form>
            )}
            {selectedSettingsSection === 1 && (
              <div className="w-full flex flex-col items-center gap-y-3">
                <CustomButton
                  className={`w-[80%] h-[2.5rem] rounded-[0.625rem] flex items-center justify-center`}
                  color={"var(--new-blue)"}
                  onClick={() => {
                    // set create branch true
                    setSelectedSettingsSection(3);
                  }}
                >
                  <p className="text-white">{t("add_branch")}</p>
                </CustomButton>
                {branchesData?.map((item: any) => {
                  return (
                    <DashboardBranchCard
                      id={item?.id}
                      city={item?.city.name}
                      address={item?.address}
                      workingHoursFrom={item?.working_hours_from}
                      workingHoursTo={item?.working_hours_to}
                      offDay={item?.working_hours_off.name}
                      branchType={item?.type.name}
                      phoneOne={item?.primary_phone}
                      phoneTwo={item?.secondary_phone}
                      isEditable={true}
                      onClick={(data: any) => {
                        setSelectedSettingsSection(4);
                        setUpdatingBranchId(data.id);
                        updateBranchFormik.setFieldValue(
                          "address",
                          data.address
                        );
                        updateBranchFormik.setFieldValue(
                          "phone_one",
                          data.phone_one
                        );
                        updateBranchFormik.setFieldValue(
                          "phone_two",
                          data.phone_two
                        );
                        updateBranchFormik.setFieldValue(
                          "work_from",
                          data.work_from
                        );
                        updateBranchFormik.setFieldValue(
                          "work_to",
                          data.work_to
                        );
                        updateBranchFormik.setFieldValue(
                          "day_off",
                          data.day_off
                        );
                        updateBranchFormik.setFieldValue(
                          "branch_type",
                          data.branch_type
                        );
                        updateBranchFormik.setFieldValue("city_id", data.city);
                      }}
                    />
                  );
                })}
              </div>
            )}
            {selectedSettingsSection === 3 && (
              <div className="relative w-full flex flex-col items-center">
                <div
                  onClick={() => {
                    setSelectedSettingsSection(1);
                  }}
                  className={`absolute rounded-full left-0 top-0 ${styles.shadow} bg-white w-[2rem] h-[2rem] hover:cursor-pointer flex items-center justify-center`}
                >
                  <img alt="left" src={ArrowLeftIcon} className="" />
                </div>
                <p className="text-[var(--new-blue)] text-[1.125rem] font-[700]">
                  {t("branch_details")}
                </p>
                <form
                  onSubmit={createBranchFormik.handleSubmit}
                  className="w-full flex flex-col items-start gap-y-3"
                >
                  <p>{t("homepage_governate")}*</p>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_governate")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={citiesDropdown}
                    onSelect={(value: any) => {
                      createBranchFormik.setFieldValue("city_id", value);
                    }}
                    // status={formik.errors.city_id ? "error" : ""}
                    size="large"
                    className="w-full"
                    style={{ direction: "rtl" }}
                    dropdownStyle={{ direction: "rtl" }}
                  />
                  {createBranchFormik.errors.city_id && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("the_address")}*</p>
                  <Input
                    className="w-full h-[3.88775rem]"
                    value={createBranchFormik.values.address}
                    name="address"
                    onChange={createBranchFormik.handleChange}
                    placeholder={t("the_address")}
                  />
                  {createBranchFormik.errors.address && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("branch_type")}*</p>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("branch_type")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={branchTypesDropdown}
                    onSelect={(value: any) => {
                      createBranchFormik.setFieldValue("branch_type", value);
                    }}
                    // status={formik.errors.city_id ? "error" : ""}
                    size="large"
                    className="w-full"
                    style={{ direction: "rtl" }}
                    dropdownStyle={{ direction: "rtl" }}
                  />
                  {createBranchFormik.errors.branch_type && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("timings")}</p>
                  <div className="w-full flex flex-row items-center justify-center gap-x-2">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("from")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={generateTimesDropdown("12-hour", 30)}
                      onSelect={(value: any) => {
                        createBranchFormik.setFieldValue("work_from", value);
                      }}
                      // status={formik.errors.city_id ? "error" : ""}
                      size="large"
                      className="w-[32%]"
                      style={{ direction: "rtl" }}
                      dropdownStyle={{ direction: "rtl" }}
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("till")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={generateTimesDropdown("12-hour", 30)}
                      onSelect={(value: any) => {
                        createBranchFormik.setFieldValue("work_to", value);
                      }}
                      // status={formik.errors.city_id ? "error" : ""}
                      size="large"
                      className="w-[32%]"
                      style={{ direction: "rtl" }}
                      dropdownStyle={{ direction: "rtl" }}
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("days_off")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={weekDaysDropdown}
                      onSelect={(value: any) => {
                        createBranchFormik.setFieldValue("day_off", value);
                      }}
                      // status={formik.errors.city_id ? "error" : ""}
                      size="large"
                      className="w-[32%]"
                      style={{ direction: "rtl" }}
                      dropdownStyle={{ direction: "rtl" }}
                    />
                  </div>
                  {(createBranchFormik.errors.work_from ||
                    createBranchFormik.errors.work_to ||
                    createBranchFormik.errors.day_off) && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("with_phone_number")}</p>
                  <Input
                    className="w-full h-[3.88775rem]"
                    value={createBranchFormik.values.phone_one}
                    name="phone_one"
                    onChange={createBranchFormik.handleChange}
                    placeholder={t("with_phone_number")}
                  />
                  {createBranchFormik.errors.phone_one && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("with_phone_number")}</p>
                  <Input
                    className="w-full h-[3.88775rem]"
                    value={createBranchFormik.values.phone_two}
                    name="phone_two"
                    onChange={createBranchFormik.handleChange}
                    placeholder={t("with_phone_number")}
                  />
                  <div className="full-width-spin w-full flex items-center justify-center">
                    <Spin
                      spinning={createBranchLoading}
                      className="flex items-center justify-center"
                    >
                      <CustomButton
                        className={`w-[60%] h-[3.75rem] rounded-[0.9375rem]`}
                        color={"var(--new-blue)"}
                        type={"submit"}
                      >
                        <p className="text-white">{t("confirm")}</p>
                      </CustomButton>
                    </Spin>
                  </div>
                </form>
              </div>
            )}
            {selectedSettingsSection === 4 && (
              <div className="relative w-full flex flex-col items-center">
                <div
                  onClick={() => {
                    setSelectedSettingsSection(1);
                  }}
                  className={`absolute rounded-full left-0 top-0 ${styles.shadow} bg-white w-[2rem] h-[2rem] hover:cursor-pointer flex items-center justify-center`}
                >
                  <img alt="left" src={ArrowLeftIcon} className="" />
                </div>
                <p className="text-[var(--new-blue)] text-[1.125rem] font-[700]">
                  {t("branch_details")}
                </p>
                <form
                  onSubmit={updateBranchFormik.handleSubmit}
                  className="w-full flex flex-col items-start gap-y-3"
                >
                  <p>{t("homepage_governate")}*</p>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_governate")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={citiesDropdown}
                    value={
                      updatingCityId !== ""
                        ? updatingCityId
                        : updateBranchFormik.values.city_id
                    }
                    onSelect={(value: any) => {
                      setUpdatingCityId(value);
                    }}
                    // status={formik.errors.city_id ? "error" : ""}
                    size="large"
                    className="w-full"
                    style={{ direction: "rtl" }}
                    dropdownStyle={{ direction: "rtl" }}
                  />
                  {updateBranchFormik.errors.city_id && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("the_address")}*</p>
                  <Input
                    className="w-full h-[3.88775rem]"
                    value={updateBranchFormik.values.address}
                    name="address"
                    onChange={updateBranchFormik.handleChange}
                    placeholder={t("the_address")}
                  />
                  {updateBranchFormik.errors.address && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("branch_type")}*</p>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("branch_type")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={branchTypesDropdown}
                    value={
                      updatingBranchType !== ""
                        ? updatingBranchType
                        : updateBranchFormik.values.branch_type
                    }
                    onSelect={(value: any) => {
                      setUpdatingBranchType(value);
                    }}
                    // status={formik.errors.city_id ? "error" : ""}
                    size="large"
                    className="w-full"
                    style={{ direction: "rtl" }}
                    dropdownStyle={{ direction: "rtl" }}
                  />
                  {updateBranchFormik.errors.branch_type && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("timings")}</p>
                  <div className="w-full flex flex-row items-center justify-center gap-x-2">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("from")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={generateTimesDropdown("12-hour", 30)}
                      onSelect={(value: any) => {
                        setUpdatingWorkFrom(value);
                      }}
                      value={
                        updatingWorkFrom !== ""
                          ? updatingWorkFrom
                          : updateBranchFormik.values.work_from
                      }
                      // status={formik.errors.city_id ? "error" : ""}
                      size="large"
                      className="w-[32%]"
                      style={{ direction: "rtl" }}
                      dropdownStyle={{ direction: "rtl" }}
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("till")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={generateTimesDropdown("12-hour", 30)}
                      onSelect={(value: any) => {
                        setUpdatingWorkTo(value);
                      }}
                      value={
                        updatingWorkTo !== ""
                          ? updatingWorkTo
                          : updateBranchFormik.values.work_to
                      }
                      // status={formik.errors.city_id ? "error" : ""}
                      size="large"
                      className="w-[32%]"
                      style={{ direction: "rtl" }}
                      dropdownStyle={{ direction: "rtl" }}
                    />
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("days_off")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={weekDaysDropdown}
                      onSelect={(value: any) => {
                        setUpdatingDayOff(value);
                      }}
                      value={
                        updatingDayOff !== ""
                          ? updatingDayOff
                          : updateBranchFormik.values.day_off
                      }
                      // status={formik.errors.city_id ? "error" : ""}
                      size="large"
                      className="w-[32%]"
                      style={{ direction: "rtl" }}
                      dropdownStyle={{ direction: "rtl" }}
                    />
                  </div>
                  {(updateBranchFormik.errors.work_from ||
                    updateBranchFormik.errors.work_to ||
                    updateBranchFormik.errors.day_off) && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("with_phone_number")}</p>
                  <Input
                    className="w-full h-[3.88775rem]"
                    value={updateBranchFormik.values.phone_one}
                    name="phone_one"
                    onChange={updateBranchFormik.handleChange}
                    placeholder={t("with_phone_number")}
                  />
                  {createBranchFormik.errors.phone_one && (
                    <p className="text-[red]">
                      *{t("please_provide_this_field")}
                    </p>
                  )}
                  <p>{t("with_phone_number")}</p>
                  <Input
                    className="w-full h-[3.88775rem]"
                    value={updateBranchFormik.values.phone_two}
                    name="phone_two"
                    onChange={updateBranchFormik.handleChange}
                    placeholder={t("with_phone_number")}
                  />
                  <div className="full-width-spin w-full flex items-center justify-center">
                    <Spin
                      spinning={updateBranchLoading}
                      className="flex items-center justify-center"
                    >
                      <CustomButton
                        className={`w-[60%] h-[3.75rem] rounded-[0.9375rem]`}
                        color={"var(--new-blue)"}
                        type={"submit"}
                      >
                        <p className="text-white">{t("confirm")}</p>
                      </CustomButton>
                    </Spin>
                  </div>
                </form>
              </div>
            )}
            {selectedSettingsSection === 2 && (
              <form
                onSubmit={socialLinksFormik.handleSubmit}
                className="w-full flex flex-col items-start gap-y-2"
              >
                <p>{t("facebook_link")}*</p>
                <Input
                  className="w-full h-[3.88775rem]"
                  value={socialLinksFormik.values.facebookLink}
                  name="facebookLink"
                  onChange={socialLinksFormik.handleChange}
                  placeholder={t("facebook_link")}
                />
                <p>{t("instagram_link")}*</p>
                <Input
                  className="w-full h-[3.88775rem]"
                  value={socialLinksFormik.values.instagramLink}
                  name="instagramLink"
                  onChange={socialLinksFormik.handleChange}
                  placeholder={t("instagram_link")}
                />
                <p>{t("youtube_link")}*</p>
                <Input
                  className="w-full h-[3.88775rem]"
                  value={socialLinksFormik.values.youtubeLink}
                  name="youtubeLink"
                  onChange={socialLinksFormik.handleChange}
                  placeholder={t("youtube_link")}
                />
                <p>{t("twitter_link")}*</p>
                <Input
                  className="w-full h-[3.88775rem]"
                  value={socialLinksFormik.values.twitterLink}
                  name="twitterLink"
                  onChange={socialLinksFormik.handleChange}
                  placeholder={t("twitter_link")}
                />
                <p>{t("website_link")}*</p>
                <Input
                  className="w-full h-[3.88775rem]"
                  value={socialLinksFormik.values.websiteLink}
                  name="websiteLink"
                  onChange={socialLinksFormik.handleChange}
                  placeholder={t("website_link")}
                />
                <div className="mt-10 full-width-spin w-full flex items-center justify-center">
                  <Spin
                    spinning={updateSocialLinksLoading}
                    className="flex items-center justify-center"
                  >
                    <CustomButton
                      className={`w-[60%] h-[3.75rem] rounded-[0.9375rem]`}
                      color={"var(--new-blue)"}
                      type={"submit"}
                    >
                      <p className="text-white">{t("confirm")}</p>
                    </CustomButton>
                  </Spin>
                </div>
              </form>
            )}
          </div>
        </Modal>
        {/* End Settings Modal */}

        {/* End Modals Section */}
      </div>
      <Footer />
    </>
  );
};

export default ExhibitorDashboardScreen;
