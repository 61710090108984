import
{
 AMANLEEK_CAR_MODELS_LOADING,
 AMANLEEK_CAR_MODELS_SUCCESS,
 AMANLEEK_CAR_MODELS_FAILURE
}
from "redux/types";

const initialState = {
    amanleekCarModelsLoading: false,
    amanleekCarModels: null,
    errors: null
}

export const amanleekListCarModelsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AMANLEEK_CAR_MODELS_LOADING :
            return {...state, amanleekCarModelsLoading: action.payload};
        case AMANLEEK_CAR_MODELS_SUCCESS :
            return {...state, amanleekCarModels: action.payload};
        case AMANLEEK_CAR_MODELS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}