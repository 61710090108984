import 
{
    FAVOURITE_POST_LOADING,
    FAVOURITE_POST_SUCCESS,
    FAVOURITE_POST_FAILURE

}
from "redux/types";

import { Dispatch } from "redux";

import { favouritePostService } from "services/favouritePostService";

export const favouritePostAction = (postUUID: any, favourite: boolean) => async (dispatch: Dispatch) => {
    try{
        dispatch({type: FAVOURITE_POST_LOADING, payload: true});
        const response = await favouritePostService(postUUID, favourite);
        dispatch({type: FAVOURITE_POST_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: FAVOURITE_POST_FAILURE, payload: err});
    } finally {
        dispatch({type: FAVOURITE_POST_LOADING, payload: false});
    }
}