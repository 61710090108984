import 
{
    MY_STORIES_LOADING,
    MY_STORIES_SUCCESS,
    MY_STORIES_FAILURE
}
from "redux/types";

const initialState = {
    myStoriesLoading: false,
    myStories: null,
    errors: null
}

export const listMyStoriesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MY_STORIES_LOADING :
            return {...state, myStoriesLoading: action.payload};
        case MY_STORIES_SUCCESS :
            return {...state, myStories: action.payload.data};
        case MY_STORIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}