import api from "api";
import { verifyOtpEndPoint } from "api/apiUrls";

export const verifyOtpService = async (
  mobile: string,
  otp: string,
  name?: string,
  email?: string,
  city?: string
) => {
  const response = await api.post(
    verifyOtpEndPoint(),
    {
      mobile: mobile,
      otp: otp,
      name: name,
      email: email,
      city: city,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response;
};
