import { FC, useState } from "react";
import { useSearch } from "hooks/useSearch";
import { LeftOutlined } from "@ant-design/icons";
import CustomButton from "components/CustomButton/CustomButton";
import { useNavigate } from "react-router";
import { classNames } from "react-easy-crop/helpers";
import { useLanguage } from "hooks/useLanguage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface NewPaginatorProps {
  currentPage: number;
  lastPage: number;
  url: string;
  className?: string;
}

const NewPaginator: FC<NewPaginatorProps> = ({
  currentPage,
  lastPage,
  url,
  className,
}) => {
  const [page, setPage] = useState<number>(currentPage);

  const { getSearchURL } = useSearch();
  const { textDirectionStyle, isArabic } = useLanguage();
  const { t } = useTranslation();
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const scrollToTop = () => {
    return new Promise<void>((resolve) => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      // Wait for a short time (adjust the time as needed)
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  const handleInputChange = (e: any) => {
    // Allow only numeric input
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = inputValue;
    setPage(parseInt(e.target.value));
  };

  const handleEnterKey = (e: any) => {
    if (e.key === "Enter") {
      // Handle the Enter key press event here
      scrollToTop();
      var params = getSearchURL();
      navigate(
        `${url}?${params}${params === "" ? "" : "&"}${
          isNaN(page) ? "" : `page=${page}`
        }`
      );

      if (isNaN(page)) {
        setPage(1);
      }
    }
  };

  const handleNextPageClick = () => {
    if (page === lastPage) {
      return;
    }
    scrollToTop();
    setPage((prevPage) => prevPage + 1);
    // var params = getSearchURL();
    // navigate(
    //   `${url}?${params}${params === "" ? "" : "&"}${
    //     isNaN(page) ? "" : `page=${page + 1}`
    //   }`
    // );

    if (isNaN(page)) {
      setPage(1);
    }
  };

  const handlePrevPageClick = () => {
    if (page - 1 === 0) {
      return;
    }
    scrollToTop();
    setPage((prevPage) => prevPage - 1);
    // var params = getSearchURL();
    // navigate(
    //   `${url}?${params}${params === "" ? "" : "&"}${
    //     isNaN(page) ? "" : `&page=${page - 1}`
    //   }`
    // );

    if (isNaN(page)) {
      setPage(1);
    }
  };

  const navigate = useNavigate();

  return (
    <div
      dir={textDirectionStyle}
      className={`flex flex-row items-center justify-center gap-x-3 ${className}`}
    >
      <a
        href={`${url}?${getSearchURL()}${getSearchURL() === "" ? "" : "&"}${
          isNaN(page) ? "" : `page=${page}`
        }`}
      >
        <CustomButton
          className={`rounded-[0.625rem] w-[2.75rem] h-[2.75rem] p-2 flex items-center justify-center`}
          color={"var(--new-blue)"}
          buttonStyle={{
            opacity: `${page - 1 === 0 ? 0.1 : ""}`,
          }}
          disabled={page - 1 === 0}
          onClick={handlePrevPageClick}
        >
          <LeftOutlined
            style={{ transform: !isArabic ? "" : "rotate(180deg)" }}
            className="text-white text-[1.2rem] font-bold"
          />
        </CustomButton>
      </a>
      <input
        dir="ltr"
        onChange={handleInputChange}
        onKeyDown={handleEnterKey}
        value={isNaN(page) ? undefined : page}
        pattern="[1-9]*"
        className={`w-[2.75rem] h-[2.75rem] rounded-[0.625rem] border-[0.5px] border-[var(--new-blue)] text-center`}
      />
      <a
        href={`${url}?${getSearchURL()}${getSearchURL() === "" ? "" : "&"}${
          isNaN(page) ? "" : `page=${page}`
        }`}
      >
        <CustomButton
          className={`text-white rounded-[0.625rem] h-[2.75rem] p-2 px-3 flex items-center justify-center gap-x-1`}
          color={"var(--new-blue)"}
          onClick={handleNextPageClick}
          buttonStyle={{
            opacity: `${page === lastPage ? 0.1 : ""}`,
          }}
          disabled={page === lastPage}
        >
          {t("next")}
          <LeftOutlined
            style={{ transform: !isArabic ? "rotate(180deg)" : "" }}
            className="text-[1.2rem] font-bold"
          />
        </CustomButton>
      </a>
    </div>
  );
};

export default NewPaginator;
