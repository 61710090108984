import
{
    LIST_CAR_CONDITION_LOADING,
    LIST_CAR_CONDITION_SUCCESS,
    LIST_CAR_CONDITION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listTableDataService } from "services/listTableDataService";

export const listCarConditionAction = 
    () => async (dispatch: Dispatch) => {
        try {
            dispatch({type: LIST_CAR_CONDITION_LOADING, payload: true});
            const response = await listTableDataService('carConditionList');
            dispatch({type: LIST_CAR_CONDITION_SUCCESS, payload: response.data});
        } catch (err) {
            dispatch({type: LIST_CAR_CONDITION_FAILURE, payload: err});
        } finally {
            dispatch({type: LIST_CAR_CONDITION_LOADING, payload: false});
        }
    }