import api from "api";
import { updateCoverPhotoEndPoint } from "api/apiUrls";
import store from "redux/store";

export const updateCoverPhotoService = async (data: any) => {
    const response = await api.post(
        updateCoverPhotoEndPoint(),
        data,
        {
            headers: {
                'Authorization' : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`,
                'Content-Type' : 'multipart/form-data'
            }
        }
    );

    return response;
}