import
{
    FAVOURITE_EXHIBITORS_LOADING,
    FAVOURITE_EXHIBITORS_SUCCESS,
    FAVOURITE_EXHIBITORS_FAILURE
}
from "redux/types";

const initialState = {
    favouriteExhibitorsLoading: false,
    favouriteExhibitors: null,
    errors: null
}

export const listFavouriteExhibitorsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FAVOURITE_EXHIBITORS_LOADING :
            return {...state, favouriteExhibitorsLoading: action.payload};
        case FAVOURITE_EXHIBITORS_SUCCESS :
            return {...state, favouriteExhibitors: action.payload.data};
        case FAVOURITE_EXHIBITORS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}