import styles from "components/CustomUserInfoCard/ProfileScreenUserInfoCard/ProfileScreenUserInfoCard.module.css";
import { FC, useEffect } from "react";
import {
  PhoneFilled,
  EyeFilled,
  ShareAltOutlined,
  HeartFilled,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { LOG_OUT } from "redux/types";
import { useNavigate } from "react-router";
import { set } from "lodash";
import { RootState } from "redux/rootReducer";
import { useTranslation } from "react-i18next";
import { userIcon } from "assets/icons";
import ShareButton from "components/ShareButton/ShareButton";
import { Tooltip, notification } from "antd";
import { useUtilities } from "hooks/useUtilities";
import { useLanguage } from "hooks/useLanguage";
import SettingsIcon from "assets/icons/icons/SettingsIcon.svg";
import SettingsIconWhite from "assets/icons/icons/SettingsIconWhite.svg";

import { useResponsive } from "hooks/useResponsive";

interface ProfileScreenUserInfoCardProps {
  showSettings?: boolean;
  setShowSettings?: (showSettings: boolean) => void;
  username: string;
  userImage: any;
  userBrief?: string;
  email?: string;
  mobile?: string;
  joinedSince?: string;
  isVendor?: boolean;
  membershipType?: string;
  height?: string;
  mb?: string;
  callsCount?: any;
  viewsCount?: any;
  viewAsMe?: boolean;
  // displayLinks?: boolean;
}

const ProfileScreenUserInfoCard: FC<ProfileScreenUserInfoCardProps> = ({
  showSettings = false,
  setShowSettings = () => {},
  isVendor = false,
  callsCount,
  viewsCount,
  username,
  userBrief,
  email,
  mobile,
  joinedSince,
  membershipType,
  userImage,
  height = "auto",
  mb = "",
  viewAsMe = false,
  // displayLinks = true,
}) => {
  const { userData, isExhibitor, dispatch, navigate } = useUtilities();

  const { isMobile } = useResponsive();

  const { textDirectionStyle, currentLanguage, isArabic } = useLanguage();

  // const isArabic = currentLanguage === "ar";

  const handleLogoutClick = () => {
    dispatch({ type: LOG_OUT });
    navigate("/");
  };

  const { t } = useTranslation();

  const handleFavoriteClick = () => {
    // setFavoriteState(prev => !prev);
    //
    //
    // dispatch(favouritePostAction(id, !favoriteState));
    // navigate(`/${currentLanguage}/ads/show/${id}`);
  };

  const separator = (
    <div className={`w-full ${styles.horizontalSeparator}`}></div>
  );

  useEffect(() => {}, []);

  const settingsBtn = (
    <button
      className={`${
        isArabic ? styles.settingsContainerAr : styles.settingsContainer
      }`}
      onClick={() => {
        setShowSettings(true);
      }}
    >
      <img
        src={isMobile ? SettingsIcon : SettingsIconWhite}
        className={`${styles.settingsIcon}`}
        alt=""
      />
    </button>
  );

  return (
    <div dir={textDirectionStyle} className={`flex flex-col gap-y-4 ${mb}`}>
      {/* USER STATS MOBILE */}
      {isMobile && viewAsMe && (
        <div
          className={`w-full flex flex-row justify-start items-center gap-x-1`}
        >
          <div
            className={`${styles.shadow} w-1/3 flex flex-row items-center justify-center gap-x-2 rounded-md py-2`}
          >
            <EyeFilled className="text-sm" />
            <p className="text-sm">{viewsCount}</p>
          </div>
          <div
            className={`${styles.shadow} w-1/3 flex flex-row items-center justify-center gap-x-2 rounded-md py-2`}
          >
            <PhoneFilled className="text-sm" />
            <p className="text-sm">{callsCount}</p>
          </div>
        </div>
      )}
      <div
        className={`${styles.shadow} relative w-full p-4 bg-white rounded-lg flex flex-col items-center justify-between gap-y-0`}
      >
        {
          // isVendor
          true && (
            <div
              className={`w-full flex flex-row justify-${
                viewAsMe ? "between" : "end"
              } items-center`}
            >
              {viewAsMe &&
                (!isMobile ? (
                  <div
                    className={`w-full flex flex-row items-center gap-x-4 mt-1`}
                  >
                    <div
                      className={`${styles.grayBackground} flex flex-row items-center gap-x-2 rounded-md`}
                    >
                      <EyeFilled className="text-xs" />
                      <p className="text-xs">{viewsCount}</p>
                    </div>
                    <div
                      className={`${styles.grayBackground} flex flex-row items-center gap-x-2 rounded-md`}
                    >
                      <PhoneFilled className="text-xs" />
                      <p className="text-xs">{callsCount}</p>
                    </div>

                    {/* <HeartFilled
                      className={`${favoriteState ? 'favoriteIconSelected' : 'favoriteIcon'}`}
                      onClick={handleFavoriteClick}
                      style={{ fontSize: "1.5rem"}}
                    /> */}
                  </div>
                ) : (
                  settingsBtn
                ))}

              <ShareButton
                url={window.location.href}
                copyURL={
                  isVendor
                    ? window.location.href.split("&page=")[0] + "&page=1"
                    : window.location.href
                }
                showText={false}
              />
              {/* {isMobile ? (
                  <Tooltip
                    color="white"
                    style={{ padding: 10 }}
                    title={
                      <div className="w-full flex flex-col items-center gap-y-2"></div>
                    }
                  >
                    <div
                      className={`${styles.grayBackground} flex flex-row items-center gap-x-2 rounded-full px-1 hover:cursor-pointer`}
                    >
                      <p className="text-sm">{t("viewpost_share")}</p>
                      <ShareAltOutlined className="text-sm" />
                    </div>
                  </Tooltip>
                ) : (
                  <></>
          )} */}
            </div>
          )
        }
        <div
          className={`w-full ${
            isVendor ? "flex justify-center items-center" : ""
          } ${!isMobile && "mb-4"}`}
        >
          <div
            className={`${
              styles.profileBriefSection
            } w-full py-0 flex flex-col items-center justify-center rounded-xl gap-y-4 ${
              !isVendor ? `${!isMobile && "mb-4"}` : ""
            }`}
          >
            <div
              className={`rounded-full ${styles.blueBg} flex flex-row items-center justify-center`}
              style={{
                backgroundImage: `url('${encodeURI(userImage)}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* <img
                src={userIcon}
                className={`${styles.loginIconBurger}`}
                alt="Login Icon"
              /> */}
            </div>
            <p
              className={`text-black`}
              style={{
                fontSize: "1.25rem",
                fontFamily: "rbFont",
                textAlign: "center",
              }}
            >
              {username}
            </p>
            {/* <p className="text-xs">{userBrief ? userBrief : t("brief")}</p> */}
          </div>

          {
            // SETTINGS BTN
            viewAsMe && !isMobile && settingsBtn
          }
        </div>

        {/* {!isVendor ? (
          <div className="w-full flex flex-col items-center gap-y-2 mb-2">
            <div className="w-full h-[1px] mb-3 bg-stone-200"></div>
            {isExhibitor && (
              <a
                className={`${styles.cardLink} ${styles.grayCard}`}
                href={`/${currentLanguage}/vendor/${userData?.user.id}`}
              >
                {t("see_profile")}
              </a>
            )}
            <a
              className={`${styles.cardLink} ${styles.blueCard}`}
              onClick={() => {
                setShowSettings(!showSettings);
              }}
            >
              {showSettings ? t("user_my_account") : t("user_settings")}
            </a>
            <a
              onClick={handleLogoutClick}
              className={`${styles.cardLink} ${styles.redCard}`}
            >
              {t("drawer_logout")}
            </a>
          </div>
        ) : null} */}
      </div>

      {/* USER DETAILS */}
      <div
        className={`${styles.shadow} w-full py-2 px-6 bg-white rounded-lg flex flex-col items-center justify-center ${mb}`}
      >
        {
          // !isVendor
          true && (
            <div className="w-full flex flex-col items-center gap-y-0">
              {mobile ? (
                <div
                  className={`${styles.infoCard}  w-full flex flex-wrap items-center justify-start gap-x-4 gap-y-0`}
                >
                  <p className=" text-black" style={{ width: "4.5rem" }}>
                    {t("user_phone_number")}
                  </p>
                  <div className={`${styles.verticalSeparator}`}></div>
                  <p className={``}>{mobile}</p>
                </div>
              ) : (
                <></>
              )}
              {email && (
                <div
                  className={`${styles.infoCard}  w-full flex flex-wrap items-center justify-start gap-x-4 gap-y-0`}
                >
                  {/* {separator} */}
                  <p className=" text-black" style={{ width: "4.5rem" }}>
                    {t("user_email")}
                  </p>
                  <div className={`${styles.verticalSeparator}`}></div>
                  <p className={``}>{email}</p>
                </div>
              )}
              {joinedSince && (
                <div
                  className={`${styles.infoCard}  w-full flex flex-wrap items-center justify-start gap-x-4 gap-y-1`}
                >
                  {/* {separator} */}
                  <p className=" text-black" style={{ width: "4.5rem" }}>
                    {t("user_member_since")}
                  </p>
                  <div className={`${styles.verticalSeparator}`}></div>
                  <p className={``}>{joinedSince}</p>
                </div>
              )}
              {membershipType && (
                <div
                  className={`${styles.infoCard}  w-full flex flex-wrap items-center justify-start gap-x-4 gap-y-1`}
                >
                  {/* {separator} */}
                  <p className=" text-black" style={{ width: "4.5rem" }}>
                    {t("user_membership_type")}
                  </p>
                  <div className={`${styles.verticalSeparator}`}></div>
                  <p className={``}>{membershipType}</p>
                </div>
              )}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ProfileScreenUserInfoCard;
