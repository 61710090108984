import 
{
    LIST_ENGINE_CAPACITY_LOADING,
    LIST_ENGINE_CAPACITY_SUCCESS,
    LIST_ENGINE_CAPACITY_FAILURE
}
from "redux/types";

const initialState = {
    engineCapactiyLoading: false,
    engineCapacity: null,
    errors: null
};

export const listEngineCapacityReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_ENGINE_CAPACITY_LOADING :
            return {...state, engineCapacityLoading: action.payload};
        case LIST_ENGINE_CAPACITY_SUCCESS :
            return {...state, engineCapacity: action.payload.data};
        case LIST_ENGINE_CAPACITY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}