import
{
    EXHIBITIONS_LOADING,
    EXHIBITIONS_SUCCESS,
    EXHIBITIONS_FAILURE
}
from "redux/types";

import { listTableDataService } from "services/listTableDataService";

import { Dispatch } from "redux";

export const listExhibitionsAction = (page: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: EXHIBITIONS_LOADING, payload: true});
        const response = await listTableDataService(`getAllExhibitors?page=${page}`);
        dispatch({type: EXHIBITIONS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: EXHIBITIONS_FAILURE, payload: err});
    } finally {
        dispatch({type: EXHIBITIONS_LOADING, payload: false});
    }
}