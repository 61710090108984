import styles from "screens/ErrorScreen/ErrorScreen.module.css";
import pageNotFound from "assets/images/pageNotFound.svg";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useEffect, useState } from "react";
import { useUtilities } from "hooks/useUtilities";

const ErrorScreen = () => {
  var headerH = 0;
  // Get a reference to the header element by its ID
  const header = document.getElementById("header");

  // Get the height of the header
  headerH = header?.offsetHeight || 0;

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { t } = useUtilities();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
    // Get a reference to the header element by its ID
    const header = document.getElementById("header");

    // Get the height of the header
    headerH = header?.offsetHeight || 0;
  }, []);

  return (
    <div
      dir={currentLanguage === "ar" ? "rtl" : "ltr"}
      className="w-full flex flex-row items-start justify-center py-8"
      style={{
        backgroundColor: "var(--new-souq-background)",
        height: `calc(100vh - ${headerH}px)`,
      }}
    >
      <div
        className={`${styles.shadow} h-full bg-white w-[90%] rounded-xl flex flex-col-reverse desk:flex-row items-center desk:justify-evenly justify-between desk:py-20 py-12 px-10`}
      >
        <div
          className={`desk:w-[30%] desk:h-auto h-[40%] flex flex-col desk:items-start items-center gap-y-${
            isMobile ? "2" : "10"
          }`}
        >
          <p
            className={`font-bold text-[#3127FF]`}
            style={{
              fontSize: isMobile ? "3.125rem" : "4.5rem",
            }}
          >
            404
          </p>
          <p
            className={`whitespace-nowrap`}
            style={{
              fontSize: isMobile ? "1.20rem" : "2rem",
            }}
          >
            {t("page_not_found")}
          </p>
          <a
            href="/"
            className={`text-[blue] font-bold text-${
              isMobile ? "md" : "lg"
            } my-[auto]`}
          >
            {t("back_to_previous_page")}
          </a>
        </div>

        <img
          src={pageNotFound}
          alt="page not found"
          className={`desk:w-[40%] desk:max-h-full max-h-[50%]`}
        />
      </div>
    </div>
  );
};

export default ErrorScreen;
