import * as React from "react";
import type { SVGProps } from "react";
const SvgFacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 669 669"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M335 1C151.7 1 1.7 150.7 1.7 335c0 166.7 122 305 281.3 330V431.7h-84.7V335H283v-73.7c0-83.7 49.7-129.7 126-129.7 36.3 0 74.3 6.3 74.3 6.3v82.3h-42c-41.3 0-54.3 25.7-54.3 52V335h92.7l-15 96.7H387V665c78.5-12.4 150.1-52.5 201.7-113 51.6-60.5 79.9-137.5 79.7-217C668.3 150.7 518.3 1 335 1z"
    />
  </svg>
);
export default SvgFacebookIcon;
