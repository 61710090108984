import { DRAWER_STATUS_TYPE } from "redux/types";
import { Dispatch } from "redux";

export const drawerStatusAction =
  (drawerOpen: any) => async (dispatch: Dispatch) => {
    dispatch({ type: DRAWER_STATUS_TYPE, payload: drawerOpen });

    // document.addEventListener('click', (event: any) => {
    //   if (event.target.id !== 'always-click') {
    //     disableClick(event);
    //   }
    // }, true);

    // function disableClick(event: any) {
    //   event.stopPropagation();
    //   event.preventDefault();
    // }

    if (drawerOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
      // document.removeEventListener('click', disableClick, true);
    }
  };
