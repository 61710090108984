import styles from "screens/ViewProfileScreen/ViewProfileScreen.module.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Button, Empty, Input, Modal, Spin, Switch, notification } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import UpdateUserDataModel from "models/UpdateUserDataModel";
import { userIcon } from "assets/icons";
import defaultUserImage from "assets/images/customer_avatar.jpeg";
import { t } from "i18next";
import UpdatePostScreen from "screens/UpdatePostScreen/UpdatePostScreen";
import { listMyStoriesAction } from "redux/actions/StoriesActions/ListMyStoriesAction/listMyStoriesAction";

import {
  ProfileDataCard,
  ProfileScreenUserInfoCard,
  WidePostCard,
  CustomButton,
  PostCard,
} from "components/index";

import {
  listPostStatusAction,
  listProfilePostsAction,
  listCategoriesAction,
  displayMobileAction,
  suspendPostAction,
  updateCustomerAction,
  reactivatePostAction,
  setUpdatePostData,
  changeCurrentPageAction,
  setMapCoordinatesPostAction,
  profilePostCategoryAction,
  profilePostStatusAction,
} from "redux/actions/index";
import { useIsDefined } from "hooks/useIsDefined";

import { EditOutlined, StarFilled, DeleteOutlined } from "@ant-design/icons";

import UserSettings from "components/UserSettings/UserSettings";
import { useResponsive } from "hooks/useResponsive";
import MiniWidePostCard from "components/CustomPostCard/MiniWidePostCard/MiniWidePostCard";
import ExhibitorSettings from "components/ExhibitorSettings/ExhibitorSettings";
import { listExhibitorDataAction } from "redux/actions/ExhibitorDataActions/listExhibitorDataAction";
import { getUserDataAction } from "redux/actions/GetUserDataAction/getUserDataAction";
import { is } from "@babel/types";
import { useLanguage } from "hooks/useLanguage";
import NewCustomPostCard from "components/V3/NewCustomPostCard/NewCustomPostCard";
import DeletePostModal from "components/ModalComponents/DeletePostModal/DeletePostModal";
import NewCustomPostCardWithEdit from "components/V3/NewCustomPostCard/NewWidePostCardWithEdit";
import NewUserSettings from "components/V3/UserSettings/NewUserSettings";
import ExhibitorDashboardScreen from "screens/V3/ExhibitorDashboardScreen/ExhibitorDashboardScreen";
import ArchiveStoryCard from "components/V3/ArchiveStoryCard/ArchiveStoryCard";
import { usePostActions } from "hooks/usePostActions";

const ViewProfileScreen = () => {
  const { isMobile } = useResponsive();
  const { verifyOtpLoading, userData, isExhibitor, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { postStatusLoading, postStatus } = useSelector(
    (state: RootState) => state.listPostStatusReducer
  );

  const { categoriesLoading, categories } = useSelector(
    (state: RootState) => state.listCategoriesReducer
  );

  // const { categoriesLoading, categories } = useSelector(
  //   (state: RootState) => state.displayMobileReducer
  // );

  const { suspendPostLoading, suspendPostSuccess } = useSelector(
    (state: RootState) => state.suspendPostReducer
  );

  const { reactivatePostLoading, reactivatePostSuccess } = useSelector(
    (state: RootState) => state.reactivatePostReducer
  );

  const {
    profilePostsLoading,
    profilePosts,
    pendingCount,
    activeCount,
    inactiveCount,
    pending,
    active,
    inactive,
  } = useSelector((state: RootState) => state.listProfilePostsReducer);

  const { updateCustomerDataLoading, updatedCustomer } = useSelector(
    (state: RootState) => state.updateCustomerDataReducer
  );

  const { myStories, myStoriesLoading } = useSelector(
    (state: RootState) => state.listMyStoriesReducer
  );

  useEffect(() => {}, []);

  const renderCategory = (item: any) => {
    return (
      <div
        className={`${isArabic ? styles.ar : styles.en} ${
          styles.categoryLink
        } ${
          item?.id === profilePostCategory && styles.selectedPostStatus
        } w-full p-[0.4rem] rounded-full text-black flex flex-row gap-x-2 items-center justify-center ${
          styles.hover
        }`}
        onClick={() => {
          dispatch(profilePostCategoryAction(item?.id));

          if (item?.id === categories?.length) {
            dispatch(listMyStoriesAction());
          }
        }}
      >
        <p className={`${isMobile && "text-xs"}`}>
          {/* {(profilePostStatus === "Pending" &&
            Array.isArray(pending) &&
            pending[item?.id - 1][item?.id]) ||
          (profilePostStatus === "Active" &&
            Array.isArray(active) &&
            active[item?.id - 1][item?.id]) ||
          (profilePostStatus === "Inactive" &&
            Array.isArray(inactive) &&
            inactive[item?.id - 1][item?.id]) ? (
            <div
              className={`${styles.redCircle}`}
              style={!isArabic ? { left: "auto", right: "-20%" } : {}}
            ></div>
          ) : (
            <></>
          )} */}
          {item?.id === 1 ? t("sell") : item.name}
        </p>
      </div>
    );
  };

  const handleUpdateUserDataClick = async (
    name: any,
    email: any,
    personal_image: any
  ) => {
    await dispatch(updateCustomerAction(name, email, personal_image));
  };

  const { isDefined } = useIsDefined();

  const formik = useFormik({
    initialValues: {
      //   phone: "",
      name: userData?.user?.name,
      email: userData?.user?.email,
      personal_image: userData?.user?.personal_image,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required(),
      // email: Yup.string().email().required(),
    }),
    onSubmit: async (values: UpdateUserDataModel) => {
      await handleUpdateUserDataClick(
        values.name,
        values.email,
        values.personal_image
      );
      if (updatedCustomer) {
        if (isArabic)
          notification.success({
            message: "تم تحديث البيانات بنجاح",
            description: "",
          });
        else
          notification.success({
            message: "Data updated successfully",
            description: "",
          });
      }
    },
  });

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const {
    handleSuspendPostClick,
    handleReactivatePostClick,
    handlePostEditClick,
  } = usePostActions();

  /* 
        selectedPostStatus:
        - 0 المفضلة
        - 1 الموقوف
        - 2 المرفوضة
        - 3 الغير مغعلة
        - 4 المعلقة
        - 5 المفعلة
    */
  const [selectedPostStatus, setSelectedPostStatus] = useState("Active");
  const [selectedPostCategory, setSelectedPostCategory] = useState(1);

  const { profilePostStatus } = useSelector(
    (state: RootState) => state.profilePostStatusReducer
  );

  const { profilePostCategory } = useSelector(
    (state: RootState) => state.profilePostCategoryReducer
  );

  /*
        selectedPostCategory:
        - 0 قسم قطع الغيار
        - 1 قسم الأيجار
        - 2 قسم البيع و الشراء
    */

  const [showSettings, setShowSettings] = useState(false);

  const settingsSections = [t("my_info"), t("user_settings")];
  const [selectedSettingsSection, setSelectedSettingsSection] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  const [userProfileImage, setUserProfileImage] = useState<any>(null);
  const [profileIsFile, setProfileIsFile] = useState(false);

  const [displayMobileSwitch, setDisplayMobileSwitch] = useState<any>();
  const [displayEmailSwitch, setDisplayEmailSwitch] = useState<any>();

  const { isArabic, textDirectionStyle, currentLanguage } = useLanguage();

  useEffect(() => {
    if (userData === null || errors) {
      navigate("/");
    }
  }, [userData, errors]);

  useEffect(() => {
    dispatch(listProfilePostsAction(profilePostStatus, profilePostCategory));
  }, [profilePostStatus, profilePostCategory]);

  useEffect(() => {
    setDisplayMobileSwitch(
      userData?.user?.show_phone === 1 || userData?.user?.show_phone
    );
  }, [userData]);

  useEffect(() => {
    setUserProfileImage(userData?.user?.personal_image);
    dispatch(listPostStatusAction());
    dispatch(listCategoriesAction());
    dispatch(getUserDataAction());

    setDisplayMobileSwitch(
      userData?.user?.show_phone === 1 || userData?.user?.show_phone
    );
  }, []);

  const handleFileUpload = (e: any, image?: any) => {
    if (!image) {
      setUserProfileImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("personal_image", e.target.files[0]);
    } else {
      setUserProfileImage(null);

      formik.setFieldValue("personal_image", null);
      // clearTimeout(timeout);
    }
  };

  const setIsUpdatingClick = (value: boolean) => {
    setIsUpdating(value);
  };

  // return isExhibitor ? (
  return isExhibitor ? (
    <ExhibitorDashboardScreen />
  ) : (
    <>
      {isMobile ? (
        /////////////////
        // MOBILE VIEW //
        /////////////////
        <div
          className={`w-full flex flex-col items-center justify-start p-4`}
          style={{ backgroundColor: "var(--new-souq-background)" }}
        >
          <div
            className={`w-full flex flex-col items-center justify-start gap-y-4`}
          >
            {/* USER INFO CARD */}
            <div className="w-full">
              <ProfileScreenUserInfoCard
                username={userData?.user?.name || t("unspecified")}
                mobile={userData?.user?.phone}
                email={userData?.user?.email || t("unspecified")}
                userImage={userData?.user?.personal_image}
                callsCount={userData?.user?.total_contact}
                viewsCount={userData?.user?.total_views}
                // joinedSince={
                //   userData?.user?.created_at?.split("T")[0] || t("unspecified")
                // }
                // membershipType={t("membership_special")}
                height={"43rem"}
                setShowSettings={setShowSettings}
                viewAsMe={true}
              />
            </div>

            <div
              dir={textDirectionStyle}
              className={`w-full flex flex-col gap-y-4`}
            >
              {/* //////////////////
                // PROFILE PAGE //
                ////////////////// */}
              <div
                className={`w-full flex flex-row items-stretch justify-end gap-x-3`}
              >
                <ProfileDataCard
                  circularSectionData={userData?.user?.available_stars}
                  // isSpecial={true}
                  tooltipText={t("profile_tooltip_stars")}
                  primaryText={t("num_of_stars")}
                  secondaryText={t("more_stars")}
                  isStars={true}
                />
              </div>
              <div
                className={`w-full flex flex-row items-stretch justify-end gap-x-3`}
              >
                <ProfileDataCard
                  // circularSectionData="-111"
                  circularSectionData={userData?.user?.available_posts}
                  primaryText={t("num_ads")}
                  tooltipText={t("profile_tooltip_ads")}
                  // isSpecial={true}
                  secondaryText={t("more_ads")}
                />

                <ProfileDataCard
                  circularSectionData={userData?.user?.available_stories?.toString()}
                  // isSpecial={true}
                  tooltipText={t("profile_tooltip_stories")}
                  primaryText={t("available_stories")}
                  secondaryText={t("more_stories")}
                />
              </div>
              {/* <ProfileDataCard
                      circularSectionData={t("membership_special")}
                      isSpecial={true}
                      primaryText={t("membership_type")}
                      // secondaryText="تطوير"
                    /> */}

              <div
                className={`${styles.shadow} w-full flex items-center justify-between ${styles.whiteBackground} rounded-xl`}
              >
                {postStatusLoading ? (
                  <Spin />
                ) : (
                  Array.isArray(postStatus) &&
                  postStatus?.map((item: any, idx: any) => {
                    return (
                      <div
                        key={idx}
                        className={`w-full ${
                          isArabic ? styles.ar : styles.en
                        } ${
                          item.key === profilePostStatus &&
                          styles.selectedPostStatus
                        }  p-2 py-4 rounded-xl text-black flex flex-row gap-x-2 items-center justify-center ${
                          styles.hover
                        } ${isMobile && "text-xs"}`}
                        style={{
                          // nowrap
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                          dispatch(profilePostStatusAction(item.key));
                        }}
                      >
                        <p>{item?.id === 1 ? t("sell") : item.name}</p>
                      </div>
                    );
                  })
                )}
              </div>

              <div
                className={`${styles.postTypeFilterContainer} ${styles.shadow} ${styles.whiteBackground} w-full flex items-center justify-between`}
              >
                {categoriesLoading ? (
                  <Spin />
                ) : (
                  Array.isArray(categories) &&
                  categories?.map((item: any, idx: any) => {
                    if (item?.id !== 5 && item?.id !== 6) {
                      return (
                        <div
                          className={`${isArabic ? styles.ar : styles.en} ${
                            styles.categoryLink
                          } ${
                            item?.id === profilePostCategory &&
                            styles.selectedPostStatus
                          } w-full  p-[0.4rem] rounded-full text-black flex flex-row gap-x-2 items-center justify-center ${
                            styles.hover
                          } ${isMobile && "text-xs"}`}
                          style={{
                            // nowrap
                            whiteSpace: "nowrap",
                          }}
                          onClick={() => {
                            if (item) {
                              dispatch(profilePostCategoryAction(item?.id));
                            }
                          }}
                        >
                          <p>
                            {(profilePostStatus === "Pending" &&
                              Array.isArray(pending) &&
                              pending[item?.id - 1][item?.id]) ||
                            (profilePostStatus === "Active" &&
                              Array.isArray(active) &&
                              active[item?.id - 1][item?.id]) ||
                            (profilePostStatus === "Inactive" &&
                              Array.isArray(inactive) &&
                              inactive[item?.id - 1][item?.id]) ? (
                              <div
                                className={`${styles.redCircle}`}
                                style={
                                  !isArabic
                                    ? { left: "auto", right: "-20%" }
                                    : {}
                                }
                              ></div>
                            ) : (
                              <></>
                            )}
                            {item?.id === 1 ? t("sell") : item.name}
                          </p>
                        </div>
                      );
                    }
                  })
                )}
                {!categoriesLoading &&
                  renderCategory({
                    id: categories?.length,
                    name: t("story"),
                  })}
              </div>

              {profilePostCategory === categories?.length ? (
                <div className="w-full flex justify-between flex-wrap gap-y-4">
                  {myStories?.map((item: any, idx: any) => {
                    return (
                      <ArchiveStoryCard
                        id={item.id}
                        key={idx}
                        description={item.description}
                        createdAt={item.created_at?.split("T")[0]}
                        calls={item.contact_count ?? 0}
                        views={item.views ?? 0}
                        favourites={item.favourite_count ?? 0}
                        shares={item.shares ?? 0}
                        width={"w-full"}
                      />
                    );
                  })}
                </div>
              ) : !isUpdating ? (
                profilePostsLoading ? (
                  <Spin />
                ) : profilePosts?.length === 0 || !profilePosts ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ transform: "scale(1.5)" }}
                    description={t("no_posts")}
                  />
                ) : (
                  Array.isArray(profilePosts) &&
                  profilePosts?.map((item: any, idx: number) => {
                    return (
                      <NewCustomPostCardWithEdit
                        idx={idx}
                        item={item}
                        handleSuspendPostClick={() =>
                          handleSuspendPostClick(item?.uuid)
                        }
                        handleReactivatePostClick={() =>
                          handleReactivatePostClick(item?.id)
                        }
                        handlePostEditClick={() =>
                          handlePostEditClick(item.uuid, item.lat, item.long)
                        }
                        isDeleted={profilePostStatus === "Inactive"}
                        showActions={profilePostStatus !== "Rejected"}
                        showPayButton={profilePostStatus === "Outstanding"}
                      />
                    );
                  })
                )
              ) : (
                <UpdatePostScreen />
              )}
              {
                ///////////////////
                // SETTINGS PAGE //
                ///////////////////
                <Modal
                  open={showSettings}
                  onCancel={() => setShowSettings(false)}
                  footer={null}
                  width="45rem"
                  style={{
                    borderRadius: "1rem",
                    direction: textDirectionStyle,
                  }}
                >
                  {/* {isExhibitor ? <ExhibitorSettings /> : <UserSettings />} */}
                  <NewUserSettings />
                </Modal>
              }
            </div>
          </div>
        </div>
      ) : (
        //////////////////
        // DESKTOP VIEW //
        //////////////////
        <div
          className={`w-full flex flex-col items-center justify-start pt-10`}
          style={{ backgroundColor: "var(--new-souq-background)" }}
        >
          <div
            className={`contentWidth flex flex-col items-center justify-start gap-y-4`}
          >
            {/* USER INFO CARD */}
            <div className="w-full">
              <ProfileScreenUserInfoCard
                username={userData?.user?.name || t("unspecified")}
                mobile={userData?.user?.phone}
                email={userData?.user?.email || t("unspecified")}
                userImage={userData?.user?.personal_image}
                callsCount={userData?.user?.total_contact}
                viewsCount={userData?.user?.total_views}
                // joinedSince={
                //   userData?.user?.created_at?.split("T")[0] || t("unspecified")
                // }
                // membershipType={t("membership_special")}
                height={"43rem"}
                setShowSettings={setShowSettings}
                viewAsMe={true}
              />
            </div>

            <div
              dir={textDirectionStyle}
              className={`w-full flex flex-col gap-y-4 pb-12`}
            >
              {/* GREETING */}
              {/* <div className={`w-full flex flex-col justify-start`}>
                <div className={`flex gap-x-1 justify-start`}>
                  <p dir="ltr" className="font-bold text-lg">
                    {" "}
                    {t("user_greeting")}
                  </p>
                  <p className={`font-bold text-lg`}>
                    {(userData && userData.user && userData?.user["name"]) ||
                      t("unspecified")}
                  </p>
                </div>
                <p className={`w-full`}>{t("profile_msg")}</p>
              </div> */}

              {/* //////////////////
                // PROFILE PAGE //
                ////////////////// */}
              <div
                className={`w-full flex flex-row items-stretch justify-end gap-x-3`}
              >
                <ProfileDataCard
                  // isSpecial={true}
                  circularSectionData={userData?.user?.available_stars}
                  primaryText={"عدد نجوم تمييز الأعلان"}
                  tooltipText={t("profile_tooltip_stars")}
                  secondaryText="نجوم أكتر"
                  isStars={true}
                />
                <ProfileDataCard
                  // circularSectionData="-111"
                  circularSectionData={userData?.user?.available_posts}
                  primaryText={t("num_ads")}
                  tooltipText={t("profile_tooltip_ads")}
                  // isSpecial={true}
                  secondaryText="أعلانات أكثر"
                />
                {/* <ProfileDataCard
                      circularSectionData={"0"}
                      // isSpecial={true}
                      primaryText={"عدد نجوم تمييز الأعلان"}
                      secondaryText="نجوم تميز أكتر"
                      isStars={true}
                    /> */}
                {/* <ProfileDataCard
                      circularSectionData={userData?.user?.posts_count}
                      primaryText={t("total_ads")}
                    />
                    <ProfileDataCard
                      circularSectionData="-222"
                      primaryText="الرسائل الغير مقروئه"
                      secondaryText={t("all_msgs")}
                      secondaryTextColor="#3127ff"
                    /> */}
                <ProfileDataCard
                  circularSectionData={userData?.user?.available_stories?.toString()}
                  // isSpecial={true}
                  primaryText={"الستوري المتاحة"}
                  tooltipText={t("profile_tooltip_stories")}
                  secondaryText="ستوري أكتر"
                />
                {/* <ProfileDataCard
                      circularSectionData={t("membership_special")}
                      isSpecial={true}
                      primaryText={t("membership_type")}
                      // secondaryText="تطوير"
                    /> */}
              </div>

              <div
                className={`${styles.shadow} w-full flex items-center justify-between ${styles.whiteBackground} rounded-xl`}
              >
                {postStatusLoading ? (
                  <Spin />
                ) : (
                  Array.isArray(postStatus) &&
                  postStatus?.map((item: any, idx: any) => {
                    return (
                      <div
                        key={idx}
                        className={`w-full ${
                          isArabic ? styles.ar : styles.en
                        } ${
                          item.key === profilePostStatus &&
                          styles.selectedPostStatus
                        }  p-2 px-4 py-4 rounded-xl text-black flex flex-row gap-x-2 items-center justify-center ${
                          styles.hover
                        }`}
                        onClick={() => {
                          dispatch(profilePostStatusAction(item.key));
                        }}
                      >
                        {item.key === "Pending" ||
                        item.key === "Active" ||
                        item.key === "Inactive" ? (
                          <div
                            className={`${
                              item.key === profilePostStatus
                                ? styles.pendingCounterSelected
                                : styles.pendingCounterNormal
                            } ${styles.pendingCounterContainer}`}
                          >
                            <div className={`${styles.counterText}`}>
                              {item.key === "Pending"
                                ? pendingCount
                                : item.key === "Active"
                                ? activeCount
                                : inactiveCount}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <p>{item?.id === 1 ? t("sell") : item.name}</p>
                      </div>
                    );
                  })
                )}
              </div>

              <div
                className={`${styles.postTypeFilterContainer} ${styles.shadow} ${styles.whiteBackground} w-full flex items-center justify-between`}
              >
                {categoriesLoading ? (
                  <Spin />
                ) : (
                  Array.isArray(categories) &&
                  categories?.map((item: any, idx: any) => {
                    // categories desktop
                    if (item?.id !== 5 && item?.id !== 6) {
                      return renderCategory(item);
                    }
                  })
                )}
                {renderCategory({
                  id: categories?.length,
                  name: t("story"),
                })}
              </div>

              {profilePostCategory === categories?.length ? (
                <div className="w-full flex justify-between flex-wrap gap-y-4">
                  {myStories?.map((item: any, idx: any) => {
                    return (
                      <ArchiveStoryCard
                        id={item.id}
                        key={idx}
                        description={item.description}
                        createdAt={item.created_at?.split("T")[0]}
                        calls={item.contact_count ?? 0}
                        views={item.views ?? 0}
                        favourites={item.favourite_count ?? 0}
                        shares={item.shares ?? 0}
                        // width={"w-[49%]"}
                        style={{
                          width: "calc(50% - 0.5rem)",
                        }}
                      />
                    );
                  })}
                </div>
              ) : !isUpdating ? (
                profilePostsLoading ? (
                  <Spin />
                ) : profilePosts?.length === 0 || !profilePosts ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ transform: "scale(1.5)" }}
                    description={t("no_posts")}
                  />
                ) : (
                  Array.isArray(profilePosts) &&
                  profilePosts?.map((item: any, idx: number) => {
                    //
                    //
                    return (
                      <NewCustomPostCardWithEdit
                        idx={idx}
                        item={item}
                        handleSuspendPostClick={() =>
                          handleSuspendPostClick(item?.uuid)
                        }
                        handleReactivatePostClick={() =>
                          handleReactivatePostClick(item?.id)
                        }
                        handlePostEditClick={() =>
                          handlePostEditClick(item.uuid, item.lat, item.long)
                        }
                        isDeleted={profilePostStatus === "Inactive"}
                        showActions={profilePostStatus !== "Rejected"}
                        showPayButton={profilePostStatus === "Outstanding"}
                      />
                    );
                  })
                )
              ) : (
                <UpdatePostScreen />
              )}
              {
                ///////////////////
                // SETTINGS PAGE //
                ///////////////////
                <Modal
                  open={showSettings}
                  onCancel={() => setShowSettings(false)}
                  footer={null}
                  width="45rem"
                  style={{
                    borderRadius: "1rem",
                    direction: textDirectionStyle,
                  }}
                >
                  {/* {isExhibitor ? <ExhibitorSettings /> : <UserSettings />} */}
                  <NewUserSettings />
                </Modal>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewProfileScreen;
