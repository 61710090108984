import styles from "components/V3/ArchiveStoryCard/ArchiveStoryCard.module.css";
import { FC } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  HeartFilled,
  MessageOutlined,
  PhoneFilled,
  RedoOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import CustomButton from "components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useTranslation } from "react-i18next";
import { deleteStoryAction } from "redux/actions/StoriesActions/DeleteStoryAction/DeleteStoryAction";
import { Spin } from "antd";

interface ArchiveStoryCardProps {
  id: any;
  description: string;
  createdAt: string;
  favourites: number;
  calls: number;
  views: number;
  shares: number;
  width?: any;
  style?: any;
}

const ArchiveStoryCard: FC<ArchiveStoryCardProps> = ({
  id,
  description,
  createdAt,
  favourites,
  calls,
  views,
  shares,
  width,
  style,
}) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const { deleteStoryLoading } = useSelector(
    (state: RootState) => state.deleteStoryReducer
  );

  return (
    <div
      className={`${width} flex flex-col items-center gap-y-2`}
      style={{ ...style }}
    >
      <div
        dir="rtl"
        className={`w-full h-[12.22188rem] ${styles.shadow} bg-white rounded-[0.625rem] flex flex-col items-start px-3 py-4 gap-y-3`}
      >
        <p className="text-[0.9375rem]">{createdAt}</p>
        <p className="line-clamp-1 overflow-ellipsis text-[1.125rem]">
          {description}
        </p>

        <div className="w-full flex flex-row items-center justify-center gap-x-3">
          <div
            className={`${styles.blueOpacity} rounded-[0.3125rem] w-[50%] h-[2.44438rem] gap-x-2 flex items-center justify-center`}
          >
            <EyeOutlined />
            <p>{views}</p>
          </div>
          <div
            className={`${styles.blueOpacity} rounded-[0.3125rem] w-[50%] h-[2.44438rem] gap-x-2 flex items-center justify-center`}
          >
            <PhoneFilled className="rotate-[100deg]" />
            <p>{calls}</p>
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-x-3">
          <div
            className={`${styles.blueOpacity} rounded-[0.3125rem] w-[33%] h-[2.44438rem] gap-x-2 flex items-center justify-center`}
          >
            <HeartFilled />
            <p>{favourites}</p>
          </div>
          <div
            className={`${styles.blueOpacity} rounded-[0.3125rem] w-[33%] h-[2.44438rem] gap-x-2 flex items-center justify-center`}
          >
            <ShareAltOutlined />
            <p>{shares}</p>
          </div>
          <div
            className={`${styles.blueOpacity} rounded-[0.3125rem] w-[33%] h-[2.44438rem] gap-x-2 flex items-center justify-center`}
          >
            <MessageOutlined />
            <p>{calls}</p>
          </div>
        </div>
      </div>
      <div className="archive-story-spin-container w-full">
        <Spin spinning={deleteStoryLoading}>
          <div
            dir="ltr"
            className={`w-full flex flex-row items-center justify-center gap-x-2`}
          >
            <CustomButton
              className={`${styles.shadow} ${styles.button} w-[33%] h-[2.77031rem] flex items-center justify-center gap-x-2`}
              color={"white"}
              onClick={async () => {
                // delete Story
                await dispatch(deleteStoryAction(id));
                window.location.reload();
              }}
            >
              <DeleteOutlined className="text-[red]" />
              <p>{t("delete")}</p>
            </CustomButton>
            <CustomButton
              className={`${styles.shadow} w-[33%] h-[2.77031rem] flex items-center justify-center gap-x-2`}
              color={"white"}
              onClick={() => {
                // delete Story
              }}
            >
              <EditOutlined className="text-[var(--new-blue)]" />
              <p>{t("edit")}</p>
            </CustomButton>
            <CustomButton
              className={`${styles.shadow} ${styles.buttonRight} w-[33%] h-[2.77031rem] flex items-center justify-center gap-x-2`}
              color={"white"}
              onClick={() => {
                // delete Story
              }}
            >
              <RedoOutlined className="" />
              <p>{t("repost")}</p>
            </CustomButton>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default ArchiveStoryCard;
