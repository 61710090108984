import { FC } from "react";
import CustomCard from "components/CustomCard/CustomCard";
import { Carousel } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import PostCard from "components/CustomPostCard/PostCard/PostCard";
import styles from "screens/HomeScreen/HomeScreen.module.css";
import { useIsDefined } from "hooks/useIsDefined";
import NewWidePostCard from "components/V3/NewCustomPostCard/NewWidePostCard/NewWidePostCard";

interface CarouselProps {
  data?: any;
  postCardData?: any;
  slidesToShow: number;
  width: any;
  height?: any;
  cardStyle?: any;
  cardTextStyle?: any;
  isPostCard?: boolean;
  hasAutoPlay?: boolean;
  postCardWidth?: any;
  postCardStyle?: any;
  vertical?: boolean;
  arrows?: boolean;
  responsive?: any;
  isMobile: any;
  style?: any;
  isBrandCarousel?: boolean;
  onHover?: any;
  onExit?: any;
  imageWidth?: any;
}

const CustomCarousel: FC<CarouselProps> = ({
  data,
  postCardData,
  slidesToShow,
  width,
  cardStyle,
  style,
  cardTextStyle,
  isBrandCarousel = false,
  isPostCard = false,
  hasAutoPlay = true,
  postCardWidth = "w-3/4",
  height = "h-64",
  isMobile = false,
  vertical = false,
  arrows = true,
  responsive,
  postCardStyle,
  onHover,
  onExit,
  imageWidth,
}) => {
  const { isDefined } = useIsDefined();

  return (
    <div
      className={`${height} ${isPostCard ? "" : "px-2"}`}
      onMouseEnter={onHover}
      onMouseLeave={onExit}
    >
      <Carousel
        responsive={responsive}
        vertical={vertical}
        verticalSwiping={vertical ? true : false}
        swipe
        arrows={arrows}
        autoplay={hasAutoPlay}
        slidesToShow={slidesToShow}
        dots={false}
        prevArrow={<LeftOutlined />}
        nextArrow={<RightOutlined />}
      >
        {isPostCard
          ? postCardData?.map((item: any, idx: any) => {
              return (
                <div
                  key={idx}
                  className={`${
                    isBrandCarousel ? styles.carouselPostCard : ""
                  }`}
                >
                  <NewWidePostCard
                    key={item?.id}
                    id={item?.uuid}
                    isSpecialAd={true}
                    viewsCount={item?.views_count}
                    callsCount={item?.contact_count}
                    uploadDate={item?.created_at}
                    category={item?.category}
                    postType={item?.category}
                    customerName={item?.customer}
                    postTitle={item?.title}
                    paymentOption={item?.payment_type}
                    city={item?.city}
                    price={
                      isDefined(item?.down_payment)
                        ? item?.down_payment
                        : item?.price
                    }
                    carCondition={item?.car_condition}
                    productionDate={item?.production_date}
                    kilometrage={item?.kilo_meterage}
                    width={postCardWidth}
                    postCardStyle={postCardStyle}
                    isMobile={isMobile}
                    image={item?.main_image}
                    imageWidth={imageWidth}
                    showActions={false}
                    isFavorited={item.favourited}
                    brandLogo={undefined}
                    created_at={item.created_at?.split("T")[0]}
                    address={item.address}
                  />
                </div>
              );
            })
          : data?.map((item: any, idx: any) => {
              return (
                <CustomCard
                  key={idx}
                  name={item?.name}
                  image={item?.image}
                  cardStyle={cardStyle}
                  textStyle={cardTextStyle}
                  style={style}
                />
              );
            })}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
