import {
  VIEW_ARTICLE_LOADING,
  VIEW_ARTICLE_SUCCESS,
  VIEW_ARTICLE_FAILURE,
} from "redux/types";

const initialState = {
  articleLoading: null,
  articleData: null,
  errors: null,
};

export const viewArticleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case VIEW_ARTICLE_LOADING:
      return { ...state, articleLoading: action.payload };
    case VIEW_ARTICLE_SUCCESS:
      return { ...state, articleData: action.payload.data };
    case VIEW_ARTICLE_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
