import styles from "screens/V3/HomeScreen/HomeScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { carouselData } from "MockupData/carouselData";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import {
  Cascader,
  Empty,
  Input,
  Modal,
  Select,
  Spin,
  notification,
} from "antd";
import carModelData from "MockupData/carModelData";
import carMakerData from "MockupData/carMakerData";
import colorData from "MockupData/colorData";
import featuresData from "MockupData/featuresData";
import cityData from "MockupData/cityData";
import { useNavigate } from "react-router";
import { CREATE_POST_PATH, SPECIAL_ADS_PATH } from "Routes/paths";
import { RightOutlined } from "@ant-design/icons";
import { RootState } from "redux/rootReducer";
import { useTranslation } from "react-i18next";
import { plusIcon, souqCarLogo } from "assets/icons";
import Img_1 from "../../img/aftereid-1.png";
import BannerEn1 from "assets/images/Banners/en/d1.png";
import BannerEn2 from "assets/images/Banners/en/d2.png";
import BannerEn3 from "assets/images/Banners/en/d3.png";
import BannerEnMobile1 from "assets/images/Banners/en/p1.png";
import BannerEnMobile2 from "assets/images/Banners/en/p2.png";
import BannerEnMobile3 from "assets/images/Banners/en/p3.png";
import BannerAr1 from "assets/images/Banners/ar/d1.png";
import BannerAr2 from "assets/images/Banners/ar/d2.png";
import BannerAr3 from "assets/images/Banners/ar/d3.png";
import BannerArMobile1 from "assets/images/Banners/ar/p1.png";
import BannerArMobile2 from "assets/images/Banners/ar/p2.png";
import BannerArMobile3 from "assets/images/Banners/ar/p3.png";
import BannerMob1 from "assets/images/banner-mobile-1.png";

import {
  changeCurrentPageAction,
  listArticlesAction,
  listCarBodiesAction,
  listEngineCapacityAction,
  listCarConditionAction,
  listYearsAction,
  listCategoriesAction,
  listCarMakerAction,
  listFeaturesAction,
  listFeaturedPostsAction,
  listCarModelsAction,
  listColorsAction,
  listCitiesAction,
  listTransmissionTypesAction,
  listFuelTypesAction,
} from "redux/actions/index";
import {
  CustomCarousel,
  CustomCard,
  Seo,
  CustomButton,
  Footer,
  CustomDropdownField,
  PostCardCarousel,
  CategoriesCarousel,
} from "components/index";
import BannersCarousel from "components/Carousel/BannersCarousel/BannersCarousel";
import CustomArticleCard from "components/V3/CustomArticleCard/CustomArticleCard";
import CustomPostCard from "components/CustomPostCard/PostCard/PostCard";
import NewCustomPostCard from "components/V3/NewCustomPostCard/NewCustomPostCard";
import CustomModal from "components/CustomModal/Modal";
import CarBodyCarousel from "components/Carousel/CarBodyCarousel/CarBodyCarousel";
import CarMakerCarousel from "components/Carousel/CarMakerCarousel/CarMakerCarousel";
import CircularStoryCard from "components/V3/CircularStoryCard/CircularStoryCard";
import { listStoriesAction } from "redux/actions/StoriesActions/ListStoriesAction/listStoriesAction";
import { listHomeScreenExhibitorsAction } from "redux/actions/HomeScreenExhibitorsAction/listHomeSrceenExhibitorsAction";
import CircularExhibitorCard from "components/V3/CircularExhibitorCard/CircularExhibitorCard";
import AdvancedSearchSheet from "components/AdvancedSearchSheet/AdvancedSearchSheet";
import { BlackDownArrow } from "assets/IconComponents";
import HomeCarousel from "components/V3/HomeCarousel/HomeCarousel";
import {
  StoryCategoryIcon,
  ExhibitionsCategoryIcon,
  BuySellIcon,
  RentCarsIcon,
  SparePartsIcon,
  InstallmentIcon,
} from "assets/icons/V3";
import { FreeInspectionIcon } from "assets/icons";
import { useResponsive } from "hooks/useResponsive";
import ExhibitorList from "components/V3/ExhibitorList/ExhibitorList";
import StoriesList from "components/V3/StoriesList/StoriesList";
import { useLanguage } from "hooks/useLanguage";

const HomeScreen = () => {
  const { t } = useTranslation();

  const { verifyOtpLoading, userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const { isArabic, textDirectionStyle } = useLanguage();

  const arabicBanners: any = {
    desktop: [BannerAr1, BannerAr2, BannerAr3],
    phone: [BannerArMobile1, BannerArMobile2, BannerArMobile3],
  };

  const englishBanners: any = {
    desktop: [BannerEn1, BannerEn2, BannerEn3],
    phone: [BannerEnMobile1, BannerEnMobile2, BannerEnMobile3],
  };

  const { addBreakpoint } = useResponsive();

  const [isMax1440, setIsMax1440] = useState(false);
  const [isMax1330, setIsMax1330] = useState(false);
  const [isMax1100, setIsMax1100] = useState(false);
  addBreakpoint([
    {
      value: 1440,
      type: "MAX",
      setState: setIsMax1440,
    },
    {
      value: 1330,
      type: "MAX",
      setState: setIsMax1330,
    },
    {
      value: 1100,
      type: "MAX",
      setState: setIsMax1100,
    },
  ]);

  const [mainImage, setMainImage] = useState();
  const [mainImageMobile, setMainImageMobile] = useState();
  const [mainImageArray, setMainImageArray] = useState<any[]>([]);

  const [noCreditModalOpen, setNoCreditModalOpen] = useState(false);

  const { carBodiesLoading, carBodies } = useSelector(
    (state: RootState) => state.listCarBodiesReducer
  );
  const { engineCapactiyLoading, engineCapacity } = useSelector(
    (state: RootState) => state.listEngineCapacityReducer
  );
  const { carConditionLoading, carConditions } = useSelector(
    (state: RootState) => state.listCarConditionReducer
  );
  const { yearsLoading, years } = useSelector(
    (state: RootState) => state.listYearsReducer
  );
  const { categoriesLoading, categories } = useSelector(
    (state: RootState) => state.listCategoriesReducer
  );
  const { carMakersLoading, carMakers } = useSelector(
    (state: RootState) => state.listCarMakerReducer
  );
  const { featuredPostsLoading, featuredPosts } = useSelector(
    (state: RootState) => state.listFeaturedPostsReducer
  );
  const { carModelsLoading, carModels } = useSelector(
    (state: RootState) => state.listCarModelsReducer
  );
  const { colorsLoading, colors } = useSelector(
    (state: RootState) => state.listColorsReducer
  );
  const { featuresLoading, features } = useSelector(
    (state: RootState) => state.listFeaturesReducer
  );
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );
  const { fuelTypesLoading, fuelTypes } = useSelector(
    (state: RootState) => state.listFuelTypesReducer
  );
  const { transmissionTypesLoading, transmissionTypes } = useSelector(
    (state: RootState) => state.listTransmissionTypesReducer
  );

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { articlesLoading, articles } = useSelector(
    (state: RootState) => state.listarticlesReducer
  );

  const { storiesLoading, stories } = useSelector(
    (state: RootState) => state.listStoriesReducer
  );

  const { homeExhibitorsLoading, homeExhibitors } = useSelector(
    (state: RootState) => state.listHomeScreenExhibitorsReducer
  );

  const engineCapacityDropdown = engineCapacity?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const carConditionsDropdown = carConditions?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const yearsDropdown = years?.map((item: any) => {
    return {
      value: item,
      label: item,
    };
  });

  const carMakerDropdown = carMakers?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
      label_other: item.name_other,
      icon: item.image,
    };
  });

  const carModelsDropdown = carModels?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
      label_other: item.name_other,
    };
  });

  const colorsDropdown = colors?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const featuresDropdown = features?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const citiesDropdown = cities?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const carBodiesDropdown = carBodies?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const fuelTypesDropdown = fuelTypes?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const transmissionTypesDropdown = transmissionTypes?.map((item: any) => {
    return {
      value: item.key,
      lavel: item.name,
    };
  });

  const posts = Array(9).fill(0);

  /*
        selectedSearchCategory:
        - 0 for إيجار
        - 1 for بيع و شراء
    */

  const [selectedSearchCategory, setSelectedSearchCategory] = useState(1);

  const [advancedSearch, setAdvancedSearch] = useState(false);

  const [showSearchField, setShowSearchField] = useState(false);

  const [showBottomModalSheet, setShowBottomModalSheet] = useState(false);

  const [showSearchBar, setShowSearchBar] = useState(false);

  /* 
        - By default it's view by car model.
        - viewByBody sets the view to car body.
    */

  const [viewByBody, setViewByBody] = useState(false);

  /* 
        carCondition:
        - 0 for مستعمل
        - 1 for كسر زيرو
        - 2 for جديد
        - 3 for خردة
    */

  const [carCondition, setCarCondition] = useState(3);

  /* 
       selectedPostCategory:
       - 0 for قطع غيار
       - 1 for إيجار
       - 2 for بيع وشراء
   */

  const [selectedTransmissionType, setSelectedTranmissionType] = useState("");

  /*
         basic_seacrh fields
    */
  const [carConditionFilter, setCarConditionFilter] = useState("");
  const [productionYearFilter, setProductionYearFilter] = useState("");
  const [carModelFilter, setCarModelFilter] = useState("");
  const [carMakerFilter, setCarMakerFilter] = useState("");
  const [colorFilter, setColorFilter] = useState("");
  const [distanceFilter, setDistanceFilter] = useState(""); //kilometrage
  const [engineCapacityFilter, setEngineCapacityFilter] = useState("");
  const [featuresFilter, setFeaturesFilter] = useState("");
  const [carBodyFilter, setCarBodyFilter] = useState("");
  const [priceFromFilter, setPriceFromFilter] = useState("");
  const [priceToFilter, setPriceToFilter] = useState("");
  const [citiesFilter, setCitiesFilter] = useState("");
  const [fuelTypeFilter, setFuelTypeFilter] = useState("");
  const [transmissionTypeFilter, setTransmissionTypeFilter] = useState("");
  const [yearFromFilter, setYearFromFilter] = useState("");

  const [titleSearch, setTitleSearch] = useState("");

  const [selectedPostCategory, setSelectedPostCategory] = useState(1);

  const [isMobile, setIsMobile] = useState(false);
  const [postCardIsMobile, setPostCardIsMobile] = useState(false);
  const [postSectionIsMobile, setPostSectionIsMobile] = useState(false);

  const [isAutoPlay, setIsAutoPlay] = useState(true);

  const excludedCategories = (item: any) => {
    return item.id !== 5 && item.id !== 3 && item.id !== 4;
  };

  const extraCategories = [
    {
      name: t("story"),
      icon: StoryCategoryIcon,
      route: "stories",
    },
    {
      name: t("exhibitions_and_dealers"),
      icon: ExhibitionsCategoryIcon,
      route: "exhibitions",
    },
    {
      name: t("navbar_insurance_text"),
      icon: FreeInspectionIcon,
      route: "car-insurance",
    },
    {
      name: t("installment_cars"),
      icon: InstallmentIcon,
      route: "ads/view?category_id=1&payment_type=Installment",
    },
  ];

  const getSearchFilterURL = (): string => {
    interface Params {
      [key: string]: any;
    }

    var allParams: Params = {
      category_id: selectedSearchCategory,
      brand_id: carMakerFilter,
      car_model_id: carModelFilter,
      production_date: productionYearFilter,
      car_condition: carConditionFilter,
      transmission_type: transmissionTypeFilter,
      car_type: carBodyFilter,
      price_from: priceFromFilter,
      price_to: priceToFilter,
      city_id: citiesFilter,
      fuel_type: fuelTypeFilter,
      color_id: colorFilter,
      kilo_metrage: distanceFilter,
      engine_capacity: engineCapacityFilter,
      features: featuresFilter,
    };

    var counter = 0;
    var finalParams: string = "";
    Object.keys(allParams).map(function (key: string) {
      if (
        allParams[key] !== "" &&
        allParams[key] !== undefined &&
        allParams[key] !== null
      ) {
        counter++;
        if (counter === 1) finalParams += "?" + key + "=" + allParams[key];
        else finalParams += "&" + key + "=" + allParams[key];
      }
    });

    return finalParams;
  };

  useEffect(() => {
    const floatingBtn: HTMLElement = document.querySelector(
      "#floatingBtn"
    ) as HTMLElement;

    floatingBtn.style.opacity = "0";

    window.addEventListener("scroll", () => {
      // Show the button when scrolling down by a certain amount (e.g., 200 pixels)
      if (window.scrollY > 100 && window.scrollY < 2450) {
        // floatingBtn.style.display = 'flex';
        floatingBtn.style.opacity = "1";
      } else {
        // floatingBtn.style.display = 'none';
        floatingBtn.style.opacity = "0";
      }
    });
  }, []);

  useEffect(() => {
    var imageArray: any[];
    var imageArrayMobile: any[];

    if (currentLanguage === "ar") {
      imageArray = arabicBanners.desktop;
      imageArrayMobile = arabicBanners.phone;
    } else {
      imageArray = englishBanners.desktop;
      imageArrayMobile = englishBanners.phone;
    }

    var counter: number = 0;
    setMainImage(imageArray[counter]);
    setMainImage(imageArrayMobile[counter]);

    const timer = setInterval(() => {
      if (currentLanguage === "ar") {
        imageArray = arabicBanners.desktop;
        imageArrayMobile = arabicBanners.phone;
      } else {
        imageArray = englishBanners.desktop;
        imageArrayMobile = englishBanners.phone;
      }

      if (isMobile) {
        setMainImage(imageArrayMobile[counter]);
      } else {
        setMainImage(imageArray[counter]);
      }

      counter++;
      if (counter === 3) counter = 0;
    }, 1500);
  }, []);

  const isDefined = (value: any) => {
    return value !== undefined && value !== null;
  };

  useEffect(() => {
    dispatch(changeCurrentPageAction(5));
  }, []);

  useEffect(() => {
    dispatch(listCarBodiesAction());
    dispatch(listEngineCapacityAction());
    dispatch(listCarConditionAction());
    dispatch(listYearsAction());
    dispatch(listCategoriesAction());
    dispatch(listCarMakerAction());
    dispatch(
      listFeaturedPostsAction(`category_id=${selectedPostCategory.toString()}`)
    );
    dispatch(listArticlesAction());
    dispatch(listFuelTypesAction());
    dispatch(listColorsAction());
    dispatch(listFeaturesAction());
    dispatch(listCitiesAction());
    dispatch(listTransmissionTypesAction());
    dispatch(listStoriesAction(1));

    dispatch(listHomeScreenExhibitorsAction(true));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
      window.innerWidth >= 1440
        ? setPostCardIsMobile(false)
        : setPostCardIsMobile(true);
      window.innerWidth >= 1440
        ? setPostSectionIsMobile(false)
        : setPostSectionIsMobile(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
    if (window.innerWidth <= 1440) {
      setPostCardIsMobile(true);
    }
    if (window.innerWidth <= 1440) {
      setPostSectionIsMobile(true);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const [showAdvancedSearchModal, setShowAdvancedSearchModal] = useState(false);

  const handleMobileAdvancedSearchClick = () => {
    setAdvancedSearch(!advancedSearch);

    const topElement = document.getElementById("header");
    topElement?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <Modal
        open={noCreditModalOpen}
        onCancel={() => setNoCreditModalOpen(false)}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        className="max-w-[360px]"
      >
        <div className="w-full flex flex-col items-center gap-y-4">
          <p className="text-base font-bold">{t("not_enough_credits")}</p>
          <button
            style={{
              backgroundColor: "rgba(0, 80, 255, 0.1)",
            }}
            className="w-full h-[50px] rounded-[10px] flex items-center justify-center text-[var(--souq-blue)]"
            onClick={() => navigate(`/${currentLanguage}/store`)}
          >
            {t("buy_from_store")}
          </button>
          <button
            style={{
              backgroundColor: "rgba(0, 80, 255, 0.1)",
            }}
            className="w-full h-[50px] rounded-[10px] flex items-center justify-center text-black"
            onClick={() => setNoCreditModalOpen(false)}
          >
            {t("dismiss")}
          </button>
        </div>
      </Modal>
      {/* Contact Us Fixed Button */}
      <div
        onClick={() => {
          navigate(`/${currentLanguage}/contact-us`);
        }}
        className={`fixed ${
          isMobile ? "top-[70%]" : "top-[40%]"
        } left-0 bg-[var(--new-blue)] rotate-[180deg] flex items-center justify-center hover:cursor-pointer`}
        style={{
          width: isMobile ? "2.2rem" : "2.6875rem",
          height: isMobile ? "7rem" : "7.9375rem",
          zIndex: "99999",
        }}
      >
        <p className="text-white rotate-[-90deg] whitespace-nowrap">
          {t("give_suggestion")}
        </p>
      </div>
      {/* End Contact Us Fixed Button */}
      {showAdvancedSearchModal && (
        <CustomModal
          setCloseState={setShowAdvancedSearchModal}
          onClose={() => setShowAdvancedSearchModal(false)}
        >
          <div className="w-full flex flex-col items-center">
            <p className="mb-[2rem] text-[1.25rem] text-[var(--new-blue)] font-[700]">
              {t("homepage_advanced_search")}
            </p>
            <div
              dir="rtl"
              className="mt-3 w-full flex flex-row gap-x-2 items-center justify-center"
            >
              <div className="modal-advanced-search w-[33%]">
                {carMakersLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_car_maker")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carMakerDropdown}
                    onSelect={(value: any) => {
                      setCarMakerFilter(value);
                      dispatch(listCarModelsAction(value));
                    }}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full`}
                  />
                )}
              </div>
              <div className="modal-advanced-search w-[33%]">
                {carModelsLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_car_model")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carModelsDropdown}
                    onSelect={(value: any) => setCarModelFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full`}
                  />
                )}
              </div>
              <div className="modal-advanced-search w-[33%]">
                {carConditionLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_car_condition")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carConditionsDropdown}
                    onSelect={(value: any) => setCarConditionFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full`}
                  />
                )}
              </div>
            </div>
            <div
              dir="rtl"
              className="mt-7 w-full flex flex-row gap-x-2 items-center justify-center"
            >
              <div className="modal-advanced-search w-[33%]">
                {carBodiesLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_carbody")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carBodiesDropdown}
                    onSelect={(value: any) => setCarBodyFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full`}
                  />
                )}
              </div>
              <div className="modal-advanced-search w-[33%]">
                {engineCapactiyLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_cc")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={engineCapacityDropdown}
                    onSelect={(value: any) => setEngineCapacityFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full`}
                  />
                )}
              </div>
              <div className="modal-advanced-search w-[33%] flex flex-row gap-x-1">
                {yearsLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_year")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={yearsDropdown}
                    onSelect={(value: any) => setProductionYearFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full`}
                  />
                )}
              </div>
            </div>
            <div
              dir="rtl"
              className="mt-7 w-full flex flex-row gap-x-2 items-center justify-center"
            >
              <div className="modal-advanced-search w-[33%] flex flex-row gap-x-1">
                <Input
                  placeholder={t("price_from")}
                  onChange={(e) => setPriceFromFilter(e.target.value)}
                  size="large"
                  className="w-[50%]"
                />
                <Input
                  placeholder={t("price_to")}
                  onChange={(e) => setPriceToFilter(e.target.value)}
                  size="large"
                  className="w-[50%]"
                />
              </div>
              <div className="modal-advanced-search w-[33%] flex items-center justify-center">
                <Input
                  placeholder={t("homepage_distance")}
                  onChange={(e) => setDistanceFilter(e.target.value)}
                  size="large"
                  className="w-fulll"
                />
              </div>
              <div className="modal-advanced-search w-[33%] flex items-center justify-center">
                {yearsLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_year")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={yearsDropdown}
                    onSelect={(value: any) => setProductionYearFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full mb-[15px]`}
                  />
                )}
              </div>
            </div>
            <div
              dir="rtl"
              className="mt-3 w-full flex flex-row gap-x-2 items-center justify-center"
            >
              <div className="modal-advanced-search w-[33%]">
                {citiesLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_governate")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={citiesDropdown}
                    onSelect={(value: any) => setCitiesFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full`}
                  />
                )}
              </div>
              <div className="modal-advanced-search w-[33%]">
                {transmissionTypesLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("transmission")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={transmissionTypesDropdown}
                    onSelect={(value: any) => setTransmissionTypeFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full`}
                  />
                )}
              </div>
              <div className="modal-advanced-search w-[33%]">
                {colorsLoading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_color")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={colorsDropdown}
                    onSelect={(value: any) => setColorFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-full`}
                  />
                )}
              </div>
            </div>

            <a href={`/${currentLanguage}/ads/view${getSearchFilterURL()}`}>
              <CustomButton
                className={
                  "mt-[3rem] w-[23rem] h-[3.125rem] rounded-[0.9375rem]"
                }
                color={"var(--new-blue)"}
                onClick={() => {
                  //advanced search
                  // navigate(`/${currentLanguage}/ads/view${getSearchFilterURL()}`);
                }}
              >
                <p className="text-white text-[1.125rem]">{t("submit")}</p>
              </CustomButton>
            </a>
          </div>
        </CustomModal>
      )}
      <Seo
        title="سوق السيارات - سوق كار اكبر سوق بيع وشراء سيارات مستعملة وجديدة للبيع في مصر"
        description="سوق السيارات - سوق كار اكبر سوق بيع وشراء سيارات مستعملة وجديدة للبيع في مصر. بيع و شراء عربيات في مصر باسرع وقت ممكن"
        keywords="سوق السيارات،سوق سيارات،سوق السيارات المستعملة،سوق العربيات المستعملة،سوق السيارات في مصر،سوق السيارات المستعملة في مصر،سوق كار، كار للسيارات"
        thumbnailImage={souqCarLogo}
        thumbnailDescription="سوق السيارات - سوق كار سوق بيع وشراء السيارات الاسرع في مصر"
      />
      <div
        id={"floatingBtn"}
        dir="rtl"
        className={`${styles.floatingCreateButton} ${
          isMobile && "h-[2.6875rem]"
        } flex flex-row items-center gap-x-2`}
        onClick={() => {
          if (userData?.user && userData?.user.available_posts < 1) {
            setNoCreditModalOpen(true);
            return;
          }
          navigate(`posts/create`);
        }}
        style={
          isMobile
            ? {
                opacity: "100%",
                visibility: advancedSearch ? "hidden" : "visible",
                // zIndex: "99999999999999999999"
              }
            : {}
        }
      >
        <div
          style={{
            width: "1.5rem",
            height: "1.5rem",
          }}
        >
          <img src={plusIcon} alt="create-post" />
        </div>
        <p className="text-[0.9375rem]">{t("navbar_create_post_text")}</p>
      </div>
      {/* Search Navbar */}
      {!isMobile && (
        <div
          className={`w-full h-[80px] flex flex-row items-center justify-center gap-x-3 bg-white ${styles.searchNavbar}`}
        >
          <div className="contentWidth flex flex-row items-center gap-x-3 justify-end">
            <CustomButton
              onClick={() => {
                setShowSearchBar((prevState) => !prevState);
              }}
              className={`rounded-[8px] border-[var(--new-blue)] border-[1px] w-[62px] h-[52px]`}
            >
              <p>
                {showSearchBar
                  ? t("homepage_fast_search")
                  : t("homepage_search_bar")}
              </p>
            </CustomButton>
            <CustomButton
              className={`rounded-[8px] border-[var(--new-blue)] border-[1px] w-[62px] h-[52px]`}
              onClick={() => setShowAdvancedSearchModal(true)}
            >
              <p>{t("homepage_advanced_search")}</p>
            </CustomButton>
            <div className="border-[0.5px] border-[black] opacity-[0.2] h-12"></div>
            <a
              href={
                showSearchBar
                  ? `/${currentLanguage}/ads/view?category_id=${selectedSearchCategory}&title=${titleSearch}`
                  : `/${currentLanguage}/ads/view${getSearchFilterURL()}`
              }
            >
              <CustomButton
                className={`rounded-[8px] w-[83px] h-[52px]`}
                color={"var(--new-blue)"}
                onClick={() => {
                  // if (showSearchBar)
                  //   navigate(
                  //     `/${currentLanguage}/ads/view?category_id=${selectedSearchCategory}&title=${titleSearch}`
                  //   );
                  // else
                  //   navigate(
                  //     `/${currentLanguage}/ads/view${getSearchFilterURL()}`
                  //   );
                }}
              >
                <SearchOutlined className="text-white font-bold text-xl" />
              </CustomButton>
            </a>

            {/* Search Bar Section */}
            {showSearchBar && (
              <div className="homepage-navbar-search-bar w-[40%]">
                <Input
                  value={titleSearch}
                  onChange={(e) => {
                    setTitleSearch(e.target.value);
                  }}
                  size="large"
                  placeholder="أبحث بالماركة ، الموديل ، السنة"
                  className="w-full text-right"
                />
              </div>
            )}
            {/* End Search Bar Section */}

            {!showSearchBar && carConditionLoading ? (
              <Spin />
            ) : (
              !showSearchBar && (
                <div className="navbar-dropdown">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    suffixIcon={<BlackDownArrow />}
                    placeholder={t("homepage_car_condition")}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carConditionsDropdown}
                    onSelect={(value: any) => setCarConditionFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-[121px] mb-[12px]`}
                  />
                </div>
              )
            )}
            {!showSearchBar && yearsLoading ? (
              <Spin />
            ) : (
              !showSearchBar && (
                <div className="navbar-dropdown">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_year")}
                    suffixIcon={<BlackDownArrow />}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={yearsDropdown}
                    onSelect={(value: any) => setProductionYearFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-[121px] mb-[12px]`}
                  />
                </div>
              )
            )}
            {!showSearchBar && carModelsLoading ? (
              <Spin />
            ) : (
              !showSearchBar && (
                <div className="navbar-dropdown">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_car_model")}
                    suffixIcon={<BlackDownArrow />}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carModelsDropdown}
                    onSelect={(value: any) => setCarModelFilter(value)}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-[121px] mb-[12px]`}
                  />
                </div>
              )
            )}
            {!showSearchBar && carMakersLoading ? (
              <Spin />
            ) : (
              !showSearchBar && (
                <div className="navbar-dropdown">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("homepage_car_maker")}
                    suffixIcon={<BlackDownArrow />}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carMakerDropdown}
                    onSelect={(value: any) => {
                      setCarMakerFilter(value);
                      dispatch(listCarModelsAction(value));
                    }}
                    size="large"
                    style={currentLanguage === "ar" ? { direction: "rtl" } : {}}
                    dropdownStyle={
                      currentLanguage === "ar" ? { direction: "rtl" } : {}
                    }
                    className={`${
                      showSearchField
                        ? styles.hideSearchField
                        : styles.searchField
                    } w-[121px] mb-[12px]`}
                  />
                </div>
              )
            )}
            <div className="border-[0.5px] border-[black] opacity-[0.2] h-12"></div>
            <CustomButton
              className={`${
                selectedSearchCategory === 1
                  ? "border-[var(--new-blue)] border-[1px]"
                  : ""
              } rounded-[8px] w-[62px] h-[52px]`}
              color={"rgba(0, 80, 255, 0.1)"}
              onClick={() => setSelectedSearchCategory(1)}
              textStyling={
                selectedSearchCategory === 1
                  ? "text-[var(--new-blue)]"
                  : "text-black"
              }
            >
              <p>{t("navbar_buy_text")}</p>
            </CustomButton>
            <CustomButton
              className={`${
                selectedSearchCategory === 2
                  ? "border-[var(--new-blue)] border-[1px]"
                  : ""
              } rounded-[8px] w-[62px] h-[52px]`}
              color={"rgba(0, 80, 255, 0.1)"}
              onClick={() => setSelectedSearchCategory(2)}
              textStyling={
                selectedSearchCategory === 2
                  ? "text-[var(--new-blue)]"
                  : "text-black"
              }
            >
              <p>{t("navbar_rental_text")}</p>
            </CustomButton>
          </div>
        </div>
      )}
      {/* End Search Navbar */}

      <div className={`w-full flex justify-center`}>
        <div
          id="top-section"
          className={`contentWidth flex flex-col items-center ${
            styles.topSection
          } px-[${!isMobile ? "" : "0.5rem"}]`}
          // style={{
          //   paddingInline: isMobile
          //     ? "0.5rem"
          //     : isMax1100
          //     ? "3rem"
          //     : isMax1330
          //     ? "10rem"
          //     : "16rem",
          // }}
        >
          <div
            className={`${
              !isMobile ? styles.topSectionContainer : ""
            } w-full flex flex-col items-center ${isMobile ? "mb-8" : "mb-8"}`}
          >
            <div
              className={`${styles.mainBanner} w-full flex items-center justify-center`}
            >
              <BannersCarousel />
            </div>

            {/* Categories List */}
            {!isMobile && (
              <div className="py-5 w-full flex flex-row items-center justify-between gap-x-4">
                {extraCategories.map((item: any) => {
                  return (
                    <a
                      href={`/${currentLanguage}/${item.route}`}
                      onClick={() => {
                        // navigate(`/${currentLanguage}/${item.route}`);
                      }}
                      className={`${styles.shadow} ${styles.categoriesCard} w-full h-[143px] rounded-[20px] bg-white flex flex-col gap-y-3 items-center justify-center`}
                    >
                      <img src={item.icon} />
                      <p>{item.name}</p>
                    </a>
                  );
                })}
                {categoriesLoading ? (
                  <Spin />
                ) : (
                  categories
                    ?.filter((item: any) => excludedCategories(item))
                    ?.map((item: any) => {
                      return (
                        <a
                          href={
                            item?.id === 6
                              ? `/${currentLanguage}/News/view`
                              : `/${currentLanguage}/ads/view?category_id=${item?.id}`
                          }
                          onClick={() => {
                            // if (item?.id === 6) {
                            //   navigate(`/${currentLanguage}/News/view`);
                            // } else {
                            //   navigate(
                            //     `/${currentLanguage}/ads/view?category_id=${item?.id}`
                            //   );
                            // }
                          }}
                          className={`${styles.shadow} ${styles.categoriesCard} ${item.id} w-full h-[143px] rounded-[20px] bg-white flex flex-col gap-y-3 items-center justify-center`}
                        >
                          {item.id === 1 && (
                            <img
                              src={BuySellIcon}
                              className="w-[43px] h-[43px]"
                            />
                          )}
                          {item.id === 2 && (
                            <img
                              src={RentCarsIcon}
                              className="w-[43px] h-[43px]"
                            />
                          )}
                          {item.id === 4 && (
                            <img
                              src={SparePartsIcon}
                              className="w-[43px] h-[43px]"
                            />
                          )}
                          {item.id === 6 && (
                            <img
                              src={item.image}
                              className="w-[43px] h-[43px]"
                            />
                          )}
                          <p>{item?.name}</p>
                        </a>
                      );
                    })
                    ?.reverse()
                )}
              </div>
            )}
            {/* End Categories List */}

            {/* Desktop Exhibitions List */}
            {!isMobile && <ExhibitorList whiteVariant />}
            {/* End Desktop Exhibitions List */}

            {/* Desktop Stories Section */}
            {!isMobile && <StoriesList />}
            {/* End Desktop Stories Section */}

            {/* Mobile Basic Search */}
            {isMobile && (
              <div
                className={`mt-3 w-full flex flex-col items-center h-[12rem] rounded-[0.625rem] bg-white ${styles.shadow}`}
              >
                <div className="w-full pt-2 flex flex-row items-center justify-center gap-x-2">
                  <CustomButton
                    className={`rounded-[0.3125rem] w-[47%] h-[1.875rem] py-2`}
                    color={
                      selectedSearchCategory === 1
                        ? "var(--new-blue)"
                        : "rgba(0, 80, 255, 0.1)"
                    }
                    onClick={() => setSelectedSearchCategory(1)}
                    textStyling={
                      selectedSearchCategory === 1 ? "text-white" : "text-black"
                    }
                  >
                    <p className="text-[0.75rem]">{t("navbar_buy_text")}</p>
                  </CustomButton>
                  <CustomButton
                    className={`rounded-[0.3125rem] w-[47%] h-[1.875rem]`}
                    color={
                      selectedSearchCategory === 2
                        ? "var(--new-blue)"
                        : "rgba(0, 80, 255, 0.1)"
                    }
                    onClick={() => setSelectedSearchCategory(2)}
                    textStyling={
                      selectedSearchCategory === 2 ? "text-white" : "text-black"
                    }
                  >
                    <p className="text-[0.75rem]">{t("navbar_rental_text")}</p>
                  </CustomButton>
                </div>

                <div className="mt-2 h-[0.5px] bg-black w-[95%] opacity-[0.1]"></div>

                <div
                  dir="rtl"
                  className="mt-2 w-full flex flex-row items-center justify-center gap-x-2"
                >
                  {carMakersLoading ? (
                    <Spin />
                  ) : (
                    <div className="homescreen-mobile-basic-search w-[46%]">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={t("homepage_car_maker")}
                        suffixIcon={<BlackDownArrow />}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase()) ||
                          (option?.label_other ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={carMakerDropdown}
                        onSelect={(value: any) => {
                          setCarMakerFilter(value);
                          dispatch(listCarModelsAction(value));
                        }}
                        size="large"
                        style={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        dropdownStyle={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        className={`${
                          showSearchField
                            ? styles.hideSearchField
                            : styles.searchField
                        } w-full`}
                      />
                    </div>
                  )}
                  {carModelsLoading ? (
                    <Spin />
                  ) : (
                    <div className="homescreen-mobile-basic-search w-[46%]">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={t("homepage_car_model")}
                        suffixIcon={<BlackDownArrow />}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase()) ||
                          (option?.label_other ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={carModelsDropdown}
                        onSelect={(value: any) => setCarModelFilter(value)}
                        size="large"
                        style={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        dropdownStyle={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        className={`${
                          showSearchField
                            ? styles.hideSearchField
                            : styles.searchField
                        } w-full`}
                      />
                    </div>
                  )}
                </div>

                <div className="mt-2 w-full flex flex-row items-center justify-center gap-x-2">
                  {yearsLoading ? (
                    <Spin />
                  ) : (
                    <div className="homescreen-mobile-basic-search w-[46%]">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={t("homepage_year")}
                        suffixIcon={<BlackDownArrow />}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={yearsDropdown}
                        onSelect={(value: any) =>
                          setProductionYearFilter(value)
                        }
                        size="large"
                        style={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        dropdownStyle={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        className={`${
                          showSearchField
                            ? styles.hideSearchField
                            : styles.searchField
                        } w-full`}
                      />
                    </div>
                  )}
                  {carConditionLoading ? (
                    <Spin />
                  ) : (
                    <div className="homescreen-mobile-basic-search w-[46%]">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={t("homepage_car_condition")}
                        suffixIcon={<BlackDownArrow />}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={carConditionsDropdown}
                        onSelect={(value: any) => setCarConditionFilter(value)}
                        size="large"
                        style={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        dropdownStyle={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        className={`${
                          showSearchField
                            ? styles.hideSearchField
                            : styles.searchField
                        } w-full`}
                      />
                    </div>
                  )}
                </div>

                <div className="mt-2 w-full flex flex-row gap-x-2 items-center justify-center">
                  <CustomButton
                    className={`rounded-[0.3125rem] w-[20%] h-[2.1rem]`}
                    color={"var(--new-blue)"}
                    onClick={() => {
                      setShowBottomModalSheet(true);
                    }}
                  >
                    <p className="text-white text-[0.625rem]">
                      {t("homepage_advanced_search")}
                    </p>
                  </CustomButton>
                  <a
                    href={`/${currentLanguage}/ads/view${getSearchFilterURL()}`}
                    className="rounded-[0.3125rem] w-[72%] h-[2.1rem]"
                  >
                    <CustomButton
                      className={`rounded-[0.3125rem] w-full h-full`}
                      color={"var(--new-blue)"}
                      onClick={() => {
                        // navigate(
                        //   `/${currentLanguage}/ads/view${getSearchFilterURL()}`
                        // );
                      }}
                    >
                      <SearchOutlined className="text-white font-bold text-xl" />
                    </CustomButton>
                  </a>
                </div>
                <AdvancedSearchSheet
                  isSpecial={false}
                  title={t("search_filters")}
                  isOpen={showBottomModalSheet}
                  setOpen={setShowBottomModalSheet}
                  carCondition={carCondition}
                  carConditionFilter={carConditionFilter}
                  setCarConditionFilter={setCarConditionFilter}
                  setCarCondition={setCarCondition}
                  carMakerFilter={carMakerFilter}
                  setCarMakerFilter={setCarMakerFilter}
                  carModelFilter={carModelFilter}
                  setCarModelFilter={setCarModelFilter}
                  productionYearFilter={yearFromFilter}
                  setProductionYearFilter={setYearFromFilter}
                  priceFromFilter={priceFromFilter}
                  setPriceFromFilter={setPriceFromFilter}
                  priceToFilter={priceToFilter}
                  setPriceToFilter={setPriceToFilter}
                  // distanceFilter={Filter}
                  // setDistanceFilter={setDistanceFilter}
                  engineCapacityFilter={engineCapacityFilter}
                  setEngineCapacityFilter={setEngineCapacityFilter}
                  carBodyFilter={carBodyFilter}
                  setCarBodyFilter={setCarBodyFilter}
                  citiesFilter={citiesFilter}
                  setCitiesFilter={setCitiesFilter}
                  fuelTypeFilter={fuelTypeFilter}
                  setFuelTypeFilter={setFuelTypeFilter}
                  transmissionTypeFilter={transmissionTypeFilter}
                  setTransmissionTypeFilter={setTransmissionTypeFilter}
                  colorFilter={colorFilter}
                  setColorFilter={setColorFilter}
                  // selectedSearchCategory={selectedSearchCategory}
                  // setSelectedSearchCategory={setSelectedSearchCategory}
                />
              </div>
            )}
            {/* End Mobile Basic Search */}

            {/* Mobile Categories List */}
            {isMobile && (
              <div className="mt-3 w-full flex flex-row items-center justify-center gap-y-2 flex-wrap gap-x-2 ">
                {categoriesLoading ? (
                  <Spin />
                ) : (
                  categories
                    ?.filter(
                      (item: any) =>
                        item?.id !== 5 &&
                        item?.id !== 6 &&
                        item.id !== 4 &&
                        item.id !== 3
                    )
                    ?.map((item: any) => {
                      return (
                        <a
                          href={`/${currentLanguage}/ads/view?category_id=${item.id}`}
                          onClick={() => {
                            // navigate(
                            //   `/${currentLanguage}/ads/view?category_id=${item.id}`
                            // );
                          }}
                          className={`w-[48%] h-[2.85rem] rounded-[0.625rem] bg-white flex flex-row items-center justify-center gap-x-3 ${styles.shadow} hover:cursor-pointer`}
                        >
                          <p>{item?.name}</p>
                          <img
                            className="w-[1.35rem] h-[1.35rem]"
                            src={item?.image}
                          />
                        </a>
                      );
                    })
                )}
                <a
                  href={`/${currentLanguage}/exhibitions`}
                  onClick={() => {
                    // navigate(`/${currentLanguage}/exhibitions`);
                  }}
                  className={`w-[48%] h-[2.85rem] rounded-[0.625rem] bg-white flex flex-row items-center justify-center gap-x-3 ${styles.shadow} hover:cursor-pointer`}
                >
                  <p>{t("the_exhibitions")}</p>
                  <img
                    className="w-[1.35rem] h-[1.35rem]"
                    src={ExhibitionsCategoryIcon}
                  />
                </a>
                <div
                  onClick={() => {
                    navigate(`/${currentLanguage}/car-insurance`);
                  }}
                  className={`w-[48%] h-[2.85rem] rounded-[0.625rem] bg-white flex flex-row items-center justify-center gap-x-3 ${styles.shadow} hover:cursor-pointer`}
                >
                  <p>{t("navbar_insurance_text")}</p>
                  <img
                    className="w-[1.35rem] h-[1.35rem]"
                    src={FreeInspectionIcon}
                  />
                </div>
              </div>
            )}
            {/* End Mobile Categories List */}
          </div>

          {/* Mobile Exhibitions List */}
          {isMobile && <ExhibitorList />}
          {/* End Mobile Exhibitions List */}

          {/* Mobile Stories Section */}
          {isMobile && <StoriesList scaleDown />}
          {/* End Mobile Stories Section */}

          {/* Featured Ads Mobile */}
          {isMobile && (
            <div className="w-full flex flex-col gap-y-3">
              <div className="w-full flex flex-row items-center justify-between">
                <a
                  href={`/${currentLanguage}/special-ads?category_id=1`}
                  className="text-[var(--souq-orange)] hover:cursor-pointer text-[0.75rem]"
                >
                  {t("more")}
                </a>
                <p className="text-[var(--souq-orange)] text-[0.9375rem]">
                  {t("homepage_special_ads")}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-center">
                {categoriesLoading ? (
                  <Spin />
                ) : (
                  categories
                    ?.filter(
                      (item: any) =>
                        item?.id !== 5 &&
                        item?.id !== 3 &&
                        item?.id !== 6 &&
                        item?.id !== 4
                    )
                    ?.map((item: any) => {
                      return (
                        <div
                          onClick={() => {
                            setSelectedPostCategory(item?.id);
                            dispatch(
                              listFeaturedPostsAction(`category_id=${item.id}`)
                            );
                          }}
                          className={`w-[5.5rem] h-[1.8125rem] flex items-center justify-center rounded-[1.125rem] ${
                            selectedPostCategory === item?.id
                              ? "bg-[var(--souq-orange)]"
                              : ""
                          }`}
                        >
                          <p
                            className={`${
                              selectedPostCategory === item?.id
                                ? "text-white"
                                : "text-black"
                            }`}
                          >
                            {item?.name}
                          </p>
                        </div>
                      );
                    })
                    ?.reverse()
                )}
              </div>
            </div>
          )}

          {isMobile && (
            <div className="mt-3 w-full mobile-featured-ads-section">
              {featuredPosts?.length === 0 && (
                <div className="w-full flex flex-col items-center justify-center">
                  <Empty description={t("no_posts")} />
                </div>
              )}
              <Spin
                spinning={featuredPostsLoading}
                style={{
                  width: "100%",
                }}
              >
                <div className="w-full flex flex-col items-center justify-center gap-y-3">
                  {featuredPosts?.map((item: any, idx: number) => {
                    if (idx < 8)
                      return (
                        <NewCustomPostCard
                          key={idx}
                          isMobile={isMobile}
                          id={item.uuid}
                          isSpecialAd={true}
                          showActions={false}
                          width={"w-full"}
                          category={item.category}
                          customerName={item.customer}
                          brandLogo={item.brand_logo}
                          isFavorited={item.favourited}
                          // className={`${styles.widePostCard}`}
                          callsCount={item.contact_count}
                          viewsCount={item.views_count}
                          uploadDate={item.created_at?.split("T")[0]}
                          postTitle={item.title}
                          city={item.city}
                          price={
                            isDefined(item.down_payment)
                              ? item.down_payment
                              : item.price
                          }
                          carCondition={item.car_condition}
                          productionDate={item.production_date}
                          postDescription={item.description}
                          kilometrage={item.kilo_meterage}
                          carMaker={item.brand}
                          image={item.main_image}
                          paymentOption={item.payment_type}
                          created_at={item.created_at}
                          address={item.address}
                        />
                      );
                  })}
                </div>
              </Spin>
            </div>
          )}

          {/* End Featured Ads Mobile */}

          {/* Featured Ads Desktop */}

          {!isMobile && (
            <div
              className={`${
                styles.postsContainer
              } w-full flex flex-col items-center ${
                postSectionIsMobile ? styles.mobilePostsContainer : ""
              }`}
              style={{
                height: !isMax1440 ? "70rem" : "fit-content",
              }}
            >
              <div
                dir={textDirectionStyle}
                className={`w-full flex flex-row items-center justify-between`}
              >
                <p className="w-[25%] text-[var(--souq-orange)] text-[20px]">
                  {t("special_ads")}
                </p>

                <div
                  className={`h-12 p-2 flex ${
                    currentLanguage === "en" ? "flex-row" : "flex-row-reverse"
                  } items-center justify-between rounded-full`}
                >
                  {currentLanguage === "en"
                    ? categories?.map((item: any, idx: any) => {
                        if (
                          item.id !== 5 &&
                          item.id !== 6 &&
                          item.id !== 3 &&
                          item.id !== 4
                        ) {
                          return (
                            <div
                              key={idx}
                              className={`${
                                selectedPostCategory === item.id
                                  ? styles.selectedPostCategory
                                  : ""
                              } p-3 px-7 rounded-full flex flex-row flex-nowrap items-center ${
                                styles.postCategoryButton
                              } lg:px-6 lg:p-3`}
                              onClick={() => {
                                setSelectedPostCategory(item.id);
                                dispatch(
                                  listFeaturedPostsAction(
                                    `category_id=${item.id}`
                                  )
                                );
                              }}
                            >
                              <p className="lg:text-lg">{item.name}</p>
                            </div>
                          );
                        }
                      })
                    : categories?.map((item: any, idx: any) => {
                        if (
                          item.id !== 5 &&
                          item.id !== 6 &&
                          item.id !== 3 &&
                          item.id !== 4
                        ) {
                          return (
                            <div
                              key={idx}
                              className={`${
                                selectedPostCategory === item.id
                                  ? styles.selectedPostCategory
                                  : ""
                              } p-3 px-7 rounded-full flex flex-row flex-nowrap items-center ${
                                item.id
                              } ${styles.postCategoryButton} lg:px-6 lg:p-3`}
                              onClick={() => {
                                setSelectedPostCategory(item.id);
                                dispatch(
                                  listFeaturedPostsAction(
                                    `category_id=${item.id}`
                                  )
                                );
                              }}
                            >
                              <p
                                className="flex-nowrap"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {item.name}
                              </p>
                            </div>
                          );
                        }
                      })}
                </div>

                <a
                  href={`/${currentLanguage}/special-ads?category_id=1`}
                  onClick={() => {
                    // navigate(`/${currentLanguage}/special-ads?category_id=1`);
                  }}
                  className="w-[25%] text-[var(--souq-orange)] text-end hover:cursor-pointer"
                >
                  {t("more")}
                </a>
              </div>
              {postCardIsMobile ? (
                <div
                  className={`mt-2 w-full flex flex-row justify-center items-center ${
                    isMobile ? "" : "flex-wrap"
                  } gap-x-0`}
                >
                  {postCardIsMobile &&
                    (featuredPostsLoading ? (
                      <Spin />
                    ) : featuredPosts?.length === 0 ? (
                      <p className="text-lg text-black">No Posts</p>
                    ) : (
                      <PostCardCarousel data={featuredPosts} />
                    ))}
                </div>
              ) : (
                // <HomeCarousel />
                <div
                  className={`mt-2 w-full flex flex-row justify-center items-center ${
                    isMobile ? "" : "flex-wrap"
                  } gap-x-0`}
                >
                  {/* className="w-full flex flex-row justify-center items-center gap-x-4" */}
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <CustomCarousel
                        slidesToShow={3}
                        vertical={true}
                        arrows={false}
                        postCardData={featuredPosts?.slice(0, 4)}
                        width={"w-[100%]"}
                        postCardWidth={"w-full"}
                        imageWidth={"w-full"}
                        postCardStyle={{ marginBlock: "0.5rem" }}
                        cardTextStyle={"font-bold text-xl text-center"}
                        isPostCard={true}
                        isMobile={false}
                        onHover={() => {
                          setIsAutoPlay(false);
                        }}
                        onExit={() => {
                          setIsAutoPlay(true);
                        }}
                        hasAutoPlay={isAutoPlay}
                      />
                      <CustomCarousel
                        slidesToShow={3}
                        vertical={true}
                        arrows={false}
                        width={"w-[100%]"}
                        postCardData={featuredPosts?.slice(5, 9)}
                        postCardWidth={"w-full"}
                        imageWidth={"w-full"}
                        postCardStyle={{ marginBlock: "0.5rem" }}
                        cardTextStyle={"font-bold text-xl text-center"}
                        isPostCard={true}
                        isMobile={false}
                        onHover={() => {
                          setIsAutoPlay(false);
                        }}
                        onExit={() => {
                          setIsAutoPlay(true);
                        }}
                        hasAutoPlay={isAutoPlay}
                      />
                      <CustomCarousel
                        slidesToShow={3}
                        vertical={true}
                        arrows={false}
                        width={"w-[100%]"}
                        postCardData={featuredPosts?.slice(10, 14)}
                        postCardStyle={{ marginBlock: "0.5rem" }}
                        postCardWidth={"w-full"}
                        imageWidth={"w-full"}
                        cardTextStyle={"font-bold text-xl text-center"}
                        isPostCard={true}
                        isMobile={false}
                        onHover={() => {
                          setIsAutoPlay(false);
                        }}
                        onExit={() => {
                          setIsAutoPlay(true);
                        }}
                        hasAutoPlay={isAutoPlay}
                      />
                    </>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* End Featured Ads Desktop */}

          {/* Car Bodies And Maker Mobile */}
          {isMobile && (
            <div
              dir={currentLanguage === "ar" ? "rtl" : "ltr"}
              className="mt-3 w-full flex flex-col gap-y-2 items-start"
            >
              <p className="text-[1rem]">{t("search_by_brands")}</p>
              {carMakersLoading ? (
                <Spin />
              ) : (
                <CarMakerCarousel data={carMakers} isMobile={isMobile} />
              )}
            </div>
          )}
          {isMobile && (
            <div
              dir={currentLanguage === "ar" ? "rtl" : "ltr"}
              className="mt-6 mb-5 w-full flex flex-col gap-y-2 items-start"
            >
              <p className="text-[1rem]">{t("search_by_bodies")}</p>
              {carBodiesLoading ? (
                <Spin />
              ) : (
                <CarBodyCarousel data={carBodies} isMobile={isMobile} />
              )}
            </div>
          )}
          {/* End Car Bodies And Maker Mobile */}

          {/* Car Bodies And Maker Desktop */}

          {!isMobile && (
            <div
              dir={currentLanguage === "ar" ? "rtl" : "ltr"}
              className="w-full flex flex-col gap-y-3 items-start"
            >
              <p className="text-[1.25rem]">{t("search_by_brands")}</p>
              {carMakersLoading ? (
                <Spin />
              ) : (
                <CarMakerCarousel data={carMakers} isMobile={isMobile} />
              )}
            </div>
          )}

          {!isMobile && (
            <div
              dir={currentLanguage === "ar" ? "rtl" : "ltr"}
              className="w-full flex flex-col gap-y-3 items-start"
            >
              <p className="text-[1.25rem]">{t("search_by_bodies")}</p>
              {carBodiesLoading ? (
                <Spin />
              ) : (
                <CarBodyCarousel data={carBodies} isMobile={isMobile} />
              )}
            </div>
          )}

          {/* End Car Bodies And Maker Desktop */}

          {/* Articles Mobile */}
          {isMobile && (
            <div
              dir={currentLanguage === "ar" ? "rtl" : "ltr"}
              className="mt-3 w-full flex flex-col gap-y-3 items-start"
            >
              <div className="w-full flex flex-row items-center justify-between">
                <p className="text-[1.25rem]">
                  {t("homepage_latest_articles")}
                </p>
                <p
                  className="text-[15px] hover:cursor-pointer"
                  onClick={() => {
                    navigate(`/${currentLanguage}/News/view`);
                  }}
                >
                  {t("homepage_all_articles")}
                </p>
              </div>
              <div className="flex flex-row px-3 py-3 overflow-x-auto gap-x-3 mb-5 max-w-full">
                {articlesLoading ? (
                  <Spin />
                ) : (
                  articles?.map((item: any) => {
                    return (
                      <CustomArticleCard
                        id={item?.id}
                        title={item?.title}
                        description={item?.description}
                        image={item?.image}
                        createdAt={item?.created_at}
                      />
                    );
                  })
                )}
              </div>
            </div>
          )}
          {/* End Articles Mobile */}

          {/* Articles Desktop */}

          {!isMobile && (
            <div
              dir={currentLanguage === "ar" ? "rtl" : "ltr"}
              className="mt-3 w-full flex flex-col gap-y-3 items-start"
            >
              <div className="w-full flex flex-row items-center justify-between">
                <p className="text-[1.25rem]">
                  {t("homepage_latest_articles")}
                </p>
                <p
                  className="text-[15px] hover:cursor-pointer"
                  onClick={() => {
                    navigate(`/${currentLanguage}/News/view`);
                  }}
                >
                  {t("homepage_all_articles")}
                </p>
              </div>
              <div className="flex flex-row gap-3 py-3 mb-5 max-w-full">
                {articlesLoading ? (
                  <Spin />
                ) : (
                  articles?.slice(0, 3)?.map((item: any) => {
                    return (
                      <CustomArticleCard
                        id={item?.id}
                        title={item?.title}
                        description={item?.description}
                        image={item?.image}
                        createdAt={item?.created_at}
                      />
                    );
                  })
                )}
              </div>
            </div>
          )}

          {/* End Articles Desktop */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomeScreen;
