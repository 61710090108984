import styles from "components/V3/CreatePostForms/CommonFormSections/PhotoSection/PhotoSection.module.css";
import CustomImageContainer from "components/CustomImageContainer/CustomImageContainer";
import { useLanguage } from "hooks/useLanguage";
import { useUtilities } from "hooks/useUtilities";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  CheckOutlined,
  ArrowRightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useForm } from "hooks/useForm";
import FormButtons from "../components/FormButtons";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { motion } from "framer-motion";
import { set } from "lodash";

interface PhotoSectionProps {
  formik: any;
}

const PhotoSection: FC<PhotoSectionProps> = ({ formik }) => {
  const { t } = useUtilities();
  const { isArabic } = useLanguage();
  const dir = isArabic ? "rtl" : "ltr";
  const dirFlip = !isArabic ? "rtl" : "ltr";

  const [fileList, setFileList] = useState<any[]>(formik.values.postMedias);
  const [postMediasUpload, setPostMediasUpload] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [mainCoverMessage, setMainCoverMessage] = useState(false);
  const [coverPhotoIdx, setCoverPhotoIdx] = useState<any>(
    formik.values.postMedias?.find(
      (file: any) => file?.media_url === formik.values.main_image
    )
  );
  const { textDirectionStyle } = useLanguage();

  // file?.media_url === formik.values.main_image.media_url

  const { updatePostLoading } = useSelector(
    (state: RootState) => state.updatePostReducer
  );

  function selectFiles() {
    fileInputRef.current?.click();
  }

  const handleDeleteImage = (file: any, idx: any) => {
    setPostMediasUpload((oldFiles: any) =>
      oldFiles.filter((item: any, id: any) => id !== idx)
    );
    setFileList((oldFiles: any) =>
      oldFiles.filter((item: any, id: any) => item !== file)
    );
    if (file === coverPhotoIdx) {
      setCoverPhotoIdx(undefined);
      setMainCoverMessage(true);
      formik.setFieldValue("main_image", undefined);
    }
  };

  useEffect(() => {
    formik.setFieldValue("postMedias", fileList);
  }, [fileList]);

  const makeCoverPhoto = (inputFile: any) => {
    setMainCoverMessage(false);
    setCoverPhotoIdx(inputFile);
    const postMediasCopy = [...fileList];
    const coverPhotoFile = postMediasCopy.filter(
      (file: any, id: any) => file?.media_url === inputFile?.media_url
    );

    formik.setFieldValue("main_image", coverPhotoFile[0]);
  };

  const handleFileListUpdate = (event: any) => {
    const files = event.target.files;

    if (files.length == 0) return;

    for (let i = 0; i < files.length; i++) {
      if (!fileList.some((e: any) => e.name == files[i].name)) {
        setFileList((prevFileList: any[]) => [
          ...prevFileList,
          {
            media_url: URL.createObjectURL(files[i]),
            file: files[i],
          },
        ]);
      }
    }
    formik.setFieldValue("postMedias", fileList);
  };

  const handleFileUpload = (event: any) => {
    const files = event.target.files;

    if (files.length == 0) return;

    for (let i = 0; i < files.length; i++) {
      if (!fileList.some((e: any) => e.name == files[i].name)) {
        setPostMediasUpload((prevMedias: any[]) => [...prevMedias, files[i]]);
      }
    }
    // formik.setFieldValue("postMedias", postMediasUpload);
  };

  useEffect(() => {}, []);

  const isInitialMount = useRef(true);

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //
  //
  //
  //     formik.setFieldValue("postMedias", postMediasUpload);
  //   }
  // }, [postMediasUpload]);

  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      dir={dirFlip}
      className="w-full flex flex-col items-center"
    >
      <p className="font-bold text-xl">{t("car_photos")}</p>
      {/* <p className="font-bold">{t("upload_10")}</p> */}

      <input
        id="file"
        type="file"
        onChange={(event: any) => {
          handleFileListUpdate(event);
          // handleFileUpload(event);
        }}
        ref={fileInputRef}
        accept="image/*"
        multiple
      />

      {/* ALL FILES */}
      <div
        dir={dir}
        className={`mt-6 mb-[1.42rem] w-full flex flex-wrap gap-[0.7rem]
            ${fileList?.length === 0 ? "justify-center" : ""}`}
      >
        {Array.isArray(fileList) &&
          fileList?.map((file: any, idx: any) => {
            if (file) {
              const isCover = formik.values.main_image
                ? file?.media_url === formik.values.main_image?.media_url ||
                  file?.media_url === formik.values.main_image
                : false;

              return (
                <CustomImageContainer
                  deleteButtonOnClick={() => handleDeleteImage(file, idx)}
                  makeCoverPhotoOnClick={() => makeCoverPhoto(file)}
                  opacity={idx === coverPhotoIdx ? 0.95 : 0.95}
                  isCover={isCover}
                  key={idx}
                  thumbUrl={file?.media_url}
                />
              );
            } else {
              return <></>;
            }
          })}
      </div>
      <label
        // htmlFor="file"
        onClick={selectFiles}
        className={`${styles.uploadImageContainer}`}
      >
        <div className="flex flex-col items-center justify-center gap-y-1">
          <div
            className={`${styles.plusCircle} flex items-center justify-center`}
          >
            <PlusOutlined />
          </div>
          <p className="text-center souq-s">{t("addImage")}</p>
        </div>
      </label>
      {mainCoverMessage ? (
        <p style={{ color: "red" }}>{t("main_cover_message")}</p>
      ) : (
        <></>
      )}
      <FormButtons
        formik={formik}
        willSubmit={true}
        includedFields={["postMedias", "main_image"]}
        isLoading={updatePostLoading}
      />
    </motion.div>
  );
};

export default PhotoSection;
