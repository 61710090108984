import 
{
    LIST_FEATURED_POSTS_LOADING,
    LIST_FEATURED_POSTS_SUCCESS,
    LIST_FEATURED_POSTS_FAILURE
}
from "redux/types";

const initialState = {
    featuredPostsLoading: false,
    featuredPosts: null,
    errors: null
};

export const listFeaturedPostsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_FEATURED_POSTS_LOADING :
            return {...state, featuredPostsLoading: action.payload};
        case LIST_FEATURED_POSTS_SUCCESS :
            return {...state, featuredPosts: action.payload.data};
        case LIST_FEATURED_POSTS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}