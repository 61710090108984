import "components/Carousel/BannersCarousel/BannersCarousel.css";
import { useEffect, useState } from "react";
import BannerEn1 from "assets/images/Banners/en/d1.png";
import BannerEn2 from "assets/images/Banners/en/d2.png";
import BannerEn3 from "assets/images/Banners/en/d3.png";
import BannerEn3_1 from "assets/images/Banners/en/d3.1.png";
import BannerEn3_2 from "assets/images/Banners/en/d3.2.png";
import BannerEnMobile1 from "assets/images/Banners/en/p1.png";
import BannerEnMobile2 from "assets/images/Banners/en/p2.png";
import BannerEnMobile3 from "assets/images/Banners/en/p3.png";
import BannerEnMobile3_1 from "assets/images/Banners/en/p3.1.png";
import BannerEnMobile3_2 from "assets/images/Banners/en/p3.2.png";
import BannerAr1 from "assets/images/Banners/ar/d1.png";
import BannerAr2 from "assets/images/Banners/ar/d2.png";
import BannerAr3 from "assets/images/Banners/ar/d3.png";
import BannerAr3_1 from "assets/images/Banners/ar/d3.1.png";
import BannerAr3_2 from "assets/images/Banners/ar/d3.2.png";
import BannerArMobile1 from "assets/images/Banners/ar/p1.png";
import BannerArMobile2 from "assets/images/Banners/ar/p2.png";
import BannerArMobile3 from "assets/images/Banners/ar/p3.png";
import BannerArMobile3_1 from "assets/images/Banners/ar/p3.1.png";
import BannerArMobile3_2 from "assets/images/Banners/ar/p3.2.png";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { useResponsive } from "hooks/useResponsive";
import { useLanguage } from "hooks/useLanguage";

const BannersCarousel = () => {
  const { isMobile } = useResponsive();
  const { isArabic } = useLanguage();

  const arabicBanners: any = {
    desktop: [BannerAr1, BannerAr2, BannerAr3, BannerAr3_1, BannerAr3_2],
    phone: [
      BannerArMobile1,
      BannerArMobile2,
      BannerArMobile3,
      BannerArMobile3_1,
      BannerArMobile3_2,
    ],
  };

  const englishBanners: any = {
    desktop: [BannerEn1, BannerEn2, BannerEn3, BannerEn3_1, BannerEn3_2],
    phone: [
      BannerEnMobile1,
      BannerEnMobile2,
      BannerEnMobile3,
      BannerEnMobile3_1,
      BannerEnMobile3_2,
    ],
  };

  const renderImages = () => {
    const images = isArabic ? arabicBanners : englishBanners;
    const imagesArray = isMobile ? images.phone : images.desktop;
    return imagesArray.map((item: any, idx: any) => {
      return (
        <SwiperSlide key={idx}>
          <img
            src={item}
            className={`w-full fade-slide`}
            alt="img"
            draggable="false"
          />
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="banner-wrapper">
      <ul id="banner-carousel" className="banner-carouse bg-white">
        <Swiper
          modules={[Autoplay, EffectFade, Pagination]}
          className="w-full"
          slidesPerView={1}
          grabCursor
          loop
          speed={750}
          effect="slide"
          autoplay={{
            delay: 6000, // delay between transitions in ms
            disableOnInteraction: false, // autoplay will not be disabled after user interactions
            reverseDirection: isArabic,
          }}
          // allowTouchMove={isMobile ? true : false}
          // pagination={{
          //   clickable: true,
          // }}
          // fadeEffect={{
          //   crossFade: false,
          // }}
        >
          {renderImages()}
        </Swiper>
      </ul>
    </div>
  );
};

export default BannersCarousel;
