import
{
    DELETE_STORY_LOADING, 
    DELETE_STORY_SUCCESS,
    DELETE_STORY_FAILURE
}
from "redux/types";

const initialState = {
    deleteStoryLoading: false,
    deleteStorySuccess: null,
    errors: null
}

export const deleteStoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_STORY_LOADING :
            return {...state, deleteStoryLoading: action.payload};
        case DELETE_STORY_SUCCESS :
            return {...state, deleteStorySuccess: action.payload.success};
        case DELETE_STORY_FAILURE :
            return {...state, errors: action.paylod};
        default:
            return state;
    }
}