import api from "api";
import { favouriteStoryEndPoint } from "api/apiUrls";
import store from "redux/store";

export const favouriteStoryService = async (id: any) => {
    const response = await api.post(
        favouriteStoryEndPoint(id),
        {},
        {
            headers: {
                'Authorization' : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );

    return response;
}