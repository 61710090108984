import
{
    REPOST_STORY_LOADING,
    REPOST_STORY_SUCCESS,
    REPOST_STORY_FAILURE
}
from "redux/types";

const initialState = {
    repostStoryLoading: false,
    repostStorySuccess: null,
    errors: null
}

export const repostStoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case REPOST_STORY_LOADING :
            return {...state, repostStoryLoading: action.payload};
        case REPOST_STORY_SUCCESS :
            return {...state, repostStorySuccess: action.payload.success};
        case REPOST_STORY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}