import
{
    ALL_FEATURED_ADS_LOADING,
    ALL_FEATURED_ADS_SUCCESS,
    ALL_FEATURED_ADS_FAILURE
}
from "redux/types";

const initialState = {
    allFeaturedAdsLoading: false,
    allFeaturedAds: null,
    allFeaturedAdsCurrentPage: null,
    allFeaturedAdsLastPage: 4,
    errors: null
}

export const listAllFeaturedAdsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ALL_FEATURED_ADS_LOADING :
            return {...state, allFeaturedAdsLoading: action.payload};
        case ALL_FEATURED_ADS_SUCCESS :
            return {...state, allFeaturedAds: action.payload.data.data, allFeaturedAdsCurrentPage: action.payload.data.current_page};
        case ALL_FEATURED_ADS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}