import ProfileScreenUserInfoCard from "components/CustomUserInfoCard/ProfileScreenUserInfoCard/ProfileScreenUserInfoCard";
import { useState, useEffect } from "react";
import styles from "screens/V3/ExhibitorScreen/ExhibitorScreen.module.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { getVendorDataAction } from "redux/actions/VendorDataActions/vendorDataActions";
import { Empty, Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import Footer from "components/Footer/Footer";
import { listVendorPostsAction } from "redux/actions/VendorPostsActions/listVendorPostsAction";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useResponsive } from "hooks/useResponsive";
import PostGrid from "components/V3/PostGrid/PostGrid";
import ExhibitorCard from "components/V3/ExhibitorComponents/ExhibitorCard/ExhibitorCard";
import BranchCard from "components/V3/ExhibitorComponents/BranchCard/BranchCard";
import { set } from "lodash";
import { useSearch } from "hooks/useSearch";
import { CustomButton } from "components";
import { PhoneIcon, WhatsAppIcon } from "assets/icons/index-svg";
import { ChatIcon, DashedCircle } from "assets/IconComponents";
import DashboardBranchCard from "components/V3/DashboardBranchCard/DashboardBranchCard";
import { sendChatMessageAction } from "redux/actions";

const ExhibitorScreen = () => {
  const { t } = useTranslation();
  const { isMobile, addBreakpoint } = useResponsive();

  const [isMax950, setIsMax950] = useState(false);

  addBreakpoint({ value: 950, type: "MAX", setState: setIsMax950 });

  const { vendorData, vendorDataLoading } = useSelector(
    (state: RootState) => state.getVendorDataReducer
  );

  const {
    vendorPostsLoading,
    vendorPosts,
    vendorBranches,
    currentPage,
    lastPage,
  } = useSelector((state: RootState) => state.listVendorPostsReducer);

  const exampleLinks = {
    // website_link: "https://www.google.com",
    facebook_link: "https://www.facebook.com",
    // youtube_link: "https://www.youtube.com/user",
    // instagram_link: "https://www.instagram.com",
    // twitter_link: "https://twitter.com",
    // linkedin_link: "https://www.linkedin.com/in",
  };

  // TODO: Link these to real branches
  const branches = [
    {
      title: "الفرع الرئيسي",
      governate: "القاهرة",
      address: "شارع الخليفه المأمون روكسي مصر الجديده خلف الوطنية",
      workingHours: [10, 20],
      daysOff: ["الأحد"],
      phoneNumber: "01000000001",
      altPhoneNumber: "01000000002",
    },
    {
      title: "الفرع الرئيسي",
      governate: "القاهرة",
      address: "المعادي - القاهرة",
      workingHours: [12, 23],
      daysOff: ["الجمعة"],
      phoneNumber: "01000000003",
      altPhoneNumber: "01000000004",
    },
    {
      title: "الفرع الرئيسي",
      governate: "القاهرة",
      address: "المعادي - القاهرة",
      workingHours: [9, 17],
      daysOff: ["الجمعة", "السبت"],
      phoneNumber: "01000000005",
      altPhoneNumber: "01000000006",
    },
    {
      title: "الفرع الرئيسي",
      governate: "القاهرة",
      address: "المعادي - القاهرة",
      workingHours: [11, 21],
      daysOff: ["الجمعة"],
      phoneNumber: "01000000007",
      altPhoneNumber: "01000000008",
    },
  ];

  const fixedButtonWidth = "30%";

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const isArabic = currentLanguage === "ar";
  const dir = isArabic ? "rtl" : "ltr";
  const dirFlip = isArabic ? "ltr" : "rtl";
  const rowEnAr = !isArabic ? "flex-row-reverse" : "flex-row";
  const rowArEn = isArabic ? "flex-row-reverse" : "flex-row";
  const { getSearchParam } = useSearch();

  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const defaultTabKey = 0; // new
  const tabItems = [
    { name: isMobile ? t("all") : t("all_ads"), key: 0 },
    { name: t("new"), key: 3 },
    { name: t("used"), key: 1 },
    { name: t("almost_new"), key: 2 },
    { name: t("the_branches"), key: 4 },
  ];

  const dispatch: any = useDispatch();
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getVendorDataAction(id));
  }, []);

  useEffect(() => {
    dispatch(
      listVendorPostsAction(
        id,
        getSearchParam("page", 1),
        `selected_tab=${getSearchParam("selected_tab", defaultTabKey)}`
      )
    );
    setCurrentTab(Number(getSearchParam("selected_tab", defaultTabKey)));
  }, [searchParams]);

  function tabPages(inputTab: any) {
    // TODO : ADD PAGES FOR EACH TAB
    switch (inputTab) {
      case 4:
        return (
          // Map the branches to a component called BranchCard
          vendorBranches?.length >= 1 ? (
            <div
              className={`${styles.branchesContainer}`}
              style={{
                width: isMobile ? "100%" : "100%",
              }}
            >
              {vendorPostsLoading ? (
                <Spin />
              ) : (
                vendorBranches?.map((branch: any, idx: any) => {
                  return (
                    // <BranchCard
                    //   key={branch?.id}
                    //   title={branch?.branch_type}
                    //   governate={branch?.city}
                    //   address={branch?.address}
                    //   workingHours={[branch?.work_from, branch?.work_to]}
                    //   daysOff={[branch?.day_off]}
                    //   phoneNumber={branch?.phone_one}
                    //   altPhoneNumber={branch?.phone_two}
                    // />
                    <DashboardBranchCard
                      id={branch?.id}
                      city={branch?.city}
                      address={branch?.address}
                      workingHoursFrom={branch?.work_from}
                      workingHoursTo={branch?.work_to}
                      offDay={branch?.day_off}
                      branchType={branch?.branch_type}
                      phoneOne={branch?.phone_one}
                      phoneTwo={branch?.phone_two}
                      isEditable={false}
                    />
                  );
                })
              )}
            </div>
          ) : (
            <div className="w-full flex flex-col items-center mt-10">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ transform: "scale(1.5)" }}
                description={t("no_branches")}
              />
            </div>
          )
        );
      default:
        return (
          <PostGrid
            posts={vendorPosts}
            postsLoading={vendorPostsLoading}
            forcedViewType={isMobile ? "list" : "grid"}
            width={"100%"}
            pagination={{
              currentPage: Number(currentPage),
              lastPage: Number(lastPage),
              path: `vendor/${id}`,
            }}
          />
        );
    }
  }

  const handleSendMessage = async () => {
    await dispatch(sendChatMessageAction(id, "*$$*"));
    navigate(`/${currentLanguage}/messages?vendorId=${id}`);
  };

  return (
    <div
      dir={dir}
      className="flex flex-col items-center"
      style={{
        backgroundColor: "var(--new-souq-background)",
      }}
    >
      {/* Floating Action Button */}

      {isMobile && (
        <div
          className={`${styles.fabContainer} px-[0.55rem] py-[0.75rem] w-full z-[100] bg-white flex flex-row gap-x-3 justify-between items-center`}
        >
          <div
            className={`w-full flex flex-wrap items-center justify-center gap-2`}
          >
            <a
              href={`tel:+2${vendorData?.phone}`}
              target="_blank"
              className={`${styles.contactButton}`}
              style={{
                backgroundColor: "var(--sky-blue-background)",
                border: "1px solid var(--new-blue)",
                ...(isMobile && { width: fixedButtonWidth }),
              }}
            >
              <PhoneIcon
                className="flex-grow-0 flex-shrink-0"
                style={{ color: "var(--new-blue)" }}
                fontSize={".7rem"}
              />
              {t("call_us")}
            </a>
            <a
              // href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              className={`${styles.contactButton}`}
              style={{
                backgroundColor: "var(--sky-blue-background)",
                border: "1px solid var(--new-blue)",
                ...(isMobile && { width: fixedButtonWidth }),
              }}
              onClick={handleSendMessage}
            >
              <ChatIcon
                className="flex-grow-0 flex-shrink-0"
                style={{ color: "var(--new-blue)" }}
                fontSize={"1rem"}
              />
              {t("send_message")}
            </a>
            <a
              href={`https://wa.me/+2${vendorData?.whats_app_no}`}
              target="_blank"
              className={`${styles.contactButton}`}
              style={{
                backgroundColor: "rgba(1, 219, 49, 0.03)",
                border: "1px solid #01DB31",
                ...(isMobile && { width: fixedButtonWidth }),
              }}
            >
              <WhatsAppIcon
                className="flex-grow-0 flex-shrink-0"
                style={{ color: "#01DB31" }}
                fontSize={"1rem"}
              />
              {t("whatsapp")}
            </a>
          </div>
        </div>
      )}
      {/* End Floating Action Button */}
      <div
        className="flex flex-col items-center justify-center w-full"
        // style={{ paddingInline: "5%" }}
      >
        {!isMobile ? (
          <div
            className={`w-full flex flex-col items-center ${styles.exhibitorContainer}`}
            style={{
              paddingInline: isMax950 ? "5%" : "15%",
            }}
          >
            {/* DESKTOP VIEW */}

            <ExhibitorCard
              profileImage={vendorData?.customer_image}
              coverImage={vendorData?.cover_photo}
              callsCount={vendorData?.total_calls}
              viewsCount={vendorData?.total_views}
              whatsappCount={vendorData?.total_whatsapp}
              totalPosts={vendorData?.total_posts}
              mainService={vendorData?.main_service}
              hotline={vendorData?.hotline}
              name={vendorData?.name}
              description={vendorData?.description}
              // description={vendorData?.description ?? "هنا يظهر الوصف المختصر للمعرض المضاف في البروفايل من الاعدادت بحد اقصي سطرين فقط.."}
              phoneNumber={vendorData?.phone}
              email={vendorData?.email}
              whatsappNumber={vendorData?.whats_app_no}
              // socialLinks={vendorData?.social_links}
              isFavorited={vendorData?.favourited ?? false}
              socialLinks={vendorData?.social_links}
              style={{ marginBottom: "1.5rem" }}
            />

            {/* TABS */}
            <div
              className={`border-round-xs ${styles.tabContainer} ${styles.shadow} w-full flex flex-wrap items-center justify-center`}
              style={{ backgroundColor: "white", marginBottom: "1.5rem" }}
            >
              <div className={`w-full flex items-center justify-between`}>
                {Array.isArray(tabItems) &&
                  tabItems?.map((item: any) => {
                    return (
                      <a
                        href={`${window.location.pathname}?selected_tab=${item.key}`}
                        key={item.key}
                        className={`border-round-xs text-black flex items-center justify-center ${
                          styles.tabLink
                        } ${isArabic ? styles.ar : styles.en} ${
                          item.key ===
                          Number(getSearchParam("selected_tab", defaultTabKey))
                            ? `${styles.selectedTabItem}`
                            : `${styles.unselectedTabItem}`
                        } ${styles.hover}`}
                        onClick={() => {
                          setCurrentTab(item.key);
                          setCurrentTabIndex(item.key);

                          // navigate({
                          //   pathname: window.location.pathname,
                          //   search: `?selected_tab=${item.key}`,
                          // });
                          // dispatch(profilePostStatusAction(item.key));
                        }}
                      >
                        <p>{item.name}</p>
                      </a>
                    );
                  })}
              </div>
            </div>

            {/* PAGE FOR EACH TAB */}
            {tabPages(currentTab)}
          </div>
        ) : (
          // MOBILE VIEW
          <div
            className={`w-full flex flex-col items-between justify-start mt-[1rem]`}
          >
            <div
              className={`flex flex-col items-start justify-between gap-y-4 px-3`}
            >
              {/* USER INFO CARD */}
              <ExhibitorCard
                callsCount={vendorData?.total_calls}
                viewsCount={vendorData?.total_views}
                whatsappCount={vendorData?.total_whatsapp}
                totalPosts={vendorData?.total_posts}
                mainService={vendorData?.main_service}
                name={vendorData?.name}
                description={vendorData?.description}
                phoneNumber={vendorData?.phone}
                email={vendorData?.email}
                hotline={vendorData?.hotline}
                whatsappNumber={vendorData?.whats_app_no}
                isFavorited={vendorData?.favourited ?? false}
                profileImage={vendorData?.customer_image}
                coverImage={vendorData?.cover_photo}
                // socialLinks={vendorData?.social_links}
                socialLinks={vendorData?.social_links}
                style={{ marginBottom: "0rem" }}
              />

              {/* TABS */}
              <div
                className={`${styles.tabContainer} ${styles.shadow} w-full flex flex-wrap items-center justify-center rounded-xl`}
                style={{
                  backgroundColor: "white",
                  marginBottom: "1.5rem",
                  // marginInline: "1rem",
                }}
              >
                <div className={`w-full flex items-center justify-between`}>
                  {Array.isArray(tabItems) &&
                    tabItems?.map((item: any) => {
                      return (
                        <a
                          href={`${window.location.pathname}?selected_tab=${item.key}`}
                          key={item.key}
                          className={`${styles.tabLinkMobile} ${
                            item.key === currentTab
                              ? `${styles.selectedTabItem}`
                              : `${styles.unselectedTabItem}`
                          } text-black flex items-center justify-center ${
                            styles.hover
                          }`}
                          onClick={() => {
                            setCurrentTab(item.key);
                            setCurrentTabIndex(item.key);

                            // navigate({
                            //   pathname: window.location.pathname,
                            //   search: `?selected_tab=${item.key}`,
                            // });
                            // dispatch(profilePostStatusAction(item.key));
                          }}
                        >
                          <p>{item.key === "all_ads" ? t("all") : item.name}</p>
                        </a>
                      );
                    })}
                </div>
              </div>
            </div>
            {tabPages(currentTab)}
          </div>
        )}
      </div>
      {!vendorPostsLoading ? <Footer /> : <></>}
    </div>
  );
};

export default ExhibitorScreen;
