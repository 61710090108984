import 
{
    CHANGE_CURRENT_PAGE
}
from "redux/types";

import { Dispatch } from "redux";

export const changeCurrentPageAction = (newPage: any) => (dispatch: Dispatch) => {
    dispatch({type: CHANGE_CURRENT_PAGE, payload: newPage});
}