import { PROFILE_POST_STATUS } from "redux/types";

const initialState = {
  profilePostStatus: "Active",
};

export const profilePostStatusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PROFILE_POST_STATUS:
      return { ...state, profilePostStatus: action.payload };
    default:
      return state;
  }
};
