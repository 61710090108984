import styles from "components/V3/CustomHeader/CustomHeader.module.css";
import "./custom-header.css";
import { CustomIcon } from "components/CustomIcon/CustomIcon";
import { language, souqCarLogo, themeIcon } from "assets/icons";
import { NotificationsIcon, UserIcon } from "assets/icons/V3";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  GlobalOutlined,
  BellOutlined,
  MessageOutlined,
  HeartFilled,
} from "@ant-design/icons";
import { Badge, Avatar, Tooltip, Modal } from "antd";
import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import getPreferredLanguage from "lang/languageUtils";
import { changeLanguageAction } from "redux/actions/LanguageActions/changeLanguageAction";
import { useTranslation } from "react-i18next";
import { LOG_OUT } from "redux/types";
import { drawerStatusAction } from "redux/actions/DrawerStatusActions/drawerStatusAction";
import { HamburgerMenuIcon } from "assets/icons/V3";
import { StoreIcon, WorldIcon } from "assets/IconComponents";
import { useLanguage } from "hooks/useLanguage";

interface HeaderProps {
  // drawerOpen: any;
  // dispatch(drawerStatusAction: any;
}

const CustomHeader: FC<HeaderProps> = ({}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const [isMobile, setIsMobile] = useState(false);
  const [unreadNotifs, setUnreadNotifs] = useState(0);
  const [isShortScreen, setIsShortScreen] = useState(false);
  const [noCreditModalOpen, setNoCreditModalOpen] = useState(false);

  const { drawerOpen } = useSelector(
    (state: RootState) => state.drawerStatusReducer
  );

  const dispatch: any = useDispatch();

  const { currentPage } = useSelector(
    (state: RootState) => state.changeCurrentPageReducer
  );

  const { currentLanguage, textDirectionStyle } = useLanguage();

  const { notifications } = useSelector(
    (state: RootState) => state.listNotificationsReducer
  );

  useEffect(() => {
    // dispatch(listNotificationsAction());
  }, []);

  useEffect(() => {
    var counter = 0;
    notifications?.map((item: any) => {
      if (!item?.seen) {
        counter++;
      }
    });
    setUnreadNotifs(counter);
  }, [notifications]);

  const isArabic = currentLanguage === "ar";

  const { userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const handleDrawerClick = () => {
    dispatch(drawerStatusAction(!drawerOpen));
  };

  const handleLoginButtonClick = () => {
    navigate(`${currentLanguage}/login`);
    dispatch(drawerStatusAction(false));
  };

  const getNewRoute = () => {
    const currentRoute = location.pathname.split("/");
    var newRoute = "";
    for (let i = 0; i < currentRoute.length; i++) {
      if (i === 0) {
        continue;
      }
      if (i === 1) {
        newRoute += `/${getPreferredLanguage()}`;
      } else {
        newRoute += `/${currentRoute.at(i)}`;
      }
    }
    return newRoute;
  };

  const handleLogoutClick = () => {
    dispatch({ type: LOG_OUT });
    navigate("/");
  };

  const handleLanguaugeChange = () => {
    if (currentLanguage === "en") {
      dispatch(changeLanguageAction("ar"));
    } else if (currentLanguage === "ar") {
      dispatch(changeLanguageAction("en"));
    }
    navigate(getNewRoute());
    window.location.reload();
  };

  useEffect(() => {
    dispatch(drawerStatusAction(false));
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }

    if (window.outerHeight >= 800) {
      setIsShortScreen(false);
    } else {
      setIsShortScreen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
      dispatch(drawerStatusAction(false));
      window.outerHeight >= 850
        ? setIsShortScreen(false)
        : setIsShortScreen(true);
    });
  }, []);

  return (
    <div
      dir={textDirectionStyle}
      className={`w-full bg-white flex flex-row items-center justify-center ${styles.border}`}
      style={{ zIndex: 9999 }}
    >
      <Modal
        open={noCreditModalOpen}
        onCancel={() => setNoCreditModalOpen(false)}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        className="max-w-[360px]"
      >
        <div className="w-full flex flex-col items-center gap-y-4">
          <p className="text-base font-bold">{t("not_enough_credits")}</p>
          <button
            style={{
              backgroundColor: "rgba(0, 80, 255, 0.1)",
            }}
            className="w-full h-[50px] rounded-[10px] flex items-center justify-center text-[var(--souq-blue)]"
            onClick={() => navigate(`/${currentLanguage}/store`)}
          >
            {t("buy_from_store")}
          </button>
          <button
            style={{
              backgroundColor: "rgba(0, 80, 255, 0.1)",
            }}
            className="w-full h-[50px] rounded-[10px] flex items-center justify-center text-black"
            onClick={() => setNoCreditModalOpen(false)}
          >
            {t("dismiss")}
          </button>
        </div>
      </Modal>
      <div
        className={`contentWidth flex justify-${
          isMobile ? "center" : "between"
        }`}
        // style={{ width: "var(--content-width)" }}
      >
        <div className="innerHeaderWidth flex items-center">
          <div
            dir="ltr"
            id="header"
            // xl:mr-[25px] xl:w-[80%]
            className={`headerWidth ${
              drawerOpen ? styles.navBarBlurred : styles.navBar
            } flex ${
              currentLanguage === "ar" ? "flex-row" : "flex-row-reverse"
            } items-center justify-between`}
            // style={{
            //   width: "var(--content-width)",
            // }}
          >
            <div
              className={`flex flex-row${
                currentLanguage === "ar" ? "" : "-reverse"
              } items-center justify-center gap-x-3`}
            >
              <div
                className={`h-10 flex items-center justify-center rounded-md xl:hidden ${styles.drawerIconWrapper}`}
                onClick={handleDrawerClick}
                id="always-click"
              >
                <img src={HamburgerMenuIcon} />
              </div>
              <div
                className={`flex items-center justify-center rounded-full ${styles.addPostButton} xl:p-2`}
                onClick={() => {
                  if (userData?.user && userData?.user?.available_posts < 1) {
                    setNoCreditModalOpen(true);
                    return;
                  }
                  navigate(`${currentLanguage}/posts/create`);
                }}
              >
                <p className="xl:text-base text-[15px]">
                  {t("navbar_create_post_text")}
                </p>
              </div>
              {userData?.token && !isMobile ? (
                <Tooltip
                  color="white"
                  trigger={"click"}
                  // style={{ overflow: "visible" }}
                  title={
                    <div
                      className={`${styles.toolTip} flex flex-col items-center gap-y-2`}
                    >
                      <div
                        className={`${styles.tooltipProfile} flex flex-row items-center gap-x-3`}
                      >
                        <p className="text-black">
                          {userData?.user?.name || t("navbar_login_text")}
                        </p>
                        <div
                          className={`${styles.userIconContainerTooltip} flex flex-row items-center justify-center`}
                          style={{
                            backgroundImage: `url('${encodeURI(
                              userData?.user?.personal_image
                            )}')`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          {/* <img
                            src={userIcon}
                            className={`${styles.loginIconTooltip}`}
                            alt="Login Icon"
                          /> */}
                        </div>
                      </div>
                      <a
                        dir="rtl"
                        href={`/${currentLanguage}/profile/view`}
                        className={`text-black w-full rounded-[0.625rem] flex flex-row items-center justify-center gap-x-3 h-[3.0625rem] ${styles.blueOpacity}`}
                        id="always-click"
                      >
                        <img src={UserIcon} />
                        {t("user_my_account")}
                      </a>
                      <a
                        dir="rtl"
                        href={`/${currentLanguage}/messages`}
                        className={`text-black w-full rounded-[0.625rem] flex flex-row items-center justify-center gap-x-3 h-[3.0625rem] ${styles.blueOpacity}`}
                        id="always-click"
                      >
                        <MessageOutlined className="text-[var(--new-blue)] text-[1.2rem] font-[700]" />
                        {t("messages")}
                      </a>
                      <a
                        dir="rtl"
                        href={`/${currentLanguage}/favorite-posts`}
                        className={`text-black w-full rounded-[0.625rem] flex flex-row items-center justify-center gap-x-3 h-[3.0625rem] ${styles.blueOpacity}`}
                        id="always-click"
                      >
                        <HeartFilled className="text-[var(--new-blue)] text-[1.2rem]" />
                        {t("favourited")}
                      </a>
                      <a
                        onClick={handleLogoutClick}
                        className={`${styles.logoutText}`}
                        id="always-click"
                      >
                        {t("drawer_logout")}
                      </a>
                    </div>
                  }
                >
                  <div
                    className={`${styles.userIconContainer} hidden xl:flex flex-row items-center justify-center`}
                    style={{
                      backgroundImage: `url('${encodeURI(
                        userData?.user?.personal_image
                      )}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    {/* <img
                      src={}
                      className={`${styles.loginIconLogged}`}
                      alt="Login Icon"
                    /> */}
                  </div>
                </Tooltip>
              ) : (
                <div
                  className={`${
                    styles.loginButton
                  } hidden xl:flex flex-row items-center justify-center gap-x-3 ${
                    currentLanguage === "ar" ? "" : "w-24"
                  } rounded-full`}
                  onClick={handleLoginButtonClick}
                >
                  <div className={`${styles.loginText}`}>
                    <p className="font-bold text-[14px]">
                      {t("navbar_login_text")}
                    </p>
                  </div>
                  <img
                    src={UserIcon}
                    className={`${styles.loginIcon}`}
                    alt="Login Icon"
                  />
                </div>
              )}
              {userData?.token && !isMobile && (
                <img
                  src={NotificationsIcon}
                  className="hover:cursor-pointer"
                  onClick={() => {
                    navigate(`/${currentLanguage}/notifications`);
                  }}
                />
              )}
              {userData?.token && !isMobile && (
                <div
                  className="h-[2.75rem] w-[2.75rem] rounded-full flex items-center justify-center cursor-pointer"
                  style={{
                    backgroundColor: "var(--new-orange-10)",
                    color: "var(--new-orange)",
                  }}
                  onClick={() => {
                    navigate(`/${currentLanguage}/store`);
                  }}
                >
                  <StoreIcon
                    width="50%"
                    height="50%"
                    style={{ marginRight: "0.15rem" }}
                  />
                </div>
              )}

              {/* <div
                className={`${styles.notificationsContainer}`}
                onClick={() => {}}
              >
                <Tooltip
                  color="white"
                  trigger={"click"}
                  title={
                    <div className="flex flex-col items-center justify-center">
                      {
                        notifications !== null && notifications.length > 0 ? (
                          <>
                            {
                              notifications?.slice(0, 3).map((item: any) => (
                                <CustomNotification 
                                  title={item?.title}
                                  description={item?.description}
                                  seen={item?.seen}
                                  time={item?.created_at}
                                />
                              ))
                            }
                            
                            <a className={`${styles.notificationMore} notificationMore`}>
                              {
                                unreadNotifs > 3 ? `${unreadNotifs - 3}+ More`
                                : "All"
                              }
                            </a>
                          </>
                        ) : (
                          <p className={`${styles.noNotification} notificationMore`}>
                              No Notifications
                          </p>
                        )
                      }
                    </div>
                  }
                  placement="bottom"
                >
                  <Badge style={{scale:"0.8", transform:"translate(40%, -40%)", boxShadow: "0 0 0 2px #ffffff" }} count={unreadNotifs} overflowCount={5}>
                    <img src={notificationIcon} className={`${styles.bellIcon}`}alt="notifications" />
                  </Badge>
                </Tooltip>
              </div> */}
              {/* <img src={notificationIcon} style={{width:"1.5rem"}}alt="" /> */}
            </div>
            <div
              className={`hidden xl:flex ${styles.nav} ${
                currentLanguage === "ar" ? "flex-row" : "flex-row-reverse"
              } items-center ${currentLanguage === "ar" ? "mr-12" : "ml-12"}`}
            >
              <a
                href={`/${currentLanguage}/News/view`}
                className={`${
                  currentPage === 0
                    ? `${styles.linkSelected} text-[15px]`
                    : `${styles.link} text-[15px]`
                } ${styles.font}`}
                id="always-click"
              >
                {t("navbar_news_text")}
              </a>
              <a
                href={`/${currentLanguage}/car-insurance`}
                className={`${
                  currentPage === 6
                    ? `${styles.linkSelected} text-[15px]`
                    : `${styles.link} text-[15px]`
                } ${styles.font}`}
                id="always-click"
              >
                {t("navbar_insurance_text")}
              </a>
              {/* <a
                href={`/${currentLanguage}/ads/view?category_id=3`}
                className={`${
                  currentPage === 2
                    ? `${styles.linkSelected} text-[15px]`
                    : `${styles.link} text-[15px]`
                } ${styles.font}`}
                id="always-click"
              >
                {t("navbar_maintenance_text")}
              </a> */}
              {/* <a
                href={`/${currentLanguage}/ads/view?category_id=4`}
                className={`${
                  currentPage === 1
                    ? `${styles.linkSelected} text-[15px]`
                    : `${styles.link} text-[15px]`
                } ${styles.font}`}
                id="always-click"
              >
                {t("navbar_car_parts_text")}
              </a> */}
              <a
                href={`/${currentLanguage}/exhibitions`}
                className={`${
                  currentPage === 2
                    ? `${styles.linkSelected} text-[15px]`
                    : `${styles.link} text-[15px]`
                } ${styles.font}`}
                id="always-click"
              >
                {t("navbar_exhibitions_text")}
              </a>
              <a
                href={`/${currentLanguage}/stories`}
                className={`${
                  currentPage === 1
                    ? `${styles.linkSelected} text-[15px]`
                    : `${styles.link} text-[15px]`
                } ${styles.font}`}
                id="always-click"
              >
                {t("navbar_stories_text")}
              </a>
              <a
                href={`/${currentLanguage}/ads/view?category_id=2`}
                className={`${
                  currentPage === 3
                    ? `${styles.linkSelected} text-[15px]`
                    : `${styles.link} text-[15px]`
                } ${styles.font}`}
                id="always-click"
              >
                {t("navbar_rental_text")}
              </a>
              <a
                href={`/${currentLanguage}/ads/view?category_id=1`}
                className={`${
                  currentPage === 4
                    ? `${styles.linkSelected} text-[15px]`
                    : `${styles.link} text-[15px]`
                } ${styles.font}`}
                id="always-click"
              >
                {t("navbar_buy_text")}
              </a>
              <a
                href={"/"}
                className={`${
                  currentPage === 5
                    ? `${styles.linkSelected} text-[15px]`
                    : `${styles.link} text-[15]`
                } ${styles.font}`}
                id="always-click"
              >
                {t("navbar_home_text")}
              </a>
            </div>
            {/* <CustomIcon
              href={souqCarLogo}
              width={isMobile ? 105 : 200}
              height={40}
              onClick={() => navigate(HOME_PATH)}
              className={`${styles.souqCarIcon} ${isMobile ? "mr-10" : ""}`}
            /> */}
            <a href={`/${currentLanguage}`}>
              <img
                src={souqCarLogo}
                className={`${styles.souqCarIcon} ${
                  isMobile ? "w-[4.5625rem] h-[3.375rem]" : ""
                }`}
                // onClick={() => navigate(`/${currentLanguage}`)}
                alt="Logo"
              />
            </a>
          </div>
          {!isMobile && (
            <div
              className={`${styles.languageButton} hidden xl:flex flex-row items-center justify-center gap-x-1 rounded-full ms-2`}
              onClick={handleLanguaugeChange}
            >
              <p>{currentLanguage === "en" ? "عر" : "Eng"}</p>
              <WorldIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomHeader;
