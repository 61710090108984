import styles from "components/CustomCarDetailsCardSpare/CustomCarDetailsCardSpare.module.css";
import { FC } from "react";
import { CustomIcon } from "components/CustomIcon/CustomIcon";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useTranslation } from "react-i18next";

interface CustomCarDetailsCardSpareProps {
  data: any;
  isMobile: any;
}

const CustomCarDetailsCardSpare: FC<CustomCarDetailsCardSpareProps> = ({ data, isMobile }) => {

  const { currentLanguage } = useSelector((state: RootState) => state.changeLanguageReducer);
  const isArabic = currentLanguage === "ar";

  const { t } = useTranslation();

  return (
    <div
      className={`w-full p-3 bg-white flex flex-col gap-y-3 rounded-xl ${styles.shadow}`}
      style={{
        direction: isArabic ? "ltr" : "rtl",
      }}
    >
      {!isMobile ? (
        <>
          <div
            className={`w-full ${styles.blueBg} flex flex-row items-center justify-between rounded-xl`}
          >
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(2).text ? {color:"red"} : {}}>{data.at(2).text ? data.at(2).text : t("unspecified")}</p>
              <CustomIcon href={data.at(2).icon} width={45} height={45} width45/>
            </div>
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(1).text ? {color:"red"} : {}}>{data.at(1).text ? data.at(1).text : t("unspecified")}</p>
              <CustomIcon href={data.at(1).icon} width={45} height={45} width45/>
            </div>
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(0).text ? {color:"red"} : {}}>{data.at(0).text ? data.at(0).text : t("unspecified")}</p>
              <CustomIcon href={data.at(0).icon} width={28} height={28} width45/>
            </div>
          </div>
        </>
      ) :
        // MOBILE VIEW
        (
          <>
            <div
              className={`${styles.blueBg} flex flex-row${isArabic ? "-reverse" : ""} items-center justify-around rounded-xl p-2`}
            >
              <div style={{ width: ".7rem" }}></div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(0).icon} width={28} height={28} width45/>
                <p style={!data.at(0).text ? {color:"red"} : {}}>{data.at(0).text ? data.at(0).text : t("unspecified")}</p>
              </div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(1).icon} width={45} height={45} width45/>
                <p style={!data.at(1).text ? {color:"red"} : {}}>{data.at(1).text ? data.at(1).text : t("unspecified")}</p>
              </div>
            </div>
            
            <div
              className={`${styles.blueBg} flex flex-row${isArabic ? "-reverse" : ""} items-center justify-around rounded-xl p-2`}
            >
              <div style={{ width: ".7rem" }}></div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(2).icon} width={45} height={45} width45/>
                <p style={!data.at(2).text ? {color:"red"} : {}}>{data.at(2).text ? data.at(2).text : t("unspecified")}</p>
              </div>
              
              {/* <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(5).icon} width={33} height={30} width45/>
                <p style={!data.at(5).text ? {color:"red"} : {}}>{data.at(5).text ? data.at(5).text : t("unspecified")}</p>
              </div> */}
            </div>
            
          </>
        )}
    </div>
  );
};

export default CustomCarDetailsCardSpare;
