import styles from "screens/V3/StoreScreen/MobileStoreScreen/MobileStoreScreen.module.css";
import { FC, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useLanguage } from "hooks/useLanguage";

interface StickyPackageDetailsProps {
  details: any;
}

const StickyPackageDetails: FC<StickyPackageDetailsProps> = ({ details }) => {
  const [isSticky, setSticky] = useState(false);
  const stickyRef = useRef(null);
  const { t } = useTranslation();
  const { textDirectionStyle } = useLanguage();

  const yBreakpoint = getYBreakPoint();

  //yBreakPoint is var(--nav-bar-height) + 1rem
  function getYBreakPoint() {
    const navBarHeight = getComputedStyle(document.documentElement)
      .getPropertyValue("--nav-bar-height")
      .replace("px", "");
    return parseInt(navBarHeight) + 2.3 * 16;
  }

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > (yBreakpoint || 0));
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const divContents = (
    <>
      <p
        className="text-[var(--new-blue)] mb-[0.5rem] text-center"
        style={{
          fontSize: "0.75rem",
        }}
      >
        تفاصيل الباقة :
      </p>
      <AnimatePresence>
        {details?.length > 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, curve: "easeInOut" }}
          >
            <ul className={`${styles.detailsList}`}>
              {details.map((item: any, index: any) => (
                <div className="flex gap-1">
                  <span
                    className="text-[1rem]"
                    style={{ lineHeight: "1.25rem" }}
                  >
                    •
                  </span>
                  <li style={{ lineHeight: "1.25rem" }} key={index}>
                    {item}
                  </li>
                </div>
              ))}
            </ul>
          </motion.div>
        ) : (
          <motion.p
            className="w-full souq-large opacity-20 flex-grow flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.2 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, curve: "easeInOut" }}
          >
            {t("choose_product")}
          </motion.p>
        )}
      </AnimatePresence>
    </>
  );

  return (
    <>
      <div
        dir={textDirectionStyle}
        className={`storeDontDeselect w-full flex flex-col overflow-auto ${styles.pkgDetails} `}
        ref={stickyRef}
      >
        {divContents}
      </div>

      {/* STICKY */}
      <div
        dir={textDirectionStyle}
        className={`storeDontDeselect w-full flex flex-col overflow-auto ${styles.pkgDetails} ${styles.sticky}`}
        ref={stickyRef}
        style={{
          visibility: isSticky ? "visible" : "hidden",
          zIndex: isSticky ? 100 : -1,
        }}
      >
        {divContents}
      </div>
    </>
  );
};

export default StickyPackageDetails;
