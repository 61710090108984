import 
{
    EXHIBITOR_STATISTICS_LOADING,
    EXHIBITOR_STATISTICS_SUCCESS,
    EXHIBITOR_STATISTICS_FAILURE
}
from "redux/types";

const initialState = {
    statisticsLoading: false,
    statistics: null,
    errors: null
}

export const getExhibitorStatisticsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case EXHIBITOR_STATISTICS_LOADING :
            return {...state, statisticsLoading: action.payload};
        case EXHIBITOR_STATISTICS_SUCCESS :
            return {...state, statistics: action.payload.data};
        case EXHIBITOR_STATISTICS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}