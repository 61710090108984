import
{
    HOME_EXHIBITORS_LOADING,
    HOME_EXHIBITORS_SUCCESS,
    HOME_EXHIBITORS_FAILURE
}
from "redux/types";

const initialState = {
    homeExhibitorsLoading: false,
    homeExhibitors: null,
    errors: null
}

export const listHomeScreenExhibitorsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case HOME_EXHIBITORS_LOADING :
            return {...state, homeExhibitorsLoading: action.payload};
        case HOME_EXHIBITORS_SUCCESS :
            return {...state, homeExhibitors: action.payload.data};
        case HOME_EXHIBITORS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}