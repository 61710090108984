import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "components/Carousel/CarBodyCarousel/CarBodyCarousel.module.css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { useLanguage } from "hooks/useLanguage";
interface CarBodyCarouselProps {
  data: any;
  isMobile?: boolean;
}

const CarBodyCarousel: FC<CarBodyCarouselProps> = ({
  data,
  isMobile = false,
}) => {
  const navigate = useNavigate();
  const { isArabic } = useLanguage();
  const { currentLanguage } = useLanguage();

  const renderCard = (item: any, idx: any) => {
    return (
      <SwiperSlide>
        <a
          href={`/${currentLanguage}/ads/view?category_id=1&car_type=${item?.key}`}
        >
          <li
            key={idx}
            className={`${
              isMobile
                ? "w-[5.2290074757rem] h-[5rem]"
                : "w-[7.320610466rem] h-[7rem]"
            }  cursor-pointer ${
              isMobile ? "border-round-xs" : "border-round-s"
            } overflow-hidden bg-white shadow-focus`}
            onClick={() => {
              // navigate({
              //   pathname: "ads/view",
              //   search: `?category_id=1&brand_id=${item?.id}`,
              // });
            }}
          >
            <div
              className={`flex items-center justify-center py-2`}
              style={{ height: "65%" }}
            >
              <img
                src={item?.image}
                className="imageStyling h-full"
                alt="img"
                draggable="false"
              />
            </div>
            <p
              className="text-center"
              style={{
                ...(isMobile && { fontSize: "0.7rem" }),
              }}
            >
              {item?.name}
            </p>
          </li>
        </a>
      </SwiperSlide>
    );
  };

  return (
    <div
      className={`wrapper w-full flex items-center justify-center`}
      style={{ marginBottom: `${isMobile ? "0rem" : "4rem"}` }}
    >
      {/* <i id="left" className="fa-solid fa-angle-left"></i> */}
      <Swiper
        modules={[Autoplay]}
        className="w-full py-2 px-1"
        slidesPerView={isMobile ? 4 : 9}
        speed={500}
        spaceBetween={10}
        autoplay={{
          delay: 3000, // delay between transitions in ms
          disableOnInteraction: false, // autoplay will not be disabled after user interactions
        }}
      >
        {data?.map((item: any, idx: any) => {
          return renderCard(item, idx);
        })}
      </Swiper>

      {/* <i id="right" className="fa-solid fa-angle-right"></i> */}
    </div>
  );
};

export default CarBodyCarousel;
