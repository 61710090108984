import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getSearchParam = (searchParamKey: string, defaultValue?: any) => {
    var searchParamValue = "";
    Array.from(searchParams.entries()).forEach((item: any) => {
      if (item[0] === searchParamKey) {
        searchParamValue = item[1];
        return;
      }
    });
    return searchParamValue === "" ? defaultValue : searchParamValue;
  };

  const getSearchURL = () => {
    var searchFilters = "";

    // remove page from search params
    var params = Array.from(searchParams.entries());
    params = params.filter((item: any) => item[0] !== "page");
    var length = params.length;

    params.forEach((item: any, idx: number) => {
      searchFilters += `${item[0]}=${item[1]}${idx !== length - 1 ? "&" : ""}`;
    });
    return searchFilters;
  };

  return { getSearchParam, getSearchURL };
};
