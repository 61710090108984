import styles from "components/CustomPostCard/WidePostCard/WidePostCard.module.css";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ClockCircleFilled,
  PhoneFilled,
  EyeFilled,
  HeartFilled,
  ShareAltOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  EditOutlined,
  StarFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import {
  tachometerFast,
  calendar,
  reactivateIcon,
  noImage,
  souqCarWhite,
} from "assets/icons";
import { viewPostMainImageAction } from "redux/actions/ViewPostMainImageAction/viewPostMainImageAction";
import { listSinglePostAction } from "redux/actions/PostActions/listSinglePostAction";
import { setMapCoordinatesAction } from "redux/actions/SetMapCoordinatesAction/setMapCoordinatesAction";
import DeletePostModal from "components/ModalComponents/DeletePostModal/DeletePostModal";
import { favouritePostAction } from "redux/actions/FavouritePostActions/favouritePostAction";
import { listFavouritesAction } from "redux/actions/FavouritePostActions/listFavouritesAction";
import { set } from "lodash";

interface WidePostCardProps {
  isSpecialAd: boolean;
  showActions: boolean;
  width?: any;
  height?: any;
  id: any;
  callsCount: number;
  suspendClick?: any;
  postDescription?: any;
  reactivateClick?: any;
  viewsCount: number;
  uploadDate?: string;
  postType?: string[];
  carMaker?: string;
  postTitle: string;
  paymentOption?: string;
  category: any;
  customerName: string;
  isFavorited?: boolean;
  city: string;
  price: string;
  carCondition: string;
  productionDate: string;
  kilometrage: string;
  className?: any;
  isMobile?: boolean;
  postCardStyle?: any;
  isDeleted?: any;
  handlePostEditClick?: any;
  brandLogo: any;
  image: any;
}

const WidePostCard: FC<WidePostCardProps> = ({
  isSpecialAd = false,
  isDeleted = false,
  isFavorited = false,
  showActions,
  width = "w-[93%]",
  height = "16.5rem",
  id,
  suspendClick,
  brandLogo,
  reactivateClick,
  handlePostEditClick,
  postDescription,
  callsCount,
  viewsCount,
  uploadDate,
  postType,
  carMaker,
  postTitle,
  paymentOption,
  customerName = "Not Found",
  city,
  category,
  price,
  carCondition,
  productionDate,
  kilometrage,
  isMobile = false,
  className = "",
  postCardStyle,
  image,
}) => {
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const isArabic = currentLanguage === "ar";
  const [activateHover, setActivateHover] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { t } = useTranslation();

  const { mainImage } = useSelector(
    (state: RootState) => state.viewPostMainImageReducer
  );

  const { loading, post } = useSelector(
    (state: RootState) => state.listSinglePostReducer
  );

  const { verifyOtpLoading, userData, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { favouritePostsLoading, favouritePosts, currentPage, lastPage } =
    useSelector((state: RootState) => state.listFavouritesReducer);

  const handlePostClick = (event: any) => {
    const isFavorite = event.target.getAttribute("d")?.split(" ")[0] === "M923";
    if (isFavorite) {
      // Click originated from a child element, prevent navigation
      event.preventDefault();
      setFavoriteState((prev) => !prev);

      dispatch(favouritePostAction(id, !favoriteState));
      //
    } else {
      dispatch(viewPostMainImageAction(image));
      dispatch(listSinglePostAction(id));
      dispatch(setMapCoordinatesAction({ lat: post?.lat, lng: post?.long }));
    }
    // navigate(`/${currentLanguage}/ads/show/${id}`);
  };

  const getCategoryName = (categoryID: any) => {
    switch (categoryID) {
      case 2:
        return t("homepage_rental");
      case 3:
        return t("navbar_maintenance_text");
      case 4:
        return t("navbar_car_parts_text");
      default:
        return t("for_sale");
    }
  };

  const [favoriteState, setFavoriteState] = useState(isFavorited);

  useEffect(() => {
    const container: any = document.getElementById("priceContainer");
    const textElement: any =
      document.getElementById("priceContainer")?.firstChild;

    function shrinkTextToFit() {
      if (container) {
        const containerWidth = container?.width;
        const fontSize = parseInt(
          window.getComputedStyle(textElement).fontSize
        );

        while (textElement.width > containerWidth) {
          textElement.css("font-size", fontSize - 1);
        }
      }
    }

    shrinkTextToFit();
    window.addEventListener("resize", shrinkTextToFit);
  }, []);

  return (
    // <div className={className}>
    <div
      dir={isArabic ? "rtl" : "ltr"}
      className={`${className} w-full flex flex-row gap-x-3 items-center justify-end`}
      id="postCard"
    >
      <a
        href={`/${currentLanguage}/ads/show/${id}/${postTitle}`}
        className={`${styles.noLinkStyle} ${styles.shadow} ${
          isSpecialAd ? styles.specialAd : ""
        } ${width} ${styles.hover} ${
          styles.postCard
        } bg-white rounded-xl p-3 pe-6 flex flex-row justify-between`}
        style={{ height: height, overflow: "visible", borderRadius: "20px" }}
        onClick={handlePostClick}
      >
        <div className={`w-full h-full flex`}>
          {/* IMAGE */}
          <div>
            {/* image placeholder */}
            {/* <div className={`${styles.blueBg} rounded-xl h-full aspect-6/5`}></div> */}
            <div className={`${styles.blueBg}`}>
              <img
                src={souqCarWhite}
                alt=""
                className={`${styles.noImageIcon}`}
              />
              <div
                className={`${styles.postImage}`}
                style={{
                  backgroundImage: `url('${encodeURI(image)}')`,
                }}
              ></div>
              <div
                className={`${styles.postImageElements} w-full flex flex-row items-center justify-between`}
              >
                <div
                  className={`${
                    isSpecialAd ? styles.specialAdWatermark : "hidden"
                  } flex flex-row items-center justify-center gap-x-2 rounded-full px-3`}
                >
                  <p className="text-white text-sm mb-1">مميز</p>
                  <StarFilled className="text-white" />
                </div>
                <div
                  className={`${styles.postTypeContainer} rounded-full px-5 py-1 flex justify-center items-center`}
                  // style={{ display: isSpecialAd ? "" : "none" }}
                >
                  <p className="text-xs font-bold">
                    {getCategoryName(category)}
                  </p>
                </div>
              </div>

              <div
                className="flex items-center justify-start"
                style={{ display: isSpecialAd ? "" : "none" }}
              >
                {/* SELLER NAME  */}

                {/* <div
                  className={`${styles.grayBackgroundCustomer} flex items-center justify-between rounded-full`}
                >
                  <p>{customerName}</p>
                  <div
                    className={`${styles.blueCircleCustomer} rounded-full`}
                  ></div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="w-full h-full flex flex-col justify-between pb-1">
            {/* details section */}
            <div className="flex items-center justify-between">
              <div className="w-full flex items-center justify-between">
                {/* BRAND LOGO AND NAME */}
                {carMaker && (
                  <div>
                    {/* brand name */}
                    <div
                      className="flex flex-row items-center justify-center gap-x-2"
                      style={{ overflow: "visible" }}
                    >
                      {/*  LOGO */}
                      <div
                        style={{
                          backgroundImage: `url('${encodeURI(brandLogo)}')`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                        className={`${styles.blueCircle}`}
                      ></div>
                      <p style={{ whiteSpace: "nowrap" }}>{carMaker}</p>
                    </div>
                  </div>
                )}

                {/* VIEWS AND DATE */}
                <div className="flex flex-row items-center gap-x-4">
                  <div
                    className={`${styles.grayBackground} flex flex-row items-center gap-x-2 rounded-full`}
                  >
                    <EyeFilled className="text-xs" />
                    <p className="text-xs">{viewsCount}</p>
                  </div>
                  <div
                    className={`${styles.grayBackground} flex flex-row items-center gap-x-2 rounded-full`}
                  >
                    <PhoneFilled className="text-xs" />
                    <p className="text-xs">{callsCount}</p>
                  </div>

                  {category !== 3 && (
                    <div className="flex flex-row items-center gap-x-2">
                      <ClockCircleFilled className="text-xs" />
                      <p
                        className="text-xs"
                        style={{
                          direction: isArabic ? "rtl" : "ltr",
                        }}
                      >
                        {uploadDate}
                      </p>
                    </div>
                  )}
                </div>

                {category === 3 && (
                  <div className="flex flex-row items-center gap-x-2">
                    <p
                      className="text-xs"
                      style={{
                        direction: isArabic ? "rtl" : "ltr",
                      }}
                    >
                      {uploadDate}
                    </p>
                    <ClockCircleFilled className="text-xs" />
                  </div>
                )}
              </div>
            </div>
            {/* 
             7.08%
             13.44%
             17.64%
             23.28%
             30.24%
             41.28%
             52.8%
             */}
            {category !== 3 ? (
              <div
                className={`text-${
                  currentLanguage === "ar" ? "right" : "left"
                }`}
              >
                {/* heading */}
                <p
                  className={`text-base font-bold ${styles.postTitle}`}
                  style={{ fontFamily: "rbFont", fontSize: "1.3rem" }}
                >
                  {postTitle}
                </p>
                {/* location */}
                <p className="">{city}</p>
              </div>
            ) : (
              <div className="h-full flex items-center justify-start ">
                <div className={`flex flex-col items-start`}>
                  {/* heading */}
                  <p
                    className={`text-base font-bold ${styles.postTitle}`}
                    style={{ fontFamily: "rbFont", fontSize: "1.3rem" }}
                  >
                    {postTitle}
                  </p>

                  {/* location */}
                  <p>{city}</p>

                  {/* heading */}
                  <p
                    className={`text-base font-bold ${styles.postDescription}`}
                    style={{ fontFamily: "rbFont" }}
                    dangerouslySetInnerHTML={{ __html: postDescription }}
                  >
                    {/* {postDescription} */}
                  </p>
                </div>
              </div>
            )}

            {/* icons and text */}
            {category !== 3 && (
              <div className="flex flex-row justify-start gap-x-3">
                <div
                  className={`${styles.detailsText} flex flex-row items-center justify-center gap-x-1`}
                >
                  <img src={calendar} alt="" />
                  <p>
                    <span className={`${styles.lightFont}`}>
                      {productionDate}
                    </span>
                  </p>
                </div>

                <div
                  className={`${styles.detailsText} flex flex-row items-center justify-center gap-x-1`}
                >
                  <img src={tachometerFast} alt="" />
                  <p>
                    <span className={`${styles.lightFont}`}>
                      {kilometrage?.toLocaleString()} {t("homepage_km")}
                    </span>
                  </p>
                </div>
              </div>
            )}

            <div className="flex items-center justify-between">
              {category !== 3 && (
                <div className="flex items-center justify-between">
                  {/* price */}
                  {/* <p className="text-sm mr-2 ml-4">{t("upfront")}</p> */}
                  <div
                    id="priceContainer"
                    className={`${styles.grayBackground} flex items-baseline rounded-full px-2 py-1`}
                    style={{ textOverflow: "ellipsis" }}
                  >
                    <p>{price?.toLocaleString()}</p>
                    <p
                      className="text-xs"
                      style={{
                        height: "fit-content",
                        color: "var(--souq-blue)",
                        paddingInlineEnd: "0.3rem",
                        paddingInlineStart: "0.1rem",
                      }}
                    >
                      {t("egp")}
                    </p>
                  </div>
                  {/* buttons */}
                  <div className="flex justify-start">
                    {carCondition ? (
                      <p
                        className={`${styles.borderBlue} rounded-full ms-3 px-3 py-1`}
                      >
                        {carCondition}
                      </p>
                    ) : (
                      <></>
                    )}
                    {paymentOption &&
                    (paymentOption === "Installment" ||
                      paymentOption === "تقسيط") ? (
                      <p
                        className={`${styles.orangeBorder} rounded-full ms-3 px-3 py-1`}
                      >
                        {paymentOption}
                      </p>
                    ) : (
                      <></>
                    )}
                    {Array.isArray(postType) &&
                      postType?.map((item: any, idx: any) => {
                        return item ? (
                          <p
                            className={`${styles.borderBlue} rounded-full px-3 py-1`}
                            key={idx}
                          >
                            {item}
                          </p>
                        ) : (
                          <></>
                        );
                      })}
                  </div>
                </div>
              )}

              {/* share */}
              <div className="flex flex-row items-center gap-x-2">
                {/* FAVORITE */}
                {userData?.token && (
                  <div>
                    <HeartFilled
                      className={`${
                        favoriteState ? "favoriteIconSelected" : "favoriteIcon"
                      }`}
                      // onClick={handlePostClick}
                      style={{ fontSize: "1.5rem", marginRight: ".7rem" }}
                    />
                  </div>
                )}

                {category !== 3 && (
                  <div
                    className={`${styles.grayBackground} ${styles.shareButton} flex flex-row items-center justify-center gap-x-1 rounded-full`}
                  >
                    <p className="text-sm">{t("viewpost_share")}</p>
                    <ShareAltOutlined className="text-sm" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col justify-between">
          <div className="flex flex-row items-center gap-x-4">
              <div className="flex flex-row items-center gap-x-2">
                <p className="text-xs">{uploadDate}</p>
                <ClockCircleFilled className="text-xs" />
              </div>
              <div
                className={`${styles.grayBackground} flex flex-row items-center gap-x-2 rounded-full px-1`}
              >
                <p className="text-xs">{callsCount}</p>
                <PhoneFilled className="text-xs" />
              </div>
              <div
                className={`${styles.grayBackground} flex flex-row items-center gap-x-2 rounded-full px-1`}
              >
                <p className="text-xs">{viewsCount}</p>
                <EyeFilled className="text-xs" />
              </div>
            </div>
          <div className="flex flex-row items-center gap-x-2">
              <HeartFilled
                className="text-stone-300"
                style={{ fontSize: "1.5rem", marginRight: ".7rem" }}
              />
              <div
                className={`${styles.grayBackground} ${styles.shareButton} flex flex-row items-center justify-center gap-x-1 rounded-full`}
              >
                <p className="text-sm">{t("viewpost_share")}</p>
                <ShareAltOutlined className="text-sm" />
              </div>
            </div>
        </div>
        <div className="flex flex-row items-start justify-end gap-x-6">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-row items-center justify-end gap-x-2">
                <p>{carMaker}</p>
                <div className={`${styles.blueBg} w-4 h-4 rounded-full`}></div>
              </div>
            <div className="flex flex-col gap-y-1">
                <p className="font-bold text-base text-right">{postTitle}</p>
                <p className="text-right">{city}</p>
              </div>
            <div className="flex flex-row justify-end gap-x-3">
                <div className="flex flex-row items-center justify-center gap-x-1">
                  <p>{productionDate}</p>
                  <CalendarOutlined className="text-blue-600 font-bold" />
                </div>
                <div className="flex flex-row items-center justify-center gap-x-1">
                  <p>{kilometrage}</p>
                  <ClockCircleOutlined className="text-blue-600 font-bold" />
                </div>
              </div>
            <div className="flex flex-row items-center justify-end gap-x-3">
                {postType?.map((item: any, idx: any) => {
                  return (
                    <p
                      className={`${styles.borderBlue} rounded-full px-3 py-1`}
                      key={idx}
                    >
                      {item}
                    </p>
                  );
                })}
                <p className={`${styles.borderBlue} rounded-full px-3 py-1`}>
                  {carCondition}
                </p>
                <div
                  className={`${styles.grayBackground} flex flex-row items-center justify-center rounded-full py-2 px-6`}
                >
                  <p>{price}</p>
                </div>
              </div>
          </div>
        </div> */}
      </a>

      {showActions && (
        <div className="h-[200px] w-[8%] flex flex-col items-center justify-center gap-y-3">
          <div
            className={`${styles.shadow} ${styles.optionsButton}  bg-white p-3 rounded-xl flex flex-col items-center justify-center`}
            onClick={async () => {
              await handlePostEditClick();
              navigate(`/${currentLanguage}/post/update`);
            }}
          >
            <div className={`${styles.optionsIcon}`}>
              <EditOutlined className="text-lg text-blue-400" />
            </div>
            <p>{t("edit")}</p>
          </div>
          {!isDeleted ? (
            <div
              className={`${styles.shadow} ${styles.optionsButton} bg-white p-3 rounded-xl flex flex-col items-center justify-center`}
              onClick={() => setOpen(true)}
            >
              <div className={`${styles.optionsIcon}`}>
                <DeleteOutlined className="text-lg text-red-400" />
              </div>
              <DeletePostModal
                open={open}
                setOpen={setOpen}
                suspendClick={suspendClick}
              />
              <p>{t("delete")}</p>
            </div>
          ) : (
            <div
              className={`${styles.shadow} ${styles.optionsButton} ${styles.optionsButtonReactivate} rounded-xl flex flex-col items-center justify-center`}
              onMouseEnter={() => setActivateHover(true)}
              onClick={reactivateClick}
            >
              <span
                onMouseLeave={() => setActivateHover(false)}
                className={`material-symbols-outlined ${styles.neon} ${styles.icon}`}
              >
                redo
              </span>

              <p>{t("activate")}</p>
            </div>
          )}
        </div>
      )}
    </div>
    // </div>
  );
};

export default WidePostCard;
