import
{
    SEND_CHAT_MESSAGE_LOADING,
    SEND_CHAT_MESSAGE_SUCCESS,
    SEND_CHAT_MESSAGE_FAILURE
}
from "redux/types";

const initialState = {
    chatMessageLoading: false,
    chatMessageSuccess: null,
    errors: null
}

export const sendChatMessageReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SEND_CHAT_MESSAGE_LOADING :
            return {...state, chatMessageLoading: action.payload};
        case SEND_CHAT_MESSAGE_SUCCESS :
            return {...state, chatMessageSuccess: action.payload.message};
        case SEND_CHAT_MESSAGE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}