import
{
    UPDATE_BRANCH_LOADING,
    UPDATE_BRANCH_SUCCESS,
    UPDATE_BRANCH_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateBranchService } from "services/ExhibitorServices/updateBranchService";

export const updateBranchAction = (id: any, data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_BRANCH_LOADING, payload: true});
        const response = await updateBranchService(id, data);
        dispatch({type: UPDATE_BRANCH_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: UPDATE_BRANCH_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_BRANCH_LOADING, payload: false});
    }
}