import api from "api";
import { updateGeneralDataEndPoint } from "api/apiUrls";
import store from "redux/store";

export const updateGeneralDataService = async (data: any) => {
    const response = await api.post(updateGeneralDataEndPoint(), data, {
        headers: {
            'Authorization' :  `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
        }
    });

    return response;
}