import * as React from "react";
import type { SVGProps } from "react";
const SvgSendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.125.833a3.035 3.035 0 0 0-2.78-.755L3.968 2.756C1.43 3.114-.325 5.366.051 7.785c.146.94.606 1.811 1.312 2.484l1.434 1.366c.067.063.104.15.104.24v2.52c.002 1.568 1.336 2.838 2.981 2.84h2.646c.095 0 .185.035.252.1l1.434 1.365A4.741 4.741 0 0 0 13.49 20c.508 0 1.013-.08 1.494-.234 1.66-.525 2.867-1.9 3.117-3.549l2.81-12.7a2.73 2.73 0 0 0-.786-2.684ZM2.73 6.522c.238-.7.88-1.205 1.646-1.296l.092-.015 11.655-2.333-10.598 10.09v-1.093a2.753 2.753 0 0 0-.875-2.008L3.219 8.501a1.832 1.832 0 0 1-.488-1.98Zm12.793 9.224c-.007.029-.012.058-.016.087-.152 1.056-1.174 1.794-2.282 1.65a2.065 2.065 0 0 1-1.156-.547l-1.433-1.365a3.036 3.036 0 0 0-2.109-.834H7.381L17.977 4.642l-2.453 11.104Z"
    />
  </svg>
);
export default SvgSendIcon;
