import
{
    EXHIBITOR_DATA_LOADING,
    EXHIBITOR_DATA_SUCCESS,
    EXHIBITOR_DATA_FAILURE
}
from "redux/types";

const initialState = {
    exhibitorDataLoading: false,
    exhibitorData: null,
    branchesData: null,
    errors: null
};

export const listExhibitorDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case EXHIBITOR_DATA_LOADING :
            return {...state, exhibitorDataLoading: action.payload};
        case EXHIBITOR_DATA_SUCCESS :
            return {...state, exhibitorData: action.payload.data, branchesData: action.payload.branches};
        case EXHIBITOR_DATA_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}