import i18n from 'i18next';

export const changeLanguage = (lang: 'en' | 'ar') => {
    i18n.changeLanguage(lang);
    localStorage.setItem('preferredLanguage', lang); // Update preferred language in localStorage
}

const getPreferredLanguage = () => {
    try {
        const storedLanguage = localStorage.getItem('preferredLanguage');
        return storedLanguage || 'ar'; // Default to 'en' (English) if no preference is found in localStorage
    } catch (err) {
        return 'ar';
    }
};
  
export default getPreferredLanguage;