import api from "api/amanleekApi";
import { amanleekCarModelsEndPoint } from "api/apiUrls";
import store from "redux/store";

export const amanleekListCarModelsService = async () => {
    const response = await api.get(
        amanleekCarModelsEndPoint(),
        {
            headers: {
                "Authorization" : `Bearer ${store.getState()?.amanleekAuthenticationReducer?.amanleekToken}`
            }
        }
    );

    return response;
}