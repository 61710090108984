import styles from "components/V3/WideArticleCard/WideArticleCard.module.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/rootReducer";

interface WideArticleCardProps {
  id: any;
  image: string;
  title: string;
  description: string;
  date: string;
}

const WideArticleCard: FC<WideArticleCardProps> = ({
  id,
  image,
  title,
  description,
  date,
}) => {
  const { t } = useTranslation();

  const { currentLanguage } = useSelector((state: RootState) => state.changeLanguageReducer);

  const navigate = useNavigate();

  return (
    <div
      dir="rtl"
      className={`w-[75rem] h-[19rem] rounded-[0.9375rem] px-[0.63rem] flex flex-row items-center justify-start gap-x-[1.41rem] ${styles.shadow}`}
      onClick={() => {
        navigate(`/${currentLanguage}/News/view/${id}`);
      }}
    >
      <div
        style={{
          backgroundImage: `url('${image}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className={`w-[23.25rem] h-[17.47388rem] rounded-[0.625rem]`}
      ></div>
      <div className="h-full py-[1.53rem] flex flex-col justify-between">
        <div className="h-full flex flex-col justify-start gap-y-[0.62rem]">
          <p className={`text-[0.875rem]`}>{date}</p>
          <p className={`text-[1.25rem] font-[700]`}>{title}</p>
          <div
            className={`${styles.descriptionSection} flex-grow max-w-[47.125rem] line-clamp-6 overflow-ellipsis`}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          {/* <p
            className={`${styles.grayColor} max-w-[47.125rem] text-[0.9375rem] text-right line-clamp-4`}
          >
            {description}
          </p> */}
        </div>
        <p
          className={`text-[var(--new-blue)] text-[0.9375rem] hover:cursor-pointer`}
          onClick={() => {}}
        >
          {t("read_more")}...
        </p>
      </div>
    </div>
  );
};

export default WideArticleCard;
