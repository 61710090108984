import { FC } from "react";
import { checkCircle } from "assets/icons";
import { useTranslation } from "react-i18next";
import styles from "components/CustomImageContainer/CustomImageContainer.module.css";
import {
  CloseOutlined,
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons";

interface CustomImageContainerProps {
  thumbUrl: any;
  deleteButtonOnClick: () => void;
  makeCoverPhotoOnClick?: () => void;
  opacity: any;
  isCover?: boolean;
}

const CustomImageContainer: FC<CustomImageContainerProps> = ({
  thumbUrl,
  deleteButtonOnClick,
  makeCoverPhotoOnClick,
  isCover = false,
  opacity,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "10.899rem",
        height: "9.82913rem",
        // border: "1px solid black",
        borderRadius: "var(--radius-xs)",
        padding: "5px",
        backgroundImage: `url('${encodeURI(thumbUrl)}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        opacity: opacity,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "end",
      }}
    >
      <div className="w-full flex items-center justify-start cursor-pointer">
        <div className={`${styles.deleteCircle}`} onClick={deleteButtonOnClick}>
          <DeleteOutlined />
        </div>
      </div>
      <div className="w-full justify-start">
        <div
          style={{
            padding: ".25rem",
            paddingLeft: ".6rem",
            borderRadius: "20px",
            fontSize: "0.7rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            width: "fit-content",
            columnGap: ".3rem",
            transition: "all 200ms",
          }}
          className={`hover:cursor-pointer ${
            isCover ? styles.coverBackground : styles.notCoverBackground
          }`}
          onClick={makeCoverPhotoOnClick}
        >
          <div
            style={{
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isCover ? (
              <div className={`${styles.checkIcon}`}>
                <CheckOutlined />
              </div>
            ) : (
              <div className={`${styles.uncheckedState}`}></div>
            )}
          </div>
          <p>{t("mainCover")}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomImageContainer;
