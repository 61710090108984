import { POST_CATEGORY_STATE, } from "redux/types";

const initialState = {
  categoryState: -1,
};

export const postCategoryStateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_CATEGORY_STATE:
      return { ...state, categoryState: action.payload };
    default:
      return state;
  }
};
