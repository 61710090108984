import * as React from "react";
import type { SVGProps } from "react";
const SvgPhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 12.453 12.455"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12.435 9.414-.566 2.452a.756.756 0 0 1-.741.589A11.141 11.141 0 0 1 0 1.326.756.756 0 0 1 .589.585L3.041.02a.763.763 0 0 1 .87.441L5.043 3.1a.761.761 0 0 1-.218.887l-1.31 1.051a8.505 8.505 0 0 0 3.88 3.88l1.072-1.312a.757.757 0 0 1 .887-.218l2.639 1.131a.83.83 0 0 1 .442.895Z"
      data-name="Path 646"
    />
  </svg>
);
export default SvgPhoneIcon;
