import { FC, useEffect, useState } from "react";
import { Layout } from "antd";
import styles from "components/MainViewContainer/mainViewContainer.module.css";
import CustomHeader from "components/V3/CustomHeader/CustomHeader";
import { use } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { drawerStatusAction } from "redux/actions/DrawerStatusActions/drawerStatusAction";
import Headroom from "react-headroom";
import Drawer from "components/V3/Drawer/Drawer";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

interface props {
  children: React.ReactNode;
}

const MainViewContainer: FC<props> = ({ children }) => {
  const { drawerOpen } = useSelector(
    (state: RootState) => state.drawerStatusReducer
  );

  const dispatch: any = useDispatch();
  const location = useLocation();

  const renderHeader = () => {
    if (location.pathname.includes("/store")) {
      return <CustomHeader />;
    } else {
      return (
        <Headroom
          wrapperStyle={{
            position: "relative",
            zIndex: 99,
            backgroundColor: "var(new-souq-background)",
          }}
        >
          <CustomHeader />
        </Headroom>
      );
    }
  };

  return (
    <Layout className={styles.layoutContainer}>
      <Layout
        className={`${styles.body}`}
        style={{ backgroundColor: "var(--new-souq-background)" }}
      >
        <Content style={{ height: "100%" }}>
          {renderHeader()}
          {drawerOpen ? <Drawer /> : children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainViewContainer;
