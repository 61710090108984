import styles from "components/ModalComponents/ReportModalComponent/ReportModalComponent.module.css";
import { useTranslation } from "react-i18next";
import { RootState } from "redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { sendMessageAction } from "redux/actions/SendMessageAction/sendMessageAction";
import { Input, Spin, notification } from "antd";
import { useResponsive } from "hooks/useResponsive";

interface ModalComponentProps {
  dir: any;
  isArabic: boolean;
}

const ModalComponent: FC<ModalComponentProps> = ({ dir, isArabic }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [reportMessage, setReportMessage] = useState<any>();

  const { t } = useTranslation();

  const dispatch: any = useDispatch();

  const { isMobile } = useResponsive();

  const { loading, success } = useSelector(
    (state: RootState) => state.sendMessageReducer
  );
  const { verifyOtpLoading, userData, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const showModal = () => {
    setReportMessage("");
    setOpen(true);
  };

  useEffect(() => {}, []);

  const handleOk = async () => {
    if (reportMessage.trim() !== "") {
      setConfirmLoading(true);
      await dispatch(
        sendMessageAction(
          "Report Message",
          userData && userData?.user && userData?.user?.email
            ? userData?.user.email
            : "No Email",
          reportMessage
        )
      );

      if (success === true) {
        notification.success({
          message: t("success_notification_message"),
          description: t("success_notification_description"),
        });
        setOpen(false);
        setConfirmLoading(false);
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={`${styles.modalLink}`} onClick={showModal}>
        <p className={`${isMobile ? "text-[0.625rem]" : "text-[0.8125rem]"}`}>{t("viewpost_report")}</p>
      </div>

      <Modal
        title={
          <p
            className={`w-full font-bold`}
            style={{
              direction: isArabic ? "ltr" : "rtl",
              textAlign: isArabic ? "right" : "left",
            }}
          >
            {t("viewpost_report")}
          </p>
        }
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        closable={false}
      >
        <div className={`${styles.containerRow}`}>
          <div>
            <TextArea
              rows={6}
              placeholder={t("report_message_placeholder")}
              // className="text-right"
              value={reportMessage}
              name="description"
              onChange={(value: any) => setReportMessage(value.target.value)}
              // status={formik.errors.description ? "error" : ""}
              dir={dir}
            />
            {/* <p>{reportMessage}</p> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalComponent;
