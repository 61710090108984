import
{
    DISPLAY_MOBILE_LOADING,
    DISPLAY_MOBILE_SUCCESS,
    DISPLAY_MOBILE_FAILURE,
    UPDATE_CUSTOMER_DATA,
}
from "redux/types";

import { Dispatch } from "redux";
import store from "redux/store";
import { displayMobileService } from "services/displayMobileService";

export const displayMobileAction = (show: boolean) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: DISPLAY_MOBILE_LOADING,
            payload: true
        });

        const response = await displayMobileService(show);

        dispatch({
            type: DISPLAY_MOBILE_SUCCESS,
            payload: response.data
        });

        dispatch({
            type: UPDATE_CUSTOMER_DATA, 
            payload: {
                token: store.getState()?.verifyOtpReducer?.userData?.token,
                user: response.data?.customer
            }
        });
        
    } catch (error) {
        dispatch({
            type: DISPLAY_MOBILE_FAILURE,
            payload: error
        });
    } finally {
        dispatch({
            type: DISPLAY_MOBILE_LOADING,
            payload: false
        });
    }
}