import { useLanguage } from "hooks/useLanguage";
import React, { FC } from "react";
import styles from "./RenderCards.module.css";

interface RenderCardsProps {
  formik: any;
  items: any;
  name: string;
  state: any;
  setState: any;
  gap?: string;
  numberPerRow?: number;
  print?: any;
}

const RenderCards: FC<RenderCardsProps> = ({
  formik,
  items,
  name,
  state,
  setState,
  gap = "1.81rem",
  numberPerRow,
  print,
}) => {
  const calculatedNumberPerRow: number = numberPerRow ?? items?.length ?? 3;
  const { textDirectionStyle } = useLanguage();

  return (
    <div
      className={`w-full flex flex-wrap items-center justify-between`}
      style={{
        gap: gap,
        direction: textDirectionStyle, // Assuming textDirectionStyle is defined
      }}
    >
      {items?.map((item: any) => {
        const maxWidth = `calc(100% / ${calculatedNumberPerRow} - ${gap})`;
        if (print) {
        }
        return (
          <div
            key={item.key} // Assuming item.key is unique
            className={`${styles.cardOption} ${
              state === item.key ? styles.cardActive : styles.cardInactive
            }`}
            style={{
              maxWidth: maxWidth,
            }}
            onClick={() => {
              setState(item.key);
              formik.setFieldValue(name, item.key);
            }}
          >
            <p>{item.name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default RenderCards;
