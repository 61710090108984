import
{
    SUSPEND_POST_LOADING,
    SUSPEND_POST_SUCCESS,
    SUSPEND_POST_FAILURE
}
from "redux/types";

const initialState = {
    suspendPostLoading: false,
    suspendPostSuccess: null,
    errors: null
}

export const suspendPostReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SUSPEND_POST_LOADING :
            return {...state, suspendPostLoading: action.payload};
        case SUSPEND_POST_SUCCESS :
            return {...state, suspendPostSuccess: action.payload.success};
        case SUSPEND_POST_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}