import { Carousel } from "antd";
import "components/Carousel/CarImagesCarousel/car-images-carousel.css";
import { at, get, set } from "lodash";
import { FC, useEffect, useState } from "react";
import { RootState } from "redux/rootReducer";
import { viewPostMainImageAction } from "redux/actions/ViewPostMainImageAction/viewPostMainImageAction";
import { useDispatch, useSelector } from "react-redux";
import { dir } from "console";

interface CarImagesCarouselProps {
  data: any;
  // onClick: any;
  setFade: any;
  autoplay: boolean;
  setAutoplay: any;
  // selectedImageIndex: number;
  // setSelectedImageIndex: any;
}
let atStart = true;
let atEnd = false;

const CarImagesCarousel: FC<CarImagesCarouselProps> = ({
  data,
  // onClick,
  setFade,
  autoplay,
  setAutoplay,
  // selectedImageIndex,
  // setSelectedImageIndex
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [direction, setDirection] = useState("right");

  useEffect(() => {
    handleImageClick(currentIndex, data[currentIndex]);
  }, [currentIndex]);

  useEffect(() => {
    let interval: any;

    if (autoplay) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          return prevIndex === data.length - 1 ? 0 : prevIndex + 1;
        });
      }, 2500);
    }

    return () => {
      clearInterval(interval);
    };
  }, [autoplay, data]);

  const handleMouseEnter = () => {
    setAutoplay(false);
  };

  const handleMouseLeave = () => {
    setAutoplay(true);
  };

  const dispatch: any = useDispatch();

  const handleImageClick = async (index: number, item: any) => {
    await setFade(true);
    setCurrentIndex(index);
    await setFade(false);

    // onClick(item.media_url);

    dispatch(viewPostMainImageAction(item.media_url));

    const carousel: any = document.getElementById("imgsCarousel");
    const carouselChildrens = carousel ? Array.from(carousel.children) : [];

    // handle scrolling the carousel to the correct position
    const firstCardWidth = carousel?.querySelector(".imgsCard")?.offsetWidth;

    if (index == 0 && carousel) {
      // scroll to the start
      carousel.scrollLeft = 0;
    }
    // if the current card is not in view, scroll to it
    else if (
      (carousel && index * firstCardWidth < carousel.scrollLeft) ||
      index * firstCardWidth > carousel.scrollLeft + carousel.offsetWidth
    ) {
      // the card should be the leftmost card in view
      carousel.scrollLeft = index * firstCardWidth;
    }
    // scroll the carousel 1 width at a time. If the scroll reaches the end, dont scroll
    else if (
      carousel &&
      index * firstCardWidth > carousel.scrollLeft &&
      direction === "right"
    ) {
      // scroll right
      carousel.scrollLeft += firstCardWidth;
    } else {
      // scroll left
      if (carousel) {
        carousel.scrollLeft -= firstCardWidth;
      }
    }

    // set this item's border to blue
    // remove border from other items
    carouselChildrens.forEach((child: any, i: number) => {
      if (i === index) {
        child.style.outline = ".125rem solid blue";
        child.style.outlineOffset = "-.125rem";
      } else {
        child.style.outline = "none";
      }
    });

    // reset direction
    setDirection("right");
  };

  const handlePrevClick = () => {
    if (currentIndex === 0) return;

    setDirection("left");
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleNextClick = () => {
    if (currentIndex === data.length - 1) return;

    setDirection("right");
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  //   const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  //   const { mainImage } = useSelector(
  //     (state: RootState) => state.viewPostMainImageReducer
  //   );

  //   // This useEffect will log the updated value of selectedImageIndex whenever it changes.
  //   useEffect(() => {
  //     handleImageClick(selectedImageIndex, data[selectedImageIndex]);

  //     index = selectedImageIndex;

  //     // METHODS
  //     wrapper = document.querySelector(".imgsWrapper");
  //     carousel = document.getElementById("imgsCarousel");
  //     firstCardWidth = carousel?.querySelector(".imgsCard")?.offsetWidth;
  //     const arrowBtns = document.querySelectorAll(".imgsWrapper i");
  //     const carouselChildrens = [...carousel?.children];

  //     let isDragging = false,
  //       isAutoPlay = true,
  //       startX: any,
  //       startScrollLeft: any;

  //     // let timeoutId: any;
  //     let forward = true;
  //     let extra = false;

  //     infiniteScroll = () => {
  //       if (!carousel || !wrapper) return;

  //       if (carousel.scrollLeft === 0) {
  //         if (atStart) {
  //           extra = false;
  //           forward = true;
  //         } else {
  //           extra = true;
  //         }
  //       } else if (
  //         Math.abs(
  //           Math.ceil(carousel.scrollLeft) -
  //             (carousel.scrollWidth - carousel.offsetWidth)
  //         ) <= 1
  //       ) {
  //         if (atEnd) {
  //           extra = false;
  //           forward = false;
  //         } else {
  //           extra = true;
  //         }
  //       }

  //       clearTimeout(timeoutId);
  //       if (!wrapper.matches(":hover")) autoPlay();
  //     };

  //     autoPlay = () => {
  //       if (!carousel || !firstCardWidth) return;

  //       if (forward) {
  //         timeoutId = setTimeout(async () => {
  //           await setFade(true);
  //           setSelectedImageIndex((prevIndex) =>
  //             Math.min(prevIndex + 1, data.length - 1)
  //           );

  //           if (!extra) carousel.scrollLeft += firstCardWidth;
  //           else infiniteScroll();
  //           await setFade(false);
  //         }, 2500);
  //       } else {
  //         timeoutId = setTimeout(async () => {
  //           await setFade(true);
  //           setSelectedImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));

  //           if (!extra) carousel.scrollLeft -= firstCardWidth;
  //           else infiniteScroll();
  //           await setFade(false);
  //         }, 2500);
  //       }
  //     };
  //   }, [selectedImageIndex]);

  //   let index = 0;

  //   // const getIndex =
  //   //     return index;

  //   let timeoutId: any;

  //   let forward = true;
  //   let extra = false;

  //   var infiniteScroll: any;
  //   var autoPlay: any;

  //   let carousel: any, firstCardWidth: any, wrapper: any;

  //   useEffect(() => {
  //     wrapper = document.querySelector(".imgsWrapper");
  //     carousel = document.getElementById("imgsCarousel");
  //     firstCardWidth = carousel?.querySelector(".imgsCard")?.offsetWidth;
  //     const arrowBtns = document.querySelectorAll(".imgsWrapper i");
  //     const carouselChildrens = [...carousel?.children];

  //     let isDragging = false,
  //       isAutoPlay = true,
  //       startX: any,
  //       startScrollLeft: any;

  //     // Get the number of cards that can fit in the carousel at once
  //     let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

  //     // Insert copies of the last few cards to beginning of carousel for infinite scrolling
  //     // carouselChildrens.slice(-cardPerView).reverse().forEach((card: any) => {
  //     //     carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
  //     // });

  //     // Insert copies of the first few cards to end of carousel for infinite scrolling
  //     // carouselChildrens.slice(0, cardPerView).forEach((card: any) => {
  //     //     carousel.insertAdjacentHTML("beforeend", card.outerHTML);
  //     // });

  //     // Scroll the carousel at appropriate postition to hide first few duplicate cards on Firefox
  //     carousel.classList.add("no-transition");
  //     carousel.scrollLeft = 0;
  //     carousel.classList.remove("no-transition");

  //     // Add event listeners for the arrow buttons to scroll the carousel left and right
  //     arrowBtns.forEach((btn) => {
  //       btn.addEventListener("click", () => {
  //         carousel.scrollLeft +=
  //           btn.id == "left" ? -firstCardWidth : firstCardWidth;
  //       });
  //     });

  //     const dragStart = (e: any) => {
  //       isDragging = true;
  //       carousel.classList.add("dragging");
  //       // Records the initial cursor and scroll position of the carousel
  //       startX = e.pageX;
  //       startScrollLeft = carousel.scrollLeft;
  //     };

  //     const dragging = (e: any) => {
  //       if (!isDragging) return; // if isDragging is false return from here
  //       // Updates the scroll position of the carousel based on the cursor movement
  //       carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
  //     };

  //     const dragStop = () => {
  //       isDragging = false;
  //       carousel.classList.remove("dragging");
  //     };

  //     autoPlay();

  //     carousel.addEventListener("mousedown", dragStart);
  //     carousel.addEventListener("mousemove", dragging);
  //     document.addEventListener("mouseup", dragStop);
  //     carousel.addEventListener("scroll", infiniteScroll);
  //     wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
  //     wrapper.addEventListener("mouseleave", autoPlay);
  //   }, []);

  return (
    <div
      className="imgsWrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* LEFT ARROW */}
      {data?.length > 4 && (
        <i
          onClick={handlePrevClick}
          id="left"
          className="fa-solid fa-angle-left"
        ></i>
      )}

      <ul id="imgsCarousel" className="imgsCarousel">
        {data?.map((item: any, index: number) => {
          return (
            <div
              key={item?.id}
              id="imgsCard"
              className={`${
                currentIndex === index ? "selectedCard" : "notSelectedCard"
              } imgsCard`}
              onClick={() => handleImageClick(index, item)}
              style={{
                // background: `url(${item?.media_url})`,
                // backgroundSize: "cover",
                // backgroundPosition: "center",
                // // outline: selectedImageIndex === index ?  : "none",
                outlineOffset: "-.125rem",
              }}
            >
              <div
                className="carImage"
                style={{
                  backgroundImage: `url('${encodeURI(item?.media_url)}')`,
                }}
              ></div>
              {/* <img className="carImage" src={item?.media_url} alt="" /> */}
            </div>
          );
        })}
      </ul>

      {/* RIGHT ARROW */}
      {data?.length > 4 && (
        <i
          id="right"
          onClick={handleNextClick}
          className="fa-solid fa-angle-right"
        ></i>
      )}
    </div>
  );
};

export default CarImagesCarousel;
