import
{
    CHANGE_LANGUAGE
}
from "redux/types";

const initialState = {
    currentLanguage: 'ar'
}

export const changeLanguageReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CHANGE_LANGUAGE :
            return {...state, currentLanguage: action.payload};
        default:
            return state;
    }
}