import styles from "components/ProfileDataCard/ProfileDataCard.module.css";
import { FC } from "react";
import { infinityIcon, messageIcon } from "assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useResponsive } from "hooks/useResponsive";
import { useLanguage } from "hooks/useLanguage";
import { StarFilled } from "@ant-design/icons";
import infoIcon from "assets/icons/raw-svgs/InfoIcon.svg";
import { Tooltip } from "antd";

interface ProfileDataCardProps {
  circularSectionData: string;
  tooltipText: string;
  primaryText: string;
  secondaryText?: string;
  secondaryTextColor?: string;
  isSpecial?: boolean;
  style?: any;
  isStars?: boolean;
}

const ProfileDataCard: FC<ProfileDataCardProps> = ({
  circularSectionData,
  primaryText,
  secondaryText,
  tooltipText,
  // secondaryTextColor = "orange",
  secondaryTextColor = "blue",
  isSpecial = false,
  style = {},
  isStars = false,
}) => {
  const { currentLanguage, isArabic, textDirectionStyle } = useLanguage();

  const { isMobile } = useResponsive();
  const newCard = true;

  const padding = newCard ? "p-3" : "p-6";
  const flexDir = newCard ? "flex-row" : "flex-col";
  const circleSize = newCard
    ? isMobile
      ? "h-11"
      : "h-14"
    : "w-20 h-20";

  return (
    <div
      dir={textDirectionStyle}
      className={`${
        styles.profileDataCardContainer
      } ${style} bg-white ${padding} flex ${flexDir} items-center justify-start gap-y-5 gap-x-${
        isMobile ? "2" : "3"
      } w-full rounded-xl`}
    >
      {/* INFO */}
      <Tooltip
        className="cursor-pointer"
        title={<p dir={textDirectionStyle}>{tooltipText}</p>}
      >
        <div className={`${isArabic ? styles.infoBtnAr : styles.infoBtn}`}>
          <img src={infoIcon} alt="Info" />
        </div>
      </Tooltip>
      {/* CIRCLE */}
      <div
        className={`${styles.circularSection} ${
          isSpecial ? styles.specialCard : styles.normalCard
        } ${circleSize} flex flex-row items-center justify-center rounded-full`}
      >
        {circularSectionData === "-111" ? (
          <img
            className={`${styles.infinityIcon}`}
            src={infinityIcon}
            alt="infinity"
          />
        ) : circularSectionData === "-222" ? (
          <img
            className={`${styles.infinityIcon} ${styles.messageIcon}`}
            src={messageIcon}
            alt="message"
          />
        ) : (
          <p
            className={`${styles.circularSectionData} font-bold ${
              isMobile ? "text-base" : "text-lg"
            }`}
          >
            {circularSectionData}
          </p>
        )}

        {/* STAR */}
        {isStars ? (
          <div className={`${styles.star}`}>
            <StarFilled style={{ fontSize: newCard ? "1.2rem" : "1.6rem" }} />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div
        className={`w-full flex flex-col ${
          !newCard && "items-center"
        } justify-center gap-y-${newCard ? (isMobile ? "1" : "2") : "3"}`}
      >
        {/* PRIMARY TEXT */}
        {circularSectionData !== "-222" ? (
          <p className={`w-full ${isMobile ? "text-[0.8rem]" : "text-base"}`}>
            {primaryText}
          </p>
        ) : (
          <a
            className={`w-full text-center ${
              secondaryTextColor === "orange"
                ? styles.secondaryTextOrange
                : styles.secondaryTextBlue
            } ${styles.messageLink}`}
            href={`/${currentLanguage}/store`}
          >
            {secondaryText}
          </a>
        )}
        {/* SECONDARY TEXT */}
        {secondaryText && circularSectionData !== "-222" ? (
          <a
            className={`${
              secondaryTextColor === "orange"
                ? styles.secondaryTextOrange
                : styles.secondaryTextBlue
            }`}
            href={`/${currentLanguage}/store`}
          >
            {secondaryText}
          </a>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProfileDataCard;
