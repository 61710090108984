import
{
    ACTIVATE_OUTSTANDING_POST_LOADING,
    ACTIVATE_OUTSTANDING_POST_SUCCESS,
    ACTIVATE_OUTSTANDING_POST_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { activateOutstandingPostService } from "services/activateOutstandingPostService";

export const activateOutstandingPostAction = (postUUID: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: ACTIVATE_OUTSTANDING_POST_LOADING, payload: true});
        const response = await activateOutstandingPostService(postUUID);
        dispatch({type: ACTIVATE_OUTSTANDING_POST_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: ACTIVATE_OUTSTANDING_POST_FAILURE, payload: err});
    } finally {
        dispatch({type: ACTIVATE_OUTSTANDING_POST_LOADING, payload: false});
    }
}