import
{
    REACTIVATE_POST_LOADING,
    REACTIVATE_POST_SUCCESS,
    REACTIVATE_POST_FAILURE
}
from "redux/types";

const initialState = {
    reactivatePostLoading: false,
    reactivatePostSuccess: null,
    errors: null
}

export const reactivatePostReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case REACTIVATE_POST_LOADING :
            return {...state, reactivatePostLoading: action.payload};
        case REACTIVATE_POST_SUCCESS :
            return {...state, reactivatePostSuccess: action.payload.success};
        case REACTIVATE_POST_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}