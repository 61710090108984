import 
{
    COLORS_LOADING,
    COLORS_SUCCESS,
    COLORS_FAILURE
}
from "redux/types";

const initialState = {
    colorsLoading: false,
    colors: null,
    errors: null
}

export const listColorsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COLORS_LOADING :
            return {...state, colorsLoading: action.payload};
        case COLORS_SUCCESS :
            return {...state, colors: action.payload.records};
        case COLORS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}