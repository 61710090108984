import * as React from "react";
import type { SVGProps } from "react";
const SvgStoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="StoreIcon_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 23.2 19.7"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{".StoreIcon_svg__st0{fill:#f7941d}"}</style>
    <g id="StoreIcon_svg__Group_4400">
      <g id="StoreIcon_svg__Group">
        <g id="StoreIcon_svg__Group_4547">
          <path
            id="StoreIcon_svg__Vector"
            fill="currentColor"
            d="M9.2 19.6c-2 0-3.7-1.7-3.7-3.7s1.7-3.7 3.7-3.7 3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7zm0-5.4c-.9 0-1.7.8-1.7 1.7 0 .9.8 1.7 1.7 1.7.9 0 1.7-.8 1.7-1.7 0-.9-.8-1.7-1.7-1.7z"
            className="StoreIcon_svg__st0"
          />
          <path
            id="StoreIcon_svg__Vector_2"
            fill="currentColor"
            d="M18.8 19.6c-2 0-3.7-1.7-3.7-3.7s1.7-3.7 3.7-3.7 3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7zm0-5.4c-.9 0-1.7.8-1.7 1.7 0 .9.8 1.7 1.7 1.7.9 0 1.7-.8 1.7-1.7 0-.9-.8-1.7-1.7-1.7z"
            className="StoreIcon_svg__st0"
          />
          <path
            id="StoreIcon_svg__Vector_3"
            fill="currentColor"
            d="M19.8 14.2H7.3c-1.2 0-2.2-.9-2.3-2.1l-1.1-10H1.1c-.6 0-1-.5-1-1s.5-1 1-1h4c.4 0 .7.3.7.6l.2 2h14.8c.7 0 1.3.3 1.8.8.4.5.6 1.2.6 1.9l-1 6.7c-.2 1.3-1.2 2.1-2.4 2.1zM6.2 4.8l.8 7.1c0 .2.2.3.3.3h12.4c.2 0 .3-.1.3-.3l1-6.7c0-.1 0-.2-.1-.3 0 0-.1-.1-.3-.1H6.2z"
            className="StoreIcon_svg__st0"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgStoreIcon;
