import
{
    FAVOURITE_EXHIBITORS_LOADING,
    FAVOURITE_EXHIBITORS_SUCCESS,
    FAVOURITE_EXHIBITORS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listFavouriteExhibitorsService } from "services/listFavouriteExhibitorsService";

export const listFavouriteExhibitorsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: FAVOURITE_EXHIBITORS_LOADING, payload: true});
        const response = await listFavouriteExhibitorsService();
        dispatch({type: FAVOURITE_EXHIBITORS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: FAVOURITE_EXHIBITORS_FAILURE, payload: err});
    } finally {
        dispatch({type: FAVOURITE_EXHIBITORS_LOADING, payload: false});
    }
}