import api from "api";
import { createStoryEndPoint } from "api/apiUrls";
import store from "redux/store";

export const createStoryService = async (data: any) => {
    const response = await api.post(
        createStoryEndPoint(),
        data,
        {
            headers: {
                'Content-Type' : 'multipart/form-data',
                'Authorization' : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );

    return response;
}