import { Footer } from "components";
import styles from "screens/V3/NotificationsScreen/NotificationsScreen.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Spin } from "antd";
import { useEffect } from "react";
import { listNotificationsAction } from "redux/actions/NotificationsAction/listNotificationsAction";
import NotificationsCard from "components/V3/NotificationsCard/NotificationsCard";
import { useResponsive } from "hooks/useResponsive";
import NewPaginator from "components/V3/NewPaginator/NewPaginator";
import { useSearch } from "hooks/useSearch";

const NotificationScreen = () => {
  const dispatch: any = useDispatch();

  const { notificationsLoading, notifications, currentPage, lastPage } =
    useSelector((state: RootState) => state.listNotificationsReducer);

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { t } = useTranslation();

  const { getSearchParam } = useSearch();

  const { isMobile } = useResponsive();

  useEffect(() => {
    dispatch(listNotificationsAction());
  }, []);

  return (
    <div
      className="w-full flex flex-col justify-between"
      style={{
        minHeight: "var(--content-height)",
      }}
    >
      <div className="my-[2.96rem] w-full flex flex-col items-center gap-y-4">
        <p className="text-[var(--new-blue)] text-[1.25rem] font-[700]">
          {t("notifications")}
        </p>
        <div
          className={`notifications-spin-container ${
            isMobile ? "w-[90%]" : "w-[50%]"
          } flex flex-col items-center mb-4`}
        >
          <Spin className="w-full" spinning={notificationsLoading}>
            <div className="w-full flex flex-col items-center gap-y-3">
              {notifications?.map((item: any) => {
                return (
                  <NotificationsCard
                    id={item.id}
                    postID={item.post_uuid}
                    postTitle={item.post_title}
                    notificationTitle={item.title}
                    description={item.description}
                  />
                );
              })}
            </div>
          </Spin>
        </div>
        <div className="w-full flex flex-row items-center justify-center">
          <NewPaginator
            currentPage={
              isNaN(parseInt(getSearchParam("page")))
                ? 1
                : parseInt(getSearchParam("page"))
            }
            lastPage={lastPage}
            url={`/${currentLanguage}/notifications`}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotificationScreen;
