// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /**  COMMON */
  get_started: "ابدأ الآن",
  lang: "English",
  ok: "موافق",
  cancel: "إلغاء",
  currency: "رس",
  skip: "تخطى",
  save: "حفظ",
  confirm: "تأكيد",
  help: "مساعدة",
  edit: "تعديل",
  make_special: "تمييز",
  continue: "متابعة",
  amount: "الكمية",
  are_you_sure: "هل انت متأكد ؟",
  next: "التالي",
  title: "العنوان",
  favorited_ads: "الاعلانات المفضلة",
  favourited_exhibitor: "المعارض المفضلة",
  call: "أتصال",
  name: "الأسم",

  // PROFILE SCREEN
  user_greeting: " ،اهلاً",
  ads: "الاعلانات",
  profile_msg:
    "يمكنك تعديل بيانات ملفك الشخصي ومتابعة اعلانتك ورسائلك والباقات",
  num_ads: "عدد الأعلانات المتاحة",
  more_ads: "أعلانات أكتر",
  active_ads: "الأعلانات المفعلة",
  unread_messages: "الرسائل الغير مقروئه",
  membership_type: "نوع العضوية",
  all_msgs: "سجل الرسائل",
  brief: "يمكنك كتابة نبذه او معلومة هنا",
  active: "تفعيل",
  no_posts: "لا يوجد إعلانات",
  no_stories: "لا يوجد ستوريز",
  num_of_stars: "عدد نجوم تمييز الأعلان",
  more_stars: "نجوم تمييز أكثر",

  // MEMBERSHIP TYPES
  membership_free: "مجاني",
  membership_special: "مميز",

  main_cover_message: "بالرجاء اختيار صورة رئيسية",

  /** CHANGE LANGUAGE */
  changelang_title: "تغير اللغة",
  changelang_msg: "سيتطلب تغير اللغة الي اعادة تشتغل التطبيق ، هل انت موافق ؟",
  /** DRAWER  */

  drawer_home: "الرئيسية",
  drawer_financial: "المالية",
  drawer_orders: "الطلبات",
  drawer_bank: "الحساب البنكي",
  drawer_account: "الحساب",
  drawer_support: "الدعم",
  drawer_settings: "الإعدادات",
  drawer_logout: "تسجيل الخروج",

  /* Homepage Text */
  homepage_advanced_search: "البحث المتقدم",
  homepage_fast_search: "البحث السريع",
  homepage_search_bar: "شريط البحث",
  wait: "انتظر",
  notReceiveCode: "لم تحصل على كود التاكيد؟",

  /** LOGIN SCREEN STRINGS */
  login_bodytext: "تسجيل الدخول أو التسجيل",
  login_languagetext: "English",
  login_continuetitle: "استمر",
  login_browsetitle: "تصفح الخدمات",
  /** VERIFICATION SCREEN STRINGS */
  verification_header: "رمز التحقق",
  verification_bodytext:
    "أدخل رمز التحقق للرسائل القصيرة التي أرسلناها إلى هاتفك المحمول",
  verification_buttontitle: "تحقق",
  verification_resendtext: "ألم تتلق رمز التحقق؟",

  /** FINANCIAL SCREENS */
  balance: "الرصيد",
  transactions: "المعاملات",
  cashout_requests: "طلبات السحب",
  load_wallet: "تحميل المحفظة",
  request_cashout: "طلب السحب",
  filter_transactions: "تصفية المعاملات",
  filter_checkout: "تصفية طلبات السحب",
  date_from: "التاريخ من",
  date_till: "التاريخ الي",
  pick_date: "اختار التاريخ",
  filter_transaction_text: "تصفية المعاملات على أساس التاريخ",
  show_transaction: "اظهر المعاملات",
  filter_cashout_text: "تصفية طلبات السحب على أساس التاريخ",
  show_cashout: "اظهر طلبات السحب",
  from: "من",
  till: "الي",
  cashout_details: "تفاصيل السحب",
  confirm_cashout: "تأكيد الدفع",

  request_cashout_message:
    "يجب أن تكون مالك الحساب المصرفي ، ويجب أن تتطابق معلوماتك هنا مع تفاصيل حسابك المصرفي ، في حالة رفض أي معلومات خاطئة طلب الغسل الخاص بك.",
  confirm_cashout_message: "هل انت متأكد من سحب مبلغ {{amount}} ر.س ?",

  /** ERROR MESSAGES */
  phone_errormessage:
    "يجب أن يبدأ رقم الهاتف بالرقم (5) ولا يحتوي على أي أحرف أو أحرف خاصة",
  phonelength_errormessage: "يجب أن يتكون رقم الهاتف من ١١ رقم",
  otplength_errormessage: "يجب أن يتكون رقم التحقق من ٤ أرقام",
  otp_errormessage:
    "يجب أن يتكون رقم التحقق من 6 أرقام ولا يحتوي على أي احرف أو أحرف خاصة.",
  required_field: "{{field_name}} is required",
  invalid_field: "{{field_name}} is invalid",

  update_post: "عدل الاعلان",

  choose_product: "اختر باقة لعرض تفاصيلها",

  /** ORDER HISTORY */
  receive_from_client: "Receive from client",

  /* Navbar Text */
  navbar_login_text: "تسجيل الدخول",
  navbar_create_post_text: "أضف أعلانك",

  see_profile_as_visitor: "رؤية بروفايل كذائر",

  navbar_home_text: "الرئيسية",
  navbar_buy_text: "شراء",
  navbar_rental_text: "إيجار",
  navbar_car_parts_text: "قطع غيار",
  navbar_news_text: "الاخبار",
  navbar_dark_mode: "الوضع الليلي",
  navbar_store_text: "المتجر",
  navbar_maintenance_text: "صيانة",
  navbar_insurance_text: "تأمين",
  navbar_exhibitions_text: "معارض",
  navbar_stories_text: "ستوريز",

  for_rent: "لليجار",

  story: "الأستوري",

  /* Homepage Text */

  homepage_search: "أبحث",
  homepage_search_bar_placeholder: "...أبحث عن عربية",

  not_logged_in: "انت مش مسجل دخول",

  homepage_rental: "إيجار السيارات",
  homepage_buy_and_sell: "بيع و شراء السيارات",
  homepage_color: "اللون",
  homepage_distance: "المسافة المقطوعه",
  homepage_km: "كم",
  homepage_cc: "كم سي سي",
  homepage_features: "كماليات",
  homepage_carbody: "الهيكل",
  homepage_price_from: "السعر من",
  homepage_price_to: "السعر الي",
  homepage_governate: "المحافظة",
  homepage_car_maker: "الماركة",
  homepage_car_model: "الموديل",
  homepage_year: "السنة",
  homepage_car_condition: "الحالة",
  homepage_fuel_type: "نوع الوقود",

  homepage_browse_categories: "تصفح الأقسام",
  homepage_special_dealers: "المعارض المميزة",
  homepage_search_by: "بحث بحسب",
  homepage_special_ads: "الاعلانات المميزة",
  homepage_latest_articles: "احدث المقالات",
  homepage_all_articles: "كل المقالات",

  exhibitions_and_dealers: "المعارض و التجار",
  special_exhibitor: "معرض مميز",
  visit_exhibitor_page: "زر صفحة المعرض",

  search_by_brands: "بحث بحسب الماركات",
  search_by_bodies: "بحث بحسب الهيكل",

  share_your_thoughts: "شاركنا اقتراحاتك",

  send_message: "أرسال رسالة",
  /* ViewPost Page Text */
  viewpost_send_a_message: ": أرسل رسالة للبائع",
  viewpost_send_msg: "إرسال رسالة",
  viewpost_send_a_message_placeholder: "هل السيارة متاحة ؟",
  viewpost_send: "أرسل",
  viewpost_safety_percautions: "أرشادات السلامة",
  viewpost_report: "ابلاغ عن مشكلة في الاعلان",
  viewpost_description: "الوصف :",
  viewpost_features: "كماليات :",
  viewpost_location: "الموقع :",
  viewpost_share: "مشاركة",
  viewpost_user_ad_history_1: "إعلان",
  viewpost_user_ad_history_2: "عرض جميع الإعلانات",
  viewpost_whatsapp: "واتس اب",
  viewpost_phone: "اظهار رقم الموبايل",
  viewpost_call: "اتصال",
  viewpost_checkout: "شاهد",
  viewpost_spare_parts: "قطع غيار",
  viewpost_maintenance: "مراكز صيانة",
  viewpost_similar_posts: "أعلانات مشابهة قد تهمك",

  viewpost_insure_car_title: "أمن علي عربيتك",
  viewpost_insure_car_body: "شاهد أنسب برامج التأمين",

  post_owner_info: "معلومات صاحب الإعلان",
  percaution_one:
    "قابل البائع في مكان عام مثل المترو أو المولات أو محطات البنزين ",
  percaution_two: "أذهب مع صديقك عند مقابلة البائع",
  percaution_three: "عاين السيارة جيداً قبل الشراء وتأكد ان السعر مناسب ",
  percaution_four: " لا تدفع او تقوم بتحويل الثمن الا بعد المعاينة جيداً",

  unable_to_create_post: "لا يمكنك انشاء الاعلان",
  please_login_before_creating_a_post: "برجاء تسجيل الدخول قبل اضافة اعلان",

  view_full_map: "شاهد الخريطة كاملة",

  like: "أعجبني",

  /* Literal Translation */
  egp: ".ج.م",
  cash: "كاش",
  installment: "تقسيط",
  contact_customer_for_more_details: "تواصل مع البائع لتفاصيل أكتر",
  post_number: "رقم الإعلان :",
  month: "شهر",
  upfront: "مقدم",
  greetings: "أهلاً بك في موقع سوق.كار",
  v3_welcome_to_souq_car: "اهلاً بك في سوق.كار",
  login: "الدخول",
  with_phone_number: "رقم الموبايل",
  terms_and_conditions_confirm:
    "بمتابعتك فأنت توافق على شروط الاستخدام و سياسة الخصوصية",
  or: "أو",
  faqs: "الاسئلة الشائعة",
  faqs_short: "الأسئلة الشائعة",
  we_answer_all_your_questions: "نجيب على جميع الأسئلة الشائعة حول سوق . كار",
  contact_us: "تواصل معنا",
  didnt_find_your_question: "لم تجد سؤالك؟",
  do_you_have_a_question: "هل لديك سؤال؟",
  contact_info: "معلومات التواصل",
  you_can_contact_us: "يمكنك التواصل معنا مباشرة عبر",
  leave_us_a_message: "اترك لنا رسالة",
  dont_feel_hesitant:
    "لا تتردد بالتواصل معنا في اي وقت، سوف نقوم بالرد عليك في اقرب وقت ممكن",
  post_title: "عنوان الأعلان",
  email: "الإيميل",
  language: "اللغة",
  write_a_message: "أكتب الرسالة",
  success_notification_message: "رسالتك وصلت",
  success_notification_description: "رسالتك وصلت بنجاح",
  buy_and_sell_cars: "بيع و شراء السيارات",
  special_ads: "أعلانات مميزة",
  view: ": عرض",
  sort_by: "ترتيب بحسب",
  search_using_keywords: "أبحث بكلمات مفتاحية",
  search: "بحث",
  hide_filters: "أضغط لأخفاء فلاتر البحث",
  show_filters: "أضغط لأظهار فلاتر البحث",
  hide_filters_mob: "إخفاء فلاتر البحث",
  show_filters_mob: "إظهار فلاتر البحث",
  submit: "أبحث",
  delete: "حذف",
  search_filters: "فلاتر البحث",
  reset_filters: "اعادة ضبط",
  year_from: "من سنة",
  year_to: "إلي سنة",
  price_from: "السعر من",
  price_to: "السعر الي",
  engine_capacity: "سعة المحرك",
  car_body: "نوع الهيكل",
  fuel_types: "نوع الوقود",

  keyword_search: "ابحث عن سيارة:",
  keyword_search_placeholder: "أبحث بالماركة ، الموديل ، السنة",

  about_us: "من نحن",
  get_to_know_souq_car: "تعرف علي سوق.كار",

  about_souq_car: "حول سوق.كار",
  about_seller: "حول البائع",
  about_buyer: "حول المشتري",
  //footer

  commonQuestions: "الاسئلة الشائعه",
  terms_of_use: "  شروط الاستخدام    ",
  privacy_policy: "سياسة الخصوصية",
  copyRights: "جميع الحقوق محفوظه لموقع سوق.كار 2023    ",
  Quick_links: "روابط سريعة    ",
  Home: "الرئيسية",
  sections: "الأقسام",
  who_are_we: "من نحن    ",
  news: "الأخبار",
  contact_Us: "تواصل معنا ",
  The_most_famous_cars: "اشهر مصنعي السيارات",

  Mercedes: "مرسيدس",
  Chevrolet: "شيفورليه",
  Citron: "سيترون",
  Skoda: "سكودا",
  Peugeot: "بيجو",
  Alfa_Romeo: " الفا روميو",
  BMW: "بي ام دابليو",
  about_Souq_Car: "عن سوق.كار    ",
  CAR_News: " أخبار السيارات  ",
  Latest_articles: " أحدث المقالات   ",

  phone_checkbox: "استخدم رقم الموبايل المرفق بالحساب",
  phone_placeholder: "اكتب رقم موبايل لأعلانك",

  about_souqCarDesc:
    "نقدم خدمة البيع المتكاملة، من البحث عن السيارة من بين العديد من الخيارات المتاحة، والشراء والاستئجار إضافة لتوفير قطع الغيار والتسليم، والدفع، ونسعى لتحسين خدماتنا من خلال تقديم تجربة آمنة وسهلة    ",
  artic_desc:
    "تنتشر هذه العادة السيئة للأسف في المنطقة، خصوصاً خلال فصل الصيف، فبقاء المحرك في وضعية التشغيل خارج فترة القيادة لمدة تزيد عن دقيقة، يؤدي إلى هدر الوقود وإطلاق كميات هائلة من الملوثات في الهواء. ولذلك، إذا كنت بانتظار اصطحاب شخص ما أو تنوي المرور لفترة قصيرة على محل بقالة، تأكّد من إيقاف تشغيل المحرك.",
  How_to_save_your_annual_fuel_consumption:
    "كيف توفر من استهلاك وقودك سنويا    ",
  read_more: "اقرأ المزيد",

  car_news: "أخبار السيارات",
  latest_articles: "أحدث المقالات",
  more_articles: "المزيد من المقالات",

  more: "المزيد",

  special_ad: "مميز",

  addImage: "اضف صورة",
  mainCover: "الصورة الرئيسية",

  electric_cars: "سيارات كهربائية",
  rental_only: "تقسيط فقط",
  used: "مستعمل",
  new: "جديد",
  manual_transmission: "مانيوال",

  please_provide_this_field: "هذا الحقل مطلوب",
  please_check_all_fields_provided: "برجاء ادخال جميع الحقول المطلوبة",

  //  PROFILE SCREEN USER INFO CARD
  user_my_account: "حسابي",
  user_my_posts: "اعلاناتي",
  user_phone_number: "رقم الموبايل",
  user_email: "الإيميل",
  user_member_since: "عضو منذ",
  user_membership_type: "نوع العضوية",
  user_settings: "الاعدادات",
  my_info: "بياناتي",
  personal_profile: "الصفحة الشخصية",
  show_phone_number: "إظهار رقم الموبايل علي الصفحة الشخصية",
  show_email: "إظهار الإيميل علي الصفحة الشخصية",
  disable_account: "تعطيل الحساب",
  disable: "تعطيل",

  // SETTINGS
  basic_info: "البيانات الأساسية",
  basic_info_msg: "لن يظهر للزوار من بياناتك سوا الاسم والهاتف والواتساب فقط",
  add_personal_photo: "أضف صورة شخصية",
  remove_photo: "حذف الصورة",

  // PAGINATION
  previous: "السابق",
  // CREATE POST SCREEN
  pictures: "الصور",
  car_info: "معلومات السيارة",
  choose_category: "اختار القسم",
  category: "القسم",
  car_details: "بيانات العربية",
  car_info_msg: "أدخل تفاصيل عربيتك بشكل صحيح لتسهيل العثور على إعلان",
  increase: "قابل للزيادة",
  transmission: "نوع ناقل الحركة",
  turbo: "تربو",
  distance_covered_hint: "العربية ماشيه قد ايه",
  extra_info: "معلومات أضافية (اختياري)",
  title_hint: "أكتب عنوان يصف عربيتك",
  post_description: "الوصف",
  post_description_hint: "أكتب الوصف",
  price_hint: "أكتب سعر تنافسي لعربيتك",
  payment_options: "طرق الدفع",
  payment_options_hint: "مثال : كاش",
  price: "السعر",
  price_condition: "حالة السعر",
  price_condition_hint: "مثال : قابل للتفاوض",
  inspection_location: "منطقه المعاينة",
  click_next: "أضغط التالي لأضافة الصور",

  create_post_title: "أختر القسم الي عايز تضيف فيه الأعلان",

  // CAR IMAGES INSTRUCTIONS
  obscured: "يفضل ان تكون نمر اللوحات محجوبة -",
  front_photo: "يفضل ان يكون للسيارة صورة امامية -",
  clear_photos: "يجب ان تكون الصور بجوده جيده واضحة -",
  no_competing: "لا تحتوي علي شعارات لاي موقع منافس -",

  // car_photos: "صور العربية",
  car_photos: "كدا فاضل بس أضافة الصور",
  upload_10: "( يمكن رفع الي 10 صور )",

  back: "السابق",
  publish_post: "انشر الاعلان",
  publish: "انشر",
  save_draft: "حفظ كمسودة",

  main_error_message: "يرجى ادخال جميع الحقول المطلوبة",
  image_error_message: "يرجى اختيار صورة واحدة على الأقل",
  main_image_error_message: "يرجى اختيار صورة رئيسية",

  story_title: "عنوان الاستوري",
  story_desc: "اكتب نص دعائي لك",

  last_step_msg1: "عظيم ، كدا فاضل بس اخر خطوة",
  last_step_msg2: "عاش كدا فاضل بس .. بيانات التواصل داخل الاعلان",

  mobile_hint: "رقم التواصل",
  otp_hint: "ادخل رمز التحقق",
  resend: "إعادة ارسال",
  finished: "عاش ، خلصت بسهولة",

  choose_category_message: "اختار القسم الخاص بالاعلان",

  form_rental_types: "مدة الايجار",
  form_rental_driver: "السائق",
  form_type_of_service: "نوع الخدمة",

  type_of_product: "نوع المنتج",
  state_of_product: "حالة المنتج",

  unspecified: "غير محدد",

  view_in_google_maps: "خرائط جوجل",
  get_directions: "الاتجاهات",

  total: "الاجمالي",
  total_ads: "اجمالي الاعلانات",

  description: "الوصف",
  post_details: "تفاصيل الاعلان",

  address_hint: "اكتب عنوان منطقة المعاينة بالتفصيل",

  write_your_phone_number: "اكتب رقم موبايلك",
  all_messages: "الرسائل",

  store: "المتجر",

  all_ads: "كل الاعلانات",
  for_sale: "للبيع",
  almost_new: "كسر زيرو",
  the_branches: "الفروع",
  automatic: "اتوماتيك",

  new_cars: "سيارات جديدة",
  used_cars_crump: "سيارات مستعملة",
  reset_cart: "اعادة ضبط",

  profile_tooltip_ads:
    "عدد الاعلانات المتاحه في رصيدك و ليك ١ اعلان شهريا مجانا هديه من سوق.كار ويمكن الحصول علي المزيد من المتجر",
  profile_tooltip_stories:
    "عدد الاستوريز المتاحه في رصيدك تقدر تنشر استوري وزود انتشار لعربيتك او منتجك ويمكن الحصول عليها من المتجر",
  profile_tooltip_stars:
    "عدد نجوم التمييز المتاحه في رصيدك و تستخدم النجوم لتمييز اعلاناتك ويمكن الحصول عليها من المتجر",

  sedan: "سيدان",

  more_special_ads: "أعلانات مميزة اكثر",
  categories: "الاقسام",

  all: "الكل",

  otp_sent_code: "تم أرسال رمز التحقق إلي رقمك",
  just_now: "الآن",

  rejected_title: "تم رفض الاعلان الخاص بك",
  rejected_desc: "قد تم رفضه",

  accepted_title: "تم قبول الاعلان الخاص بك",
  accepted_desc: "قد تم قبوله",

  delete_title: "حذف الاعلان",
  delete_desc: "لإعادة تفعيل الإعلان ، سيتم إرساله إلى قائمة الانتظار مرة أخرى",

  activate: "تفعيل",

  send_message_error: "برجاء تسجيل الدخول لإرسال رسائل",

  report_message_placeholder: "ما المشكلة في الاعلان ؟",

  call_us: "اتصل بنا",
  whatsapp: "واتساب",
  add: "أضف",

  create_story: "أضف أستوري",

  working_hours: "ساعات العمل",
  days_off: "عطلة",

  comma: "، ",

  used_cars: "عربيات مستعملة للبيع",
  cars_for_sale: "سيارات للبيع",
  cars_for_rent: "سيارات للإيجار",
  car_exhibitions: "معارض سيارات",

  // CHAT PAGE
  select_chat: "اختار محادثة لعرضها",
  type_here: "اكتب هنا...",

  sell: "بيع",

  // Insurance Page
  insurance_page_title_one: "بتدور على أفضل تأمين سيارات شامل؟",
  insurance_page_title_two: "بأرخص سعر؟",
  total_price: "أجمالي السعر",
  owner_age: "عمر المالك",
  get_offer: "احصل على عرض",
  insurance_customer_car_info:
    "دخل بيانات عربيتك وقارن عروض تأمين السيارات المختلفة",
  service_from: "الخدمة مقدمة من",
  free_inspection: "معاينة مجانية",
  delivery_service: "خدمات توصيل",
  after_sale_service: "خدمات ما بعد البيع",
  car_insurance_info: "معلومات عن تامين عربيتك",
  fast_login: "ادخل رقمك لتسجيل الدخول السريع",
  insurance_info_section_one_title: "تأمين السيارات الشامل :",
  insurance_info_section_one_body: `
  هو نوع من تأمين الممتلكات يغطي مخاطر الحوادث المختلفة اللي ممكن تتعرضلها السيارة كالتصادم, السطو و السرقة و الحريق. عن طريق تعويض مالك العربية المشترك في التأمين الشامل 
  عن الحوادث من خلال إصلاح السيارة أو دفع قيمة السيارة السوقية في حال تعرضها لا قدر الله للهلاك الكلي (حادث لا يمكن إصلاحه).
  
  ويطلق عليه التأمين التكميلي.
  
  و يقوم مبدأ التأمين علي حصول الفرد علي التغطية التأمينية في مقابل إشتراك سنوي يدفع لشركة التأمين و اللي بتقوم بتحصيل إشتراكات من أعداد كبيرة من الأفراد بهدف توزيع المخاطر و تقديم تعويضات للحوادث اللي بتحصل لسيارات أي من المؤمن عليهم (مش كل المشتركين في التأمين هيتعرضوا لحوادث في نفس السنة).
  `,
  insurance_info_section_two_title: "مميزات الحصول علي تأمين السيارة الشامل:",
  insurance_info_section_two_body: `
  أكبر مميزات تامين السيارات الشامل هي راحة البال اللي بيكتسبها الشخص عند حصوله علي بوليصة تأمين من شركة ذات سمعة جيدة. و إن الواحد بيكون مطمن و عنده ثقة أنه لا قدر الله في حالة التعرض لحادثة هيلاقي التأمين معاه و بيساعده من خلال تعويضه لإصلاح العربية. و ده بيخليك متتحملش تكلفة مصاريف الإصلاح من جيبك و اللي مع أسعار السوق حالياً بتوصل لأضعاف سعر اشتراك تأمين السيارات في حوادث ممكن تكون نسبياً بسيطة فتخيل لو كانت لا قدر الله حادثة كبيرة و مضطر تدفعها من جيبك لو مش معاك تأمين لعربيتك.

  تأمين السيارات دلوقتي بقى أونلاين مع أمان ليك هتقدر تقارن بين عروض التأمين الشامل للسيارة وتحصل على افضل سعر مع خدمة عملاء مميزة من خلال موقع أمان ليك بتقدر أنك تقارن ما بين العروض و الأسعار من الشركات المختلفة بشكل فوري. وتكتشف كل التفاصيل عشان تختار اللي يناسبك وتأخد قرار شراء التأمين بكل ثقة. من غير ما تلف و تدور كتير علي شركات أو تشتري مباشرة من المعرض بدون معرفة تفاصيل الوثيقة و الخيارات المختلفة المتاحة في السوق .
  `,
  insurance_info_section_three_title:
    "ليه أمان ليك هو أفضل مكان لحصولك علي تأمين السيارات؟",
  insurance_info_section_three_body_one: `
  1- الحصول علي عروض متنوعة بشكل فوري أونلاين في دقائق
  `,
  insurance_info_section_three_body_two: `
  2- معرفة تفاصيل العروض و المقارنة بينها لإختيار الأنسب
  `,
  insurance_info_section_three_body_three: `
  3- بتقدر توفر في قيمة تأمين عربيتك لوجود أسعار تنافسية من شركات متعددة
  `,
  insurance_info_section_three_body_four: `
  4- المساعدة في إجرائات المعاينة و الإصدار للحصول علي البوليصة في أسرع وقت (خلال نفس اليوم)
  `,
  insurance_info_section_three_body_five: `
  5- خدمة عملاء إضافية من خلال أمان ليك للمساعدة في إستخدام البوليصة
  `,
  insurance_info_section_four_title:
    "اما بالنسبة لتأمين السيارات التكميلي (الشامل) تكون تغطياته الاساسية:",
  insurance_info_section_four_body_one:
    "1-التلف أو الهلاك اللي بتتعرض له السيارة في حالات:",
  insurance_info_section_four_point_one:
    "- التصادم أو إنقلاب السيارة بسبب حادث",
  insurance_info_section_four_point_two:
    "- الحريق أو الصاعقة أو الإنفجار الخارجي أو الإشتعال الذاتي",
  insurance_info_section_four_point_three: "- الفعل المتعمد من الغير",
  insurance_info_section_four_point_four: "- أثناء النقل أو الرفع و الإنزال",
  insurance_info_section_four_body_two:
    "2-حالات السطو و السرقة و اي تلفيات ناتجة عنها و عن الشروع فيها",
  insurance_info_section_five_title: "كيفية حساب سعر التأمين علي السيارات :",
  insurance_info_section_five_body:
    "أسعار تأمين السيارت بتختلف من شركة لأخري و كمان ممكن في نفس شركة التأمين بيكون في أكتر من برنامج بمميزات مختلفة لتلبية إحتياجات العملاء المختلفة. و بيتم إحتساب قيمة الإشتراك السنوي للتأمين كنسبة مئوية من سعر السيارة السوقي لو مستعملة أو سعر الشراء لو السيارة جديدة.",
  insurance_info_section_six_title: "مبدأ النسبية:",
  insurance_info_section_six_body_one: `وهو إمكانية التأمين علي جزء من قيمة السيارة الإجمالية و في المقابل يتم التعويض بشكل نسبي في حالة المطالبة. ( مثال: التأمين علي قيمة المبلغ المتبقي لصالح البنك في حالة شراء السيارة بالتقسيط – بحيث يقوم التأمين بتغطية قيمة قرض البنك من القيمة الكلية للسيارة و في حالة المطالبة بتعويض يكون بنسبة مساوية لما يمثله القيمة المؤمن عليها "قيمة القرض" من السعر السوقي الإجمالي للسيارة)`,
  insurance_info_section_six_body_two:
    "نقاط المقارنة الرئيسية بين برامج التأمين على السيارات المختلفة :",
  insurance_info_section_six_body_three:
    "النقاط دي بتبقي مهمة أنك تعرفها و قت شراء البوليصة عشان يبقي في مقارنة عادلة بين البرامج المختلفة مش بس قائمة علي السعر و لكن كمان علي مجموعة المزايا اللي بتحصل عليها من البوليصة.",
  insurance_info_section_seven_title: "1-التحمل:",
  insurance_info_section_seven_body_one:
    "هو جزء بيقوم العميل بدفعه من تكلفة الإصلاح/التعويض - فكرة التحمل أنه بيخليك تشارك شركة التأمين في التعويض و ده بيخلي عندك حرص أكبر علي عدم الخسارة علي عكس لو مفيش تحمل نهائياً وبيبقي في نوعين من التحمل",
  insurance_info_section_seven_body_two: "- التحمل الإختياري:",
  insurance_info_section_seven_body_three:
    "- بيكون نسبة مئوية مذكورة في البوليصة بيتحملها العميل من تكلفة الإصلاح",
  insurance_info_section_seven_body_four:
    "- لو زادت نسبة التحمل ده بيخلي سعر البوليصة يقل",
  insurance_info_section_seven_body_five:
    "- وممكن تخلي نسبة التحمل 0% وده بيخلي سعر البوليصة عالي لكن بيخليك متطمن إن شركة هتدفع كل تكلفة الإصلاح ماعدا التحمل الثابت",
  insurance_info_section_seven_body_six:
    "- في معظم البرامج بيكون في نسبة تحمل أعلي للإصلاح داخل التوكيل ويمكن حذفها حسب رغبة العميل",
  insurance_info_section_seven_body_seven: "- التحمل الإجباري:",
  insurance_info_section_seven_body_eight:
    "- هو قيمة متفق عليها في البوليصة بيتحملها العميل في أي تعويض علي سبيل المثال العميل بيتحمل أول 200 جنيه من قيمة الإصلاح لكل حادث",
  insurance_info_section_eight_title:
    "2-شبكة مراكز الصيانة المتعاقدة مع شركة التأمين لتوفير الإصلاح المباشر:",
  insurance_info_section_eight_body:
    "كل شركة بتكون متعاقدة مع عدد من مراكز الخدمة المتخصصة في إصلاح السيارت للأنواع المختلفة. وبيكون من الأفضل إنك تصلح من خلال المراكز اللي داخل الشبكة لأن ده بيوفرلك ميزة التصليح بدون دفع كاش من فلوسك إلا نسبة التحمل لو وجدت و بيتم دفع الحساب من خلال شركة التأمين لمركز الصيانة مباشرة.",
  insurance_info_section_nine_title: "3- محضر الشرطة:",
  insurance_info_section_nine_body_one:
    "- عشان تطلب تعويض من شركات تأمين السيارات بتحتاج تقدم محضر الشرطة لإثبات الحادث. لكن حسب البوليصة ممكن ميكونش مطلوب منك محضر الشرطة للتعويضات اللي بتكون أقل من قيمة معينة (علي سبيل المثال تعويضات إصلاح تكلفتها أقل من عشرة الاف جنيه)",
  insurance_info_section_nine_body_two:
    "- قيمة التعويض المقبولة بدون الحاجة لمحضر شرطة بتكون ميزة جيدة في حالة أنك محتاج تستخدم التأمين في إصلاح حوادث بسيطة أنت متوقع ان قيمتها مش هتكون أكتر من الحد المذكور في البوليصة. و بالتالي بتوفر لنفسك وقت و مجهود عمل محضر الشرطة.",
  insurance_info_section_ten_title: "4- المميزات الإضافية :",
  insurance_info_section_ten_body:
    "تقوم شركات التأمين بتقديم برامج تحتوي علي مجموعة من المميزات الإضافية زي:",
  insurance_info_section_ten_body_one:
    "- خدمات المساعدة علي الطريق ( ونش , بطارية , تزويد وقود في حال نفاذه )",
  insurance_info_section_ten_body_two: "- توفير سيارة بديلة",
  insurance_info_section_ten_body_three:
    "- تغطيات إضافية للتأمين علي قائد السيارة و الركاب ضد العجز و الوفاة نتيجة حادث",
  insurance_info_section_ten_body_four:
    "المميزات الإضافية ممكن تشوفها رفاهية أو إضافات مش مهمة في ظل أنها بتزود سعر البوليصة. بس وقت لما تتعرض للموقف هتقدر قيمتها بشكل كبير زي خدمات المساعدة علي الطريق أو السيارة البديلة.",
  insurance_info_section_eleven_title: "طريقة إصدار بوليصة تأمين السيارة:",
  insurance_info_section_eleven_body: `بعد الإستقرار علي البوليصة المناسبة و تحديد السعر بيتم تنسيق ميعاد لمعاينة السيارة لو هي مستعملة من قبل مندوب من شركة التأمين و في الفحص الفني بيتم تأكيد علي حالة السيارة و لو فيها تلفيات موجودة بيتم إستثنائها من التغطية
  لكن لو العربية زيرو بعض الشركات بتقوم بالمعاينة و البعض الأخر بيقبل جواب المعرض لإصدار البوليصة بدون معاينة و بعد الإنتهاء من المرحلة ديه بيتم الدفع و إستلام البوليصة في الحال أو تسليمها للعميل ديليفري لحد عنده`,
  insurance_info_section_twelve_title: "كيفية إستخدام تأمين السيارة :",
  insurance_info_section_twelve_body:
    "عند حصول حادث لا قدر الله و نتج عنه تلفيات في السيارة بيتم إتباع الخطوات التالية للحصول علي التعويض من شركة التأمين المتعاقد معها:",
  insurance_info_section_twelve_body_one:
    "1- إخطار شركة التأمين بالحادث في اسرع وقت",
  insurance_info_section_twelve_body_two:
    "2- تقديم كافة البيانات و المستندات القانونية الخاصة بالحادث ( كمحضر الشرطة في حال تخطت التلفيات القيمة المذكورة في البوليصة لإشتراط وجود المحضر )",
  insurance_info_section_twelve_body_three:
    "3- تقدير قيمة التلفايات وتكلفة الإصلاح وذلك بعمل مقايسة في المكان اللي عايز تصلح فيه عربيتك وتقديمها لشركة التأمين",
  insurance_info_section_twelve_body_four:
    "4- إصلاح السيارة وارسال الفواتير لشركة التأمين لإسترداد التعويض",
  insurance_info_section_twelve_body_five:
    "شروط أساسية لضمان الموافقة علي الحصول علي التعويض:",
  insurance_info_section_twelve_body_six:
    "1- عدم حصول الحادث تحت تأثير المخدرات أو المشروبات الروحية",
  insurance_info_section_twelve_body_seven:
    "2- عدم حصول الحادث نتيجة لإستخدام السيارة في التسابق",
  insurance_info_section_twelve_body_eight:
    "3- حدوث الحادثة أثناء قيادة شخص غير مرخص له بالقيادة",
  insurance_info_section_twelve_body_nine:
    "4- عدم مخالفة قواعد المرور اثناء الحادث مثل السير عكس الاتجاه",
  insurance_info_section_twelve_body_ten:
    "إستثاءات لا يغطيها التأمين (يمكن إضافة بعضها باشتراك إضافي):",
  insurance_info_section_twelve_body_eleven:
    "1- التلف خلال الحروب, أعمال الشغب والتمرد و حوادث الإرهاب",
  insurance_info_section_twelve_body_twelve:
    "2- الكوارث الطبيعية مثل الزوابع و الأعاصير و الفيضانات .. إلخ",
  insurance_info_section_twelve_body_thirteen:
    "3- المصادرة من خلال السلطات العسكرية أو المدنية",
  insurance_info_section_twelve_body_fourteen:
    "و من خلال أمان ليك هتقدر تحصل علي تأمين السيارات بكل سهولة وفي أسرع وقت وكمان هتكون مطمن و واثق في قرار شرائك للتأمين عشان هتشوف العروض و تختار الأنسب و لو أحتجت مساعدة عشان تقرر أو تفهم التفاصيل بيساعدك خبرائنا عشان توصل لأفضل بوليصة تناسب إحتياجك.",
  featured_exhibitions: "معارض مميزة",
  more_exhibitions: "معارض أكتر",
  the_exhibitions: "المعارض",

  notifications: "الاشعارات",

  today: "اليوم",
  yesterday: "أمس",
  no_notifications: "لا يوجد اشعارات",

  exhibition_name: "اسم المعرض",
  whatsapp_number: "رقم الواتساب",
  main_service: "الخدمة الرئيسية",

  social_media: "التواصل الاجتماعي",

  facebook_link: "رابط الفيسبوك",
  twitter_link: "رابط تويتر",
  instagram_link: "رابط انستجرام",
  youtube_link: "رابط يوتيوب",
  website_link: "رابط الموقع",
  linkedin_link: "رابط لينكد ان",

  the_type: "النوع",
  the_appointments: "المواعيد",
  the_address: "العنوان",
  other_number: "رقم اخر",
  add_branch: "أضف فرع",
  the_holiday: "العطلة",

  no_branches: "لا يوجد فروع",

  edit_thumbnail: "تعديل الصورة الرئيسية",
  see_profile: "شاهد صفحتك كزائر",
  please_provide_valid_link: "برجاء ادخال رابط صحيح",
  visit_exhibition_page: "زر صفحة المعرض",

  available_stories: "الاستوري المتاحه",
  more_stories: "استوري أكتر",

  notifications_available: "الاشعارات المتاحه",
  more_notifications: "اشعارات أكتر",

  exhibition_stats: "أحصائيات عن معرضك",

  page_not_found: "صفحة غير موجودة",
  back_to_previous_page: "رجوع للصفحة السابقة",

  update_branches_title: "فشل تحديث الفروع",
  update_branches_desc: "برجاء ادخال جميع الحقول المطلوبة",

  prefilled_greeting: "السلام عليكم",
  prefilled_title: "ممكن اعرف اكتر عن اعلانك على",
  prefilled_maker: "الماركة",
  prefilled_model: "الموديل",
  prefilled_year: "السنة",
  prefilled_location: "المكان",
  prefilled_link: "اللينك",
  prefilled_footer:
    "أي تغييرات تتم على هذه الرسالة سيؤدي إلى عدم إرسال الاستفسار إلى البائع",

  // more_exhibitions: "معارض اكثر",
  send_whatsapp: "أرسل واتساب",

  installment_cars: "عربيات تقسيط",

  message: "رسالة",

  total_posted_ads: "أجمالي الاعلانات المنشورة",
  total_posted_stories: "أجمالي الاستوري",
  total_notifications: "أجمالي الاشعارات",

  exhibition_statistics: "أحصائيات عن معرضك",
  views: "مشاهدات",
  calls: "أتصالات",

  general_settings: "أعدادات عامة",
  exhibitor_name: "أسم المعرض",

  badge: "البادج",
  social_media_links: "روابط التواصل الاجتماعي",
  success_notification: "تم تعديل بياناتك بنجاح",
  repost: "أعادة النشر",
  work: "العمل",
  branch_details: "بيانات الفرع",
  branch_type: "نوع الفرع",
  timings: "المواعيد",

  terms_of_use_q1: "كيف نحصل على معلوماتك الشخصية؟",
  terms_of_use_a1:
    "منك: عند تواصلك مع خدمة العملاء وتزويدهم بالمعلومات ... ملفات الارتباط (الكوكيز): عند موافقتك على ملفات الارتباط لتتبع وجودك في الموقع وتقديم تجربة مستخدم أفضل ... موقعنا الالكتروني: عند إنشائك لحساب وإدخالك لمعلوماتك الشخصية ... الموقع الجغرافي: عند تفعيلك الموقع الجغرافي أثناء تصفح الموقع عنوان بروتوكول الانترنت (IP): وهذا يشمل العناوين، نوع المتصفح، مزود خدمة الانترنت، التاريخ والوقت، وعدد النقرات  ",
  terms_of_use_q2: "لماذا نطلبها وكيف نستخدمها؟ ",
  terms_of_use_a2:
    "لإتمام عمليات الشراء وتنفيذ الخدمات المطلوبة من سوق.كار، مثل: خدمات الإعلان والوساطة، والتدوين، وغيرها ... منع الحسابات المزورة والاحتيال ... لتلبية طلباتكم والرد على استفساراتكم ... للتواصل معك وتزويدك بالتحديثات ... لاطلاعكم على تعديلات الشروط والأحكام والسياسة ... لإجرار عمليات البيع والشراء على الموقع الالكتروني ... لاستضافة الإعلانات على موقعنا الالكتروني والوصول إلى الفئة المستهدفة ... لإعداد إحصائيات وتقارير تهدف لتطوير عملنا وتحسين عمل الموقع  ",
  terms_of_use_q3: "سياستنا",
  terms_of_use_a3:
    'يتعهد سوق كار بالحفاظ على سرية المعلومات وفقًا للأنظمة المعمول بها في جمهورية مصر العربية ... لا يحق لسوق كار استخدام معلومات المستخدمين للتشهير أو الإساءة ... نقوم بإجراءات دقيقة لحماية المعلومات أو إساءة استخدامها، ومع ذلك لا يمكننا ضمان أي نقل للبيانات على الانترنت أو نظام حفظ البيانات بصورة آمنة تمامًا؛ وبذلك لا نعتبر مسؤولين عن أي ضرر قد تتعرض له أنت أو أي طرف نتيجة لانتهاك السرية ... نعتمد قوانين جمهورية مصر العربية في كل ما يتعلق بالنزاعات التي قد تنشأ من جراء استخدام هذا الموقع الالكتروني ... لا يقوم سوق.كار بالبيع أو المتاجرة بالمعلومات الشخصية لعملائه ... قد يتم تزويد طرف ثالث بالمعلومات الشخصية بهدف إتمام علمية الشراء وتنفيذ الخدمات المطلوبة ومع ذلك يلتزم سوق كار بتقديم الحد الأدنى من المعلومات ومراعاة أهلية ومصداقية الطرف الثالث في استخدامها ... لمراجعة أو تصحيح أو إلغاء أو الحد من استخدامنا لمعلوماتكم الشخصية التي سبق وقمتم بتوفيرها لنا، يمكنكم استخدام نموذج "اتصل بنا" وتحديد طلبكم بوضوح',

  terms_of_use_q4: "شروط الخصوصية للبائع  ",
  terms_of_use_a4:
    "عدم انتهاك خصوصية الباعة الآخرين أو المشترين ... عدم سرقة المعلومات ومشاركتها مع جهات خارجية ... عدم إرسال محتوى غير مرغوب فيه أو إعلانات ترويجية ... عدم رفع أي محتوى مزيف أو مضلل أو مسيء ... عدم التواصل مع الأعضاء الآخرين دون موافقة صريحة منهم",

  privacy_policy_a1:
    "يمنع سحب أو تحميل أي وسائط (صورة – فيديو - نص) من موقعنا الالكتروني دون الإشارة إلى حقوق ملكيتها لموقع سوق كار  ",
  privacy_policy_a2:
    "يمنع استخدام التصاميم أو أسماء شركات عملائنا في مواد تسويقية أو دعائية دون الحصول على موافقة مكتوبة وموقعة  ",
  privacy_policy_a3:
    "يمنع استخدام الخدمات أو شراء المنتجات المشار لها في الإعلانات المدفوعة على الموقع هو مسؤوليتك، وعرضها لا يعني أننا نرشحها بشكل صريح أو نضمن جودته وأنت المسؤول عن قرارك اتجاهها  ",
  privacy_policy_a4:
    "يمنع استخدام أي وسيلة لمحاولة الإضرار بعمل الموقع الالكتروني أو سمعته الأخلاقية والقانونية  ",
  privacy_policy_a5: "يمنع إنشاء حساب وهمي أو يتضمن معلومات غير حقيقية  ",
  privacy_policy_a6: "يمنع استخدام الموقع من قبل الأطفال دون 18 عامًا ",
  privacy_policy_a7: "يمنع رفع ملفات تحتوي فيروسات أو برمجيات خبيثة  ",
  privacy_policy_a8:
    "يمنع التهجم أو التلفظ بكلمات غير لائقة تسيء للعاملين في سوق.كار أو زواره، مثل: العنصرية أو سب الأديان أو خطاب الكراهية أو الألفاظ الخادشة للحياة أو التشهير",

  privacy_policy_a9:
    "يتحمل البائع والمشتري تبعات العقد المبرم بينهما ولا يعد موقع سوق كار طرفًا في الاتفاقية  ",
  privacy_policy_a10:
    "الالتزام بالتواصل والاتفاقات مع المشترين ضمن موقع سوق كار  ",
  privacy_policy_a11: "إذا كنت مؤسسة أو مكتب تجاري فالشروط تسري عليك أيضا  ",
  privacy_policy_a12:
    "لا يحق لك القيام بعمليات بيع منتجات ممنوعة أو مشبوهة أو مسروقة أو تعتبر مخالفة للأنظمة والقوانين المعمول بها في جمهورية مصر العربية  ",

  privacy_policy_a13: "قبول أو رفض عروض البيع على الموقع هو مسؤوليتك الشخصية",
  privacy_policy_a14:
    "الالتزام بالتواصل والاتفاقات مع البائعين ضمن موقع سوق كار  ",
  privacy_policy_a15: "ذا كنت مؤسسة أو مكتب تجاري فالشروط تسري عليك أيضا  ",
  privacy_policy_a16: "يمكن وضع تقييم منصف عن البائع بعد إتمام الصفقة",

  favourited: "المفضلة",
  messages: "الرسائل",
  bedan_word: "لاتمام العملية بشكل بسيط قولة سوق.كار هو الوسيط",

  give_suggestion: "قدم اقتراح",
  type: "النوع",
  post: "نشر",
  similar_posts: "أعلانات مشابهة قد تهمك",
  used_cars_for_sale: "سيارات مستعملة للبيع",
  car_shop: "سوق السيارات",
  choose_otp_method: "أختر طريقة ارسال رمز التحقق",
  through_whatsapp: "من خلال واتساب",
  through_phone: "من خلال رسالة sms",

  hotline: 'الخط الساخن',
  homepage: 'الصفحة الرئيسية',
  not_enough_credits: "ليس لديك رصيد أعلانات كافي لنشر الاعلان",
  buy_from_store: 'شراء من المتجر',
  dismiss: "ألغاء"
};
