import { StarIcon, whiteStar } from "assets/icons/V3";
import { tachometer } from "assets/icons";
import { ClockCircleFilled, HeartFilled } from "@ant-design/icons";
import styles from "components/V3/NewCustomPostCard/NewWidePostCard/NewWidePostCard.module.css";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { souqCarWhite } from "assets/icons";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { favouritePostAction } from "redux/actions/FavouritePostActions/favouritePostAction";
import DeletePostModal from "components/ModalComponents/DeletePostModal/DeletePostModal";
import {
  profilePostStatusAction,
  reactivatePostAction,
  setMapCoordinatesPostAction,
  setUpdatePostData,
  suspendPostAction,
} from "redux/actions";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Star } from "assets/icons/V3";
import { usePostActions } from "hooks/usePostActions";
import { Spin } from "antd";
import { useLanguage } from "hooks/useLanguage";

interface PostCardProps {
  isSpecialAd: boolean;
  showActions: boolean;
  id: any;
  width?: any;
  height?: any;
  selector?: any;
  callsCount: number;
  suspendClick?: any;
  postDescription?: any;
  reactivateClick?: any;
  viewsCount: number;
  uploadDate?: string;
  postType?: string[];
  carMaker?: string;
  postTitle: string;
  paymentOption?: string;
  category: any;
  customerName: string;
  isFavorited?: boolean;
  city: string;
  price: string;
  carCondition: string;
  productionDate: string;
  kilometrage: string;
  className?: any;
  isMobile?: boolean;
  postCardStyle?: any;
  isDeleted?: any;
  handlePostEditClick?: any;
  brandLogo: any;
  image: any;
  created_at: any;
  address: any;
  imageWidth?: any;
  favouriteCallback?: any;
  lat?: any;
  lng?: any;
}

const NewWidePostCard: FC<PostCardProps> = ({
  isSpecialAd = false,
  showActions,
  isFavorited = false,
  width,
  height,
  selector,
  id,
  postTitle,
  paymentOption,
  price,
  carCondition,
  kilometrage,
  postCardStyle,
  isDeleted,
  image,
  created_at,
  city,
  address,
  imageWidth,
  favouriteCallback,
  lat,
  lng,
}) => {
  const { currentLanguage, textDirectionStyle } = useLanguage();

  const dispatch: any = useDispatch();

  const { t } = useTranslation();

  const {
    handleSuspendPostClick,
    handleReactivatePostClick,
    handlePostEditClick,
    handleMakeSpecialClick,
  } = usePostActions();

  const { userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { makeSpecialLoading } = useSelector(
    (state: RootState) => state.makePostSpecialReducer
  );

  const [favouriteState, setFavoriteState] = useState(isFavorited);

  const [open, setOpen] = useState(false);
  const [activateHover, setActivateHover] = useState(false);

  const navigate = useNavigate();

  const favouriteIcons = [
    "M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z",
  ];

  const handleFavouritePost = () => {
    setFavoriteState((prev: boolean) => !prev);
    dispatch(favouritePostAction(id as string, !favouriteState));
    favouriteCallback && favouriteCallback();
  };

  const isCash = (paymentType: string | undefined) => {
    return paymentType === "cash" || paymentType === "كاش";
  };

  return (
    <div className={`${width ? width : "w-[27.875rem]"} flex flex-col gap-y-2`}>
      <a
        href={`/${currentLanguage}/ads/show/${id}/${postTitle}`}
        className={`w-full ${
          styles.shadow
        } rounded-[0.9375rem] flex flex-col items-center p-2 gap-y-3 ${
          isSpecialAd ? "border-[0.5px] border-[var(--souq-orange)]" : ""
        } hover:cursor-pointer ${selector}`}
        style={{ height: height, backgroundColor: "white", ...postCardStyle }}
        onClick={(e: any) => {
          if (favouriteIcons.includes(e.target.getAttribute("d"))) {
            handleFavouritePost();
          } else {
            // navigate(`/${currentLanguage}/ads/show/${id}/${postTitle}`);
          }
        }}
      >
        <div
          className={`relative ${
            imageWidth ? `${imageWidth}` : "w-full"
          } h-[12.30713rem]`}
        >
          <div className="w-full h-full rounded-[0.625rem] absolute bg-[var(--new-blue)] flex items-center justify-center">
            <img className="w-[90%]" src={souqCarWhite} />
          </div>
          <div
            style={{
              backgroundImage: `url('${encodeURI(image)}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            className="absolute w-full h-full rounded-[0.625rem] flex flex-col justify-between"
          >
            <div
              dir={textDirectionStyle}
              className="w-full flex flex-row justify-between items-center px-3 py-2"
            >
              {isSpecialAd && (
                <div className="w-[3.08775rem] h-[1.32138rem] rounded-[1rem] bg-[var(--souq-orange)] flex flex-row items-center justify-center gap-x-[1px]">
                  <img
                    className="w-[0.63338rem] h-[0.62181rem]"
                    src={whiteStar}
                  />
                  <p className="text-white text-[0.8125rem] mb-[0.5px]">
                    {t("special_ad")}
                  </p>
                </div>
              )}
              {userData?.token && (
                <HeartFilled
                  className={`${
                    favouriteState ? "text-[red]" : "text-white"
                  } hover:cursor-pointer`}
                  style={{ fontSize: "1.3rem", marginRight: ".7rem" }}
                />
              )}
            </div>
            {!isCash(paymentOption) && (
              <div
                dir="ltr"
                className="px-3 py-2 w-full flex flex-row items-center justify-start"
              >
                <div className="bg-white h-[1.53044rem] px-3 rounded-[1.25rem] flex items-center justify-center">
                  <p className="text-[var(--souq-orange)]">
                    {t("installment")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          dir={textDirectionStyle}
          className="w-full h-[32%] flex flex-col items-start justify-between"
        >
          <div className="w-full h-full flex flex-col gap-y-1">
            <p className="text-[0.9375rem] font-[700] line-clamp-1 text-ellipsis">
              {postTitle}
            </p>
            <p className="text-[0.8125rem] line-clamp-1 text-ellipsis">
              {city}
              {t("comma")}
              {address}
            </p>
          </div>
          <div className="w-full flex flex-row items-end justify-between">
            <div className="flex flex-row items-center justify-end gap-x-1">
              <img className="w-[0.875rem] h-[0.8125rem]" src={tachometer} />
              <p className="text-[0.8125rem]">
                {kilometrage?.toLocaleString()}
              </p>
              <div className="bg-[var(--new-blue)] w-[0.5px] h-[0.98706rem]"></div>
              <p className="text-[0.8125rem] whitespace-nowrap">
                {carCondition}
              </p>
              <div className="mr-2 flex flex-row gap-x-1 items-center">
                <ClockCircleFilled className="text-[0.6rem]" />
                <p className="text-[0.8125rem] whitespace-nowrap">
                  {created_at}
                </p>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "rgba(0, 80, 255, 0.1)",
              }}
              className="w-[8.4445rem] h-[2.54344rem] rounded-[1.875rem] flex items-center justify-center"
            >
              <p className="text-[0.9375rem]">{price?.toLocaleString()}</p>
            </div>
          </div>
        </div>
      </a>

      {showActions && (
        <Spin spinning={makeSpecialLoading}>
          <div
            className={`w-full flex items-start justify-center gap-y-3 gap-x-2`}
          >
            <div
              className={`${styles.shadow} ${styles.optionsButton} ${
                isSpecialAd ? "cursor-not-allowed" : "cursor-pointer"
              } bg-white flex p-2 w-1/3 items-center justify-center gap-x-1`}
              onClick={() => {
                if (!isSpecialAd) {
                  handleMakeSpecialClick(id);
                }
              }}
            >
              <div className={`${styles.optionsIcon}`}>
                <img
                  alt="star"
                  src={Star}
                  className={`h-[1rem] ${isSpecialAd && styles.grayOut}`}
                />
              </div>
              <p
                className={`${styles.optionsText} ${
                  isSpecialAd && styles.grayOut
                }`}
              >
                {t("make_special")}
              </p>
            </div>
            <div
              className={`${styles.shadow} ${styles.optionsButton}  bg-white flex p-2 w-1/3 items-center justify-cente gap-x-1`}
              onClick={() => {
                handlePostEditClick(id, lat, lng);
              }}
            >
              <div className={`${styles.optionsIcon}`}>
                <EditOutlined className="text-lg text-blue-400" />
              </div>
              <p className={`${styles.optionsText}`}>{t("edit")}</p>
            </div>
            {!isDeleted ? (
              <div
                className={`${styles.shadow} ${styles.optionsButton} bg-white flex p-2 w-1/3 items-center justify-center gap-x-1`}
                onClick={() => setOpen(true)}
              >
                <div className={`${styles.optionsIcon}`}>
                  <DeleteOutlined className="text-lg text-red-400" />
                </div>
                <DeletePostModal
                  open={open}
                  setOpen={setOpen}
                  suspendClick={() => handleSuspendPostClick(id)}
                />
                <p className={`${styles.optionsText}`}>{t("delete")}</p>
              </div>
            ) : (
              <div
                className={`${styles.shadow} ${styles.optionsButton} ${styles.optionsButtonReactivate} flex p-2 w-1/3 items-center justify-center gap-x-1`}
                onMouseEnter={() => setActivateHover(true)}
                onClick={() => handleReactivatePostClick(id)}
              >
                <span
                  onMouseLeave={() => setActivateHover(false)}
                  className={`material-symbols-outlined ${styles.neon} ${styles.icon}`}
                >
                  redo
                </span>

                <p className={`${styles.optionsText}`}>{t("activate")}</p>
              </div>
            )}
          </div>
        </Spin>
      )}
    </div>
  );
};

export default NewWidePostCard;
