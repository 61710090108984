import
{
    LIST_NOTIFICATIONS_LOADING,
    LIST_NOTIFICATIONS_SUCCESS,
    LIST_NOTIFICATIONS_FAILURE
}   
from "redux/types";

import { Dispatch } from "redux";

import { listNotificationsService } from "services/listNotificationsService";

export const listNotificationsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_NOTIFICATIONS_LOADING, payload: true});
        const response = await listNotificationsService();
        dispatch({type: LIST_NOTIFICATIONS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_NOTIFICATIONS_FAILURE, payload: err})
    } finally {
        dispatch({type: LIST_NOTIFICATIONS_LOADING, payload: false});
    }
}