import CustomButton from "components/CustomButton/CustomButton";
import styles from "components/V3/DashboardBranchCard/DashboardBranchCard.module.css";
import { FC, useState } from "react";
import { PhoneFilled, EditOutlined } from "@ant-design/icons";

interface DashboardBranchCardProps {
  id: any;
  city: string;
  address: string;
  workingHoursFrom: string;
  workingHoursTo: string;
  offDay: string;
  branchType: string;
  phoneOne: string;
  phoneTwo: string;
  onClick?: any;
  isEditable: boolean;
}

const DashboardBranchCard: FC<DashboardBranchCardProps> = ({
  id,
  city,
  address,
  workingHoursFrom,
  workingHoursTo,
  offDay,
  branchType,
  phoneOne,
  phoneTwo,
  onClick,
  isEditable,
}) => {
  const [showPhoneOne, setShowPhoneOne] = useState(false);
  const [showPhoneTwo, setShowPhoneTwo] = useState(false);

  return (
    <div
      dir="rtl"
      className={`relative w-full h-[12.0625rem] flex flex-col justify-between gap-y-3 ${styles.shadow} bg-white rounded-[0.625rem] px-3 py-3`}
    >
      <div className={`w-full flex flex-row items-center justify-between`}>
        <p className="text-[var(--new-blue)] text-[1.25rem]">{city}</p>
        <div
          className={`flex items-center justify-center h-[2.0625rem] min-w-[5.875rem] rounded-[var(--radius-xxxs)] px-3 ${styles.blueOpacity}`}
        >
          <p>{branchType}</p>
        </div>
      </div>
      <p className="text-[var(--font-size-s)]">{address}</p>
      <div dir="rtl" className="flex flex-row items-center gap-x-4">
        <div className="flex flex-row items-center gap-x-[0.2rem]">
          <p>العمل:</p>
          <p className="text-[var(--new-blue)]">{workingHoursFrom}</p>
          <p>-</p>
          <p className="text-[var(--new-blue)]">{workingHoursTo}</p>
        </div>
        <div className="flex flex-row gap-x-[0.2rem]">
          <p className="">العطلة:</p>
          <p className="text-[var(--new-blue)]">{offDay}</p>
        </div>
      </div>
      <div className="mt-3 w-full flex flex-row items-center justify-center gap-x-2">
        <CustomButton
          className={`w-[50%] h-[2.5625rem] flex items-center justify-center gap-x-2 border-[0.5px] rounded-[var(--radius-xxxs)] border-[var(--new-blue)]`}
          onClick={() => {
            setShowPhoneOne((prev) => !prev);
          }}
        >
          {showPhoneOne ? (
            <p>{phoneOne}</p>
          ) : (
            <>
              <p>أتصال</p>
              <PhoneFilled className="text-[var(--new-blue)] rotate-[100deg]" />
            </>
          )}
        </CustomButton>
        {phoneTwo && (
          <CustomButton
            className={`w-[50%] h-[2.5625rem] flex items-center justify-center gap-x-2 border-[0.5px] rounded-[var(--radius-xxxs)] border-[var(--new-blue)]`}
            onClick={() => {
              setShowPhoneTwo((prev) => !prev);
            }}
          >
            {showPhoneTwo ? (
              <p>{phoneTwo}</p>
            ) : (
              <>
                <p>أتصال</p>
                <PhoneFilled className="text-[var(--new-blue)] rotate-[100deg]" />
              </>
            )}
          </CustomButton>
        )}
      </div>
      {isEditable && (
        <div
          onClick={() => {
            // set edit
            onClick({
              id: id,
              city: city,
              address: address,
              branch_type: branchType,
              work_from: workingHoursFrom,
              work_to: workingHoursTo,
              day_off: offDay,
              phone_one: phoneOne,
              phone_two: phoneTwo,
            });
          }}
          className="absolute left-[-3%] top-[calc(35%)] w-[3rem] h-[3rem] rounded-full bg-[var(--new-blue)] flex items-center justify-center hover:cursor-pointer"
        >
          <EditOutlined className="text-white" />
        </div>
      )}
    </div>
  );
};

export default DashboardBranchCard;
