import { useStore } from "./useStore";
import { useResponsive } from "hooks/useResponsive";
import MobileStoreScreen from "screens/V3/StoreScreen/MobileStoreScreen/MobileStoreScreen";
import DesktopStoreScreen from "screens/V3/StoreScreen/DesktopStoreScreen/DesktopStoreScreen";
import Footer from "components/Footer/Footer";

const StoreScreen = () => {
  const useStoreData = useStore();
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <>
        <MobileStoreScreen useStore={useStoreData} />
        <Footer />
      </>
    );
  }
  return (
    <>
      <DesktopStoreScreen useStore={useStoreData} />
      <Footer />
    </>
  );
};

export default StoreScreen;
