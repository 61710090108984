import { Modal } from "antd";
import { FC, useEffect, useState } from "react";
import Stories from "react-insta-stories";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface CircularStoryCardProps {
  id: any;
  image: string;
  customerName: string;
  isMobile: boolean;
  scaleDown?: boolean;
}

const CircularStoryCard: FC<CircularStoryCardProps> = ({
  id,
  image,
  customerName,
  isMobile,
  scaleDown,
}) => {
  const [openStory, setOpenStory] = useState(false);
  const [storySlideIndex, setStorySlideIndex] = useState(0);

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const navigate = useNavigate();

  const slidePrevStorySlide = () => {
    setStorySlideIndex((prev) => prev - 1);
  };

  const slideNextStorySlide = () => {
    setStorySlideIndex((prev) => prev + 1);
  };

  const stories: any = [
    {
      url: "https://souqcarlive.s3.amazonaws.com/posts/1699357452photo1697103038.jpeg",
      seeMore: ({ close }: { close: any }) => (
        <div style={{ width: "100%", height: "100%" }}>Hello</div>
      ),
      header: {
        heading: "Mohit Karekar",
        subheading: "Posted 5h ago",
        profileImage: "https://picsum.photos/1000/1000",
      },
    },
    {
      url: "https://souqcarlive.s3.amazonaws.com/posts/1699357453photo1697103038%20(1).jpeg",
      header: {
        heading: "Mohit Karekar",
        subheading: "Posted 32m ago",
        profileImage: "https://picsum.photos/1080/1920",
      },
    },
    {
      url: "https://media.idownloadblog.com/wp-content/uploads/2016/04/iPhone-wallpaper-abstract-portrait-stars-macinmac.jpg",
      header: {
        heading: "mohitk05/react-insta-stories",
        subheading: "Posted 32m ago",
        profileImage:
          "https://avatars0.githubusercontent.com/u/24852829?s=400&v=4",
      },
    },
    {
      url: "https://storage.googleapis.com/coverr-main/mp4/Footboys.mp4",
      type: "video",
    },
    {
      url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
      type: "video",
      seeMore: ({ close }: { close: any }) => (
        <div style={{ width: "100%", height: "100%" }}>Hello</div>
      ),
    },
    {
      url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
      type: "video",
    },
    "https://images.unsplash.com/photo-1534856966153-c86d43d53fe0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=564&q=80",
  ];

  return (
    <div className={`flex flex-col items-center gap-y-2`}>
      <div
        onClick={() => {
          navigate(`/${currentLanguage}/stories/${id}`);
        }}
        style={{
          backgroundImage: `url('${image}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          ...id === "souq-car" && { border: "1px solid var(--new-blue)" },
        }}
        className={`${
          isMobile
            ? "w-[3.64119rem] h-[3.64119rem]"
            : scaleDown
            ? "w-[4.875rem] h-[4.875rem]"
            : "w-[5.875rem] h-[5.875rem]"
        } rounded-full hover:cursor-pointer`}
      ></div>
      <p
        onClick={() => {}}
        className={`${
          isMobile ? "text-[0.75rem]" : "text-[0.9375rem]"
        } hover:text-[var(--new-blue)] hover:cursor-pointer`}
      >
        {customerName}
      </p>
    </div>
  );
};

export default CircularStoryCard;
