import {
  VENDOR_POSTS_LOADING,
  VENDOR_POSTS_SUCCESS,
  VENDOR_POSTS_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listVendorPostsService } from "services/listVendorPostsService";

export const listVendorPostsAction =
  (userId: any, page: any, filters?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: VENDOR_POSTS_LOADING, payload: true });

      const response = await listVendorPostsService(userId, page, filters);
      dispatch({ type: VENDOR_POSTS_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: VENDOR_POSTS_FAILURE, payload: err });
    } finally {
      dispatch({ type: VENDOR_POSTS_LOADING, payload: false });
    }
  };
