import { FC } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Spin } from "antd";
import styles from "components/CustomMap/Map/Map.module.css";
import PlacesAutoComplete from "../PlacesAutoComplete/PlacesAutoComplete";
import { setMapCoordinatesPostAction } from "redux/actions/SetMapCoordinatesPostAction/setMapCoordinatesPostAction";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface MapProps {
  selected: any;
  setSelected?: React.Dispatch<
    React.SetStateAction<{ lat: number; lng: number }>
  >;
  width: any;
  height: any;
  selectWidth: any;
  isViewing?: boolean;
  top: any;
  left: any;
}

const CustomMap: FC<MapProps> = ({
  selected,
  isViewing = false,
  width,
  height,
  selectWidth,
  top,
  left,
}) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries: ["places"],
  });

  const [isMobile, setIsMobile] = useState(false);

  const [customSelect, setCustomSelect] = useState<any>({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState({
    lat: 30.056021,
    lng: 30.976639,
  });
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (selected) {
      setMarkerPosition(selected);
    }
  }, []);

  const { mapState } = useSelector(
    (state: RootState) => state.setMapCoordinatesPostReducer
  );

  const handleMapClick = (event: any) => {
    // Get the new coordinates from the click event
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    // Update the marker's position
    setMarkerPosition({ lat: newLat, lng: newLng });
    dispatch(setMapCoordinatesPostAction({ lat: newLat, lng: newLng }));
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      {!isLoaded ? (
        <Spin />
      ) : (
        <div dir="ltr" className={styles.mainWrapper}>
          {isViewing ? (
            <GoogleMap
              zoom={15}
              center={selected}
              mapContainerStyle={{
                width: width,
                height: height,
                display: "flex",
                position: "relative",
              }}
            >
              <Marker position={selected} />
            </GoogleMap>
          ) : (
            <GoogleMap
              zoom={15}
              center={markerPosition}
              mapContainerStyle={{
                width: width,
                height: height,
                display: "flex",
                position: "relative",
              }}
              onClick={handleMapClick}
            >
              <div
                className={`${
                  isMobile ? styles.placesContainerMob : styles.placesContainer
                }`}
                style={{ height: "fit-content", backgroundColor: "#00FF004F" }}
              >
                <PlacesAutoComplete setMarkerPosition={setMarkerPosition} />
              </div>
              <Marker position={markerPosition} />
            </GoogleMap>
          )}
        </div>
      )}
    </>
  );
};

export default CustomMap;
