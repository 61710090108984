import styles from "screens/V3/AboutUs/AboutUs.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton, Footer, Seo } from "components";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useResponsive } from "hooks/useResponsive";

const AboutUs = () => {
  const { t } = useTranslation();

  const { isMobile } = useResponsive();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const navigate = useNavigate();

  return (
    <div className="mt-[2.94rem] w-full flex flex-col items-center gap-y-[2.94rem]">
      <Seo
        title="سوق كار - من نحن"
        description="من نحن"
        keywords="سوق السيارات,سيارات,شراء عربيات,مدينة نصر، مصر,هيونداي,بيع سيارات مستعملة,عربيات للبيع,شراء سيارات,سيارات مستعملة للبيع,souq.car,souq car,سوق دوت كار,سوق كار"
      />
      <h1 className={`text-[var(--new-blue)] text-[1.25rem] font-[700]`}>
        {t("get_to_know_souq_car")}
      </h1>
      <div dir="rtl" className={`${isMobile ? "w-[95%]" : "w-[70%]"}`}>
        <p className="leading-[2.5rem] text-[0.9375rem]">
          تأسس موقع سوق.كار بصفته وسيط الكتروني يربط بين البائع والمشتري في مجال
          بيع السيارات وتوفير خدماتها، ونعمل بجد لإتاحة كافة خيارات السيارات
          الجديدة والمستعملة بأفضل المواصفات. كما نحرص على تطوير المنصة لتكون
          رائدة في السوق المصري، فصالات عرض السيارات صارت بين يديك في مكان واحد.
          نقدم خدمة البيع المتكاملة، من البحث عن السيارة من بين العديد من
          الخيارات المتاحة، والشراء والاستئجار، إضافة لتوفير قطع الغيار،
          والتسليم، والدفع، ونسعى لتحسين خدماتنا من خلال تقديم تجربة آمنة وسهلة.
          سوق.كار دليلك الأمثل لاختيار السيارة التي تناسبك حتى إن كنت لا تعرف
          شيئًا في عالم السيارات، ووجهتك الأولى لبيع سيارتك واستبدالها في دقائق
          وبأفضل سعر.
        </p>
      </div>
      <div
        dir="rtl"
        className={`mt-[2.87rem] w-[20.75rem] h-[3.875rem] p-[0.6rem] rounded-[0.625rem] flex flex-row items-center justify-between gap-x-3 ${styles.shadow}`}
      >
        <div className="w-1/2 h-full flex items-center justify-center">
          <p>{t("do_you_have_a_question")}</p>
        </div>
        <CustomButton
          className={`w-1/2 h-full rounded-[0.3125rem] flex items-center justify-center`}
          onClick={() => {
            navigate(`/${currentLanguage}/faq`);
          }}
          color={"var(--new-blue)"}
        >
          <p className="text-white">{t("faqs")}</p>
        </CustomButton>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
