import { Modal, Spin, Tooltip } from "antd";
import { CustomButton, Footer } from "components";
import styles from "screens/V3/SingleArticleScreen/SingleArticleScreen.module.css";
import { useState, useEffect } from "react";
import Stories from "react-insta-stories";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listCustomerStoriesAction } from "redux/actions/StoriesActions/CustomerStoriesAction/listCustomerStoriesAction";
import {
  HeartFilled,
  MessageOutlined,
  PhoneFilled,
  CloseOutlined,
} from "@ant-design/icons";
import { CloseIcon, ShareButtonIcon, WhiteLogo } from "assets/icons/V3";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useResponsive } from "hooks/useResponsive";
import ShareButton from "components/V3/ShareButton/ShareButton";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { increaseStoryViewsService } from "services/OneTimeServices/increaseStoryViewsService";
import { favouriteStoryService } from "services/OneTimeServices/favouriteStoryService";
import { sendChatMessageAction } from "redux/actions";
import { useLanguage } from "hooks/useLanguage";
import { useUtilities } from "hooks/useUtilities";
import SouqStory1 from "assets/images/SouqStory1.png";
import SouqStory2 from "assets/images/SouqStory2.png";
import SouqProfile from "assets/images/SouqProfile.png";
import { language, souqCarLogo, themeIcon } from "assets/icons";

const SingleStoryScreen = () => {
  const { uuid } = useParams<any>();

  const { userData, t, dispatch, navigate } = useUtilities();
  const { isMobile } = useResponsive();

  const { currentLanguage, textDirectionStyle } = useLanguage();

  const { customerStoriesLoading, customerStories } = useSelector(
    (state: RootState) => state.listCustomerStoriesReducer
  );

  const [isStoryPaused, setIsStoryPaused] = useState(false);

  const [favouritedState, setFavouritedState] = useState(false);
  const [currentPlayingStoryIndex, setCurrentPlayingStoryIndex] = useState(0);
  const [currentPlayingStoryId, setCurrentPlayingStoryId] = useState("");

  const stories: any = [
    {
      url: SouqStory1,
      header: {
        heading: "Souq.Car",
        subheading: "Posted Now",
        profileImage: SouqProfile,
      },
      duration: 10000,
      caption:
        "استمتع بتجربة فريدة في بناء معرض سيارتك عبر سوق.كار! تصميم سهل وسريع لعرض سياراتك ",
    },
    {
      url: SouqStory2,
      header: {
        heading: "Souq.Car",
        subheading: "Posted Now",
        profileImage: SouqProfile,
      },
      duration: 10000,
      caption:
        "سوق.كار يقدم لك واجهة مستخدم جديدة ومثيرة لتحقيق أفضل تجربة في بيع سيارتك بأسرع وقت",
    },
    // {
    //   url: "https://souqcarlive.s3.amazonaws.com/posts/1699357453photo1697103038%20(1).jpeg",
    //   header: {
    //     heading: "Mohit Karekar",
    //     subheading: "Posted 32m ago",
    //     profileImage: "https://picsum.photos/1080/1920",
    //   },
    // },
    // {
    //   url: "https://media.idownloadblog.com/wp-content/uploads/2016/04/iPhone-wallpaper-abstract-portrait-stars-macinmac.jpg",
    //   header: {
    //     heading: "mohitk05/react-insta-stories",
    //     subheading: "Posted 32m ago",
    //     profileImage:
    //       "https://avatars0.githubusercontent.com/u/24852829?s=400&v=4",
    //   },
    // },
    // {
    //   url: "https://storage.googleapis.com/coverr-main/mp4/Footboys.mp4",
    //   type: "video",
    // },
    // {
    //   url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
    //   type: "video",
    //   seeMore: ({ close }: { close: any }) => (
    //     <div style={{ width: "100%", height: "100%" }}>Hello</div>
    //   ),
    // },
    // {
    //   url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
    //   type: "video",
    // },
    // "https://images.unsplash.com/photo-1534856966153-c86d43d53fe0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=564&q=80",
  ];

  useEffect(() => {}, [isStoryPaused]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (uuid !== "souq-car") {
      dispatch(listCustomerStoriesAction(uuid));
      if (customerStories?.length > 0) {
        setCurrentPlayingStoryId(customerStories[0]?.id);
      }
    }
  }, []);

  const handleSendMessage = async (id: any) => {
    await dispatch(sendChatMessageAction(id, "*$$*"));
    navigate(`/${currentLanguage}/messages?vendorId=${id}`);
  };

  const [currentStory, setCurrentStory] = useState<number>(0);

  return (
    <div
      className={`w-full h-[100vh] flex flex-row items-center justify-center relative ${
        isMobile ? "" : "px-[4.87rem]"
      } bg-black overflow-y-hidden`}
    >
      {!isMobile ? (
        <div className="stories-container h-full flex flex-row items-end justify-center gap-x-6">
          <Spin
            spinning={customerStoriesLoading}
            className="w-[60%] h-full flex flex-row items-center justify-center"
          >
            {customerStoriesLoading ? (
              <div className="w-full mb-[2.64rem] h-[40rem] bg-gray-500 flex items-center justify-center"></div>
            ) : (
              <div className="h-full flex flex-col items-center gap-y-3">
                <img src={WhiteLogo} />
                <div className="story-screen w-full h-full mb-[2.64rem] flex flex-row items-center justify-center relative">
                  <Stories
                    onPrevious={() => {
                      if (currentStory > 0)
                        setCurrentStory((prevIndex: any) => prevIndex - 1);
                    }}
                    onNext={() => {
                      if (currentStory + 1 < stories.length)
                        setCurrentStory((prevIndex: any) => prevIndex + 1);
                    }}
                    onStoryStart={() => {
                      increaseStoryViewsService(currentPlayingStoryId);
                    }}
                    onStoryEnd={() => {
                      if (currentStory + 1 < stories.length)
                        setCurrentStory((prevIndex: any) => prevIndex + 1);

                      setCurrentPlayingStoryIndex((prevIndex) => prevIndex + 1);
                      if (
                        currentPlayingStoryIndex + 1 <
                        customerStories?.length
                      ) {
                        setCurrentPlayingStoryId(
                          customerStories[currentPlayingStoryIndex + 1].id
                        );
                      }
                      setFavouritedState(false);
                    }}
                    onAllStoriesEnd={() => {
                      navigate(-1);
                    }}
                    stories={
                      uuid === "souq-car"
                        ? stories
                        : customerStories?.map((item: any) => {
                            return {
                              url: item.image,
                              header: {
                                heading: item.customer?.name,
                                subheading: item.created_at,
                                profileImage: item.customer?.personal_image,
                              },
                            };
                          })
                    }
                    width={"30rem"}
                    height={"90dvh"}
                    storyContainerStyles={{
                      borderRadius: "1rem",
                    }}
                    defaultInterval={5000}
                    loop={false}
                    storyStyles={{
                      backgroundColor: "red",
                    }}
                  />
                  <div
                    dir={textDirectionStyle}
                    className="w-full h-[20rem] absolute pt-[40%] px-4"
                    style={{
                      // black to transparent gradient from bottom to up
                      backgroundImage:
                        "linear-gradient(to top, rgba(0,0,0,0.55), rgba(0,0,0,0))",
                      bottom: "0rem",
                      right: "0rem",
                      color: "white",
                      fontSize: "1.05rem",
                      fontWeight: "bold",
                    }}
                  >
                    {uuid === "souq-car" && (
                      <p className="text-justify line-clamp-2">
                        {stories[currentStory]?.caption}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Spin>
          <div className="flex flex-col items-center justify-center mb-[2.8rem] gap-y-9">
            <div className="flex flex-col gap-y-2">
              <ShareButton url={window.location.href}>
                <img
                  alt="share"
                  src={ShareButtonIcon}
                  className="w-[3.18669rem] h-[3.18669rem]"
                />
              </ShareButton>
              {/* <p className="text-white text-[0.9375rem]">
                {t("viewpost_share")}
              </p> */}
            </div>
            {uuid !== "souq-car" && (
              <div className="flex flex-col gap-y-2">
                <div
                  className="w-[3.18669rem] h-[3.18669rem] rounded-full bg-white flex items-center justify-center hover:cursor-pointer"
                  onClick={() => {
                    setFavouritedState(true);
                    favouriteStoryService(currentPlayingStoryId);
                  }}
                >
                  <HeartFilled
                    className={`text-[1.5rem] ${
                      favouritedState ? "text-[red]" : "text-gray-500"
                    }`}
                  />
                </div>
                {/* <p className="text-white text-[0.9375rem]">{t("like")}</p> */}
              </div>
            )}
            {uuid !== "souq-car" &&
              customerStories?.[0]?.customer?.id !== userData?.user?.id && (
                <div className="flex flex-col gap-y-2 items-center">
                  <div
                    className="w-[3.18669rem] h-[3.18669rem] rounded-full bg-white flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      handleSendMessage(customerStories?.[0]?.customer?.id);
                    }}
                  >
                    <MessageOutlined className="text-[1.5rem] text-[var(--new-blue)]" />
                  </div>
                </div>
              )}
            {uuid !== "souq-car" &&
              customerStories?.[0]?.customer?.id !== userData?.user?.id && (
                <div className="flex flex-col gap-y-2 items-center">
                  <a
                    className="w-[3.18669rem] h-[3.18669rem] rounded-full bg-white flex items-center justify-center cursor-pointer"
                    href={`tel:+20${customerStories?.[0]?.customer?.phone}`}
                  >
                    <PhoneFilled className="text-[1.5rem] text-[var(--new-blue)]" />
                  </a>
                </div>
              )}
            {customerStories !== null && (
              <div
                className="w-[3.18669rem] h-[3.18669rem] rounded-full bg-[var(--new-blue)] flex items-center justify-center hover:cursor-pointer"
                onClick={() => {
                  // navigate to vendor profile
                }}
                style={{
                  backgroundImage: `url('${customerStories[0]?.customer?.personal_image}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full">
          <Spin
            spinning={customerStoriesLoading}
            className="w-full flex flex-row items-center justify-center"
            style={{
              width: "100%",
            }}
          >
            {customerStoriesLoading ? (
              <div className="w-full mb-[2.64rem] h-[40rem] bg-gray-500 flex items-center justify-center"></div>
            ) : (
              <div className="w-full flex flex-col items-center gap-y-3">
                <div className="w-full flex flex-row items-center justify-between px-4">
                  <img src={WhiteLogo} />
                  <CloseOutlined
                    className="text-white text-[1.5rem]"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <div className="w-full  flex flex-col items-center justify-center relative">
                  <Stories
                    onPrevious={() => {
                      if (currentStory > 0)
                        setCurrentStory((prevIndex: any) => prevIndex - 1);
                    }}
                    onNext={() => {
                      if (currentStory + 1 < stories.length)
                        setCurrentStory((prevIndex: any) => prevIndex + 1);
                    }}
                    onStoryStart={() => {
                      increaseStoryViewsService(currentPlayingStoryId);
                    }}
                    onStoryEnd={() => {
                      if (currentStory + 1 < stories.length)
                        setCurrentPlayingStoryIndex(
                          (prevIndex) => prevIndex + 1
                        );

                      if (
                        currentPlayingStoryIndex + 1 <
                        customerStories?.length
                      ) {
                        setCurrentPlayingStoryId(
                          customerStories[currentPlayingStoryIndex + 1].id
                        );
                      }
                      setFavouritedState(false);
                    }}
                    onAllStoriesEnd={() => {
                      navigate(-1);
                    }}
                    stories={
                      customerStories?.map((item: any) => {
                        return {
                          url: item.image,
                          header: {
                            heading: item.customer?.name,
                            subheading: item.created_at,
                            profileImage: item.customer?.personal_image,
                          },
                        };
                      }) || stories
                    }
                    width={"100%"}
                    height={"90dvh"}
                    storyContainerStyles={{
                      borderRadius: "1rem",
                    }}
                    defaultInterval={5000}
                    // preventDefault
                    loop={false}
                    storyStyles={{
                      backgroundColor: "red",
                    }}
                  />
                  <div
                    className="px-4 w-full flex flex-row items-center justify-start gap-x-3 absolute bottom-[2rem]"
                    style={{
                      zIndex: 1000,
                    }}
                  >
                    <ShareButton url={window.location.href}>
                      <img
                        alt="share"
                        src={ShareButtonIcon}
                        className="w-[2.5rem] h-[2.5rem]"
                      />
                    </ShareButton>
                    {uuid !== "souq-car" && (
                      <>
                        <div
                          className="bg-white w-[2.5rem] h-[2.5rem] rounded-full flex items-center justify-center"
                          style={{
                            fontSize: "1rem",
                          }}
                          onClick={() => {
                            setFavouritedState(true);
                            favouriteStoryService(currentPlayingStoryId);
                          }}
                        >
                          <HeartFilled
                            className={` ${
                              favouritedState ? "text-[red]" : "text-gray-500"
                            }`}
                          />
                        </div>
                        <div
                          className="bg-white w-[2.5rem] h-[2.5rem] rounded-full flex items-center justify-center"
                          style={{
                            fontSize: "1rem",
                          }}
                          onClick={() => {
                            handleSendMessage(
                              customerStories?.[0]?.customer?.id
                            );
                          }}
                        >
                          <MessageOutlined className="text-[var(--new-blue)]" />
                        </div>
                        <a
                          className="bg-white w-[2.5rem] h-[2.5rem] rounded-full flex items-center justify-center"
                          style={{
                            fontSize: "1rem",
                          }}
                          href={`tel:+20${customerStories?.[0]?.customer?.phone}`}
                        >
                          <PhoneFilled className="text-[var(--new-blue)] rotate-[100deg]" />
                        </a>
                      </>
                    )}
                  </div>
                  <div
                    dir={textDirectionStyle}
                    className="w-full h-[20rem] absolute pt-[40%] px-4"
                    style={{
                      // black to transparent gradient from bottom to up
                      backgroundImage:
                        "linear-gradient(to top, rgba(0,0,0,0.55), rgba(0,0,0,0))",
                      bottom: "0rem",
                      right: "0rem",
                      color: "white",
                      fontSize: "1.05rem",
                      fontWeight: "bold",
                    }}
                  >
                    {uuid === "souq-car" && (
                      <p className="text-justify line-clamp-2">
                        {stories[currentStory]?.caption}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Spin>
        </div>
      )}
      {!isMobile && (
        <div className="absolute top-[2.68rem] right-[4.87rem] flex flex-row items-center justify-center">
          <img
            src={CloseIcon}
            className="hover:cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
            alt="close"
          />
        </div>
      )}
    </div>
  );
};

export default SingleStoryScreen;
