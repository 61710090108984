import * as React from "react";
import type { SVGProps } from "react";
const SvgFooterTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <circle cx={13.697} cy={13.697} r={13.697} fill="#0050FF" />
    <path
      fill="currentColor"
      d="m14.557 12.791 4.645-5.66h-1.1l-4.034 4.915-3.222-4.916H7.13l4.872 7.433L7.13 20.5h1.101l4.26-5.191 3.402 5.19h3.716l-5.053-7.708Zm-1.508 1.838-.494-.74-3.927-5.89h1.69l3.17 4.753.494.74 4.12 6.179H16.41l-3.362-5.042Z"
    />
  </svg>
);
export default SvgFooterTwitter;
