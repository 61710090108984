import
{
    CHANGE_LANGUAGE
}
from "redux/types";

import { changeLanguage } from "lang/languageUtils";

import { Dispatch } from "redux";

export const changeLanguageAction = (lang: 'ar' | 'en') => (dispatch: Dispatch) => {
    dispatch({type: CHANGE_LANGUAGE, payload: lang});
    changeLanguage(lang);
}