// import "components/Carousel/ExhibitorCarousel/exhibitor-carousel.css";
import { useLanguage } from "hooks/useLanguage";
import { useResponsive } from "hooks/useResponsive";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";

interface ExhibitorCarouselProps {
  data: any;
  slidesToShow?: number;
  smallVariant?: boolean;
}

const ExhibitorCarousel: FC<ExhibitorCarouselProps> = ({
  data,
  slidesToShow,
  smallVariant = false,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { currentLanguage } = useLanguage();

  const renderCard = (item: any) => {
    return (
      <SwiperSlide>
        <a
          href={`/${currentLanguage}/vendor/${item?.id}`}
          className="w-full flex items-center justify-center"
        >
          <div
            style={{
              width: smallVariant || isMobile ? "3.25rem" : "4.6725rem",
              height: smallVariant || isMobile ? "3.25rem" : "4.6725rem",
              borderRadius: "var(--radius-xs)",
              backgroundImage: `url('${encodeURI(item?.image)}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              alignSelf: "center",
            }}
          />
        </a>
      </SwiperSlide>
    );
  };

  return (
    <Swiper
      modules={[Autoplay]}
      className="w-full"
      slidesPerView={slidesToShow ?? (isMobile ? 5 : 9)}
      speed={500}
      spaceBetween={20}
      autoplay={{
        delay: 3000, // delay between transitions in ms
        disableOnInteraction: false, // autoplay will not be disabled after user interactions
      }}
    >
      {data?.map((item: any, idx: any) => {
        return renderCard(item);
      })}
    </Swiper>
  );
};

export default ExhibitorCarousel;
