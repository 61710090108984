import
{
    TRANSMISSION_TYPES_LOADING,
    TRANSMISSION_TYPES_SUCCESS,
    TRANSMISSION_TYPES_FAILURE
}
from "redux/types";

const initialState = {
    transmissionTypesLoading: false,
    transmissionTypes: null,
    errors: null
}

export const listTransmissionTypesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TRANSMISSION_TYPES_LOADING :
            return {...state, transmissionTypesLoading: action.payload};
        case TRANSMISSION_TYPES_SUCCESS :
            return {...state, transmissionTypes: action.payload.data};
        case TRANSMISSION_TYPES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}