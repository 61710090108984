import
{
    FEATURES_LOADING,
    FEATURES_SUCCESS,
    FEATURES_FAILURE
}
from "redux/types";

import { listTableDataService } from "services/listTableDataService";

import { Dispatch } from "redux";

export const listFeaturesAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: FEATURES_LOADING, payload: true});
        const response = await listTableDataService('features');
        dispatch({type: FEATURES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: FEATURES_FAILURE, payload: err});
    } finally {
        dispatch({type: FEATURES_LOADING, payload: false});
    }
}