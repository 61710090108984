import {
  GET_USER_DATA_LOADING,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  UPDATE_CUSTOMER_DATA,
  VERIFY_OTP_SUCCESS,
} from "redux/types";

import { Dispatch } from "redux";

import { getUserDataService } from "services/getUserDataService";
import store from "redux/store";

export const getUserDataAction = () => async (dispatch: Dispatch) => {
  try {
    const response = await getUserDataService();
    dispatch({
      type: UPDATE_CUSTOMER_DATA,
      payload: {
        token: store.getState()?.verifyOtpReducer?.userData?.token,
        user: response.data?.data,
        is_exhibitor:
          response.data?.data.is_exhibitor,
      },
    });
  } catch (err) {
    dispatch({ type: GET_USER_DATA_FAILURE, payload: err });
  } finally {
    dispatch({ type: GET_USER_DATA_LOADING, payload: false });
  }
};
