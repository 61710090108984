import
{
    POST_STATUS_LOADING,
    POST_STATUS_SUCCESS,
    POST_STATUS_FAILURE
}
from "redux/types";

const initialState = {
    postStatusLoading: false,
    postStatus: null,
    errors: null
}

export const listPostStatusReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case POST_STATUS_LOADING :
            return {...state, postStatusLoading: action.payload};
        case POST_STATUS_SUCCESS :
            return {...state, postStatus: action.payload.data};
        case POST_STATUS_FAILURE :
            return {...state, errosr: action.payload};
        default:
            return state;
    }
}