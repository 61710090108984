import api from "api";
import { reactivatePostEndPoint } from "api/apiUrls";
import store from "redux/store";

export const reactivatePostService = async (postId: any) => {
  const response = await api.post(
    reactivatePostEndPoint(postId),
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          store.getState().verifyOtpReducer?.userData?.token
        }`,
      },
    }
  );
  return response;
};
