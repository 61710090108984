import
{
    Articles_TYPES_LOADING,
    Articles_TYPES_SUCCESS,
    Articles_TYPES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listTableDataService } from "services/listTableDataService";

export const listArticlesAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: Articles_TYPES_LOADING, payload: true});
        const response = await listTableDataService('articlesList');
        dispatch({type: Articles_TYPES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: Articles_TYPES_FAILURE, payload: err});
    } finally {
        dispatch({type: Articles_TYPES_LOADING, payload: false});
    }
}