import api from "api";
import { updateCustomerDataEndPoint } from "api/apiUrls";
import store from "redux/store";

export const updateCustomerDataService = async (name: string, email: string, personal_image: any) => {
    const response = await api.post(
        updateCustomerDataEndPoint(),
        {
            name: name,
            email: email,
            personal_image: personal_image,
        },
        {
            headers: {
                'Content-Type' : 'multipart/form-data',
                'Authorization' : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );
    return response;
}