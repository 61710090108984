import * as React from "react";
import type { SVGProps } from "react";
const SvgFooterFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M24 11.83a11.782 11.782 0 0 1-2.863 7.686 11.968 11.968 0 0 1-7.21 4.003v-8.246H16.7l.528-3.411h-3.301V9.648a1.693 1.693 0 0 1 .524-1.385 1.723 1.723 0 0 1 1.417-.458h1.5V4.901a18.442 18.442 0 0 0-2.67-.23 4.232 4.232 0 0 0-3.329 1.252 4.13 4.13 0 0 0-1.161 3.338v2.6H7.186v3.412h3.022v8.246a11.976 11.976 0 0 1-6.062-2.839A11.819 11.819 0 0 1 .584 15.05a11.735 11.735 0 0 1 .063-6.647 11.823 11.823 0 0 1 3.667-5.564A11.98 11.98 0 0 1 10.43.112c2.259-.31 4.56.027 6.632.974a11.903 11.903 0 0 1 5.055 4.365A11.76 11.76 0 0 1 24 11.83Z"
    />
  </svg>
);
export default SvgFooterFacebook;
