import AppRoutes from "Routes/AppRoutes";
import "App.css";
import { HelmetProvider } from "react-helmet-async";
import ErrorBoundary from "components/V3/ErrorBoundary/ErrorBoundary";

const helmetProvider = {};

function App() {
  return (
    <div className="App">
      <HelmetProvider context={helmetProvider}>
        <AppRoutes />
      </HelmetProvider>
    </div>
  );
}

export default App;
