import
{
    DISPLAY_MOBILE_LOADING,
    DISPLAY_MOBILE_SUCCESS,
    DISPLAY_MOBILE_FAILURE
}
from "redux/types";

const initialState = {
    displayMobileLoading: false,
    displayMobileSuccess: null,
    errors: null
}

export const displayMobileReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DISPLAY_MOBILE_LOADING:
            return {...state, displayMobileLoading: action.payload};
        case DISPLAY_MOBILE_SUCCESS:
            return {...state, displayMobileSuccess: action.payload.success};
        case DISPLAY_MOBILE_FAILURE:
            return {...state, errors: action.payload};
        default:
            return state;
    }
}