import
{
    GET_USER_DATA_LOADING,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILURE
}
from "redux/types";

const initalState = {
    getUserDataLoading: false,
    userData: null,
    errors: null
}

export const getUserDataReducer =  (state = initalState, action: any) => {
    switch (action.type) {
        case GET_USER_DATA_LOADING :
            return {...state, getUserDataLoading: action.payload};
        case GET_USER_DATA_SUCCESS :
            return {...state, userData : action.payload.data};
        case GET_USER_DATA_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}