import
{
    SPARE_PART_CONDITION_LOADING,
    SPARE_PART_CONDITION_SUCCESS,
    SPARE_PART_CONDITION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSparePartConditionService } from "services/listSparePartConditionService";

export const listSparePartConditionAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: SPARE_PART_CONDITION_LOADING, payload: true});
        const response = await listSparePartConditionService();
        dispatch({type: SPARE_PART_CONDITION_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: SPARE_PART_CONDITION_FAILURE, payload: err});
    } finally {
        dispatch({type: SPARE_PART_CONDITION_LOADING, payload: false});
    }
}