import styles from "components/ModalComponents/DeletePostModal/DeletePostModal.module.css";
import { useTranslation } from "react-i18next";
import { RootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";

interface DeletePostModalProps {
  // dir: any;
  // isArabic: boolean;
  open: boolean;
  setOpen: any;
  suspendClick: any;
}

const DeletePostModal: FC<DeletePostModalProps> = ({open, setOpen, suspendClick}) => {
  // const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [reportMessage, setReportMessage] = useState("");
  const { t } = useTranslation();

  // const showModal = () => {
  //   setOpen(true);
  // };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      suspendClick();
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setTimeout(() => {
      setOpen(false);
    }, 250);
  };

  return (
    <>
      <Modal
          open={open}
          onOk={handleOk}
          okText={t("confirm")}
          cancelText={t("cancel")}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          closable={false}
        >
          <div className={`${styles.containerRow}`}>
            <p
              className={`w-full font-bold text-right`}
              style={{fontFamily:"rbFontBold", fontSize: "1.25rem"}}
            >
              {t("delete_title")}
            </p>

            <p className={`w-full font-bold text-right`}>{t("delete_desc")}</p>

          </div>
        </Modal>
    </>
  );
};

export default DeletePostModal;
