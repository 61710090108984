import axios from "axios";

export const amanleekAuthService = async () => {
    const response = await axios.post(
        "https://develop.amanleek.com/wp-json/v3/access_token",
        {
            "client_id": process.env.REACT_APP_AMANLEEK_CLIENT_ID,
            "client_secret" : process.env.REACT_APP_AMANLEEK_CLIENT_SECRET
        },
        {
            headers: {
                "Content-Type" : "application/json",
                "Accept" : "application/json"
            }
        }
    );

    return response;
}