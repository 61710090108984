import { CopyOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Tooltip, notification } from "antd";
import styles from "components/V3/ShareButton/ShareButton.module.css";
import { t } from "i18next";
import { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface ShareButtonProps {
  url: string;
  className?: string;
  width?: number;
  style?: any;
  children?: any;
  copyURL?: string;
  fontSize?: any;
  px?: any;
  py?: any;
  showText?: boolean;
}

const ShareButton: FC<ShareButtonProps> = ({
  url,
  copyURL,
  className,
  fontSize = "",
  style,
  width = 3,
  px = "",
  py = "",
  children,
  showText = true,
}) => {
  const currentLanguage = useSelector(
    (state: any) => state.changeLanguageReducer.currentLanguage
  );
  const dirFlip = currentLanguage === "en" ? "rtl" : "ltr";
  const [copySuccess, setCopySuccess] = useState("");
  const [copyText, setCopyText] = useState("Copy");
  const textAreaRef = useRef(null);

  return (
    <Tooltip
      className={`${className} border-round-s`}
      color="white"
      style={{ padding: 10 }}
      title={
        <div className="flex flex-col items-center gap-y-2">
          <div className="w-full flex flex-row gap-x-2 items-center">
            <FacebookShareButton className="flex items-center gap-2" url={url}>
              <div className="overflow-hidden flex items-start justify-start w-fit rounded-full">
                <FacebookIcon size={30} />
              </div>
              <p className="text-black">Facebook</p>
            </FacebookShareButton>
          </div>
          <div className="w-full flex flex-row gap-x-2 items-center">
            <WhatsappShareButton className="flex items-center gap-2" url={url}>
              <div className="overflow-hidden flex items-start justify-start w-fit rounded-full">
                <WhatsappIcon size={30} />
              </div>
              <p className="text-black">Whatsapp</p>
            </WhatsappShareButton>
          </div>
          <CopyToClipboard text={copyURL ?? url}>
            <div
              className="w-full flex flex-row gap-x-2 items-center"
              onClick={() => {
                if (copyText === "Copy") {
                  setCopyText("Copied!");
                }
              }}
            >
              <div
                className="flex items-center justify-center"
                style={{ width: "30px", height: "30px" }}
              >
                <CopyOutlined className="text-black" />
              </div>
              <button style={{ color: "black", fontFamily: "rbFont" }}>
                {copyText}
              </button>
            </div>
          </CopyToClipboard>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export default ShareButton;
