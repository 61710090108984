import
{
    DELETE_STORY_LOADING, 
    DELETE_STORY_SUCCESS,
    DELETE_STORY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { deleteStoryService } from "services/deleteStoryService";

export const deleteStoryAction = (storyId: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: DELETE_STORY_LOADING, payload: true});
        const response = await deleteStoryService(storyId);
        dispatch({type: DELETE_STORY_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: DELETE_STORY_FAILURE, payload: err});
    } finally {
        dispatch({type: DELETE_STORY_LOADING, payload: false});
    }
}