import api from "api";
import { createInsuranceEntry } from "api/apiUrls";
import store from "redux/store";

export const createInsuranceEntryService = async (data: any) => {
    const response = api.post(
        createInsuranceEntry(),
        data,
        {
            headers: {
                "Content-Type" : "application/json",
                "Authorization" : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );

    return response;
}