import styles from "components/V3/CreatePostForms/CommonFormSections/PostDetails/PostDetails.module.css";
import CustomImageContainer from "components/CustomImageContainer/CustomImageContainer";
import { useLanguage } from "hooks/useLanguage";
import { useUtilities } from "hooks/useUtilities";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  CheckOutlined,
  ArrowRightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useForm } from "hooks/useForm";
import FormButtons from "../components/FormButtons";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import CustomMap from "components/CustomMap/Map/Map";
import { useResponsive } from "hooks/useResponsive";
import TextArea from "antd/es/input/TextArea";
import { Checkbox, CheckboxProps, Input, Select, Spin } from "antd";
import { useDropdowns } from "hooks/useDropdowns";
import { motion } from "framer-motion";
import { useIsDefined } from "hooks/useIsDefined";

interface PostDetailsProps {
  formik: any;
}

const PostDetails: FC<PostDetailsProps> = ({ formik }) => {
  const { citiesDropdown } = useDropdowns();
  const { t } = useUtilities();
  const { isMobile } = useResponsive();
  const { isArabic } = useLanguage();
  const dir = isArabic ? "rtl" : "ltr";
  const dirFlip = !isArabic ? "rtl" : "ltr";
  const { textDirectionStyle } = useLanguage();
  const [selected, setSelected] = useState({ lat: 30.11525, lng: 31.52652 });
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const { isDefined } = useIsDefined();

  // CHECKBOX LOGIC
  const [checked, setChecked] = useState(
    !isDefined(formik.values.phone_checked)
      ? true
      : isDefined(formik.values.phone_checked) && formik.values.phone_checked
  );

  const onChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("phone", undefined);
    }
    formik.setFieldValue("phone_checked", e.target.checked);

    setChecked(e.target.checked);
  };

  const label = t("phone_checkbox");

  // END CHECKBOX LOGIC

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      dir={dirFlip}
      className="w-full flex flex-col items-center"
    >
      <p className="mb-10">عاش ، دلوقتي ضيف بيانات الأعلان</p>
      <div className={`${styles.containerRow} mb-6`}>
        <p className="text-right font-bold">: {t("post_title")}</p>
        <>
          <Input
            placeholder={t("title_hint")}
            size="large"
            className="w-full"
            defaultValue={formik.values.title}
            name="title"
            onChange={formik.handleChange}
            status={formik.errors.title ? "error" : ""}
            dir={dir}
          />
          {formik.errors.title && (
            <p className="text-[red]">{t("please_provide_this_field")}</p>
          )}
        </>
      </div>
      <div className={`${styles.containerRow} mb-6`}>
        <p className="text-right font-bold">: {t("post_description")}</p>
        <>
          <TextArea
            rows={6}
            placeholder={t("post_description_hint")}
            defaultValue={formik.values.description}
            // className="text-right"
            name="description"
            onChange={formik.handleChange}
            status={formik.errors.description ? "error" : ""}
            dir={dir}
          />
          {formik.errors.description && (
            <p className="text-[red]">{t("please_provide_this_field")}</p>
          )}
        </>
      </div>

      {/*  CITY AND MAP */}
      <div className={`${styles.containerRow} mb-6`}>
        <p className="text-right font-bold">: {t("homepage_governate")}</p>
        {citiesLoading ? (
          <Spin />
        ) : (
          <>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder={t("homepage_governate")}
              defaultValue={formik.values.city_id}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={citiesDropdown}
              onSelect={(value: any) => {
                formik.setFieldValue("city_id", value);
              }}
              status={formik.errors.city_id ? "error" : ""}
              size="large"
              className="w-full"
              style={{ direction: dir }}
              dropdownStyle={{ direction: dir }}
            />
            {formik.errors.city_id && (
              <p className="text-[red]">{t("please_provide_this_field")}</p>
            )}
          </>
        )}
      </div>
      <div dir={textDirectionStyle} className={`${styles.containerRow} mb-6`}>
        {/* <p className="text-right font-bold">: {t("homepage_governate")}</p> */}
        <Checkbox checked={checked} onChange={onChange}>
          <p>{label}</p>
        </Checkbox>
        <>
          <Input
            placeholder={t("phone_placeholder")}
            size="large"
            className={`w-full`}
            style={{
              opacity: checked ? 0.5 : 1,
              // pointerEvents: checked ? "none" : "auto",
            }}
            name="phone"
            disabled={checked}
            value={formik.values.phone}
            defaultValue={formik.values.phone}
            onChange={formik.handleChange}
            status={formik.errors.phone ? "error" : ""}
            dir={dir}
          />
          {formik.errors.phone && (
            <p className="text-[red]">{t("please_provide_this_field")}</p>
          )}
        </>
      </div>
      <div className={`${styles.containerRow} mb-6`}>
        <p className="text-right font-bold">: {t("inspection_location")}</p>
        <div className={`${styles.containerRow} mb-6`}>
          <>
            <Input
              placeholder={t("address_hint")}
              size="large"
              className="w-full"
              name="address"
              defaultValue={formik.values.address}
              onChange={formik.handleChange}
              status={formik.errors.address ? "error" : ""}
              dir={dir}
            />
            {formik.errors.address && (
              <p className="text-[red]">{t("please_provide_this_field")}</p>
            )}
          </>
        </div>
        <div className={`w-full flex items-center justify-center `}>
          <CustomMap
            width={"100%"}
            height={"400px"}
            selectWidth={isMobile ? "150px" : "300px"}
            top={isMobile ? "60px" : "10px"}
            left={isMobile ? "28%" : "62%"}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      </div>
      <FormButtons
        formik={formik}
        includedFields={[
          "title",
          "description",
          "city_id",
          "address",
          // "lat",
          // "lng",
        ]}
      />
    </motion.div>
  );
};

export default PostDetails;
