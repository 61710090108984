import api from "api";
import { displayMobileEndPoint } from "api/apiUrls";
import store from "redux/store";

export const displayMobileService = async (show: boolean) => {
    const response = await api.post(
        displayMobileEndPoint(show),
        {},
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().verifyOtpReducer?.userData?.token}`
            }
        }
    );
    return response;
}