import styles from "screens/V3/ContactUs/ContactUs.module.css";
import { useTranslation } from "react-i18next";
import { Input, Select, Spin, notification } from "antd";
import { useFormik } from "formik";
import SendAMessageModel from "models/SendAMessageModel";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { sendMessageAction } from "redux/actions/SendMessageAction/sendMessageAction";
import { RootState } from "redux/rootReducer";
import { CustomButton, Footer, Seo } from "components";
import { useResponsive } from "hooks/useResponsive";
import { BlackDownArrow } from "assets/IconComponents";

const ContactUs = () => {
  const formik = useFormik({
    initialValues: {
      post_title: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      post_title: Yup.string().required(),
      email: Yup.string().email().required(),
      message: Yup.string().required(),
    }),
    onSubmit: async (values: SendAMessageModel) => {
      await dispatch(
        sendMessageAction(values.post_title, values.email, values.message)
      );
      if (success === true) {
        notification.success({
          message: t("success_notification_message"),
          description: t("success_notification_description"),
        });
      }
    },
  });

  const { isMobile } = useResponsive();

  const dispatch: any = useDispatch();

  const { success, loading } = useSelector(
    (state: RootState) => state.sendMessageReducer
  );

  const { t } = useTranslation();

  const { TextArea } = Input;

  return (
    <div className="mt-[2.94rem] w-full flex flex-col items-center gap-y-5">
      <Seo
        title="سوق كار - تواصل معنا"
        description="تواصل معنا"
        keywords="سوق السيارات,سيارات,شراء عربيات,مدينة نصر، مصر,هيونداي,بيع سيارات مستعملة,عربيات للبيع,شراء سيارات,سيارات مستعملة للبيع,souq.car,souq car,سوق دوت كار,سوق كار"
      />
      <h1 className={`text-[1.25rem] text-[var(--new-blue)] font-[700]`}>
        {t("leave_us_a_message")}
      </h1>
      <div
        className={`${
          isMobile ? "w-[95%]" : "w-[38.0625rem]"
        } h-[40rem] py-3 flex flex-col items-center rounded-[1.25rem] ${
          styles.shadow
        } mb-2`}
      >
        <form
          className="w-full h-full flex flex-col items-center gap-y-6"
          onSubmit={formik.handleSubmit}
        >
          <div dir="rtl" className="mt-4 w-[85%] flex flex-col gap-y-2">
            <p>{t("name")}*</p>
            <Input
              placeholder={t("name")}
              className={`w-full`}
              name="name"
              size="large"
            />
          </div>
          <div dir="rtl" className="w-[85%] flex flex-col gap-y-2">
            <p>{t("type")}*</p>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder={t("type")}
              suffixIcon={<BlackDownArrow />}
              // value={carBodyFilter}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "Suggestion",
                  label: "أقتراح",
                },
                {
                  value: "Complaint",
                  label: "شكوي"
                }
              ]}
              onSelect={(value: any) => {
                formik.setFieldValue("post_title", value);
              }}
              size="large"
              style={{ direction: "rtl" }}
              dropdownStyle={{
                direction: "rtl",
                zIndex: 9999999,
              }}
              className={`${styles.searchField} w-full`}
            />
            {/* <Input
              placeholder={t("type")}
              className={`w-full`}
              value={formik.values.post_title}
              onChange={formik.handleChange}
              name="post_title"
              status={formik.errors.post_title ? "error" : ""}
              size="large"
            /> */}
          </div>
          <div dir="rtl" className="w-[85%] flex flex-col gap-y-2">
            <p>{t("email")}*</p>
            <Input
              placeholder={t("email")}
              className={`w-full`}
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              status={formik.errors.email ? "error" : ""}
              size="large"
            />
          </div>
          <div dir="rtl" className="w-[85%] flex flex-col gap-y-2">
            <p>{t("write_a_message")}*</p>
            <TextArea
              rows={6}
              className={`w-full`}
              placeholder={t("write_a_message")}
              value={formik.values.message}
              onChange={formik.handleChange}
              name="message"
              status={formik.errors.message ? "error" : ""}
              style={{ height: "100%", resize: "none" }}
            />
          </div>
          <Spin className="w-full" spinning={loading}>
            <CustomButton
              className={`p-3 ${
                isMobile ? "rounded-[0.9375rem]" : "rounded-[1.25rem]"
              } text-white ${isMobile ? "w-[12.125rem]" : "w-[32.6875rem]"} ${
                isMobile ? "h-[2.5625rem]" : "h-[3.75rem]"
              }`}
              color={"var(--new-blue)"}
              type={"submit"}
            >
              {t("viewpost_send")}
            </CustomButton>
          </Spin>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
