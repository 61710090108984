import
{
    ABOUT_US_LOADING,
    ABOUT_US_SUCCESS,
    ABOUT_US_FAILURE
}
from "redux/types";

const initialState = {
    aboutUsLoading: false,
    aboutUs: null,
    errors: null
}

export const aboutUsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ABOUT_US_LOADING :
            return {...state, aboutUsLoading: action.payload};
        case ABOUT_US_SUCCESS :
            return {...state, aboutUs: action.payload.data};
        case ABOUT_US_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}