import
{
    VIEW_PROFILE_POSTS_LOADING,
    VIEW_PROFILE_POSTS_SUCCESS,
    VIEW_PORFILE_POSTS_FAILURE
}
from "redux/types";

const initialState = {
    profilePostsLoading: false,
    profilePosts: null,
    pendingCount: null,
    activeCount: null,
    inactiveCount: null,
    pending: null,
    active: null,
    inactive: null,
    errors: null,
}

export const listProfilePostsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case VIEW_PROFILE_POSTS_LOADING :
            return {...state, profilePostsLoading: action.payload};
        case VIEW_PROFILE_POSTS_SUCCESS :
            return {...state, profilePosts: action.payload.data, pendingCount: action.payload.pendingPostsCount, activeCount: action.payload.activePostsCount, inactiveCount: action.payload.inactivePostsCount ,pending: action.payload.pending, active: action.payload.active, inactive: action.payload.inactive};
        case VIEW_PORFILE_POSTS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}