import api from "api";
import { increaseStoryViewsEndPoint } from "api/apiUrls";

export const increaseStoryViewsService = async (id: any) => {
    const response = await api.post(
        increaseStoryViewsEndPoint(id),
        {}
    );

    return response;
}