import
{
    LIST_PAYMENT_TYPES_LOADING,
    LIST_PAYMENT_TYPES_SUCCESS,
    LIST_PAYMENT_TYPES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listTableDataService } from "services/listTableDataService";

export const listPaymentTypesAction = 
    () => async (dispatch: Dispatch) => {
        try {
            dispatch({type: LIST_PAYMENT_TYPES_LOADING, payload: true});
            const response = await listTableDataService('paymentTypeList');
            dispatch({type: LIST_PAYMENT_TYPES_SUCCESS, payload: response.data});
        } catch (err) {
            dispatch({type: LIST_PAYMENT_TYPES_FAILURE, payload: err});
        } finally {
            dispatch({type: LIST_PAYMENT_TYPES_LOADING, payload: false});
        }
    }