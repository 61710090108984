import {
  LIST_STORIES_LOADING,
  LIST_STORIES_SUCCESS,
  LIST_STORIES_FAILURE,
} from "redux/types";

const initialState = {
  storiesLoading: false,
  currentPage: null,
  lastPage: null,
  stories: null,
  errors: null,
};

export const listStoriesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LIST_STORIES_LOADING:
      return { ...state, storiesLoading: action.payload };
    case LIST_STORIES_SUCCESS:
      return {
        ...state,
        stories: action.payload.data.data,
        currentPage: action.payload.data.current_page,
        lastPage: action.payload.data.last_page,
      };
    case LIST_STORIES_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
