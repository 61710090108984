import 
{
    LIST_STORIES_LOADING,
    LIST_STORIES_SUCCESS,
    LIST_STORIES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listTableDataService } from "services/listTableDataService";

export const listStoriesAction = (page: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_STORIES_LOADING, payload: true});
        const response = await listTableDataService(`getStories?page=${page}`);
        dispatch({type: LIST_STORIES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_STORIES_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_STORIES_LOADING, payload: false});
    }
}