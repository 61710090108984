import api from "api";
import { getStoreItemsEndPoint } from "api/apiUrls";
import store from "redux/store";

export const getStoreItemsService = async () => {
  const response = await api.get(getStoreItemsEndPoint(), {
    headers: {
      Authorization: `Bearer ${
        store.getState().verifyOtpReducer?.userData?.token
      }`,
    },
  });
  return response;
};
