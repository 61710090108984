import 
{
    LIST_ENGINE_CAPACITY_LOADING,
    LIST_ENGINE_CAPACITY_SUCCESS,
    LIST_ENGINE_CAPACITY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listTableDataService } from "services/listTableDataService";

export const listEngineCapacityAction = 
    () => async (dispatch: Dispatch) => {
        try {
            dispatch({type: LIST_ENGINE_CAPACITY_LOADING, payload: true});
            const response = await listTableDataService('engineCapacityList');
            dispatch({type: LIST_ENGINE_CAPACITY_SUCCESS, payload: response.data});
        } catch (err) {
            dispatch({type: LIST_ENGINE_CAPACITY_FAILURE, payload: err});
        } finally {
            dispatch({type: LIST_ENGINE_CAPACITY_LOADING, payload: false});
        }
    }